import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  bold: {
    fontWeight: 'bold',
  },
  border: {
    border: '1px solid black',
    '& td': {
      border: '1px solid black',
    },
  },
}));
