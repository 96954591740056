import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../../../globals/color-properties';

export default makeStyles()((theme) => ({
  dialog: {
    width: theme.spacing(69),
    height: theme.spacing(69.5),
  },
  inputLine: {
    borderBottom: CAT_COLOR.WHITE,
    marginLeft: theme.spacing(1.25),
    width: '95%',
    marginBottom: theme.spacing(5),
  },
  selectReasonText: {
    fontSize: '14px',
    lineHeight: theme.spacing(2.375),
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
    paddingBottom: theme.spacing(1),
  },
  iconPosition: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  actionButtons: {
    padding: theme.spacing(1.75),
  },
  dialogContentPlacement: {
    display: 'flex',
    flexDirection: 'column',
  },
  headingText: {
    fontWeight: 700,
    fontSize: '20px',
  },
  completedIcon: {
    color: CAT_COLOR.BRIGHT_GOLD,
  },
  radioButtonAlignment: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(-1.125),
  },
  warningText: {
    color: CAT_COLOR.PERSIAN_RED,
    marginTop: theme.spacing(0.5),
    fontWeight: 400,
    fontSize: '12px',
  },
}));
