import { UsersSearchResponse, UsersSearch } from '../../entities/user-management-v1/models';

export enum userManagementActionNames {
  CLEAR_USER_MANAGEMENT = 'CLEAR_USER_MANAGEMENT',
  SET_LOADING_FOR_USER_MANAGEMENT = 'SET_LOADING_FOR_USER_MANAGEMENT',
  SET_USER_SEARCH_ERROR = 'SET_USER_SEARCH_ERROR',
  SET_USERS = 'SET_USERS',
  CLEAR_USERS = 'CLEAR_USERS',
  SET_NO_USER_FOUND_FLG = 'SET_NO_USER_FOUND_FLG',
  SET_ADMIN_USER = 'SET_ADMIN_USER',
  SET_UPDATED_USER = 'SET_UPDATED_USER',
  SET_UPDATE_USER_ERROR = 'SET_UPDATE_USER_ERROR',
}

export type ErrorType = {
  code: number;
  message: string;
};

export interface UserManagementState {
  users?: UsersSearchResponse;
  isLoadingUserManagement?: boolean;
  searchUserError?: ErrorType;
  noUsersFound: boolean;
  selectedAdmin?: UsersSearch;
  updatedAdmin?: UsersSearch;
  updateUserError?: ErrorType;
}

interface ClearUsers {
  type: typeof userManagementActionNames.CLEAR_USERS;
}

interface SetUsers {
  type: typeof userManagementActionNames.SET_USERS;
  payload: UsersSearchResponse;
}

interface SetSearchUserError {
  type: typeof userManagementActionNames.SET_USER_SEARCH_ERROR;
  payload: ErrorType;
}

interface SetUpdateUserError {
  type: typeof userManagementActionNames.SET_UPDATE_USER_ERROR;
  payload: ErrorType;
}

interface SetAdminUser {
  type: typeof userManagementActionNames.SET_ADMIN_USER;
  payload: UsersSearch;
}
interface SetNoUserFoundFlag {
  type: typeof userManagementActionNames.SET_NO_USER_FOUND_FLG;
  payload: boolean;
}

interface SetUserManagementIsLoading {
  type: typeof userManagementActionNames.SET_LOADING_FOR_USER_MANAGEMENT;
  payload: boolean;
}

interface SetUpdatedUser {
  type: typeof userManagementActionNames.SET_UPDATED_USER;
  payload: UsersSearch;
}

export type UserManagementActionTypes =
  | SetUsers
  | ClearUsers
  | SetUserManagementIsLoading
  | SetSearchUserError
  | SetNoUserFoundFlag
  | SetAdminUser
  | SetUpdatedUser
  | SetUpdateUserError;
