import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../../../globals/color-properties';

export default makeStyles()((theme) => ({
  user: {
    display: 'flex',
    flexDirection: 'column',
    position: 'static',
    width: theme.spacing(44),
    height: 'auto',
    background: CAT_COLOR.WHITE,
    boxShadow: '0px 15px 40px rgba(67, 72, 92, 0.2)',
  },
  title: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    padding: '13px',
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
    fontWeight: 600,
    fontSize: '19px',
    lineHeight: '28px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '-0.03em',
  },
  selectedSection: {
    cursor: 'pointer',
    width: theme.spacing(44),
    background: CAT_COLOR.WHITE_SMOKE,
  },
  selected: {
    paddingLeft: theme.spacing(2),
    width: theme.spacing(20),
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
  },
  selectedName: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    display: 'flex',
    width: theme.spacing(44),
    fontSize: '16px',
    color: CAT_COLOR.MULLED_WINE,
  },
  primaryAccount: {
    cursor: 'pointer',
    width: theme.spacing(44),
  },
  primary: {
    paddingLeft: theme.spacing(2),
    addingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: theme.spacing(20),
    fontWeight: 800,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    color: CAT_COLOR.LIGHT_SLATE_GREY,
  },
  primaryName: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: theme.spacing(44),
    height: 'auto',
    fontSize: '16px',
    lineHeight: '20px',
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
  },
}));
