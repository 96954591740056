import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const ProfileImage = (props: iconType) => {
  return (
    <SvgIcon
      {...props}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14.5" cy="14.5" r="12.5" fill="white" />
      <path
        d="M11.7391 17.3913C11.7391 17.3913 10.0435 16.8261 10.0435 14C9.10522 14 9.10522 11.7391 10.0435 11.7391C10.0435 11.3661 8.34783 7.21739 11.1739 7.78261C11.7391 5.52174 17.9565 5.52174 18.5217 7.78261C18.9174 9.35391 17.9565 11.4565 17.9565 11.7391C18.8948 11.7391 18.8948 14 17.9565 14C17.9565 16.8261 16.2609 17.3913 16.2609 17.3913V20.2174C19.0643 21.2687 21.8339 22.1391 23.2583 23.1339C25.5757 20.7826 27 17.5609 27 14C27 6.82174 21.1783 1 14 1C6.82174 1 1 6.82174 1 14C1 17.5609 2.43565 20.7939 4.76435 23.1452C6.26783 22.1052 9.22956 21.167 11.7391 20.2174V17.3913Z"
        fill="white"
        stroke="#707175"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M4.76465 23.1453C7.12726 25.5306 10.3942 27.0002 14.0003 27.0002C17.6177 27.0002 20.896 25.5193 23.2586 23.134"
        fill="white"
      />
      <path
        d="M4.76465 23.1453C7.12726 25.5306 10.3942 27.0002 14.0003 27.0002C17.6177 27.0002 20.896 25.5193 23.2586 23.134"
        stroke="#707175"
        strokeMiterlimit="10"
        strokeLinejoin="round"
        fill="white"
      />
    </SvgIcon>
  );
};

export default ProfileImage;
