import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  container: {
    backgroundColor: CAT_COLOR.LIGHT_GRAY,
    borderRadius: '4px',
    textAlign: 'center',
    padding: theme.spacing(2),
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
  },
  fillContainer: {
    height: '100%',
    backgroundColor: CAT_COLOR.LIGHT_GRAY,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  iconContainer: {
    paddingBottom: theme.spacing(0.5),
  },
}));
