import React from 'react';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type ScrollModalProperties = {
  title: string;
  bodyText: Function;
  mainMenuStyle?: boolean;
};
export default function ScrollModal(properties: ScrollModalProperties) {
  const { title, bodyText, mainMenuStyle } = properties;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  const theme = useTheme();
  const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementReference = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementReference;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div data-testid="scroll-modal-component">
      <Button
        data-testid="scroll-modal-open-button"
        onClick={handleClickOpen('paper')}
        style={
          mainMenuStyle ? { fontWeight: 600, padding: theme.spacing(0, 1), color:'#3F3F3F' } : { fontWeight: 400 }
        }
      >
        {title}
      </Button>
      <Dialog open={open} onClose={handleClose} scroll={scroll}>
        <DialogTitle data-testid="scroll-modal-title-dialog">{title}</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            test-dataid="scroll-modal-description"
            ref={descriptionElementReference}
            tabIndex={-1}
          >
            {bodyText()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="scroll-modal-close-button"
            onClick={handleClose}
            variant="contained"
            color="secondary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
