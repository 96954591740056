import { makeStyles } from 'tss-react/mui';

const letterSpacing = '0.5px';
const globalUseStyles = makeStyles()((theme: any) => ({
  dialogTitle: {
    fontFamily: 'NotoSans, Helvetica, Arial, sans-serif',
    fontSize: '20px',
    lineHeight: '32px',
    fontWeight: 700,
    letterSpacing,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  dynamicDialog: {
    height: 'fit-content',
    minHeight: '668px',
  },
  mediumWidth: {
    maxWidth: '820px',
  },
}));
export default globalUseStyles;

export const emptyClassName = 'emptyClassName';
