/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from 'react';
import { Box, Checkbox, TextField, Autocomplete, Popper, Link, PopperProps } from '@mui/material';
import useStyles from './styles';
import CheckedIcon from '../../assets/icons/checked-icon';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import TextWithToolTip from '../text-with-tooltip';
import { nameIdPair, nameIdPairArray } from '../../globals/generic-types';
import { selectAllID } from '../../pages/invitations/components/invitations-drawer/types';
import CAT_COLOR from '../../globals/color-properties';

const TaglessMultiselect = (properties: {
  checkSelectAll?: boolean;
  displayClearAll?: boolean;
  noOptionsText?: string;
  options: nameIdPairArray;
  placeholderText?: string;
  rowSubtitle?: boolean;
  showSelectAllOption?: boolean;
  setValue: Function;
  value: nameIdPairArray;
  label?: string;
}) => {
  const {
    checkSelectAll,
    displayClearAll,
    noOptionsText,
    options,
    placeholderText,
    rowSubtitle = false,
    setValue,
    value,
    label,
  } = properties;
  const { classes } = useStyles();
  const [inputString, setInputString] = useState<string>('');
  const checkedIcon = <CheckedIcon width="16" height="16" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

  const popperComponent = useMemo(
    () =>
      ({ style, ...props }: PopperProps) => (
        <Popper
          {...props}
          placement="bottom-start"
          style={{
            ...style,
            width: 'fit-content',
            minWidth: 188,
            height: 0,
          }} // height 0 tricks the autcomplete positioner to displaying the menu below the input
        />
      ),
    [],
  );

  return (
    <Box className={classes.parent}>
      <Box
        style={{
          width: displayClearAll ? '70%' : '100%',
        }}
      >
        <Autocomplete
          multiple
          disableCloseOnSelect
          data-testid="auto-complete-input-container"
          id="dealer-codes-multi-select"
          options={options}
          noOptionsText={noOptionsText && options.length === 0 ? noOptionsText : 'No options'}
          disableClearable
          value={value}
          classes={{
            input: classes.input,
            paper: classes.paper,
            noOptions: classes.noOptions,
          }}
          getOptionLabel={(option) => option.id}
          isOptionEqualToValue={(option, valueAutoComplete) => option.id === valueAutoComplete.id}
          inputValue={inputString}
          renderTags={() => null}
          onChange={(event, newValue: nameIdPair[]) => setValue(newValue)}
          renderInput={(parameters) => (
            <TextField
              variant="standard"
              className={classes.textField}
              {...parameters}
              label={label}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setInputString(e.target.value)}
              placeholder={placeholderText ?? ''}
              fullWidth
            />
          )}
          renderOption={(props, option) => {
            const selected = value.some((item) => item.id === option.id) || checkSelectAll;
            return (
              <li
                {...props}
                data-testid="multi-drop-down-renderer"
                className={classes.option}
                key={`li-${option.id}`}
              >
                <Box>
                  <Checkbox
                    color="secondary"
                    data-testid={option.id}
                    icon={icon}
                    checkedIcon={checkedIcon}
                    className={classes.checkbox}
                    sx={{ '&.Mui-checked': { marginLeft: '2px' } }}
                    checked={selected}
                  />
                </Box>
                <Box mt={1} ml={selected ? '2px' : 0} pr={2}>
                  <Box>
                    <TextWithToolTip
                      longText={option.name ?? ''}
                      maxWidth="180px"
                      bold={rowSubtitle}
                    />
                  </Box>
                  {rowSubtitle && option.id !== selectAllID && (
                    <Box>
                      <TextWithToolTip
                        longText={option.id ?? ''}
                        maxWidth="180px"
                        color={CAT_COLOR.STORM_GREY}
                      />
                    </Box>
                  )}
                </Box>
              </li>
            );
          }}
          PopperComponent={popperComponent}
        />
      </Box>
      {displayClearAll && value.length > 0 && (
        <Box className={classes.childClearAll}>
          <Link
            underline="none"
            variant="subtitle1"
            data-testid="clear-all"
            onClick={() => {
              setValue([]);
            }}
          >
            {'Clear All'}
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default TaglessMultiselect;
