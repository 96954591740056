import {
  associateCatFiProductsActionNames,
  associateCatFiProductsActionTypes,
  AssociateCatFiProductsState,
} from './types';

const initialState: AssociateCatFiProductsState = {
  isLoadingCatFiAssociations: false,
  associateCatFiProductsResponse: [],
};

const associateCatFiProductsReducer = (
  state: AssociateCatFiProductsState = initialState,
  action: associateCatFiProductsActionTypes,
): AssociateCatFiProductsState => {
  switch (action.type) {
    case associateCatFiProductsActionNames.SET_IS_LOADING_CAT_FI_ASSOCIATIONS: {
      return {
        ...state,
        isLoadingCatFiAssociations: action.payload,
      };
    }
    case associateCatFiProductsActionNames.SET_ASSOCIATE_CAT_FI_PRODUCTS_RESPONSE: {
      return {
        ...state,
        associateCatFiProductsResponse: action.payload,
      };
    }
    default:
      return state;
  }
};

export default associateCatFiProductsReducer;
