import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  block: {
    display: 'block',
    width: '100%',
  },
  text: {
    wordBreak: 'break-word',
  },
  truncated: {
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
