import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { SearchOrganizationResponse } from '../../../../../../entities/customer-master-v1/models';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import TextWithToolTip from '../../../../../../components/text-with-tooltip';
import AccountTypes from '../../../../../../components/account-types';
import TEXT from '../../../../../../globals/translation-map';

type CustomerInfoProperties = {
  selectedCompany: SearchOrganizationResponse | undefined;
  customerTitle?: string;
  customerIdTitle?: string;
  numberOfCharsToDisplay?: number;
  containerStyle?: React.CSSProperties;
  maxWidth?: string | undefined;
  gridContainerStyle?: React.CSSProperties;
  companyItemStyle?: React.CSSProperties;
  customerIdStyle?: React.CSSProperties;
  customerNameDataTestId?: string;
  customerIdDataTestId?: string;
};

const CustomerInfo = (properties: CustomerInfoProperties) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    selectedCompany,
    customerTitle,
    customerIdTitle,
    numberOfCharsToDisplay,
    containerStyle,
    maxWidth,
    gridContainerStyle,
    companyItemStyle,
    customerIdStyle,
    customerNameDataTestId,
    customerIdDataTestId,
  } = properties;
  return (
    <Box
      alignItems="center"
      data-testid="search-dealer-customer-container"
      mt={2}
      style={containerStyle}
    >
      <Grid
        container
        spacing={0}
        justifyContent="flex-end"
        flexWrap="nowrap"
        style={gridContainerStyle}
      >
        <Grid item className={classes.company} style={companyItemStyle}>
          <Typography className={classes.companyHeader}>
            {customerTitle || t(TEXT.COMMON.CUSTOMER_NAME)}
          </Typography>
          <Box className={classes.text} display="flex">
            <TextWithToolTip
              customDataTestId={customerNameDataTestId}
              bold
              fontSize={14}
              width="auto"
              maxWidth={maxWidth || theme.spacing(17.5)}
              longText={
                selectedCompany?.customerOrganizationDetails?.customerOrganizationBusinessName || ''
              }
              numberOfCharsToDisplay={numberOfCharsToDisplay || 20}
            />
            <Box component="span" mt={theme.spacing(-0.5)}>
              <AccountTypes
                size={theme.spacing(2)}
                marginLeft={4}
                row={{ ...selectedCompany?.customerOrganizationDetails }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} className={classes.customerId} style={customerIdStyle}>
          <Typography className={classes.companyHeader}>
            {customerIdTitle || t(TEXT.MY_CUSTOMERS.COLUMNS.CCID)}
          </Typography>
          <TextWithToolTip
            customDataTestId={customerIdDataTestId}
            bold
            height={theme.spacing(2.5)}
            fontSize={14}
            longText={
              selectedCompany?.customerOrganizationDetails?.customerOrganizationIdentifier || ''
            }
            numberOfCharsToDisplay={numberOfCharsToDisplay || 20}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerInfo;
