import { AssetOwnershipState, assetOwnershipActionNames, assetOwnershipActionTypes } from './types';
const initialState: AssetOwnershipState = {
  assetOwnershipData: undefined,
  isLoadingAssetOwnership: false,
  selectedAssetRecommendation: undefined,
};

const assetOwnershipReducer = (
  state: AssetOwnershipState = initialState,
  action: assetOwnershipActionTypes,
): AssetOwnershipState => {
  switch (action.type) {
    case assetOwnershipActionNames.SET_IS_LOADING_ASSET_OWNERSHIP: {
      return {
        ...state,
        isLoadingAssetOwnership: action.payload,
      };
    }
    case assetOwnershipActionNames.SET_SELECTED_ASSET_RECOMMENDATION: {
      return {
        ...state,
        selectedAssetRecommendation: action.payload,
      };
    }
    case assetOwnershipActionNames.SET_ASSET_OWNERSHIP_DATA: {
      return {
        ...state,
        assetOwnershipData: action.payload,
      };
    }
    case assetOwnershipActionNames.SET_ASSET_OWNERSHIP_FEEDBACK: {
      return {
        ...state,
        feedback: action.payload,
      };
    }
    case assetOwnershipActionNames.SET_ASSET_OWNERSHIP_REASON: {
      return {
        ...state,
        reason: action.payload,
      };
    }
    case assetOwnershipActionNames.CLEAR_ASSET_OWNERSHIP: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default assetOwnershipReducer;
