import React from 'react';

const CloseFilterTagChipIcon = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.98842 12.3974C10.3009 12.3974 12.9861 9.75711 12.9861 6.50019C12.9861 3.24328 10.3009 0.603027 6.98842 0.603027C3.67598 0.603027 0.990723 3.24328 0.990723 6.50019C0.990723 9.75711 3.67598 12.3974 6.98842 12.3974Z"
        fill="#979797"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M4.63764 4.52007L9.18878 9.07122"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M4.71856 8.99515L9.19341 4.5201"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CloseFilterTagChipIcon;
