import { AppState, AppThunkDispatch } from '..';
import CountriesStates from '../../services/countries-states';
import { countriesStatesActionNames } from './types';
import { formatDetailedErrorMessage, showNotification } from '../../utils/util';
import i18n from 'i18next';
import TEXT from '../../globals/translation-map';

const fetchCountries = () => {
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const {
      login: { authInfo, environmentVariables },
    } = getState();
    try {
      const countriesData = new CountriesStates(authInfo, environmentVariables.countriesV1);
      const countriesRespone = await countriesData.fetchCountries({
        logicalExpression: '$0',
        filters: [
          {
            propertyName: 'status',
            type: 'contains',
            value: 'officially-assigned',
          },
        ],
      });
      dispatch({
        type: countriesStatesActionNames.SET_COUNTRIES,
        payload: countriesRespone.data.countries,
      });
    } catch (error: any) {
      showNotification(
        dispatch,
        'error',
        formatDetailedErrorMessage(error) || i18n.t(TEXT.COMMON.ERRORS.GENERIC),
        { persist: true },
      );
    }
  };
};

export default fetchCountries;
