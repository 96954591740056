import { MaterialDesignContent } from 'notistack';
import theme from '../../globals/theme';
import { styled } from '@mui/material';
export const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#E4F6E9 !important',
    color: '#000 !important',
    border: '1px solid #61CA7F',
    boxSizing: 'border-box',
    borderRadius: 5,
    paddingTop: 0,
    paddingBottom: 0,
    height: theme.spacing(6),
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#F5D0D1 !important',
    color: '#000 !important',
    border: '1px solid #ED1C24',
    boxSizing: 'border-box',
    borderRadius: 5,
    paddingTop: 0,
    paddingBottom: 0,
    height: theme.spacing(6),
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: '#FBE1CE !important',
    color: '#000 !important',
    border: '1px solid #127ACA',
    boxSizing: 'border-box',
    borderRadius: 5,
    height: theme.spacing(6),
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: '#E6F4FF !important',
    color: '#000 !important',
    border: '1px solid #2595FF',
    boxSizing: 'border-box',
    borderRadius: 5,
    height: theme.spacing(6),
    flexWrap: 'unset !important' as 'nowrap',
  },
}));
