import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const Settings = (props: iconType) => {
  const { stroke = '#78869E' } = props;
  return (
    <SvgIcon {...props} viewBox="0 0 20 20" style={{ fill: 'none', width: '24px', height: '24px' }}>
      <svg
        width="20"
        height="19"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.0002 9.46596C19.0002 14.4549 14.9598 18.5 9.97554 18.5C4.99133 18.4992 1.00098 14.2929 1.00098 9.30397C1.00098 4.44574 4.81212 0.701904 9.61321 0.508608C9.74312 0.50313 9.87303 0.5 10.0045 0.5C14.9887 0.5 19.0002 4.47704 19.0002 9.46596Z"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M9.61314 0.508598C4.91769 5.59533 4.91769 12.2316 9.61314 18.4922"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M10.3957 0.508598C15.0911 5.59533 15.0911 12.2292 10.3957 18.4898"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M2.47693 4.41287H17.4515"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M1.0033 9.10832H19.0002"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M2.16321 13.8038H17.893"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default Settings;
