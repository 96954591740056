/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs, get financial account details, associate/disassociate financial products to a customer organization. 
 *
 * OpenAPI spec version: 1.41.2
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AuditLogRecommendationAssociateDcnToExistingCatAssociationItem } from './auditLogRecommendationAssociateDcnToExistingCatAssociationItem';
import { RecommendationTypeLabel } from './recommendationTypeLabel';

/**
 * Audit log details for ASSOCIATE MULTIPLE DCN recommendation type. 
 */
export interface AuditLogRecommendationAssociateMultipleDcn { 
    /**
     * The recommendation relationship type represents the various types of recommendation.
     */
    recommendationType: AuditLogRecommendationAssociateMultipleDcn.RecommendationTypeEnum;
    recommendationTypeLabel?: RecommendationTypeLabel;
    recommendedAssociations: Array<AuditLogRecommendationAssociateDcnToExistingCatAssociationItem>;
}
export namespace AuditLogRecommendationAssociateMultipleDcn {
    export type RecommendationTypeEnum = 'ASSOCIATE MULTIPLE DCN';
    export const RecommendationTypeEnum = {
        ASSOCIATEMULTIPLEDCN: 'ASSOCIATE MULTIPLE DCN' as RecommendationTypeEnum
    };
}