import React from 'react';
import { iconType } from './icon-types';

const CheckedIcon = (props: iconType) => {
  const { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5952 12.9997C16.5952 14.6497 15.2448 16 13.5947 16H3.59468C1.94561 16 0.595215 14.6497 0.595215 12.9997V3.00033C0.595215 1.35031 1.94561 0 3.59468 0H13.5947C15.2448 0 16.5952 1.35031 16.5952 3.00033V12.9997Z"
        fill="#FFCC11"
      />
      <path
        d="M4.15088 8.26068L7.22694 11.5552L13.0398 5.33301"
        stroke="#43485C"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CheckedIcon;
