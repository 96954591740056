import { Parties } from '../entities/entitlements-v1/parties';
import { PartiesResponse } from '../entities/entitlements-v1/partiesResponse';
import { EnvironmentVariables } from '../environments';
import { Roles } from '../store/invitations/types';

export const findRoleByParties = (parties: Parties | undefined, roleNames: Array<string>) => {
  const filteredParties = parties?.filter((party) =>
    party.roles.some((role) => roleNames.includes(role.roleName)),
  );

  return filteredParties && filteredParties.length > 0;
};
// get roles for custom navigation item enabled
export const getCustomRoles = (parties: Parties | undefined) => {
  const roles = [Roles.TESTUSERADMIN, Roles.USERMANAGEMENTADMIN_FM3, Roles.STANDARDTEST];
  let customRolesFlag = {};
  for (let role of roles) {
    if (findRoleByParties(parties, [role])) {
      customRolesFlag = {
        ...customRolesFlag,
        [role]: true,
      };
    }
  }
  return customRolesFlag;
};
export const hasStandardTest = (
  environmentVariables: EnvironmentVariables,
  partiesResponse?: PartiesResponse,
) => {
  return (
    environmentVariables?.standardTest &&
    findRoleByParties(partiesResponse?.parties, [Roles.STANDARDTEST])
  );
};
