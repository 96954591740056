import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  text: {
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  paper: {
    padding: theme.spacing(1),
  },
  bold: {
    fontWeight: 600,
  },
  underline: {
    textDecoration: 'underline',
  },
  whiteSpaceNormal: {
    whiteSpace: 'normal',
  },
}));
