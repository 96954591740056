import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  sortIcon: {
    cursor: 'pointer',
    '& :hover': {
      fill: '#127ACA',
    },
  },
  iconClicked: {
    marginRight: theme.spacing(0.5),
    transform: 'rotate(180deg)',
  },
  iconDefault: {
    fill: '#5C656C',
    '& :hover': {
      fill: '#127ACA',
    },
  },

  columnNameContainer: {
    alignItems: 'center',
  },
  defaultArrowClass: {
    marginRight: theme.spacing(0.5),
  },
}));
