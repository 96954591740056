import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()(() => ({
  copyButton: {
    borderColor: CAT_COLOR.BLACK,
    color: CAT_COLOR.BLACK,
    height: '40px',
    fontWeight: 600,
  },
}));
