import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  reviewPageContainer: {
    overflowY: 'auto',
  },
  divider: {
    marginTop: theme.spacing(1),
  },
  stepContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    width: theme.spacing(87.5),
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectLanguage: {
    marginTop: theme.spacing(7),
    width: '100%',
    maxWidth: theme.spacing(67.5),
  },
  sectionHeader: {
    marginBottom: theme.spacing(1),
  },
  sectionSubheader: {
    marginBottom: theme.spacing(2),
  },
  languageDropdown: {
    textAlign: 'initial',
    width: '75%',
  },
  toggleSwitchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    width: theme.spacing(50.625),
  },
  customLabel: {
    width: theme.spacing(48.75),
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    margin: 0,
    marginLeft: theme.spacing(0.625),
  },
  footerActions: {
    backgroundColor: 'white',
  },
  enterEmailSection: {
    marginTop: theme.spacing(8),
    paddingBottom: theme.spacing(50),
    maxWidth: theme.spacing(50.625),
  },
  emailContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  infoIconContainer: {
    marginRight: theme.spacing(0.75),
  },
}));
