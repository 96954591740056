import React from 'react';
import { Box } from '@mui/material';
import ScrollModal from '../../../components/side-nav/components/scroll-modal';
import useStyles from './styles';
import LegalText from '../legal';
import PrivacyText from '../privacy';

export const termsAndConditionsVersion: string = 'v1';
const TermsAndConditions = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.text} data-testid="terms-prompt">
      By accessing and/or using the Caterpillar Customer Admin Tool, you agree to and accept the
      Customer Admin Tool
      <Box className={classes.link}>
        <ScrollModal title="Privacy Statement" bodyText={PrivacyText} />
        and
        <ScrollModal title="Terms of Use" bodyText={LegalText} />
      </Box>
    </Box>
  );
};
export default TermsAndConditions;
