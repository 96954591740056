import { Box, Grid, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import React from 'react';
import useStyles from './styles';
import DropdownMultiSelect from '../../../../components/dropdown-multi-select';
import { useTranslation } from 'react-i18next';
import TEXT from '../../../../globals/translation-map';

type Values = {
  dealerCode?: string;
};

type AdvanceFiltersFormProperties = {
  formikProps: FormikProps<Values>;
  selectedDealerCode: string[];
  dealerCodes?: Array<string>;
};
const sortAlphaNumeric = (a: string, b: string) => a.localeCompare(b, 'en', { numeric: true });
const AdvanceFiltersForm = (props: AdvanceFiltersFormProperties) => {
  const { classes } = useStyles();
  const { formikProps, selectedDealerCode, dealerCodes } = props;
  const { t } = useTranslation();

  const onDealerCodeSelect = (selectedDealerCodes: string[]) => {
    formikProps.setFieldValue('dealerCode', selectedDealerCodes);
  };

  return (
    <Box mt={3} className={classes.row}>
      <Typography variant="h4" className={classes.filterByText}>
        {t(TEXT.COMMON.FILTERS.SECTIONS.CATEGORY)}
      </Typography>
      <Box alignItems="center" data-testid="asset-recommendation-filters-dialog-content" mt={3}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <DropdownMultiSelect
              label={t(TEXT.COMMON.FILTERS.INPUT_LABELS.DEALER_CODE)}
              prevSelectedValue={selectedDealerCode ?? []}
              customClass={classes.typeahead}
              dropdownValues={
                dealerCodes
                  ?.filter((dealerCode) => dealerCode.length < 6)
                  .sort(sortAlphaNumeric)
                  .map((dealerCode) => dealerCode) ?? []
              }
              onSelectCallbackFunction={onDealerCodeSelect}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AdvanceFiltersForm;
