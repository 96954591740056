import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const AdminIcon = (props: iconType) => {
  const { stroke = '#78869E' } = props;
  return (
    <SvgIcon
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.51111 6.75262C5.51111 4.82573 7.07316 3.26368 9.00005 3.26368C10.9269 3.26368 12.489 4.82573 12.489 6.75262C12.489 8.67951 10.9269 10.2416 9.00005 10.2416C7.07316 10.2416 5.51111 8.67951 5.51111 6.75262ZM9.00005 4.38868C7.69448 4.38868 6.63611 5.44705 6.63611 6.75262C6.63611 8.05819 7.69448 9.11656 9.00005 9.11656C10.3056 9.11656 11.364 8.05819 11.364 6.75262C11.364 5.44705 10.3056 4.38868 9.00005 4.38868Z"
        fill={stroke}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99999 0.336922C4.48713 0.336922 0.828735 3.99532 0.828735 8.50817C0.828735 13.021 4.48713 16.6794 8.99999 16.6794C13.5128 16.6794 17.1712 13.021 17.1712 8.50817C17.1712 3.99532 13.5128 0.336922 8.99999 0.336922ZM1.95374 8.50817C1.95374 4.61664 5.10845 1.46192 8.99999 1.46192C12.8915 1.46192 16.0462 4.61664 16.0462 8.50817C16.0462 10.3994 15.3012 12.1166 14.0885 13.3822C13.5618 12.6961 12.9002 12.1216 12.1418 11.6956C11.1824 11.1566 10.1004 10.8735 8.99985 10.8735C7.89934 10.8735 6.81734 11.1566 5.85786 11.6956C5.09961 12.1216 4.43802 12.696 3.91128 13.3821C2.69874 12.1164 1.95374 10.3993 1.95374 8.50817ZM4.75442 14.1323C5.93511 15.025 7.40572 15.5544 8.99999 15.5544C10.5942 15.5544 12.0647 15.025 13.2454 14.1324C12.8033 13.5378 12.24 13.0411 11.5909 12.6765C10.7996 12.232 9.90737 11.9985 8.99985 11.9985C8.09233 11.9985 7.20007 12.232 6.40884 12.6765C5.75972 13.0411 5.1965 13.5377 4.75442 14.1323Z"
        fill={stroke}
      />
    </SvgIcon>
  );
};

export default AdminIcon;
