/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs, get financial account details, associate/disassociate financial products to a customer organization. 
 *
 * OpenAPI spec version: 1.41.2
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AuditLogRecommendationAssociateDcnToExistingCatAssociationItem } from './auditLogRecommendationAssociateDcnToExistingCatAssociationItem';
import { AuditLogRecommendationAssociateMultipleCatAssociationItem } from './auditLogRecommendationAssociateMultipleCatAssociationItem';
import { RecommendationTypeLabel } from './recommendationTypeLabel';

/**
 * Audit log details for ASSOCIATE MULTIPLE CAT ASSOCIATION & ASSOCIATE DCN TO EXISTING CAT ASSOCIATION recommendation type. 
 */
export interface AuditLogRecommendationAssociateMultipleCatAssociationAndAssociateDcnToExistingCatAssociation { 
    /**
     * The recommendation relationship type represents the various types of recommendation.
     */
    recommendationType: AuditLogRecommendationAssociateMultipleCatAssociationAndAssociateDcnToExistingCatAssociation.RecommendationTypeEnum;
    recommendationTypeLabel?: RecommendationTypeLabel;
    recommendedAssociations: Array<AuditLogRecommendationAssociateMultipleCatAssociationItem | AuditLogRecommendationAssociateDcnToExistingCatAssociationItem>;
}
export namespace AuditLogRecommendationAssociateMultipleCatAssociationAndAssociateDcnToExistingCatAssociation {
    export type RecommendationTypeEnum = 'ASSOCIATE MULTIPLE CAT ASSOCIATION & ASSOCIATE DCN TO EXISTING CAT ASSOCIATION';
    export const RecommendationTypeEnum = {
        ASSOCIATEMULTIPLECATASSOCIATIONASSOCIATEDCNTOEXISTINGCATASSOCIATION: 'ASSOCIATE MULTIPLE CAT ASSOCIATION & ASSOCIATE DCN TO EXISTING CAT ASSOCIATION' as RecommendationTypeEnum
    };
}