import { makeStyles } from 'tss-react/mui';
import { createTheme } from '@mui/material/styles';
import CAT_COLOR from '../../../../globals/color-properties';

export const theme = createTheme({
  palette: {
    error: {
      main: CAT_COLOR.PERSIAN_RED,
    },
  },
});

// eslint-disable-next-line @typescript-eslint/no-shadow
export default makeStyles()((theme) => ({
  bold: {
    fontWeight: 'bold',
  },
  gridContainer: {
    width: '100%',
    margin: 'auto',
    '& p': {
      marginBottom: theme.spacing(0.5),
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    height: '77px',

    justifyContent: 'center',
    alignItems: 'center',
  },
  divider: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
  },
  actionButton: {
    backgroundColor: CAT_COLOR.PERSIAN_RED,
    color: CAT_COLOR.WHITE,
    '&:hover': {
      backgroundColor: CAT_COLOR.DARK_RED,
    },
  },
  warningText: {
    color: 'black',
  },
  infoLarger: {
    height: 'auto !important',
    alignItems: 'flex-start !important',
    '& #notistack-snackbar': {
      alignItems: 'flex-start',
    },
  },
}));
