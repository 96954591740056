import {
  OrganizationResponse,
  SearchOrganizationResponse,
} from '../../entities/customer-master-v1/models';
import { SetIsLoading } from '../api/loadable';

export enum customerOrganizationActionNames {
  CLEAR_MY_CUSTOMER_ORGANIZATIONS = 'CLEAR_MY_CUSTOMER_ORGANIZATIONS',
  CLEAR_NEXT_CURSOR_FOR_ORGANIZATION = 'CLEAR_NEXT_CURSOR_FOR_ORGANIZATION',
  CLEAR_ORGANIZATION = 'CLEAR_ORGANIZATION',
  CLEAR_SEARCHED_CUSTOMER_ORGANIZATIONS = 'CLEAR_SEARCHED_CUSTOMER_ORGANIZATIONS',
  RESET_CUSTOMERS = 'RESET_CUSTOMERS',
  SET_ADD_IMPORT_CUSTOMER_DISPLAY = 'SET_ADD_IMPORT_CUSTOMER_DISPLAY',
  SET_EDIT_ERROR = 'SET_EDIT_ERROR',
  SET_EDITED_ORGANIZATION = 'SET_EDITED_ORGANIZATION',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_IS_LOADING_CUSTOMERS_COUNT = 'SET_IS_LOADING_CUSTOMERS_COUNT',
  SET_MY_CUSTOMER_ORGANIZATIONS = 'SET_MY_CUSTOMER_ORGANIZATIONS',
  SET_MY_CUSTOMER_ORGANIZATIONS_BY_ID = 'SET_MY_CUSTOMER_ORGANIZATIONS_BY_ID',
  SET_MY_CUSTOMER_SELECTED_FILTERS = 'SET_MY_CUSTOMER_SELECTED_FILTERS',
  SET_NEXT_CURSOR_FOR_ORGANIZATION = 'SET_NEXT_CURSOR_FOR_ORGANIZATION',
  SET_ORGANIZATION = 'SET_ORGANIZATION',
  SET_DEALER_CODE = 'SET_DEALER_CODE',
  SET_SEARCH_VALUE = 'SET_SEARCH_VALUE',
  SET_SEARCH_FIELD_VALUE = 'SET_SEARCH_FIELD_VALUE',
  SET_SEARCHED_CUSTOMER_ORGANIZATIONS = 'SET_SEARCHED_CUSTOMER_ORGANIZATIONS',
  SET_TOTAL_CUSTOMERS_COUNT = 'SET_TOTAL_CUSTOMERS_COUNT',
  SET_MY_CUSTOMER_STALE_DATA = 'SET_MY_CUSTOMER_STALE_DATA',
  SET_TOTAL_COUNT = 'SET_TOTAL_COUNT',
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  DELETE_CUSTOMER = 'DELETE_CUSTOMER',
}

export type ErrorType = {
  code: number;
  message: string;
};

export type CursorType = {
  cursors: Array<string>;
};
type UserInputFilters = {
  customerName?: string;
  dealerCode?: string[];
  country?: string;
  stateOrProvince?: string;
  hasAssociation?: boolean;
  startDate?: Date;
  endDate?: Date;
};

export interface CustomerOrganizationState {
  myCustomerOrganizations?: Array<SearchOrganizationResponse>;
  searchedCustomerOrganizations?: Array<SearchOrganizationResponse>;
  organization?: OrganizationResponse;
  dealerCode?: string;
  cursorForOrganization?: CursorType | null;
  isLoading?: boolean;
  isLoadingCount?: boolean;
  searchFieldValue: string;
  editedOrganization?: OrganizationResponse;
  editError?: ErrorType;
  totalCount?: number;
  currentPage: number;
  myCustomersSelectedFilter?: UserInputFilters;
  addImportCustomerDisplayEnabled?: boolean;
  myCustomerOrganizationsStaleData: boolean;
}

interface SetMyCustomerOrganizations {
  type: typeof customerOrganizationActionNames.SET_MY_CUSTOMER_ORGANIZATIONS;
  payload: Array<SearchOrganizationResponse>;
}
interface SetMyCustomerOrganizationsStaleData {
  type: typeof customerOrganizationActionNames.SET_MY_CUSTOMER_STALE_DATA;
  payload: boolean;
}
interface SetMyCustomerOrganizationsByID {
  type: typeof customerOrganizationActionNames.SET_MY_CUSTOMER_ORGANIZATIONS_BY_ID;
  payload: Array<SearchOrganizationResponse>;
}

interface ClearMyCustomerOrganizations {
  type: typeof customerOrganizationActionNames.CLEAR_MY_CUSTOMER_ORGANIZATIONS;
}

interface SetSearchedCustomerOrganizations {
  type: typeof customerOrganizationActionNames.SET_SEARCHED_CUSTOMER_ORGANIZATIONS;
  payload: Array<SearchOrganizationResponse>;
}

interface ClearSearchedCustomerOrganizations {
  type: typeof customerOrganizationActionNames.CLEAR_SEARCHED_CUSTOMER_ORGANIZATIONS;
}

interface SetOrganization {
  type: typeof customerOrganizationActionNames.SET_ORGANIZATION;
  payload?: OrganizationResponse;
}

interface SetOrganizationAndDealerCode {
  type: typeof customerOrganizationActionNames.SET_DEALER_CODE;
  payload: string;
}

interface SetEditedOrganization {
  type: typeof customerOrganizationActionNames.SET_EDITED_ORGANIZATION;
  payload?: OrganizationResponse;
}

interface SetEditError {
  type: typeof customerOrganizationActionNames.SET_EDIT_ERROR;
  payload: ErrorType;
}

interface ClearOrganization {
  type: typeof customerOrganizationActionNames.CLEAR_ORGANIZATION;
}

interface SetNextCursorForOrganization {
  type: typeof customerOrganizationActionNames.SET_NEXT_CURSOR_FOR_ORGANIZATION;
  payload: CursorType;
}

interface ClearNextCursorForOrganization {
  type: typeof customerOrganizationActionNames.CLEAR_NEXT_CURSOR_FOR_ORGANIZATION;
  payload: null;
}

interface SetTotalCount {
  type: typeof customerOrganizationActionNames.SET_TOTAL_CUSTOMERS_COUNT;
  payload: number;
}
interface SetMyCustomersSelectedFilters {
  type: typeof customerOrganizationActionNames.SET_MY_CUSTOMER_SELECTED_FILTERS;
  payload: UserInputFilters;
}

interface ResetCustomers {
  type: typeof customerOrganizationActionNames.RESET_CUSTOMERS;
}

interface SetAddImportCustomerDisplay {
  type: typeof customerOrganizationActionNames.SET_ADD_IMPORT_CUSTOMER_DISPLAY;
  payload: boolean;
}

interface SetSearchFieldValue {
  type: typeof customerOrganizationActionNames.SET_SEARCH_FIELD_VALUE;
  payload: string;
}
interface DeleteCustomer {
  type: typeof customerOrganizationActionNames.DELETE_CUSTOMER;
}
interface SetCurrentPage {
  type: typeof customerOrganizationActionNames.SET_CURRENT_PAGE;
  payload: number;
}

export type CustomerOrganizationActionTypes =
  | ClearMyCustomerOrganizations
  | SetSearchedCustomerOrganizations
  | SetOrganization
  | SetOrganizationAndDealerCode
  | SetNextCursorForOrganization
  | SetSearchFieldValue
  | SetEditedOrganization
  | SetEditError
  | ClearNextCursorForOrganization
  | ClearOrganization
  | ClearSearchedCustomerOrganizations
  | ResetCustomers
  | SetAddImportCustomerDisplay
  | SetMyCustomerOrganizations
  | SetMyCustomerOrganizationsByID
  | SetMyCustomersSelectedFilters
  | SetMyCustomerOrganizationsStaleData
  | SetTotalCount
  | SetIsLoading<customerOrganizationActionNames.SET_IS_LOADING_CUSTOMERS_COUNT>
  | SetIsLoading<customerOrganizationActionNames.SET_IS_LOADING>
  | DeleteCustomer
  | SetCurrentPage;

// TODO: as part of redux refactor combine this into separate domain
export interface SetDuplicateCustomers<T> {
  type: T;
  payload: Array<SearchOrganizationResponse>;
}

export interface ClearDuplicateCustomers<T> {
  type: T;
}
