import { DealerCustomerState, dealerCustomerActionNames, DealerCustomerActionTypes } from './types';

const initialState: DealerCustomerState = {
  noDealerCustomersFound: false,
};

const dealerCustomerReducer = (
  state: DealerCustomerState = initialState,
  action: DealerCustomerActionTypes,
): DealerCustomerState => {
  switch (action.type) {
    case dealerCustomerActionNames.SET_DEALER_CUSTOMER: {
      return {
        ...state,
        dealerCustomers: action.payload,
      };
    }
    case dealerCustomerActionNames.SET_NO_DEALER_CUSTOMER_FOUND_FLG: {
      return {
        ...state,
        noDealerCustomersFound: action.payload,
      };
    }
    case dealerCustomerActionNames.CLEAR_DEALER_CUSTOMER: {
      return {
        ...state,
        dealerCustomers: initialState.dealerCustomers,
      };
    }
    case dealerCustomerActionNames.SET_FETCH_DCN_ERROR: {
      return {
        ...state,
        fetchError: action.payload,
      };
    }
    case dealerCustomerActionNames.SET_LOADING_FOR_DCN: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case dealerCustomerActionNames.SET_LOADING_FOR_DCN_DELETE: {
      return {
        ...state,
        isLoadingDCNDelete: action.payload,
      };
    }
    case dealerCustomerActionNames.SET_LOADING_FOR_SEARCH_DCN: {
      return {
        ...state,
        isLoadingDcnSearch: action.payload,
      };
    }
    case dealerCustomerActionNames.SET_EDIT_DCN_ERROR: {
      return {
        ...state,
        editDealerError: action.payload,
      };
    }
    case dealerCustomerActionNames.SET_DEALER_CUSTOMER_EDIT_SUCCESS: {
      return {
        ...state,
        editedDealerCustomer: action.payload,
      };
    }
    case dealerCustomerActionNames.CLEAR_EDITED_DEALER_CUSTOMER: {
      return {
        ...state,
        editedDealerCustomer: undefined,
      };
    }
    case dealerCustomerActionNames.RESET_STATE: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default dealerCustomerReducer;
