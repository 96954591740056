import React from 'react';
import { SnackbarProvider } from 'notistack';
import { InfoTwoTone } from '@mui/icons-material';
import { Box } from '@mui/material';
import CAT_COLOR from '../../globals/color-properties';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { StyledMaterialDesignContent } from './styles';

import { UI_BLOCKS, useUIBlocks } from '../../hooks/use-ui-blocks';
import Toast from '../toast';

const SnackbarContainer = (properties: { children?: JSX.Element | JSX.Element[] }) => {
  const { children } = properties;
  const customToast = useUIBlocks(UI_BLOCKS.TOAST) ? Toast : StyledMaterialDesignContent;
  return (
    <SnackbarProvider
      Components={{
        success: customToast,
        error: customToast,
        warning: customToast,
        info: customToast,
      }}
      preventDuplicate
      maxSnack={3}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      iconVariant={{
        success: (
          <Box mr={1}>
            <CheckCircleIcon fontSize="small" style={{ color: CAT_COLOR.MEDIUM_SEA_GREEN }} />
          </Box>
        ),
        error: (
          <Box mr={1}>
            <InfoTwoTone fontSize="small" style={{ color: CAT_COLOR.PIGMENT_RED }} />
          </Box>
        ),
        warning: (
          <Box mr={1}>
            <InfoTwoTone fontSize="small" style={{ color: CAT_COLOR.PIGMENT_RED }} />
          </Box>
        ),
        info: (
          <Box mr={1}>
            <InfoTwoTone fontSize="small" style={{ color: CAT_COLOR.AZURE }} />
          </Box>
        ),
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default SnackbarContainer;
