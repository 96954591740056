export enum erpAssetListActionNames {
  SET_ERP_ASSET_LIST_FILTERS = 'SET_ERP_ASSET_LIST_FILTERS',
  SET_ERP_ASSET_LIST_FILTERS_MODAL_OPEN = 'SET_ERP_ASSET_LIST_FILTERS_MODAL_OPEN',
}

export type ErpAssetListFilters = {
  selectedAccount: string;
  status: string[];
  dateRange: FiltersDateType;
};
export type FiltersDateType = {
  startDate: Date | undefined;
  endDate: Date | undefined;
  selectedButton: number | undefined;
};

export enum ErpAsssetListFields {
  selectedAccount = 'selectedAccount',
  status = 'status',
  dateRange = 'dateRange',
}

export interface ErpAsssetListState {
  filters: ErpAssetListFilters;
  filtersModalOpen: boolean;
}

export interface SetErpAsssetListFilters {
  type: typeof erpAssetListActionNames.SET_ERP_ASSET_LIST_FILTERS;
  payload: ErpAssetListFilters;
}

export interface SetErpAsssetListFiltersModalOpen {
  type: typeof erpAssetListActionNames.SET_ERP_ASSET_LIST_FILTERS_MODAL_OPEN;
  payload: boolean;
}

export type ErpAsssetListActionTypes = SetErpAsssetListFilters | SetErpAsssetListFiltersModalOpen;
