import { dealerDetailsActionNames, DealerDetailsActionTypes, DealerDetailsState } from './types';

const initialState: DealerDetailsState = {
  dealers: [],
};

const dealerDetailsReducer = (
  state = initialState,
  action: DealerDetailsActionTypes,
): DealerDetailsState => {
  switch (action.type) {
    case dealerDetailsActionNames.SET_DEALER_DETAILS: {
      return {
        ...state,
        dealers: [...state.dealers, ...action.payload],
      };
    }

    default:
      return state;
  }
};

export default dealerDetailsReducer;
