import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

const useStyles = makeStyles<{ color: string }>()((theme, { color = CAT_COLOR.GREY }) => {
  return {
    statusContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    statusItem: {
      height: theme.spacing(1),
      width: theme.spacing(1),
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: theme.spacing(1),
      color: color,
      backgroundColor: color,
    },
    bold: {
      fontWeight: 600,
      color: CAT_COLOR.BLACK_OLIVE,
      '&::first-letter': {
        textTransform: 'capitalize',
      },
    },
  };
});

export default useStyles;
