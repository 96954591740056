import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusItem: {
    height: theme.spacing(1),
    width: theme.spacing(1),
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
  statusApproved: {
    color: CAT_COLOR.GREEN,
    backgroundColor: CAT_COLOR.GREEN,
  },
  statusDenied: {
    color: CAT_COLOR.GREY,
    backgroundColor: CAT_COLOR.GREY,
  },
  statusPending: {
    color: CAT_COLOR.SAFETY_ORANGE,
    backgroundColor: CAT_COLOR.SAFETY_ORANGE,
  },
  statusProcessing: {
    color: CAT_COLOR.GOLDEN_POPPY,
    backgroundColor: CAT_COLOR.GOLDEN_POPPY,
  },
  statusFailed: {
    color: CAT_COLOR.PIGMENT_RED,
    backgroundColor: CAT_COLOR.PIGMENT_RED,
  },
  bold: {
    fontWeight: 600,
    color: CAT_COLOR.BLACK_OLIVE,
  },
}));
