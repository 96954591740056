import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../../../globals/color-properties';

export default makeStyles()((theme) => ({
  input: {
    borderBottom: 'none',
    paddingRight: 0,
    '&:hover': {
      borderBottom: 'none',
    },
    '&:focus': {
      borderBottom: 'none',
      paddingRight: 0,
    },
  },
  paper: {
    boxShadow: '0px 0px 10px rgb(80 95 122 / 30%)',
    '& .MuiAutocomplete-listbox': {
      paddingTop: 1,
      paddingBottom: 1,
    },
  },
  clearIndicator: {
    display: 'none',
  },
  label: {
    padding: 0,
    margin: 0,
    fontWeight: 800,
  },
  endAdornment: {
    marginTop: theme.spacing(1),
  },
  iconPosition: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  root: {
    paddingBottom: theme.spacing(2),
    paddingRight: '0 !important',
    '& .MuiAutocomplete-inputRoot': {
      paddingRight: 0,
    },
  },
  typeahead: {
    width: '100%',
    paddingRight: theme.spacing(8),
  },
  sectionSubHeader: {
    display: 'inline',
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
    fontWeight: 700,
    fontSize: '14px',
  },
  inlineHeaderBlock: {
    fontSize: '12px',
    color: CAT_COLOR.DARK_SLATE_GRAY,
    fontWeight: 700,
  },
  inlineNameBlock: {
    fontSize: '12px',
    color: CAT_COLOR.DARK_SLATE_GRAY,
  },
  nameBlock: {
    fontSize: '16px',
    color: CAT_COLOR.DARK_SLATE_GRAY,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
