import React, { MouseEvent, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText, Typography, Box, IconButton } from '@mui/material';
import { KeyboardArrowUpRounded, KeyboardArrowDownRounded } from '@mui/icons-material';
import useStyles from './styles';
import { NavObject } from '../../../../globals/nav-list';

type menuItem = {
  active: boolean;
  navItem: NavObject;
  open?: boolean;
  uri: string;
  onClick: (event: MouseEvent<HTMLAnchorElement>, url: string) => void;
  clickedUrl: string;
};

const MenuItem = ({ active, navItem, open = false, uri, onClick, clickedUrl }: menuItem) => {
  const { classes } = useStyles();
  const location = useLocation();

  const mainUrlActive = useMemo(() => {
    if (!!navItem?.subNavItems?.length && open && clickedUrl === uri) {
      return false;
    }
    if (open) {
      const pathname = '/' + location.pathname.split('/')[1];
      if (!navItem?.subNavItems?.length && pathname === uri) {
        return true;
      }
      if (
        !navItem?.subNavItems?.length &&
        pathname !== uri &&
        navItem.relatedPages.includes(pathname)
      ) {
        return true;
      }
      return active && clickedUrl === uri;
    }
    return active;
  }, [open, navItem, location, clickedUrl, uri, active]);

  const getTopLevelItem = (
    activeTopLevelItem: boolean | undefined,
    children: React.ReactNode,
    message?: string,
  ) => {
    if (!activeTopLevelItem)
      return (
        <Box data-testid="mouse-over-popover" ml={1} mr={1}>
          {children}
          <Typography className={classes.titleForClosedDrawer}>{message}</Typography>
        </Box>
      );
    return (
      <Box data-testid="mouse-over-popover-icons" pb={'10px'}>
        {children}
      </Box>
    );
  };
  const [showHideTable, setShowHideTable] = useState(true);

  const renderExpandIcon = () => {
    return (
      <Box className={classes.expandCollapseIconButton}>
        <IconButton
          aria-label="expand row"
          size="small"
          type="button"
          onClick={(event: MouseEvent) => {
            setShowHideTable((prev) => !prev);
          }}
          data-testid="expand-collapse-icon-button"
        >
          {showHideTable ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
        </IconButton>
      </Box>
    );
  };

  const titleMenuStyleCheck = () => {
    if ((navItem.subNavItems || []).length) {
      return navItem.relatedPages.some(
        (item: string) => item === location.pathname || item === clickedUrl,
      );
    }
    return false;
  };

  return (
    <>
      <Box position={'absolute'} right={0} mr={'35px'} mt={'5px'}>
        {!!(navItem.subNavItems || []).length && open && renderExpandIcon()}
      </Box>
      <Link
        data-testid={navItem.key}
        id={`side-nav-link-${navItem.title.toLocaleLowerCase()}`}
        className={classes.sideNavLink}
        to={uri}
        key={uri}
        onClick={(event) => {
          onClick(event, uri);
        }}
      >
        <ListItem
          data-testid="list-item"
          button
          className={clsx(
            classes.listContainer,
            {
              [classes.activeContainerTitle]: mainUrlActive || titleMenuStyleCheck(),
              [classes.inactiveContainer]: !(mainUrlActive || titleMenuStyleCheck()),
            },
            { [classes.openListContainer]: open },
          )}
        >
          {getTopLevelItem(
            open,
            <ListItemIcon className={classes.iconContainer}>
              {navItem.icon && navItem.icon({ stroke: '#3F3F3F' })}
            </ListItemIcon>,
            navItem.title,
          )}
          {open && (
            <ListItemText
              data-testid="list-item-text"
              className={classes.listItemText}
              primary={
                <Typography
                  className={clsx(classes.titleConatainer, {
                    [classes.titleConatainerActive]: mainUrlActive || titleMenuStyleCheck(),
                  })}
                >
                  {navItem.title}
                </Typography>
              }
            />
          )}
        </ListItem>
      </Link>

      {open && !!(navItem.subNavItems || []).length && showHideTable && (
        <>
          {Object.entries(navItem.subNavItems || []).map(([key, subNavItem]) => {
            const isActiveClickedUrl = subNavItem.uri === clickedUrl;
            const isActivePathname = subNavItem.uri === location.pathname;
            return (
              <div key={key}>
                <Link
                  data-testid={subNavItem.key}
                  id={`side-nav-link-${subNavItem.title?.toLocaleLowerCase()}`}
                  className={classes.sideNavLink}
                  to={subNavItem.uri}
                  key={subNavItem.uri}
                  onClick={(event) => {
                    onClick(event, subNavItem.uri);
                    event.stopPropagation();
                  }}
                >
                  <ListItem
                    data-testid="sub-nav-list-item"
                    button
                    className={clsx(classes.listContainer, classes.inactiveContainer, {
                      [classes.activeContainer]: active && (isActiveClickedUrl || isActivePathname),
                      [classes.openListContainer]: open,
                    })}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      width="100%"
                      ml={6.75}
                      overflow="hidden"
                    >
                      <ListItemText
                        className={classes.listItemText}
                        primary={
                          <Typography className={classes.titleConatainer}>
                            {subNavItem.title}
                          </Typography>
                        }
                      />
                    </Box>
                  </ListItem>
                </Link>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default MenuItem;
