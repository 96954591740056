import * as yup from 'yup';

const customerSearchValidationSchema = yup.object().shape({
  customerName: yup.string().when('customerNumber', {
    is: (customerNumber: string) => !customerNumber || customerNumber.length === 0,
    then: () => yup.string().required('Either Name or ID is required'),
    otherwise: () => yup.string(),
  }),
});

export default customerSearchValidationSchema;
