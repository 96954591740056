import {
  SearchOrganizationResponse,
} from '../../entities/customer-master-v1/models';

export enum mergeOrganizationActionNames {
  SET_FROM_ORGANIZATION = 'SET_FROM_ORGANIZATION',
  CLEAR_ORGANIZATIONS = 'CLEAR_ORGANIZATIONS',
  SET_TO_ORGANIZATION = 'SET_TO_ORGANIZATION',
  CLEAR_TO_ORGANIZATION = 'CLEAR_TO_ORGANIZATION',
}

export type CursorType = {
  cursors: Array<string>;
};

export interface MergeOrganizationState {
  fromOrganization?: SearchOrganizationResponse;
  toOrganization?: SearchOrganizationResponse;
}

interface SetFromOrganization {
  type: typeof mergeOrganizationActionNames.SET_FROM_ORGANIZATION;
  payload: SearchOrganizationResponse;
}

interface SetToOrganization {
  type: typeof mergeOrganizationActionNames.SET_TO_ORGANIZATION;
  payload: SearchOrganizationResponse;
}
interface ClearOrganizations {
  type: typeof mergeOrganizationActionNames.CLEAR_ORGANIZATIONS;
}

export type MergeOrganizationActionTypes =
  | SetFromOrganization
  | SetToOrganization
  | ClearOrganizations;
