import {
  ErpAssetListFilters,
  erpAssetListActionNames,
  ErpAsssetListActionTypes,
  ErpAsssetListState,
} from './types';

const initialState: ErpAsssetListState = {
  filters: {
    selectedAccount: '',
    status: [],
    dateRange: {
      startDate: undefined,
      endDate: undefined,
      selectedButton: undefined,
    },
  },
  filtersModalOpen: false,
};

const erpAssetListReducer = (
  state: ErpAsssetListState = initialState,
  action: ErpAsssetListActionTypes,
) => {
  switch (action.type) {
    case erpAssetListActionNames.SET_ERP_ASSET_LIST_FILTERS: {
      return {
        ...state,
        filters: { ...state.filters, ...(action.payload as ErpAssetListFilters) },
      };
    }
    case erpAssetListActionNames.SET_ERP_ASSET_LIST_FILTERS_MODAL_OPEN: {
      return {
        ...state,
        filtersModalOpen: action.payload as boolean,
      };
    }
    default:
      return state;
  }
};

export default erpAssetListReducer;
