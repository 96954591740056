import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../../../../../globals/color-properties';

export default makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
  },
  company: {
    textAlign: 'initial',
    minWidth: theme.spacing(19),
    paddingRight: theme.spacing(3),
  },
  customerId: {
    textAlign: 'start',
    maxWidth: 'fit-content',
  },
  companyHeader: {
    color: CAT_COLOR.SONIC_SILVER,
    marginBottom: theme.spacing(1),
  },
  companyInfo: {
    fontWeight: 'bold',
    color: CAT_COLOR.BLACK,
  },
  text: {
    '& p': {
      fontWeight: 'bold',
      color: CAT_COLOR.BLACK,
    },
    position: 'relative',
    zIndex: 1,
  },
}));
