import React, {
  useState,
  useEffect,
  MouseEvent,
  Dispatch,
  SetStateAction,
  FC,
  ReactNode,
} from 'react';
import { Box, Popover, Typography } from '@mui/material';
import useStyles from './styles';
import ArrowDown from '../../assets/icons/arrow-down';
import SortArrow from '../../assets/icons/sort-arrow';
import InformationIconPopOverStatus from '../information-icon-popover-status';

export type SortLabelProperties = {
  name: string;
  sortBy: string;
  sortDirection: string;
  sortOptionsOpen: boolean;
  setSortOptionsOpen: Dispatch<SetStateAction<boolean>>;
  children?: ReactNode;
  title?: string;
  columnIdentifier?: string;
  sortingSelected: boolean;
  informationIconFlag?: boolean;
  statusMap?: { [key: string]: string };
  reverseSortArrow?: boolean;
  tooltipText?: string;
};

const SortLabelComponent: FC<SortLabelProperties> = (
  sortLabelComponentProperties: SortLabelProperties,
) => {
  const { classes } = useStyles();
  const {
    name,
    sortBy,
    sortDirection,
    sortOptionsOpen,
    setSortOptionsOpen,
    columnIdentifier,
    sortingSelected,
    children,
    title,
    informationIconFlag,
    statusMap,
    reverseSortArrow,
    tooltipText,
  } = sortLabelComponentProperties;
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorElement);
  const id = open ? 'simple-popover' : undefined;
  const wasSortApplied = window.sessionStorage.getItem('isSortApplied');
  const handleClose = () => {
    setAnchorElement(null);
  };
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
    setSortOptionsOpen(true);
  };

  useEffect(() => {
    if (!sortOptionsOpen) {
      setAnchorElement(null);
    }
  }, [sortOptionsOpen]);

  const hasInfoTooltip = informationIconFlag && (statusMap || tooltipText);
  return (
    <Box
      display="flex"
      flexDirection="row"
      data-testid="column-name-with-sort"
      className={classes.columnNameContainer}
    >
      {(sortingSelected || wasSortApplied === 'true') && columnIdentifier === sortBy ? (
        <SortArrow
          width="12"
          height="12"
          className={
            (!reverseSortArrow && sortDirection === 'DESC') ||
            (reverseSortArrow && sortDirection === 'ASC')
              ? classes.iconClicked
              : classes.defaultArrowClass
          }
        />
      ) : null}

      <Typography variant="body2" style={{ fontWeight: 600, fontSize: '12px' }}>
        {title}
      </Typography>
      {hasInfoTooltip && (
        <Box marginLeft="4px">
          <InformationIconPopOverStatus statusMap={statusMap} htmlElement={tooltipText} />
        </Box>
      )}
      <Box key={name} data-testid="app-sort-label-container" className={classes.sortIcon}>
        <Box ml={1}>
          <Box component="span" onClick={handleClick} data-testid="sort-options-icon">
            <ArrowDown className={classes.iconDefault} />
          </Box>
          <Popover
            data-testid="sort-options-popover"
            id={id}
            open={open}
            onClose={handleClose}
            anchorEl={anchorElement}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box onClick={handleClose}>{children}</Box>
          </Popover>
        </Box>
      </Box>
    </Box>
  );
};

export default SortLabelComponent;
