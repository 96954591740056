import React from 'react';
import { Typography } from '@mui/material';
import ScrollModal from '../../../components/side-nav/components/scroll-modal';
import CookieNotice from '../cookie-notice';
import useStyles from './styles';

const PrivacyText = () => {
  const { classes } = useStyles();
  return (
    <>
      <Typography className={classes.bold}>Privacy Notice for Customer Admin Tool</Typography>
      <p>
        <Typography className={classes.bold}>Last Revised:</Typography> October 15, 2021
      </p>
      <p>
        <Typography>
          This privacy notice describes how Caterpillar Inc. collect(s), share(s), and process(es)
          the personal information through the Customer Admin Tool; this notice is consistent with
          Caterpillar’s Global Data Privacy Statement and provides additional details relevant to
          this application. Caterpillar’s Global Data Privacy Statement, which should be read with,
          and treated as part of this notice, is available at
          http://www.caterpillar.com/dataprivacy.
        </Typography>
      </p>
      <Typography>
        What personal information does the Customer Admin Tool collect and process, and why?
      </Typography>
      <Typography className={classes.underline}>User-Provided & Collected Information</Typography>
      <p>
        <Typography>
          The Customer Admin Tool is an interactive application that provides information and offers
          for Caterpillar Inc. products and services. We collect information about your computer,
          your use of the Customer Admin Tool, and may collect or receive user provided personal
          information as you interact with us in order to optimize your user experience or address
          your request. Examples of how your information may be used (but not limited to) include
          the delivery of marketing communications or promotional materials, improvements to the
          website, products, and services we provide, and detecting, preventing, and responding to
          misuse of the website (i.e. fraud, violation of law, etc.).
        </Typography>
      </p>
      <p>
        <Typography>
          Personal information is any information about a specific individual or that identifies or
          may identify a specific individual. Some additional information we may collect includes
          contact and relationship or role.
        </Typography>
      </p>
      <p>
        <Typography>
          <Typography className={classes.bold}>• Contact information</Typography> allows us to
          interact and communicate with you.
        </Typography>
      </p>
      <p>
        <Typography>
          <Typography className={classes.bold}>• Relationship or role information</Typography> helps
          us understand who you are and what you are seeking to properly fulfill your request.
        </Typography>
      </p>
      <p>
        <Typography>
          We may share personal information with our affiliated companies, suppliers, dealers, and
          business partners which may use it for the purposes listed above.
        </Typography>
      </p>
      <p>
        <Typography>
          Users are advised that information provided may be cross referenced with other information
          you have provided to us. For example, if you provide your contact information, we may be
          able to provide additional information on products you own and services you use.
        </Typography>
      </p>
      <p>
        <Typography>
          The Customer Admin Tool may directly authenticate or connect a user to an authenticated
          (or logged in) experience based on the user’s digital activity or data inputted by the
          user. An authenticated experience occurs when a set of log-in credentials are obtained
          from the users and will allow for additional access and experiences not allotted to
          non-authenticated users. In these circumstances an anonymous user is not acceptable or
          allowed. Common authenticated users include Caterpillar and Dealer personnel, customers,
          and affiliates. In an authenticated experience we collect the information discussed in the
          section above which will now be tied to an authenticated account profile and additional
          information associated with the account profile. In the event the user provides personal
          information on behalf of another individual the user should ensure proper consent is
          obtained from the individual. Examples of how this information may be used (but not
          limited to) include requesting, accessing, and managing Caterpillar systems, services, or
          programs.
        </Typography>
      </p>
      <p>
        <Typography>
          Some additional personal information we may collect includes personal preferences and
          authenticated account information.
        </Typography>
      </p>
      <p>
        <Typography>
          <Typography className={classes.bold}>• Personal Preferences </Typography>allows us to
          provide an experience adjusted to the way you prefer to ingest content.
        </Typography>
      </p>
      <p>
        <Typography>
          <Typography className={classes.bold}>• Authenticated Account Information</Typography> is
          an identity profile which is requested or created by the user and collected by Caterpillar
          to ensure you are an authorized user.
        </Typography>
      </p>
      <Typography className={classes.underline}>Do Not Track Signals</Typography>
      <p>
        <Typography>
          At this time, this website does not respond to “Do Not Track” signals that may be sent by
          your browser. We may revisit this as standards develop on how such signals should be
          interpreted and applied.
        </Typography>
      </p>
      <Typography className={classes.underline}>Cookies</Typography>
      <p>
        <Typography>
          Cookies and other tracking technologies, in general, are used to improve website and
          application efficiency, enable better processing of information, and for various other
          reasons. To learn more about the Customer Admin Tool’s usage of cookies please refer to
          the cookie notice by visiting:
          <div className={classes.cookieLink}>
            <ScrollModal title="Cookie Notice" bodyText={CookieNotice} />
          </div>
          or by selecting “Cookies” on the website or application website.
        </Typography>
      </p>
      <Typography className={classes.underline}>
        Third Party Integration, Services, and Tracking
      </Typography>
      <p>
        <Typography>
          We may link to third party solutions that enable third-party companies to collect certain
          information when you visit this Site. These companies use non-personally-identifiable
          information (e.g., click stream information, browser type, time and date, subject of
          advertisements clicked or scrolled over, hardware/software information, cookie and session
          ID) and information that may allow for identifying you (e.g., static IP address) during
          your visits to this Site and other websites in order to provide advertisements for
          products likely to be of greater interest to you or advertising-related services, such as
          ad delivery, reporting, attribution, analytics, and market research. These third parties
          typically use a cookie, pixel tag, web beacon or other similar tracking technologies to
          collect this information. For example, in the course of visiting our site, these third
          parties may place or recognize a unique cookie on your browser in order to collect certain
          information about you and your interest in our Products and could be used to show you ads
          on other websites based on your interests. This information may also be used to relate
          devices which belong to the same user (based on user behavior or other information
          collected) or re-identified to an individual user (e.g. an email address). More
          information on these is available in our Cookie Disclosure. This may include:
        </Typography>
      </p>
      <p>
        <Typography>
          • Google’s Google Analytics - Google Analytics is a third party analytics service. We use
          Google Analytics to collect information about the use of this Site. Google Analytics
          collects information on visitor behavior on this Site such as how often users visit this
          Site, what pages they view when they do so, and what other websites they used prior to
          coming to this Site. We use the information we get from Google Analytics to analyze user
          behavior, improve Site performance, personalize the user experience, and analyze the
          effectiveness of our marketing campaigns. Google Analytics collects only the IP address
          assigned to you on the date you visit this site, rather than your name or other
          identifying information. Google Analytics anonymizes the IP address before it is stored.
          We do not receive the non-anonymized IP address. We may combine the Google Analytics data
          with first and third-party data information which may include personally identifiable
          information. Although Google Analytics plants a permanent cookie on your web browser to
          identify you as a unique user the next time you visit this site, the cookie cannot be used
          by anyone but Google. To learn more about how Google collects and uses data, visit
          https://policies.google.com/privacy/partners. For opt-out options specific to Google
          Analytics, please visit https://tools.google.com/dlpage/gaoptout.
        </Typography>
      </p>
      <Typography className={classes.underline}>Website Linkages</Typography>
      <p>
        <Typography>
          As users explore the Customer Admin Tool, they may encounter linkages to other Caterpillar
          and non-Caterpillar websites. Users are advised that as links are selected, they may
          encounter different features and experiences that may have different privacy notices and
          processes not associated with the Customer Admin Tool.
        </Typography>
      </p>
      <Typography className={classes.underline}>
        Where can I obtain further information about this website and its privacy practices?
      </Typography>
      <p>
        <Typography>
          For additional information about Caterpillar’s privacy practices, including corporate
          contact information, please see the Global Data Privacy Statement at
          http://www.caterpillar.com/dataprivacy.
        </Typography>
      </p>
      <Typography className={classes.underline}>
        What happens if this privacy notice changes?
      </Typography>
      <p>
        <Typography>
          We reserve the right to amend this privacy notice. If we update or change this privacy
          notice, the changes will be made on this page. Your use of the Website following the
          posting of changes to this privacy notice will demonstrate your acceptance of those
          changes.
        </Typography>
      </p>
    </>
  );
};

export default PrivacyText;
