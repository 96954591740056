import {
  accessManagementActionNames,
  accessManagementActionTypes,
  AccessManagementFilters,
  AccessManagementState,
} from './types';

const initialState: AccessManagementState = {
  staleAccessManagement: true,
  filters: {
    selectedAccount: '',
    manualSelectedAccountEntry: false,
    selectedCustomers: [],
  },
  accessManagementSearchValue: '',
  isLoadingAccessManagement: false,
  isAccessManagementServerError: false,
  accessManagementData: [],
};

const accessManagementReducer = (
  state: AccessManagementState = initialState,
  action: accessManagementActionTypes,
): AccessManagementState => {
  switch (action.type) {
    case accessManagementActionNames.SET_ACCESS_MANAGEMENT_INITIAL_STATE: {
      return {
        ...initialState,
      };
    }
    case accessManagementActionNames.SET_ACCESS_MANAGEMENT: {
      return {
        ...state,
        accessManagementData: action.payload,
      };
    }
    case accessManagementActionNames.SET_ACCESS_MANAGEMENT_SEARCH_VALUE: {
      return {
        ...state,
        accessManagementSearchValue: action.payload as string,
      };
    }
    case accessManagementActionNames.SET_ACCESS_MANAGEMENT_FILTERS: {
      return {
        ...state,
        filters: action.payload as AccessManagementFilters,
      };
    }
    case accessManagementActionNames.CLEAR_ACCESS_MANAGEMENT_FILTERS: {
      return {
        ...state,
        filters: initialState.filters,
      };
    }
    case accessManagementActionNames.SET_IS_LOADING_ACCESS_MANAGEMENT_DATA: {
      return {
        ...state,
        isLoadingAccessManagement: action.payload,
      };
    }
    case accessManagementActionNames.SET_IS_ACCESS_MANAGEMENT_SERVER_ERROR: {
      return {
        ...state,
        isAccessManagementServerError: action.payload,
      };
    }
    case accessManagementActionNames.REMOVE_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload as keyof AccessManagementFilters]:
            initialState.filters[action.payload as keyof AccessManagementFilters],
        },
      };
    }
    case accessManagementActionNames.SET_STALE_ACCESS_MANAGEMENT: {
      return {
        ...state,
        staleAccessManagement: action.payload,
      };
    }
    default:
      return state;
  }
};

export default accessManagementReducer;
