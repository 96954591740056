import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  dividerText: {
    marginTop: theme.spacing(1.25),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  filterByText: {
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
    fontWeight: 600,
  },
  closeIcon: {
    color: CAT_COLOR.BLACK,
  },
  dialogContent: {
    padding: 0,
  },
  footerSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
