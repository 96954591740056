import React, { FC, ReactElement } from 'react';
import { Grid, Box, Typography, useTheme } from '@mui/material';
import { KeyboardArrowLeftRounded } from '@mui/icons-material';
import shortid from 'shortid';
import InformationIconPopOver from '../information-icon-popover';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import TEXT from '../../globals/translation-map';

export type SubHeaderProperties = {
  title: string | ReactElement;
  titleText?: string;
  actionComponents?: ReactElement[];
  showBottomBorder?: boolean;
  showInformationPopOver?: boolean;
  backButtonCallback?: Function;
  backButtonText?: string;
};

const SubHeader: FC<SubHeaderProperties> = (props: SubHeaderProperties) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { classes } = useStyles();

  const {
    actionComponents,
    title,
    titleText,
    showBottomBorder,
    showInformationPopOver,
    backButtonCallback,
    backButtonText,
  } = props;

  return (
    <Box>
      <Grid
        data-testid="app-sub-header"
        container
        className={showBottomBorder ? classes.container : undefined}
      >
        <Grid item xs={12}>
          <Box
            pl={theme.spacing(3)}
            pr={theme.spacing(1.5)}
            height={theme.spacing(9)}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex">
              {backButtonText && backButtonCallback && (
                <Box className={classes.flex}>
                  <Box onClick={() => backButtonCallback()} className={classes.backContainer}>
                    <Box className={classes.iconContainer}>
                      <KeyboardArrowLeftRounded />
                    </Box>
                    <Typography
                      className={classes.backButton}
                      data-testid="back-button"
                      variant="h4"
                    >
                      {backButtonText}
                    </Typography>
                  </Box>
                  <Typography className={classes.spacer}>{` / `}</Typography>
                </Box>
              )}
              <Typography className={classes.title} data-testid="sub-header-title" variant="h4">
                {title}
              </Typography>
              {titleText && (
                <Typography className={classes.title} variant="h4">
                  :
                </Typography>
              )}
              <Typography className={classes.titleText} data-testid="sub-header-title-text">
                {titleText}
              </Typography>
              {showInformationPopOver ? (
                <Box ml={0.4} mt={0.4}>
                  <InformationIconPopOver
                    placement="right"
                    width="15px"
                    height="15px"
                    title={
                      <Box textAlign={'center'}>
                        {t(TEXT.GLOBALS.HEADER.RECOMMENDATIONS_USE_NAME)}
                      </Box>
                    }
                  />
                </Box>
              ) : null}
            </Box>
            <Box display="flex" flexDirection="row-reverse">
              {actionComponents?.map((component: ReactElement) => (
                <Box key={shortid.generate()} ml={3}>
                  {component}
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SubHeader;
