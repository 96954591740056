import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
  },
  pageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100vh',
    paddingBottom: '10vh',
  },
  siteTitle: {
    fontFamily: 'lato-hairline',
    paddingBottom: '10vh',
  },
  link: {
    fontSize: '14px',
  },
  clearAll: {
    marginTop: theme.spacing(1.25),
    color: CAT_COLOR.WATER_BLUE,
    fontSize: '12px',
    cursor: 'pointer',
  },
  boldRowText: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  smallRowText: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
  },
}));
