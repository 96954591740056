import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  subheader: {
    color: CAT_COLOR.AZURE,
    cursor: 'pointer',
    float: 'right',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  checkmark: {
    float: 'right',
    fill: CAT_COLOR.BRIGHT_GOLD,
  },
  addRemoveItemIcon: {
    float: 'right',
    fill: 'none',
    '&:hover': {
      fill: CAT_COLOR.BRIGHT_GOLD,
    },
  },
  tooltip: {
    marginTop: theme.spacing(2),
  },
  container: {
    marginBottom: theme.spacing(10),
  },
}));
