import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  emailRow: {
    alignItems: 'center',
    width: theme.spacing(50.625),
  },
  addItemIcon: {
    fill: 'none',
    '&:hover': {
      fill: '#FFCC11',
    },
  },
  searchRowRule: {
    '& .MuiFormControl-root': {
      width: theme.spacing(75),
    },
  },
  addItemIconContainer: {
    alignSelf: 'flex-start',
    display: 'flex',
  },
  inputField: {
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
}));
