import React, { ReactNode } from 'react';
import globalStyles from '../../../../globals/styles';
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import TEXT from '../../../../globals/translation-map';

type TransferTableWarningProperties<T> = {
  item: T;
  open: boolean;
  confirmCallback?: React.MouseEventHandler<HTMLButtonElement>;
  cancelCallback?: React.MouseEventHandler<HTMLButtonElement>;
  confirmButtonText?: string;
  cancelButtonText?: string;
  warningText: string | ReactNode;
  titleText?: string;
};

const TransferTableWarning = <T,>(properties: TransferTableWarningProperties<T>) => {
  const {
    open,
    confirmCallback,
    cancelCallback,
    confirmButtonText,
    cancelButtonText,
    warningText,
    titleText,
  } = properties;
  const { classes } = useStyles();
  const { classes: globalClasses } = globalStyles();
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={() => {}}>
      <Box className={classes.headerContainer}>
        <DialogTitle data-testid="warning-title">
          <Typography className={globalClasses.dialogTitle}>
            {titleText ?? t(TEXT.COMMON.WARNING)}
          </Typography>
        </DialogTitle>
        <IconButton
          size="large"
          className={classes.closeIcon}
          onClick={cancelCallback}
          data-testid="transfer-table-warning-cancel-icon-btn"
        >
          <CloseIcon></CloseIcon>
        </IconButton>
      </Box>
      <DialogContent data-testid="warning-content-text" dividers>
        <DialogContentText component="div">
          <Typography component="div">{warningText}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelButtonText && (
          <Button
            data-testid="transfer-warning-cancel-btn"
            onClick={cancelCallback}
            variant="contained"
            color={confirmButtonText ? 'secondary' : 'primary'}
          >
            {cancelButtonText}
          </Button>
        )}
        {confirmButtonText && (
          <Button
            data-testid="transfer-warning-confirm-btn"
            color="primary"
            onClick={confirmCallback}
            variant="contained"
          >
            {confirmButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TransferTableWarning;
