import { UserIdentityState, userIdentityActionNames, UserIdentityActionTypes } from './types';

const initialState: UserIdentityState = {
  noIdentitiesFound: false,
  isLoadingUserIdentity: false,
};

const userIdentityReducer = (
  state: UserIdentityState = initialState,
  action: UserIdentityActionTypes,
): UserIdentityState => {
  switch (action.type) {
    case userIdentityActionNames.SET_IDENTITIES: {
      return {
        ...state,
        identities: action.payload,
      };
    }
    case userIdentityActionNames.SET_NO_USER_FOUND_FLG: {
      return {
        ...state,
        noIdentitiesFound: action.payload,
      };
    }
    case userIdentityActionNames.CLEAR_IDENTITIES: {
      return {
        ...state,
        identities: initialState.identities,
      };
    }

    case userIdentityActionNames.SET_USER_IDENTITY_SEARCH_ERROR: {
      return {
        ...state,
        searchUserIdentityError: action.payload,
      };
    }
    case userIdentityActionNames.SET_LOADING_FOR_USER_IDENTITY: {
      return {
        ...state,
        isLoadingUserIdentity: action.payload,
      };
    }
    default:
      return state;
  }
};

export default userIdentityReducer;
