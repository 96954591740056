import React from 'react';
import { Box, Typography } from '@mui/material';
import useStyle from './styles';
import { CatInlineNotification } from 'blocks-react/bedrock/components/InlineNotification/InlineNotification';
import { CatIconInfo } from 'blocks-react/bedrock/components/Icons/Info';

const InlineNotification = (props: {
  header?: string;
  text: string;
  testId?: string;
  width?: string;
  variant?: 'error' | 'warning' | 'success';
}) => {
  const { variant, header, text, testId = 'inline-notification', width } = props;
  const { classes } = useStyle();

  return (
    <Box data-testid={`${testId}-wrapper`} width={width}>
      <CatInlineNotification variant={variant}>
        <CatIconInfo slot="before" data-testid={`${testId}-icon`}></CatIconInfo>
        <Box>
          {header && (
            <Typography data-testid={`${testId}-header`} className={classes.header}>
              {header}
            </Typography>
          )}
          <Typography data-testid={`${testId}-text`}>{text}</Typography>
        </Box>
      </CatInlineNotification>
    </Box>
  );
};
export default InlineNotification;
