/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs, get financial account details, associate/disassociate financial products to a customer organization. 
 *
 * OpenAPI spec version: 1.41.2
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * `dealerCustomerDetails` and `dealerCustomerAddresses` properties that can be used for filtering. 
 */
export type DealerCustomerSearchPropertiesForFiltering = 'dealerCustomerIdentifier' | 'dealerCustomerNumber' | 'dealerCustomerBusinessName' | 'address1' | 'cityName' | 'stateOrProvinceCode' | 'postalCode' | 'countryCode' | 'status' | 'dealerCode' | 'associatedCustomerOrganizationIdentifier' | 'associatedCustomerOrganizationBusinessName' | 'keyCatAccountNumber' | 'brandCode';

export const DealerCustomerSearchPropertiesForFiltering = {
    DealerCustomerIdentifier: 'dealerCustomerIdentifier' as DealerCustomerSearchPropertiesForFiltering,
    DealerCustomerNumber: 'dealerCustomerNumber' as DealerCustomerSearchPropertiesForFiltering,
    DealerCustomerBusinessName: 'dealerCustomerBusinessName' as DealerCustomerSearchPropertiesForFiltering,
    Address1: 'address1' as DealerCustomerSearchPropertiesForFiltering,
    CityName: 'cityName' as DealerCustomerSearchPropertiesForFiltering,
    StateOrProvinceCode: 'stateOrProvinceCode' as DealerCustomerSearchPropertiesForFiltering,
    PostalCode: 'postalCode' as DealerCustomerSearchPropertiesForFiltering,
    CountryCode: 'countryCode' as DealerCustomerSearchPropertiesForFiltering,
    Status: 'status' as DealerCustomerSearchPropertiesForFiltering,
    DealerCode: 'dealerCode' as DealerCustomerSearchPropertiesForFiltering,
    AssociatedCustomerOrganizationIdentifier: 'associatedCustomerOrganizationIdentifier' as DealerCustomerSearchPropertiesForFiltering,
    AssociatedCustomerOrganizationBusinessName: 'associatedCustomerOrganizationBusinessName' as DealerCustomerSearchPropertiesForFiltering,
    KeyCatAccountNumber: 'keyCatAccountNumber' as DealerCustomerSearchPropertiesForFiltering,
    BrandCode: 'brandCode' as DealerCustomerSearchPropertiesForFiltering
};