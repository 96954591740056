import React from 'react';

import UserLeadsHeader from './components/user-leads-header';
import { Box } from '@mui/material';
import UserLeadsTable from './components/user-leads-table';

const UserLeads: React.FC = () => {
  return (
    <Box>
      <UserLeadsHeader />
      <UserLeadsTable />
    </Box>
  );
};

export default UserLeads;
