import React from 'react';
import useStyles from './styles';

const LegalText = () => {
  const { classes } = useStyles();
  return (
    <>
      <div className={classes.header}>Caterpillar Legal Notices</div>
      <div>
        Customer Admin Tool Terms of Use
        <p>
          <text className={classes.underlineBold}>For dealer users</text>, the Master Dealer
          Technology Services Agreement and Technology Offering Schedule for the Customer Admin Tool
          applies to your use of the Customer Admin Tool as an Authorized User. You can contact your
          dealer’s Authorized Signatories if you have questions regarding your use of and access to
          the Customer Admin Tool.
        </p>
        <p>
          <text className={classes.underlineBold}>For Caterpillar users</text>, this User Agreement
          (this “Agreement”) is intended to be an agreement between you, as a User, and Caterpillar
          Inc. (Caterpillar Inc., and its subsidiaries and affiliates being collectively referred to
          herein as “Caterpillar”) that governs your access and use of the Customer Admin Tool,
          including the data contained therein.
        </p>
        <p>
          <p>
            <div className={classes.bold}>Purpose </div> User shall use the Customer Admin Tool
            consistent with the User’s job role for business purposes.
          </p>
          <p>
            <div className={classes.bold}>Confidentiality</div> User must comply with Caterpillar
            Enterprise Procedure 1:46 in using the Customer Admin Tool. User shall comply with
            Caterpillar Enterprise Procedure 1:37 (Information Security) and Caterpillar Enterprise
            Procedure 1:46 (Confidential Information) to protect the Customer Admin Tool from misuse
            and unauthorized access or disclosure.
          </p>
          <p>
            <div className={classes.bold}>Personal Information</div> User will not use or disclose
            any personally information in violation of Caterpillar Enterprise Procedure 2:28 (Data
            Privacy) or laws and regulations, including but not limited to the General Data
            Protection Regulation of the European Union.
          </p>
          <p>
            <div className={classes.bold}>Changes</div> Except to the extent prohibited by
            applicable Laws, Caterpillar may modify the terms and conditions of this Agreement. Your
            continued access to or use of the Customer Admin Tool after any change to this Agreement
            goes into effect will constitute your acceptance of such change. The “Last Revised” date
            is set forth below.
          </p>
        </p>
        Last Revised Date: October 15, 2021
      </div>
    </>
  );
};
export default LegalText;
