import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  formControl: {
    width: '100%',
  },
  textField: {
    width: '75%',
    '& div': {
      fontSize: 12,
      textAlign: 'left',
    },
  },
  input: {
    borderBottom: 'none',
    '&:hover': {
      borderBottom: 'none',
    },
    '&:focus': {
      borderBottom: 'none',
      paddingRight: 0,
    },
  },
  paper: {
    boxShadow: '0px 0px 10px rgb(80 95 122 / 30%)',
    '& .MuiAutocomplete-listbox': {
      paddingTop: 1,
      paddingBottom: 1,
    },
    '& .MuiAutocomplete-option': {
      backgroundColor: CAT_COLOR.WHITE,
      '&[aria-selected=true]': {
        backgroundColor: 'transparent',
        '& .MuiTypography-root': {
          color: CAT_COLOR.DARK_GRAYISH_BLUE,
        },
        '&:hover': {
          backgroundColor: CAT_COLOR.DARK_GRAYISH_BLUE,
          '& .MuiTypography-root': {
            color: CAT_COLOR.WHITE,
          },
        },
      },
      '& .Mui-focused': {
        backgroundColor: 'transparent',
      },
      '& .MuiCheckbox-root': {
        color: CAT_COLOR.DARK_GRAYISH_BLUE,
      },
      '& .MuiTypography-root': {
        color: CAT_COLOR.DARK_GRAYISH_BLUE,
      },
      '&:hover': {
        backgroundColor: CAT_COLOR.DARK_GRAYISH_BLUE,
        '& .MuiCheckbox-root': {
          color: CAT_COLOR.DARK_GRAYISH_BLUE,
        },
        '& .MuiTypography-root': {
          color: CAT_COLOR.DARK_GRAYISH_BLUE,
        },
      },
    },
  },
  checkedIcon: {
    backgroundColor: CAT_COLOR.DARK_GRAYISH_BLUE,
    color: CAT_COLOR.GOLDEN_POPPY,
  },
  option: {
    display: 'flex',
  },
}));
