import React from 'react';
import { iconType } from './icon-types';

const UsersIcon = (props: iconType) => {
  const { stroke = '#666565' } = props;
  return (
    <svg width="22" height="23" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0651 5.66926C10.0651 7.59676 8.50509 9.15676 6.57759 9.15676C4.65009 9.15676 3.09009 7.59676 3.09009 5.66926C3.09009 3.74176 4.65009 2.18176 6.57759 2.18176C8.50509 2.18176 10.0651 3.74176 10.0651 5.66926ZM8.94009 5.66926C8.94009 4.36426 7.88259 3.30676 6.57759 3.30676C5.27259 3.30676 4.21509 4.36426 4.21509 5.66926C4.21509 6.97426 5.27259 8.03176 6.57759 8.03176C7.88259 8.03176 8.94009 6.97426 8.94009 5.66926Z"
        fill={stroke}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.8475 10.3718C7.83 10.4243 8.79 10.7168 9.63 11.2343V11.2418C10.47 11.7593 11.1675 12.4868 11.655 13.3418C12.1425 14.1968 12.405 15.1718 12.4125 16.1543V16.7918C12.4125 17.0993 12.1575 17.3543 11.85 17.3543H1.3125C1.005 17.3543 0.75 17.0993 0.75 16.7918V16.1543C0.7575 15.1643 1.02 14.1893 1.5075 13.3343C1.995 12.4793 2.6925 11.7518 3.5325 11.2343C4.365 10.7168 5.3325 10.4168 6.315 10.3718C6.495 10.3643 6.6675 10.3643 6.8475 10.3718ZM1.875 16.2293H11.2875V16.1543C11.28 15.3668 11.07 14.5793 10.68 13.8893C10.29 13.1993 9.72 12.6143 9.045 12.1943C8.37 11.7743 7.59 11.5343 6.795 11.4968H6.3675C5.5725 11.5343 4.7925 11.7818 4.1175 12.1943C3.435 12.6143 2.8725 13.1993 2.4825 13.8893C2.0925 14.5793 1.8825 15.3668 1.875 16.1618V16.2293Z"
        fill={stroke}
      />
      <path
        d="M11.2646 8.03176C10.9571 8.03176 10.7021 8.28676 10.7021 8.59426C10.7021 8.90176 10.9571 9.15676 11.2646 9.15676C13.1921 9.15676 14.7521 7.59676 14.7521 5.66926C14.7521 3.74176 13.1921 2.18176 11.2646 2.18176C10.9571 2.18176 10.7021 2.43676 10.7021 2.74426C10.7021 3.05176 10.9571 3.30676 11.2646 3.30676C12.5696 3.30676 13.6271 4.36426 13.6271 5.66926C13.6271 6.97426 12.5696 8.03176 11.2646 8.03176Z"
        fill={stroke}
      />
      <path
        d="M14.3097 11.2342C15.1497 11.7517 15.8472 12.4792 16.3347 13.3342C16.8222 14.1892 17.0847 15.1642 17.0922 16.1467V16.7842C17.0922 17.0917 16.8372 17.3467 16.5297 17.3467H14.1897C13.8822 17.3467 13.6272 17.0917 13.6272 16.7842C13.6272 16.4767 13.8822 16.2217 14.1897 16.2217H15.9672V16.1467C15.9522 15.3592 15.7422 14.5717 15.3522 13.8817C14.9622 13.1917 14.3922 12.6067 13.7172 12.1867C13.2747 11.9167 12.8022 11.7217 12.2997 11.6092C11.9997 11.5417 11.8122 11.2342 11.8797 10.9342C11.9472 10.6342 12.2472 10.4467 12.5547 10.5142C13.1772 10.6567 13.7697 10.8967 14.3097 11.2342Z"
        fill={stroke}
      />
    </svg>
  );
};

export default UsersIcon;
