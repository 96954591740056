import { CountrySearchResponseItem } from '../../entities/countries-v1/models';

export enum countriesStatesActionNames {
  SET_COUNTRIES = 'SET_COUNTRIES',
}

export interface CountriesStatesState {
  countries: Array<CountrySearchResponseItem>;
}

interface SetCountriesData {
  type: countriesStatesActionNames.SET_COUNTRIES;
  payload: Array<CountrySearchResponseItem>;
}
export type CountriesStatesActionTypes = SetCountriesData;
