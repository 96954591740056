import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const CheckMarkIcon = ({ stroke = '#78869E', ...restProps }: iconType) => (
  <SvgIcon
    stroke={stroke}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...restProps}
    viewBox="0 0 30 30"
    style={{ width: '30px', height: '30px' }}
  >
    <circle cx="15" cy="15" r="14.5" stroke="#FFCC11" />

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4346 14.9277L14.5106 18.2223L20.3235 12"
      fill="#FFCC11"
    />
    <path
      d="M11.4346 14.9277L14.5106 18.2223L20.3235 12"
      stroke="#43485C"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </SvgIcon>
);

export default CheckMarkIcon;
