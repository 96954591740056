import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  flexBox: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    margin: 'auto',
  },
  flexItem: {
    flexGrow: 0,
    flexShrink: 0,
    textAlign: 'left',
    padding: theme.spacing(2),
  },
  label: {
    color: CAT_COLOR.LIGHT_SLATE_GREY,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '14.5px',
    letterSpacing: '0.18px',
    paddingBottom: theme.spacing(1),
  },
  data: {
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '20px',
    paddingBottom: theme.spacing(1),
  },
}));
