import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const ContactSupport = (props: iconType) => {
  const { stroke = '#78869E' } = props;
  return (
    <SvgIcon
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
      viewBox="0 0 22 22"
      style={{ fill: 'none', width: '24px', height: '24px' }}
    >
      <svg
        width="22"
        height="21"
        viewBox="0 0 22 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.7401 8.32609H14.0445V11.8043L17.9575 8.32609H21.001V0.5H7.95752V3.97826"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <mask id="path-2-inside-1" fill="white">
          <path d="M17.9575 4.41304H17.088V5.28261H17.9575V4.41304Z" />
        </mask>
        <path
          d="M17.9575 4.41304H18.9575V3.41304H17.9575V4.41304ZM17.088 4.41304V3.41304H16.088V4.41304H17.088ZM17.088 5.28261H16.088V6.28261H17.088V5.28261ZM17.9575 5.28261V6.28261H18.9575V5.28261H17.9575ZM17.9575 3.41304H17.088V5.41304H17.9575V3.41304ZM16.088 4.41304V5.28261H18.088V4.41304H16.088ZM17.088 6.28261H17.9575V4.28261H17.088V6.28261ZM18.9575 5.28261V4.41304H16.9575V5.28261H18.9575Z"
          fill={stroke}
          mask="url(#path-2-inside-1)"
        />
        <mask id="path-4-inside-2" fill="white">
          <path d="M15.3488 4.41304H14.4792V5.28261H15.3488V4.41304Z" />
        </mask>
        <path
          d="M15.3488 4.41304H16.3488V3.41304H15.3488V4.41304ZM14.4792 4.41304V3.41304H13.4792V4.41304H14.4792ZM14.4792 5.28261H13.4792V6.28261H14.4792V5.28261ZM15.3488 5.28261V6.28261H16.3488V5.28261H15.3488ZM15.3488 3.41304H14.4792V5.41304H15.3488V3.41304ZM13.4792 4.41304V5.28261H15.4792V4.41304H13.4792ZM14.4792 6.28261H15.3488V4.28261H14.4792V6.28261ZM16.3488 5.28261V4.41304H14.3488V5.28261H16.3488Z"
          fill={stroke}
          mask="url(#path-4-inside-2)"
        />
        <mask id="path-6-inside-3" fill="white">
          <path d="M12.7401 4.41304H11.8705V5.28261H12.7401V4.41304Z" />
        </mask>
        <path
          d="M12.7401 4.41304H13.7401V3.41304H12.7401V4.41304ZM11.8705 4.41304V3.41304H10.8705V4.41304H11.8705ZM11.8705 5.28261H10.8705V6.28261H11.8705V5.28261ZM12.7401 5.28261V6.28261H13.7401V5.28261H12.7401ZM12.7401 3.41304H11.8705V5.41304H12.7401V3.41304ZM10.8705 4.41304V5.28261H12.8705V4.41304H10.8705ZM11.8705 6.28261H12.7401V4.28261H11.8705V6.28261ZM13.7401 5.28261V4.41304H11.7401V5.28261H13.7401Z"
          fill={stroke}
          mask="url(#path-6-inside-3)"
        />
        <path
          d="M5.78359 14.2583V16.0365L2.35663 17.2644C1.53576 17.5583 1.00098 18.3365 1.00098 19.2061V20.5H14.0445V19.2061C14.0445 18.3365 13.5088 17.5583 12.6888 17.2652L9.26185 16.0365V14.2583"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M7.52269 14.827C9.41823 14.827 10.9549 12.9816 10.9549 10.7052C10.9549 8.42885 9.41823 6.58348 7.52269 6.58348C5.62715 6.58348 4.09052 8.42885 4.09052 10.7052C4.09052 12.9816 5.62715 14.827 7.52269 14.827Z"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M10.928 10.3357C10.8132 10.3513 10.7184 10.3409 10.6028 10.3643C9.43319 10.5887 8.68276 10.1652 8.03841 9.03826C7.65319 9.77913 6.44624 10.3643 5.45406 10.3643C4.96537 10.3643 4.54537 10.2643 4.12537 10.0435"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default ContactSupport;
