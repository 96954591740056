import SubHeader from '../../../../components/sub-header';
import { t } from 'i18next';
import TEXT from '../../../../globals/translation-map';
import AppPagination from '../../../../components/app-pagination';
import FilterTags from '../../../../components/filter-tags';
import { Box, useTheme } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import SearchInput from '../../../../components/search-input';
import { FilterTagProperties } from '../../../../components/filter-tags/types';

import HeaderRightButtons from './header-right-buttons';
import { useSelector } from 'react-redux';
import { AppState, useDispatchTs } from '../../../../store';
import {
  FiltersDateType,
  userLeadsActionNames,
  UserLeadsFilters,
  UserLeadsFiltersFields,
} from '../../../../store/user-leads/types';

const UserLeadsHeader: React.FC = () => {
  const dispatch = useDispatchTs();
  const theme = useTheme();
  const filters = useSelector((state: AppState) => state.userLeads.filters);
  const [filterTags, setFilterTags] = useState<FilterTagProperties>([]);

  useEffect(() => {
    setupFilterTags();
  }, [filters]);

  const removeSelectedAccount = () => {
    dispatch({
      type: userLeadsActionNames.CLEAR_USER_LEADS_FILTERS,
    });
  };

  const removeUserLeadStatus = (filterValue: string) => {
    dispatch({
      type: userLeadsActionNames.SET_USER_LEADS_FILTERS,
      payload: {
        ...filters,
        status: filters?.status?.filter((status: string) => status !== filterValue),
      },
    });
  };

  const removeDateRange = () => {
    dispatch({
      type: userLeadsActionNames.SET_USER_LEADS_FILTERS,
      payload: {
        ...filters,
        dateRange: {
          startDate: undefined,
          endDate: undefined,
          selectedTimeRangeButton: undefined,
        },
      },
    });
  };

  const setupFilterTags = () => {
    let newFilterTags: FilterTagProperties = [];
    Object.keys(filters as UserLeadsFilters)
      .sort((a, b) => {
        if (a === UserLeadsFiltersFields.selectedAccount) return -1;
        if (b === UserLeadsFiltersFields.selectedAccount) return 1;
        if (a > b) return 1;
        return -1;
      })
      // eslint-disable-next-line unicorn/no-array-for-each
      .forEach((key: string) => {
        if (
          (filters && (filters[key as keyof UserLeadsFilters] as string | string[])?.length > 0) ||
          (filters[key as keyof UserLeadsFilters] as FiltersDateType)
        )
          switch (key) {
            case UserLeadsFiltersFields.selectedAccount:
              newFilterTags.push({
                code: t(TEXT.COMMON.FILTERS.FILTER_TAGS.ACCOUNT, {
                  account: filters[key],
                }),
                removeFunction: () => {
                  removeSelectedAccount();
                },
              });
              break;
            case UserLeadsFiltersFields.status:
              // eslint-disable-next-line unicorn/no-array-for-each
              filters.status.forEach((status) => {
                newFilterTags.push({
                  code: status,
                  removeFunction: () => removeUserLeadStatus(status),
                });
              });
              break;
            case UserLeadsFiltersFields.dateRange:
              if (filters?.dateRange?.startDate || filters?.dateRange?.endDate) {
                const dateString = `${filters?.dateRange?.startDate
                  ?.toDateString()
                  .slice(4)
                  .toLocaleUpperCase()} - ${filters?.dateRange?.endDate
                  ?.toDateString()
                  .slice(4)
                  .toLocaleUpperCase()}`;
                newFilterTags.push({
                  code: dateString,
                  removeFunction: () => removeDateRange(),
                });
              }
              break;
            default:
          }
      });
    newFilterTags && setFilterTags(newFilterTags);
  };

  const customComponentsForAppPagination: ReactElement[] = [
    <Box data-testid="search-input-container">
      <SearchInput
        disabled={false}
        onClickOfSearch={() => {}}
        placeholder={t(TEXT.COMMON.METADATA.BASE.SEARCH_EMAIL)}
        searchHistory="userLeadsSearchHistory"
        clearInput={() => {}}
        searchFieldValue={''}
        searchOnFieldCleared
        trimInput
      />
    </Box>,
    <HeaderRightButtons />,
  ];

  return (
    <Box data-testid="user-leads-header">
      <SubHeader title={t(TEXT.COMMON.TITLE.USER_LEADS)} actionComponents={[]} />
      <AppPagination
        customComponents={customComponentsForAppPagination}
        currentPage={0}
        limit={100}
        totalRecordCount={200}
        onClickActionForNext={() => {}}
        onClickActionForPrevious={() => {}}
      />
      {filters && filterTags.length > 0 && (
        <FilterTags
          filterTags={filterTags}
          expandable={true}
          tagMaxWidth={theme.spacing(40)}
          tagLength={38}
        />
      )}
    </Box>
  );
};

export default UserLeadsHeader;
