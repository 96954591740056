import { makeStyles } from 'tss-react/mui';
export default makeStyles()((theme) => ({
  header: {
    fontWeight: 'bold',
  },
  underlineBold: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  bold: {
    fontWeight: 'bold',
  },
}));
