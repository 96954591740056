/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs, get financial account details, associate/disassociate financial products to a customer organization. 
 *
 * OpenAPI spec version: 1.41.2
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Enum that defines all possible filter types.
 */
export type FilterType = 'contains' | 'stringEquals' | 'startsWith' | 'endsWith' | 'fuzzyMatch';

export const FilterType = {
    Contains: 'contains' as FilterType,
    StringEquals: 'stringEquals' as FilterType,
    StartsWith: 'startsWith' as FilterType,
    EndsWith: 'endsWith' as FilterType,
    FuzzyMatch: 'fuzzyMatch' as FilterType
};