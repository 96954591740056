import { AppState, AppThunkDispatch } from '../index';
import { userLeadsActionNames } from './types';

export const setActiveUserLead = (selectedUserLead: any) => {
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    dispatch({
      type: userLeadsActionNames.SET_ACTIVE_USER_LEAD,
      payload: selectedUserLead,
    });
  };
};
