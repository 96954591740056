import { TimeRange } from '../entities/assets-v2/timeRange';
import { InternalAxiosRequestConfig } from 'axios';

export const convertRangeDateToUTC = ({ left, right, ...otherParams }: TimeRange) => {
  return {
    ...otherParams,
    left: left ? new Date(new Date(left).setUTCHours(0, 0, 0, 0)) : left,
    right: right ? new Date(new Date(right).setUTCHours(23, 59, 59, 999)) : right,
  };
};

export async function handleDateRangeIntercept(config: InternalAxiosRequestConfig) {
  try {
    if (
      config?.data?.filters?.find(
        (filter: any) => Object.hasOwn(filter, 'range') && filter.type === 'timeRange',
      )
    ) {
      config.data.filters = config.data.filters.map((filter: any) =>
        filter?.range && filter.type === 'timeRange'
          ? { ...filter, range: convertRangeDateToUTC(filter.range) }
          : filter,
      );
    }
  } catch {
    return config;
  }

  return config;
}
