import { AppState, AppThunkDispatch } from '../index';
import DealerDetails from '../../services/dealer-details';
import { FiltersIncludingResponseAttr } from '../../entities/dealer-details-v1/filtersIncludingResponseAttr';
import { DealerSearchResponse } from '../../entities/dealer-details-v1/dealerSearchResponse';
import { dealerDetailsActionNames } from './types';
import { DealerDetailsResponse } from '../../entities/dealer-details-v1/dealerDetailsResponse';

export const getDealerDetails = (dealersCodes: string[]) => {
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const {
      login: { authInfo, environmentVariables },
      shared,
    } = getState();

    const existedDealerInfoCodes = new Set<string>(
      shared.dealerDetails.dealers.map(
        ({ dealerCode }: DealerDetailsResponse): string => dealerCode,
      ),
    );

    const newDealerDetailsCodes: string[] = dealersCodes.filter(
      (dealerCode: string): boolean => !existedDealerInfoCodes.has(dealerCode),
    );

    if (newDealerDetailsCodes.length <= 0) {
      return;
    }

    const dealerDetailsService = new DealerDetails(authInfo, environmentVariables.dealerDetailsV1);
    const dealerRequest: FiltersIncludingResponseAttr = {
      logicalExpression: '($0)',
      responseAttributes: ['dealerCode', 'dealerName'],
      filters: [
        {
          propertyName: 'dealerCode',
          type: 'stringEquals',
          values: newDealerDetailsCodes,
        },
      ],
    };

    const dealerResponse = await dealerDetailsService.searchDealerDetails({
      body: dealerRequest,
      limit: 100,
    });

    if (dealerResponse.status === 200 && (dealerResponse.data as DealerSearchResponse)?.dealers) {
      dispatch({
        type: dealerDetailsActionNames.SET_DEALER_DETAILS,
        payload: (dealerResponse.data as DealerSearchResponse).dealers,
      });
    }

    return dealerResponse;
  };
};
