export enum unsavedChangesNotificationActionNames {
  SET_CURRENT_FORM_IS_DIRTY = 'SET_CURRENT_FORM_IS_DIRTY',
  SET_HANDLE_CONTINUE = 'SET_HANDLE_CONTINUE',
  RESET_UNSAVEDCHANGES_STORE = 'RESET_UNSAVEDCHANGES_STORE',
}

export interface UnsavedChangesNotificationState {
  currentPageStatus?: boolean;
  handleContinueNotification?: Function | undefined;
}

interface SetCurrentFormStatus {
  type: typeof unsavedChangesNotificationActionNames.SET_CURRENT_FORM_IS_DIRTY;
  payload: boolean;
}

interface SetClickedMenuItem {
  type: typeof unsavedChangesNotificationActionNames.SET_HANDLE_CONTINUE;
  payload: () => void;
}
interface ResetState {
  type: typeof unsavedChangesNotificationActionNames.RESET_UNSAVEDCHANGES_STORE;
}

export type UnsavedChangesNotificationActionTypes =
  | SetClickedMenuItem
  | SetCurrentFormStatus
  | ResetState;
