import { Box, Typography } from '@mui/material';
import TEXT from '../../../../../../globals/translation-map';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface PartyNameProps {
  partyName?: string;
  partyNumber?: string;
  testId?: string;
}

const PartyNameCell: React.FC<PartyNameProps> = ({
  partyName,
  partyNumber,
  testId,
}: PartyNameProps) => {
  const { t } = useTranslation();
  return (
    <Box data-testid={testId ?? 'party-name-cell-container'}>
      <Typography variant="h5" mb={1}>
        {partyName || '--'}
      </Typography>
      <Box display="flex">
        <Typography variant="h6" fontWeight="600">
          {`${t(TEXT.COMMON.TITLE.CCID)}:`}&nbsp;
        </Typography>
        <Typography variant="h6">{partyNumber || '--'}</Typography>
      </Box>
    </Box>
  );
};

export default PartyNameCell;
