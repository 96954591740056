import {
  CustomerOrganizationActionTypes,
  customerOrganizationActionNames,
  CustomerOrganizationState,
} from './types';

const initialState: CustomerOrganizationState = {
  searchFieldValue: '',
  currentPage: 0,
  myCustomerOrganizationsStaleData: true,
};
const customerOrganizationReducer = (
  state: CustomerOrganizationState = initialState,
  action: CustomerOrganizationActionTypes,
): CustomerOrganizationState => {
  switch (action.type) {
    case customerOrganizationActionNames.SET_MY_CUSTOMER_ORGANIZATIONS: {
      return {
        ...state,
        myCustomerOrganizations: action.payload,
      };
    }
    case customerOrganizationActionNames.SET_MY_CUSTOMER_STALE_DATA: {
      return {
        ...state,
        myCustomerOrganizationsStaleData: action.payload,
      };
    }
    case customerOrganizationActionNames.CLEAR_MY_CUSTOMER_ORGANIZATIONS: {
      return {
        ...state,
        myCustomerOrganizations: initialState.myCustomerOrganizations,
      };
    }
    case customerOrganizationActionNames.SET_SEARCHED_CUSTOMER_ORGANIZATIONS: {
      return {
        ...state,
        searchedCustomerOrganizations: action.payload,
      };
    }
    case customerOrganizationActionNames.CLEAR_SEARCHED_CUSTOMER_ORGANIZATIONS: {
      return {
        ...state,
        searchedCustomerOrganizations: initialState.searchedCustomerOrganizations,
      };
    }
    case customerOrganizationActionNames.SET_ORGANIZATION: {
      return {
        ...state,
        organization: action.payload,
      };
    }
    case customerOrganizationActionNames.SET_DEALER_CODE: {
      return {
        ...state,
        dealerCode: action.payload,
      };
    }
    case customerOrganizationActionNames.SET_EDITED_ORGANIZATION: {
      return {
        ...state,
        editedOrganization: action.payload,
      };
    }
    case customerOrganizationActionNames.SET_EDIT_ERROR: {
      return {
        ...state,
        editError: action.payload,
      };
    }
    case customerOrganizationActionNames.CLEAR_ORGANIZATION: {
      return {
        ...state,
        organization: initialState.organization,
      };
    }
    case customerOrganizationActionNames.SET_NEXT_CURSOR_FOR_ORGANIZATION: {
      return {
        ...state,
        cursorForOrganization: action.payload,
      };
    }
    case customerOrganizationActionNames.CLEAR_NEXT_CURSOR_FOR_ORGANIZATION: {
      return {
        ...state,
        cursorForOrganization: action.payload,
      };
    }
    case customerOrganizationActionNames.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case customerOrganizationActionNames.SET_IS_LOADING_CUSTOMERS_COUNT: {
      return {
        ...state,
        isLoadingCount: action.payload,
      };
    }

    case customerOrganizationActionNames.SET_SEARCH_FIELD_VALUE: {
      return {
        ...state,
        searchFieldValue: action.payload,
      };
    }
    case customerOrganizationActionNames.SET_TOTAL_CUSTOMERS_COUNT: {
      return {
        ...state,
        totalCount: action.payload,
      };
    }
    case customerOrganizationActionNames.SET_MY_CUSTOMER_SELECTED_FILTERS: {
      return {
        ...state,
        myCustomersSelectedFilter: action.payload,
      };
    }
    case customerOrganizationActionNames.RESET_CUSTOMERS: {
      return {
        ...initialState,
      };
    }
    case customerOrganizationActionNames.SET_ADD_IMPORT_CUSTOMER_DISPLAY: {
      return {
        ...state,
        addImportCustomerDisplayEnabled: action.payload,
      };
    }
    case customerOrganizationActionNames.DELETE_CUSTOMER: {
      return {
        ...state,
      };
    }
    case customerOrganizationActionNames.SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    default:
      return state;
  }
};

export default customerOrganizationReducer;
