/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs, get financial account details, associate/disassociate financial products to a customer organization.
 *
 * OpenAPI spec version: 1.41.2
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AuditLogRecommendationItemBase } from './auditLogRecommendationItemBase';
import { CustomerOrganizationIdentifier } from './customerOrganizationIdentifier';
import { DealerCustomerIdentifier } from './dealerCustomerIdentifier';
import { FeedbackReason } from './feedbackReason';
import { RecommendationFeedback } from './recommendationFeedback';
import { RecommendationItemStatus } from './recommendationItemStatus';
import { RecommendationTypeLabel } from './recommendationTypeLabel';

/**
 * Audit log recommendation item for 'ASSOCIATE DCN TO EXISTING CAT ASSOCIATION'.
 */
export interface AuditLogRecommendationAssociateDcnToExistingCatAssociationItem {
  /**
   * The recommendation relationship type represents the various types of recommendation. The field is required when request type supports various types of items.
   */
  recommendationType?: AuditLogRecommendationAssociateDcnToExistingCatAssociationItem.RecommendationTypeEnum;
  recommendationTypeLabel?: RecommendationTypeLabel;
  dealerCustomerIdentifier: DealerCustomerIdentifier;
  customerOrganizationIdentifier: CustomerOrganizationIdentifier;
  feedbackStatus?: RecommendationFeedback;
  feedbackReason?: FeedbackReason;
  recommendationItemStatus: RecommendationItemStatus;
}
export namespace AuditLogRecommendationAssociateDcnToExistingCatAssociationItem {
  export type RecommendationTypeEnum = 'ASSOCIATE DCN TO EXISTING CAT ASSOCIATION';
  export const RecommendationTypeEnum = {
    ASSOCIATEDCNTOEXISTINGCATASSOCIATION:
      'ASSOCIATE DCN TO EXISTING CAT ASSOCIATION' as RecommendationTypeEnum,
  };
}
