import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import useStyles from './styles';
import TEXT from '../../../../globals/translation-map';
import { CatContextualMenu } from 'blocks-react/bedrock/components/ContextualMenu';
import { CatButton } from 'blocks-react/bedrock/components/Button';
import { CatIconMenuDots } from 'blocks-react/bedrock/components/Icons/MenuDots';
import { CatListItem } from 'blocks-react/bedrock/components/ListItem';
import { getOwnershipRequest } from '../../../../store/ownership-requests/actions';
import { useDispatchTs } from '../../../../store';
import { useNavigate } from 'react-router-dom';
import { SearchOwnershipRequestResponse } from '../../../../entities/customerAssetWorkflow-v1/searchOwnershipRequestResponse';

const MoreOptions = (properties: { row: SearchOwnershipRequestResponse }) => {
  const { t } = useTranslation();
  const dispatch = useDispatchTs();
  const { classes } = useStyles();
  const { row } = properties;
  const navigate = useNavigate();

  const handleRowClick = (requestId: string) => {
    dispatch(getOwnershipRequest(requestId));
    navigate('/ownership-details');
  };

  return (
    <Box display="flex" flexDirection="row-reverse" mr={4}>
      <CatContextualMenu position="bottom-end" data-testId="cat-contextual-menu">
        <React.Fragment key=".0">
          <CatButton
            hideText
            slot="trigger"
            variant="ghost"
            data-testId="cat-more-options-icon-btn"
          >
            Menu
            <CatIconMenuDots slot="after" data-testId="cat-more-options-icon" />
          </CatButton>
          <CatListItem
            className={classes.textRed}
            onClick={(event: any) => {
              handleRowClick(row?.ownershipRequestIdentifier || '');
            }}
            data-testid="cancel-ownership-reqest"
          >
            {t(TEXT.OWNERSHIP_REQUESTS.CANCEL_REQUESTS.BUTTON)}
          </CatListItem>
        </React.Fragment>
      </CatContextualMenu>
    </Box>
  );
};

export default MoreOptions;
