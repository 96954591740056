import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  text: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    lineHeight: 1.6,
  },
  link: {
    display: 'inline-block',
    '& div': {
      float: 'left',
      marginTop: theme.spacing(-5 / 4),
    },
    '& div:nth-of-type(1)': {
      float: 'right',
      marginTop: theme.spacing(-5 / 4),
    },
    '& span': {
      color: 'rgb(0, 0, 238)',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& button': {
      color: 'rgb(0, 0, 238)',
      marginTop: 3,
      '&:hover': {
        background: 'none',
      },
    },
  },
}));
