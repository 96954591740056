/* eslint-disable unicorn/no-array-for-each */
import { createSelector } from 'reselect';
import { Condition } from '../../entities/entitlements-v1/condition';
import { Conditions } from '../../entities/entitlements-v1/conditions';
import { PermissionsHashMapForUserBased } from '../../entities/entitlements-v1/permissionsHashMapForUserBased';
import { PartiesResponse } from '../../entities/entitlements-v1/partiesResponse';
import { onlyUnique } from '../../utils/util';

/* mapping to account id --> domain-> permission type*/
// @ts-ignore
// issue https://github.com/reduxjs/reselect/issues/571
export const selectDataPermissionsByAccount = createSelector(
  [
    // Usual first input - extract value from `state`
    (state: PermissionsHashMapForUserBased) => state,
  ],
  (state: { [x: string]: { [x: string]: { filterConditions: Conditions } } }) => {
    const globalAccounts: {
      [key: string]: { [key: string]: { [key: string]: { [key: string]: string } } };
    } = {};
    Object.keys(state).forEach((item) => {
      Object.keys(state[item]).forEach((perm) => {
        if (state[item][perm].filterConditions) {
          (state[item][perm].filterConditions as Conditions).forEach((filters) => {
            let tempfilters = { ...filters };
            delete tempfilters['partyNumbers'];
            filters?.partyNumbers?.forEach((party) => {
              globalAccounts[party] = globalAccounts[party] ?? {};

              globalAccounts[party][item] = globalAccounts[party][item] ?? {};
              globalAccounts[party][item][perm] = globalAccounts[party][item][perm] ?? {
                ...tempfilters,
              };
            });
          });
        }
      });
    });

    return globalAccounts;
  },
);
// @ts-ignore
export const selectAccountsByPermissionType = createSelector(
  [
    (state: PermissionsHashMapForUserBased) => state,
    (state: PermissionsHashMapForUserBased, domain: string, action: string) => {
      return {
        domain,
        action,
      };
    },
  ],
  (
    state: { [x: string]: { [x: string]: { filterConditions: Conditions } } },
    { domain, action }: any,
  ) => {
    return state?.[domain]?.[action]
      ? (state[domain][action].filterConditions as Conditions)
          .reduce((acc: Array<string>, party: Condition) => {
            return [...acc, ...party.partyNumbers];
          }, [])
          .filter((element, index, array) => onlyUnique(element, index, array))
      : [];
  },
);

export const findPrimarayPartyNumber = createSelector(
  [(state: PartiesResponse | undefined) => state],
  (partiesResponse) => {
    return partiesResponse?.parties?.find((party) => party.isPrimary === true)?.partyNumber;
  },
);
