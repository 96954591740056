import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const AdminFormIcon = (props: iconType) => (
  <SvgIcon
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.43478 12.3478C8.43478 12.3478 7.26087 11.9565 7.26087 10C6.6113 10 6.6113 8.43478 7.26087 8.43478C7.26087 8.17652 6.08696 5.30435 8.04348 5.69565C8.43478 4.13043 12.7391 4.13043 13.1304 5.69565C13.4043 6.78348 12.7391 8.23913 12.7391 8.43478C13.3887 8.43478 13.3887 10 12.7391 10C12.7391 11.9565 11.5652 12.3478 11.5652 12.3478V14.3043C13.5061 15.0322 15.4235 15.6347 16.4096 16.3234C18.0139 14.6956 19 12.4652 19 10C19 5.03044 14.9696 1 10 1C5.03044 1 1 5.03044 1 10C1 12.4652 1.99391 14.7035 3.60609 16.3313C4.64696 15.6113 6.69739 14.9617 8.43478 14.3043V12.3478Z"
      stroke="#78869E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default AdminFormIcon;
