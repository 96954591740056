import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Divider, TextField } from '@mui/material';
import { SearchOrganizationResponse } from '../../../../../../entities/customer-master-v1/models';

import StepperButton from '../../../../../../components/stepper-button';
import useStyles from './styles';
import customerSearchValidationSchema from '../../utils/validations';
import { useTranslation } from 'react-i18next';
import TEXT from '../../../../../../globals/translation-map';
import { CustomerInfoWrapper } from '../../../../../my-customers/wrappers';
type SearchCustomerProperties = {
  selectedCompany: SearchOrganizationResponse | undefined;
  handleCustomerSearch: (input: {
    inputCustomerName?: string;
    inputCustomerNumber?: string;
    isNewSearch?: boolean;
  }) => void;
  setCustomers: Function;
  setNextCursor: Function;
  isLoadingForData?: boolean;
  hideParentCustomer?: boolean;
};

const SearchCustomer = (properties: SearchCustomerProperties) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    selectedCompany,
    handleCustomerSearch,
    setCustomers,
    setNextCursor,
    isLoadingForData,
    hideParentCustomer,
  } = properties;
  const [customerName, setCustomerName] = useState<string>('');
  const [error, setError] = useState<string>();
  const [touched, setTouched] = useState<boolean>(false);

  const handleCustomerNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerName(event.target.value);
  };

  const handleSearchClick = () => {
    setCustomers([]);
    setNextCursor();
    handleCustomerSearch({
      inputCustomerName: customerName,
      isNewSearch: true,
    });
  };

  useEffect(() => {
    const validate = async () => {
      try {
        await customerSearchValidationSchema.validate({
          customerName,
        });
        setError(undefined);
      } catch (validateError: any) {
        setError(validateError.message);
      }
    };
    if (touched) {
      validate();
    }
  }, [customerName, touched]);

  const enterPressed = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      handleSearchClick();
    }
  };

  return (
    <Box alignItems="center" data-testid="search-customer-container">
      {!hideParentCustomer && (
        <>
          <CustomerInfoWrapper
            selectedCompany={selectedCompany}
            customerIdTitle={t(TEXT.COMMON.PARENT_CUSTOMER_ID)}
            customerTitle={t(TEXT.COMMON.PARENT_CUSTOMER_NAME)}
          />
          <Box mt={4} mb={4}>
            <Divider />
          </Box>
        </>
      )}

      <Box className={classes.searchContainer}>
        <Typography className={classes.dcnLookup}>{t(TEXT.COMMON.CUSTOMER_LOOKUP)}:</Typography>
        <Grid container spacing={4} mt={2}>
          <Grid item xs={12} sm={7} className={classes.inputField}>
            <Box mb={2} alignContent="center">
              <TextField
                variant="standard"
                disabled={isLoadingForData}
                fullWidth={false}
                data-testid="customer-name"
                id="customer-name"
                className={classes.textField}
                label={t(TEXT.MY_CUSTOMERS.CUSTOMER_DEALER_SEARCH_NAME_ID)}
                value={customerName}
                name="Customer Name"
                onChange={handleCustomerNameChange}
                onBlur={() => setTouched(true)}
                error={!!error}
                helperText={error}
                onKeyPress={(event) => enterPressed(event)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={5} className={classes.inputField}>
            <Box mb={2} alignContent="center">
              <StepperButton
                onClick={handleSearchClick}
                type="submit"
                buttonText={t(TEXT.COMMON.SEARCH)}
                id="search-customer-btn"
                color="primary"
                disabled={!customerName}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SearchCustomer;
