import {
  globalPreferenceActionNames,
  GlobalPreferenceActionTypes,
  GlobalPreferenceState,
} from './types';

const initialState: GlobalPreferenceState = {
  userPreferences: {},
  defaults: {},
  languages: [],
};

const globalPreferenceReducer = (
  state: GlobalPreferenceState = initialState,
  action: GlobalPreferenceActionTypes,
): GlobalPreferenceState => {
  switch (action.type) {
    case globalPreferenceActionNames.SET_GLOBAL_PREFERENCE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case globalPreferenceActionNames.SET_USER_GLOBAL_PREFERENCE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case globalPreferenceActionNames.SET_IS_LOADING_GLOBAL_PREF: {
      return {
        ...state,
        isLoadingGlobalPref: action.payload,
      };
    }
    default:
      return state;
  }
};

export default globalPreferenceReducer;
