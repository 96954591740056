import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../../../../../globals/color-properties';

export default makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
  },
  pageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100vh',
    paddingBottom: '10vh',
  },
  siteTitle: {
    fontFamily: 'lato-hairline',
    paddingBottom: '10vh',
  },
  selectedCompanyContainer: {
    marginBottom: theme.spacing(2),
  },
  searchContainer: {
    marginTop: theme.spacing(2),
  },
  searchButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  company: {
    textAlign: 'initial',
  },
  customerId: {
    textAlign: 'start',
  },
  dcnLookup: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
    color: CAT_COLOR.LIGHT_SLATE_GREY,
    textAlign: 'start',
    fontSize: '16px',
  },
  companyHeader: {
    color: CAT_COLOR.LIGHT_SLATE_GREY,
    marginBottom: theme.spacing(1),
  },
  companyInfo: {
    fontWeight: 'bold',
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
  },
  inputField: {
    button: {
      float: 'left',
    },
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  text: {
    '& p': {
      fontWeight: 'bold',
      color: CAT_COLOR.DARK_GRAYISH_BLUE,
    },
  },
  textField: {
    width: '75%',
    '& input': {
      fontSize: '12px',
    },
  },
}));
