import { IconButton, Divider, Typography, Grid, Box, useTheme } from '@mui/material';
import React, { useCallback, useState } from 'react';
import useStyles from './styles';
import SubHeader from '../../../../components/sub-header';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import {
  addCustomerActionNames,
  SearchDealerCustomerResponseWithErrorResponse,
} from '../../../../store/add-customer/types';
import { AppState, useDispatchTs } from '../../../../store';
import { useSelector } from 'react-redux';
import CustomerAddressDisplay from '../../../../components/customer-address';
import { Accordion, AccordionDetails, AccordionSummary } from '../../../../components/accordion';
import ChipComponent from '../../../../components/chip';
import TextWithToolTip from '../../../../components/text-with-tooltip';
import AccountTypes from '../../../../components/account-types';
import InformationIconPopOver from '../../../../components/information-icon-popover';
import StepperButton from '../../../../components/stepper-button';
import { recommendationsActionNames } from '../../../../store/recommendations/types';
import { customerRecommendationsActionNames } from '../../../../store/customer-recommendations/types';
import { customerOrganizationActionNames } from '../../../../store/customer-organization/types';
import CAT_COLOR from '../../../../globals/color-properties';
import { CustomerInfoWrapper } from '../../../my-customers/wrappers';
import TEXT from '../../../../globals/translation-map';
import { useTranslation } from 'react-i18next';
import { capitalizeString } from '../../../../utils/util';
import {
  DealerCustomer,
  DealerCustomerBusinessName,
  SearchDealerCustomerResponse,
} from '../../../../entities/customer-master-v1/models';

type AssociateDCNProperties = {
  steps?: Array<string>;
};

const ReviewDisassociateDealerCustomerFlow = (props: AssociateDCNProperties) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { classes } = useStyles();
  const dispatch = useDispatchTs();
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { selectedCustomer, disassociatedDcns } = useSelector(
    (state: AppState) => state.customer.addCustomer,
  );
  const { environmentVariables } = useSelector((state: AppState) => state.login);

  const [expandedAccordion, setExpandedAccordion] = useState<boolean>(true);

  const handleClickOk = () => {
    handleClose();
    dispatch({
      type: customerOrganizationActionNames.SET_MY_CUSTOMER_STALE_DATA,
      payload: true,
    });
  };
  const handleClose = useCallback(() => {
    dispatch({
      type: addCustomerActionNames.CLEAR_ASSOCIATED_DEALER_CUSTOMERS,
    });
    dispatch({
      type: addCustomerActionNames.CLEAR_ASSOCIATED_USERS,
    });
    dispatch({
      type: addCustomerActionNames.CLEAR_SELECTED_CUSTOMER,
    });
    dispatch({
      type: addCustomerActionNames.CLEAR_DUPLICATE_CUSTOMERS,
    });
    dispatch({
      type: recommendationsActionNames.CLEAR_RECOMMENDATION_ITEMS,
    });
    dispatch({
      type: customerRecommendationsActionNames.CLEAR_ASSOCIATIONS_FEEDBACK,
    });
    navigate('/my-customers');
  }, [dispatch, navigate]);

  const handleContinue = () => {
    dispatch({
      type: addCustomerActionNames.CLEAR_SELECTED_CUSTOMER,
    });
    navigate('/my-customers');
  };

  const cancelNotification = () => {
    handleContinue();
  };

  const disassociateDealerCustomersNoName = disassociatedDcns?.disassociateDealerCustomersFailed
    ?.disassociateDealerCustomers as SearchDealerCustomerResponseWithErrorResponse[];

  const disassociateDealerCustomersManual = disassociatedDcns?.dcnsToDisassociateManual
    ?.map((item: { dealerCustomerDetails?: DealerCustomer }) => ({
      dealerCustomerDetails: item?.dealerCustomerDetails,
    }))
    ?.map((dcn: SearchDealerCustomerResponse, index: number) => {
      const run = dcn?.dealerCustomerDetails;
      return run;
    });

  const disassociateDealerCustomersName = disassociateDealerCustomersManual?.map(
    (item?: { dealerCustomerBusinessName?: DealerCustomerBusinessName }) => ({
      dealerCustomerBusinessName: item?.dealerCustomerBusinessName,
    }),
  );
  const disassociateDealerCustomers =
    disassociatedDcns?.associatedDcnsLength === undefined && !!disassociateDealerCustomersName
      ? disassociateDealerCustomersNoName?.map(
          (item: SearchDealerCustomerResponseWithErrorResponse, index: number) => ({
            ...item,
            ...disassociateDealerCustomersName[index],
          }),
        )
      : disassociateDealerCustomersManual;

  const apartSuccessful =
    disassociatedDcns?.associatedDcnsLength === undefined && !!disassociateDealerCustomersName
      ? disassociateDealerCustomersNoName
          ?.map((item: SearchDealerCustomerResponseWithErrorResponse, index: number) => ({
            ...item,
            ...disassociateDealerCustomersName[index],
          }))
          .filter(
            (item: SearchDealerCustomerResponseWithErrorResponse) => item.details === undefined,
          )
      : disassociateDealerCustomersManual;

  const apartFailed =
    disassociatedDcns?.associatedDcnsLength === undefined && !!disassociateDealerCustomersName
      ? disassociateDealerCustomersNoName
          ?.map((item: SearchDealerCustomerResponseWithErrorResponse, index: number) => ({
            ...item,
            ...disassociateDealerCustomersName[index],
          }))
          .filter(
            (item: SearchDealerCustomerResponseWithErrorResponse) => item.details !== undefined,
          )
      : [];

  const apartSuccessfulLength = apartSuccessful?.length;
  const apartFailedLength = apartFailed?.length;

  const successfulDisassociatedLength: number =
    disassociatedDcns?.associatedDcnsLength !== undefined
      ? disassociatedDcns?.associatedDcnsLength
      : disassociatedDcns?.associatedDcnsLength === undefined &&
          disassociateDealerCustomers !== undefined &&
          apartSuccessful !== undefined &&
          !!apartSuccessfulLength
        ? apartSuccessfulLength
        : 0;

  const failedDisassociatedLength =
    disassociatedDcns?.associatedDcnsLength !== undefined
      ? 0
      : disassociatedDcns?.associatedDcnsLength === undefined &&
          disassociateDealerCustomers !== undefined &&
          apartFailed !== undefined &&
          !!apartFailedLength
        ? apartFailedLength
        : 0;

  const getStepContent = () => {
    return (
      <Box
        mt={theme.spacing(5)}
        mb={theme.spacing(9)}
        alignItems="center"
        data-testid="parent-customer-info-container-invite"
        textAlign="start"
        className={classes.widthContainer}
      >
        <Typography variant="h4">{t(TEXT.MY_CUSTOMERS.CUSTOMER_PROFILE)}</Typography>

        <Grid container pl={2}>
          <Grid item xs={8}>
            <CustomerInfoWrapper
              customerNameDataTestId="invite-selected-customer-name"
              customerIdDataTestId="invite-selected-customer-id"
              selectedCompany={selectedCustomer}
            />
          </Grid>
          <Grid item xs={4}>
            <Box className={classes.customerAddress}>
              <Typography data-testid="invite-customer-address" className={classes.companyHeader}>
                {t(TEXT.COMMON.ADDRESS)}
              </Typography>
              <Box className={`${classes.companyInfo}`}>
                <CustomerAddressDisplay
                  showFullAddress={true}
                  width="200px"
                  fontWeight={700}
                  address1={
                    selectedCustomer?.customerAddresses?.[0]?.address1 ??
                    selectedCustomer?.customerAddresses?.[0].address2 ??
                    ''
                  }
                  cityName={selectedCustomer?.customerAddresses?.[0]?.cityName}
                  stateOrProvinceCode={
                    selectedCustomer?.customerAddresses?.[0]?.stateOrProvinceCode
                  }
                  countryCode={selectedCustomer?.customerAddresses?.[0]?.countryCode}
                  postalCode={selectedCustomer?.customerAddresses?.[0]?.postalCode}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Typography variant="h4" mt={6} mb={3}>
          {t(TEXT.MY_CUSTOMERS.DISASSOCIATE_DEALER_CUSTOMER.DISASSOCIATIONS)}
        </Typography>
        <Box
          className={`${classes.widthContainer} ${classes.accordionContainer}`}
          data-testid="accordion-container"
        >
          <Accordion
            expanded={expandedAccordion}
            onChange={() => setExpandedAccordion(!expandedAccordion)}
          >
            <AccordionSummary>
              <Typography className={classes.accordionTitle}>
                {t(TEXT.MY_CUSTOMERS.ROW_DETAILS.DEALER_CUSTOMERS)}
              </Typography>
              <Box className={classes.chipContainer}>
                {successfulDisassociatedLength > 0 && (
                  <ChipComponent
                    id={'successful-chip'}
                    label={t(TEXT.COMMON.SUCCESS_COUNT, { count: successfulDisassociatedLength })}
                    backgroundColor={CAT_COLOR.LIGHT_SLATE_GREY}
                    foreColor={CAT_COLOR.WHITE}
                    fontSize={'10px'}
                    fontWeight="bold"
                    blocks={environmentVariables.blocksComponents}
                    variant="green"
                  />
                )}
                {failedDisassociatedLength > 0 && (
                  <ChipComponent
                    id={'failed-chip'}
                    label={t(TEXT.COMMON.FAILED_COUNT, { count: failedDisassociatedLength })}
                    backgroundColor={theme.palette.error.main}
                    foreColor={CAT_COLOR.WHITE}
                    fontSize={'10px'}
                    fontWeight="bold"
                    blocks={environmentVariables.blocksComponents}
                    variant="red"
                  />
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails variant={'grey'}>
              <Box className={classes.associationContainer}>
                <Box
                  className={classes.associationList}
                  data-testid="accordion-successful-list-container"
                >
                  <Typography
                    className={classes.associationListTitle}
                    data-testid="accordion-body-successful-header"
                  >
                    {t(
                      TEXT.MY_CUSTOMERS.DISASSOCIATE_DEALER_CUSTOMER.SUCCESS_DISASSOCIATIONS_COUNT,
                      { count: successfulDisassociatedLength },
                    )}
                  </Typography>
                  {successfulDisassociatedLength === 0 && (
                    <Typography data-testid="empty-sucess-message">(None)</Typography>
                  )}
                  {apartSuccessful?.map((dcn: DealerCustomer | undefined, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <Box className={classes.successfulAssociationsCustomerInfo}>
                          <Box display="flex">
                            <Typography className={classes.dealerCustomerName}>
                              <TextWithToolTip
                                customDataTestId="successful-associated-customer-name"
                                bold
                                fontSize={14}
                                longText={dcn?.dealerCustomerBusinessName ?? ''}
                                numberOfCharsToDisplay={20}
                              />
                            </Typography>
                            <AccountTypes
                              size={theme.spacing(2)}
                              marginLeft={4}
                              managedIconMarginTop="-3px"
                              //the use of any is necessary to avoid changing the logic, spread types may only be created from object types
                              row={{ ...(dcn?.dealerCustomerIdentifier as any) }}
                            />
                          </Box>
                          <Typography data-testid="successful-associated-customer-id" mb={2}>
                            {dcn?.dealerCustomerIdentifier}
                          </Typography>
                        </Box>
                      </React.Fragment>
                    );
                  })}
                </Box>
                <Box
                  className={classes.associationList}
                  data-testid="accordion-failed-list-container"
                >
                  <Typography
                    className={classes.associationListTitle}
                    data-testid="accordion-body-failed-header"
                  >
                    {t(
                      TEXT.MY_CUSTOMERS.DISASSOCIATE_DEALER_CUSTOMER.FAILED_DISASSOCIATIONS_COUNT,
                      { count: failedDisassociatedLength },
                    )}
                  </Typography>
                  {failedDisassociatedLength === 0 && (
                    <Typography data-testid="empty-failure-message">
                      ({capitalizeString(t(TEXT.COMMON.NONE))})
                    </Typography>
                  )}
                  {apartFailed?.map(
                    (
                      dcn: SearchDealerCustomerResponseWithErrorResponse & DealerCustomer,
                      index: number,
                    ) => {
                      return (
                        <React.Fragment key={index}>
                          <Box className={classes.failedAssociationsCustomerInfo}>
                            <Box className={classes.errorIcon}>
                              <InformationIconPopOver
                                width="15px"
                                height="15px"
                                variant="error"
                                shape="alert-info"
                                title={dcn.failed ?? 'Error'}
                              />
                            </Box>
                            <Box className={classes.errorDescription}>
                              <Box display="flex">
                                <Typography className={classes.dealerCustomerName} mr={0.5}>
                                  <TextWithToolTip
                                    customDataTestId="failed-associated-customer-name"
                                    bold
                                    fontSize={14}
                                    longText={dcn.dealerCustomerBusinessName ?? ''}
                                    numberOfCharsToDisplay={20}
                                  />
                                </Typography>
                                <AccountTypes
                                  size={theme.spacing(2)}
                                  marginLeft={4}
                                  managedIconMarginTop="-3px"
                                  //the use of any is necessary to avoid changing the logic, spread types may only be created from object types
                                  row={{ ...(dcn.dealerCustomerIdentifier as any) }}
                                />
                              </Box>
                              <Typography data-testid="failed-associated-customer-id">
                                {dcn.dealerCustomerIdentifier}
                              </Typography>
                            </Box>
                          </Box>
                        </React.Fragment>
                      );
                    },
                  )}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <SubHeader
        title={
          <span>
            {t(TEXT.MY_CUSTOMERS.DISASSOCIATE_DEALER_CUSTOMER.TITLE)}:{' '}
            <span className={classes.titleTextSmall}>{t(TEXT.COMMON.TITLE.REVIEW_RESULTS)}</span>
          </span>
        }
        data-testid="disassociate-dcn-customer"
        actionComponents={[
          <IconButton
            onClick={() => cancelNotification()}
            data-testid="disassociate-dcn-close-icon"
            size="large"
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
      <Divider />
      <Box display="flex" justifyContent="center" mt={4}>
        <Box mb={theme.spacing(2)}> {getStepContent()}</Box>
      </Box>
      <Box
        bottom={0}
        right={0}
        width="100%"
        height={theme.spacing(10)}
        pb={2}
        className={classes.footerActions}
        position="fixed"
      >
        <Divider className={classes.divider} />
        <Box
          display="flex"
          justifyContent="flex-end"
          mt={2}
          mb={1}
          mr={2}
          width="100%"
          data-testid="footer-buttons-container"
        >
          <Box key="ok-button-container" mr={2}>
            <StepperButton
              buttonText={t(TEXT.COMMON.OK)}
              id="ok-button"
              color="primary"
              type="button"
              onClick={() => handleClickOk()}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ReviewDisassociateDealerCustomerFlow;
