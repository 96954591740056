import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },

  tbody: {
    display: 'flex',
    flexGrow: 1,
  },
}));
