import { AssetSearchResponse } from '../../entities/asset-metadata-v1/models';
import {
  SubscribedAndUnsubscribedAsset,
  CreateDcdcnRequestForAssetRegistration,
  AddUpdateAssetRequestForAssetRegistration,
  AssociationForSubscribedAndUnsubscribedAsset,
  AssetMetadata,
  BaseAssetName,
  AssetDcDcnInfo,
} from '../../entities/assets-v2/models';
import { OrganizationResponse } from '../../entities/customer-master-v1/organizationResponse';
import { SearchDealerCustomerResponse } from '../../entities/customer-master-v1/searchDealerCustomerResponse';
import { OwnershipType } from '../../services/assets';

export enum assetManagementActionNames {
  CLEAR_ASSET_MANAGEMENT_FILTERS = 'CLEAR_ASSET_MANAGEMENT_FILTERS',
  CLEAR_EXISTING_RECORD = 'CLEAR_EXISTING_RECORD',
  CLEAR_OWNERSHIP_DETAILS = 'CLEAR_OWNERSHIP_DETAILS',
  CLEAR_SELECTED_ASSET = 'CLEAR_SELECTED_ASSET',
  CLEAR_SELECTED_CUSTOMER = 'CLEAR_SELECTED_CUSTOMER',
  SET_ALL_ASSET_MANAGEMENT = 'SET_ALL_ASSET_MANAGEMENT',
  SET_ASSET_MANAGEMENT = 'SET_ASSET_MANAGEMENT',
  SET_ASSET_MANAGEMENT_COUNT = 'SET_ASSET_MANAGEMENT_COUNT',
  SET_ASSET_MANAGEMENT_CURSOR = 'SET_ASSET_MANAGEMENT_CURSOR',
  SET_ASSET_MANAGEMENT_FILTERS = 'SET_ASSET_MANAGEMENT_FILTERS',
  SET_ASSET_MANAGEMENT_ORDER_BY = 'SET_ASSET_MANAGEMENT_ORDER_BY',
  SET_ASSET_MANAGEMENT_SEARCH_VALUE = 'SET_ASSET_MANAGEMENT_SEARCH_VALUE',
  SET_ASSET_MANAGEMENT_SORT_BY = 'SET_ASSET_MANAGEMENT_SORT_BY',
  SET_EXPIRE_ASSET_LOADING = 'SET_EXPIRE_ASSET_LOADING',
  SET_INITIAL_SERIAL_NUMBER = 'SET_INITIAL_SERIAL_NUMBER',
  SET_IS_CREATE_DC_DCN_RELATIONSHIP_LOADING = 'SET_IS_CREATE_DC_DCN_RELATIONSHIP_LOADING',
  SET_IS_EDIT_ASSET_LOADING = 'SET_IS_EDIT_ASSET_LOADING',
  SET_IS_GET_ASSET_DETAILS_LOADING = 'SET_IS_GET_ASSET_DETAILS_LOADING',
  SET_CAT_ASSET_LOADING_METADATA = 'SET_CAT_ASSET_LOADING_METADATA',
  SET_IS_LOADING_ASSET_MANAGEMENT_DATA = 'SET_IS_LOADING_ASSET_MANAGEMENT_DATA',
  SET_IS_LOADING_ASSET_MANAGEMENT_DATA_COUNT = 'SET_IS_LOADING_ASSET_MANAGEMENT_DATA_COUNT',
  SET_IS_UPDATE_OWNERSHIP_LOADING = 'SET_IS_UPDATE_OWNERSHIP_LOADING',
  SET_OWNERSHIP_DETAILS = 'SET_OWNERSHIP_DETAILS',
  SET_OWNING_DEALER = 'SET_OWNING_DEALER',
  SET_SELECTED_ASSET = 'SET_SELECTED_ASSET',
  SET_SELECTED_CUSTOMER = 'SET_SELECTED_CUSTOMER',
  SET_SELECTED_OWNER = 'SET_SELECTED_OWNER',
  SHOW_NO_ADD_ASSET = 'SHOW_NO_ADD_ASSET',
  SHOW_NO_OWNERSHIP = 'SHOW_NO_OWNERSHIP',
  UPDATE_CAT_ASSET_METADATA = 'UPDATE_CAT_ASSET_METADATA',
  UPDATE_FILTER_BY_TYPE = 'UPDATE_FILTER_BY_TYPE',
  SET_CCID_ASSETS = 'SET_CCID_ASSETS',
  SET_STALE_ASSET_MANAGEMENT = 'SET_STALE_ASSET_MANAGEMENT',
  IS_CUSTOMER_ASSET_PAGE_DATA = 'IS_CUSTOMER_ASSET_PAGE_DATA',
}

type addAssetType = {
  ownershipDetails?: (SearchDealerCustomerResponse & { ownershipType?: string }) | undefined;
};

export interface AssetManagementState {
  addAsset?: addAssetType;
  assetManagementData: SubscribedAndUnsubscribedAsset[];
  assetAllManagementData: SubscribedAndUnsubscribedAsset[];
  assetManagementSearchValue: {
    [key in AssetManagementPage]: string;
  };
  cursors: Array<string>;
  staleAssetManagement: boolean;
  filters: {
    [key in AssetManagementPage]: AssetManagementFilters;
  };
  isEditAssetLoading: boolean;
  isLoadingAssetManagement: boolean;
  isLoadingAssetManagementCount: boolean;
  isNewSelectedOwner?: boolean;
  orderBy?: Array<string>;
  selectedAsset?: SubscribedAndUnsubscribedAsset;
  selectedCustomer?: OrganizationResponse;
  selectedOwner?: selectedOwnerType;
  sortBy?: Array<string>;
  totalCount: number;
  isLoadingUpdateOwnership: {
    [key: string]: boolean;
  };
  showNoOwnership?: boolean;
  owningDealer?: AssociationForSubscribedAndUnsubscribedAsset;
  isGetAssetDetailsLoading: boolean;
  showNoAddAsset?: boolean;
  isExpireAssetLoading: boolean;
  initialSerialNumber?: string;
  existingRecord?: AssetSearchResponse;
  isCreateDcDcnRelationshipLoading: boolean;
  loadingCatAssetMetadata?: boolean;
  ccidAssets: SubscribedAndUnsubscribedAsset[];
  isCustomerAssetPageData: boolean;
}

export type createAssetRequest = {
  body: AssetRegistrationRequest;
  partyNumber: string;
  makeCode: string;
  serialNumber: string;
  ucid?: string;
  dcn?: string;
  dealerCode?: string;
};

export interface SetSelectedAsset {
  type: typeof assetManagementActionNames.SET_SELECTED_ASSET;
  payload: SubscribedAndUnsubscribedAsset | undefined;
}

export interface SetInitialSerialNumber {
  type: typeof assetManagementActionNames.SET_INITIAL_SERIAL_NUMBER;
  payload: string | undefined;
}
export interface SetCatAssetMetadata {
  type: typeof assetManagementActionNames.UPDATE_CAT_ASSET_METADATA;
  payload: AssetSearchResponse | AssetMetadata;
}

export type AssetManagementFilters = {
  makeCode?: string | undefined;
  ownershipTypeCode?: Array<string>;
  dcnRelationStatusCode?: Array<string>;
  selectedCustomers?: Array<OrganizationResponse>;
};

export type UpdateAssetRequest = {
  body: AddUpdateAssetRequestForAssetRegistration;
  partyNumber: string;
  makeCode: string;
  serialNumber: string;
  ucid?: string;
  'X-Cat-API-Tracking-Id'?: string;
};

export type UpdateDcDcnRequest = {
  body: CreateDcdcnRequestForAssetRegistration;
  partyNumber: string;
  makeCode: string;
  serialNumber: string;
  ucid: string;
  dealerCode: string;
  dcn: string;
  assetName?: BaseAssetName;
  'X-Cat-API-Tracking-Id'?: string;
};

export type createUpdateAssetResponse = {
  id: string;
  customerAssetId: string;
  makeCode: string;
  serialNumber: string;
  assetName: string;
  model: string;
  buildYear: string;
  typeCode: string;
  productFamilyName: string;
  productFamilyCode: string;
  ownership: OwnershipType;
  relationStatus: string;
  imageUrl: string;
  imageId: string;
  customerAssetName: string;
  customerMakeName: string;
  customerModel: string;
  customerBuildYear: string;
  customerProductFamilyCode: string;
  customerProductFamilyName: string;
  customerOwnershipType: string;
  customerDefinedStatusCode: string;
  customerTimezone: string;
  customerIconUrl: string;
  customerIconId: string;
};

export type ExpireAssetRequest = {
  partyNumber: string;
  makeCode: string;
  serialNumber: string;
  ucid: string;
  dealerCode: string;
  dcn: string;
  expireAssociatedDcdcn?: boolean;
  'X-Cat-API-Tracking-Id'?: string;
};

export type selectedOwnerType = {
  dealer: SearchDealerCustomerResponse;
  ownershipType: OwnershipType;
};

export interface SetIsAssetManagementLoading {
  type: typeof assetManagementActionNames.SET_IS_LOADING_ASSET_MANAGEMENT_DATA;
  payload: boolean;
}
export interface SetCatAssetLoadingMetadata {
  type: typeof assetManagementActionNames.SET_CAT_ASSET_LOADING_METADATA;
  payload: boolean;
}

export interface SetIsAssetManagementCountLoading {
  type: typeof assetManagementActionNames.SET_IS_LOADING_ASSET_MANAGEMENT_DATA_COUNT;
  payload: boolean;
}

export interface SetIsEditAssetLoading {
  type: typeof assetManagementActionNames.SET_IS_EDIT_ASSET_LOADING;
  payload: boolean;
}

export interface SetIsUpdateOwnershipLoading {
  type: typeof assetManagementActionNames.SET_IS_UPDATE_OWNERSHIP_LOADING;
  payload: {
    [key: string]: boolean;
  };
}

export interface ClearAssetManagementFilters {
  type: typeof assetManagementActionNames.CLEAR_ASSET_MANAGEMENT_FILTERS;
  payload: { page: string };
}

export interface UpdateAssetManagementFilters {
  type: typeof assetManagementActionNames.UPDATE_FILTER_BY_TYPE;
  payload: { page: string; filters: AssetManagementFilters };
}

export interface SetAssetManagement {
  type: typeof assetManagementActionNames.SET_ASSET_MANAGEMENT;
  payload: Array<AssetDcDcnInfo> | Array<SubscribedAndUnsubscribedAsset>;
}

export interface SetAllAssetManagement {
  type: typeof assetManagementActionNames.SET_ALL_ASSET_MANAGEMENT;
  payload: Array<SubscribedAndUnsubscribedAsset>;
}

export interface SetCcidAssets {
  type: typeof assetManagementActionNames.SET_CCID_ASSETS;
  payload: Array<SubscribedAndUnsubscribedAsset>;
}

export interface SetAssetManagementFilters {
  type: typeof assetManagementActionNames.SET_ASSET_MANAGEMENT_FILTERS;
  payload: { page: string; filters: AssetManagementFilters };
}

export interface SetAssetManagementSearchValue {
  type: typeof assetManagementActionNames.SET_ASSET_MANAGEMENT_SEARCH_VALUE;
  payload: { page: AssetManagementPage; value: string };
}

export interface ShowNoOwnership {
  type: typeof assetManagementActionNames.SHOW_NO_OWNERSHIP;
  payload: boolean;
}

export interface SetAssetManagementCount {
  type: typeof assetManagementActionNames.SET_ASSET_MANAGEMENT_COUNT;
  payload: number;
}

export interface SetAssetManagementCursor {
  type: typeof assetManagementActionNames.SET_ASSET_MANAGEMENT_CURSOR;
  payload: Array<string>;
}

export interface SetAssetManagementSortBy {
  type: typeof assetManagementActionNames.SET_ASSET_MANAGEMENT_SORT_BY;
  payload: Array<string>;
}

export interface SetAssetManagementOrderBy {
  type: typeof assetManagementActionNames.SET_ASSET_MANAGEMENT_ORDER_BY;
  payload: Array<string>;
}

export interface SetOwnershipDetails {
  type: typeof assetManagementActionNames.SET_OWNERSHIP_DETAILS;
  payload: SearchDealerCustomerResponse;
}

export interface SetSelectedCustomer {
  type: typeof assetManagementActionNames.SET_SELECTED_CUSTOMER;
  payload: OrganizationResponse;
}

export interface SetOwningDealer {
  type: typeof assetManagementActionNames.SET_OWNING_DEALER;
  payload: AssociationForSubscribedAndUnsubscribedAsset;
}

export interface ClearSelectedCustomer {
  type: typeof assetManagementActionNames.CLEAR_SELECTED_CUSTOMER;
}

export interface ClearSelectedAsset {
  type: typeof assetManagementActionNames.CLEAR_SELECTED_ASSET;
}

export interface ClearExistingRecord {
  type: typeof assetManagementActionNames.CLEAR_EXISTING_RECORD;
}

export interface ClearAddAssetOwnershipDetails {
  type: typeof assetManagementActionNames.CLEAR_OWNERSHIP_DETAILS;
}

export interface SetIsGetAssetDetailsLoading {
  type: typeof assetManagementActionNames.SET_IS_GET_ASSET_DETAILS_LOADING;
  payload: boolean;
}

export interface ShowNoAddAsset {
  type: typeof assetManagementActionNames.SHOW_NO_ADD_ASSET;
  payload: boolean;
}

export interface SetExpireAssetLoading {
  type: typeof assetManagementActionNames.SET_EXPIRE_ASSET_LOADING;
  payload: boolean;
}

export interface SetIsCreateDcDcnRelationshipLoading {
  type: typeof assetManagementActionNames.SET_IS_CREATE_DC_DCN_RELATIONSHIP_LOADING;
  payload: boolean;
}

export interface SetStaleAssetManagement {
  type: typeof assetManagementActionNames.SET_STALE_ASSET_MANAGEMENT;
  payload: boolean;
}

export interface SetIsCustomerAssetPageData {
  type: typeof assetManagementActionNames.IS_CUSTOMER_ASSET_PAGE_DATA;
  payload: boolean;
}

export type AssetRegistrationRequest = Omit<
  AddUpdateAssetRequestForAssetRegistration,
  'productFamilyCode'
> & {
  productFamilyCode?: string | null;
};

export type assetManagementActionTypes =
  | ClearAddAssetOwnershipDetails
  | ClearAssetManagementFilters
  | ClearExistingRecord
  | ClearSelectedAsset
  | ClearSelectedCustomer
  | SetAllAssetManagement
  | SetAssetManagement
  | SetAssetManagementCount
  | SetAssetManagementCursor
  | SetAssetManagementFilters
  | SetAssetManagementOrderBy
  | SetAssetManagementSearchValue
  | SetAssetManagementSortBy
  | SetCatAssetMetadata
  | SetExpireAssetLoading
  | SetInitialSerialNumber
  | SetIsAssetManagementCountLoading
  | SetIsAssetManagementLoading
  | SetCatAssetLoadingMetadata
  | SetIsCreateDcDcnRelationshipLoading
  | SetIsEditAssetLoading
  | SetIsGetAssetDetailsLoading
  | SetIsUpdateOwnershipLoading
  | SetOwnershipDetails
  | SetOwningDealer
  | SetSelectedAsset
  | SetSelectedCustomer
  | ShowNoAddAsset
  | ShowNoOwnership
  | UpdateAssetManagementFilters
  | SetCcidAssets
  | SetStaleAssetManagement
  | SetIsCustomerAssetPageData;

export enum AssetManagementPage {
  ASSETS = 'ASSETS',
  MY_CUSTOMER_ASSETS = 'MY_CUSTOMER_ASSETS',
}
