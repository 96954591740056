import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const Stroke = ({ stroke = '#78869E', ...restProps }: iconType) => (
  <SvgIcon
    {...restProps}
    stroke={stroke}
    strokeLinecap="round"
    strokeLinejoin="round"
    viewBox="0 0 30 30"
    style={{ width: '30px', height: '30px', marginTop: '5px' }}
  >
    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.55469 4.26117L4.63075 7.55572L10.4436 1.3335"
        stroke="#43485C"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  </SvgIcon>
);

export default Stroke;
