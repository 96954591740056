import { DealerDetailsResponse } from '../../entities/dealer-details-v1/dealerDetailsResponse';

export enum dealerDetailsActionNames {
  SET_DEALER_DETAILS = 'SET_DEALER_DETAILS',
}

export type DealerDetailsState = {
  dealers: Array<DealerDetailsResponse>;
};

export interface SetDealerDetails {
  type: typeof dealerDetailsActionNames.SET_DEALER_DETAILS;
  payload: Array<DealerDetailsResponse>;
}

export type DealerDetailsActionTypes = SetDealerDetails;
