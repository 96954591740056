import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TEXT from '../../../../globals/translation-map';
import Dropdown from '../../../../components/dropdown';
import DropdownMultiSelect from '../../../../components/dropdown-multi-select';
import { Control, Controller, UseFormWatch } from 'react-hook-form';
import { FormInputs } from '../user-leads-header/header-right-buttons';
import FilterByDate from '../../../../components/filter-by-date';
import { Condition } from '../../../../entities/entitlements-v1/condition';
import { AppState } from '../../../../store';
import { useSelector } from 'react-redux';

type FilterDialogFormProps = {
  control: Control<FormInputs>;
  watch: UseFormWatch<FormInputs>;
};

// Hard coding mock data until API is ready

const dropDownMultiSelectValues = ['Pending', 'Completed', 'Ignored'];

const FilterDialogForm = ({ watch, control }: FilterDialogFormProps) => {
  const { t } = useTranslation();

  const userLeadsFilterConditions = useSelector(
    (state: AppState) =>
      state.login?.permissionsResponse?.dataPermissions?.userLeads?.update?.filterConditions,
  );

  const filterConditions: Condition | undefined = userLeadsFilterConditions?.[0] ?? undefined;

  const isEntitledToMultiplePartyNumbers =
    filterConditions?.partyNumbers && filterConditions?.partyNumbers?.length > 1;

  const accountDropdownValues = userLeadsFilterConditions?.[0]?.partyNumbers.map(
    (account: string, index: number) => {
      return {
        title: account,
        code: account,
        index,
      };
    },
  );

  const [watchSelectedAccount, dateRange] = watch(['selectedAccount', 'dateRange']);

  return (
    <Box ml={6} data-testid="user-leads-filter-dialog-form">
      {isEntitledToMultiplePartyNumbers && (
        <Box mt={3}>
          <Typography variant="h4">{t(TEXT.COMMON.FILTERS.SECTIONS.ACCOUNT_SELECTION)}</Typography>
          <Grid container>
            <Grid item xs={12} sm={6} mt={2}>
              <Controller
                control={control}
                name="selectedAccount"
                render={({ field: { value, onChange } }) => (
                  <Dropdown
                    required
                    label={t(TEXT.COMMON.FILTERS.INPUT_LABELS.ACCOUNT)}
                    dropdownValues={accountDropdownValues}
                    callbackFunction={onChange}
                    showNoneOption={false}
                    selectedValue={value}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {(watchSelectedAccount || !isEntitledToMultiplePartyNumbers) && (
        <>
          <Box mt={3}>
            <Typography variant="h4">{t(TEXT.COMMON.FILTERS.SECTIONS.CATEGORY)}</Typography>
            <Box alignItems="center" data-testid="user-leads-status-dropdown-container" mt={3}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="status"
                    render={({ field: { value, onChange } }) => (
                      <DropdownMultiSelect
                        marginTop={0}
                        label={t(TEXT.USER_LEADS.LABEL)}
                        prevSelectedValue={value}
                        dropdownValues={dropDownMultiSelectValues}
                        onSelectCallbackFunction={onChange}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box mt={3} data-testid="user-leads-date-range-calendar-container">
            <Controller
              control={control}
              name="dateRange"
              render={({ field: { onChange } }) => (
                <FilterByDate
                  startDateValue={dateRange?.startDate}
                  endDateValue={dateRange?.endDate}
                  selectedTimeRangeButtonValue={dateRange?.selectedButton}
                  onDateChange={({ startDate, endDate, selectedButton }) =>
                    onChange({ startDate, endDate, selectedButton })
                  }
                />
              )}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default FilterDialogForm;
