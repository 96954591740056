import { Box } from '@mui/material';
import React from 'react';
import useStyles from './styles';

interface IProps {
  color: string;
  status: string;
  testId?: string;
}

const Status: React.FC<IProps> = ({ testId, status = '', color = '' }) => {
  const { classes } = useStyles({ color });

  return (
    <Box display="flex" alignItems="center" data-testid={testId ?? 'statusTextContainer'}>
      <Box className={classes.statusItem}></Box>
      <p className={classes.bold}>{status.toLowerCase()}</p>
    </Box>
  );
};

export default Status;
