import Axios, { InternalAxiosRequestConfig } from 'axios';
import { updateAndAttachTokenToCall } from '../authentication/utils';
import { refreshAuthToken } from '../../utils/util';
import { v4 as uuidv4 } from 'uuid';
import { handleDateRangeIntercept } from '../../utils/range-date';

const trackingIdInterceptor = () => {
  Axios.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      if (config?.url !== undefined && config.url.includes('cat.com/catDigital'))
        config.headers['X-Cat-API-Tracking-Id'] = uuidv4();
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );
};

export const dateRangeInterceptor = () => {
  Axios.interceptors.request.use(handleDateRangeIntercept, function (error) {
    return Promise.reject(error);
  });
};

const authTokenInterceptor = (
  store: any,
  setSessionExpiredCallback: Function,
  updateToken: boolean,
) => {
  Axios.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      return updateAndAttachTokenToCall(
        config,
        setSessionExpiredCallback,
        store,
        updateToken,
        refreshAuthToken,
      );
    },
    function (error) {
      return Promise.reject(error);
    },
  );
};

const updateHeaderInterceptor = (store: any) => {
  Axios.interceptors.request.use((config) => {
    if (config.url?.includes('/entitlements/v1/permissions')) {
      return config;
    } else if (config.url?.includes('/entitlements/v1/parties')) {
      return config;
    } else if (config.url?.includes('/userManagement/v1/users/search')) {
      return config;
    } else {
      const moduleCode = store.getState().login.moduleCode;
      config.headers['x-cat-ent-application-module'] = moduleCode;
    }
    return config;
  });
};
const setUpInterceptor = (
  store: any,
  setSessionExpiredCallback: Function,
  updateToken: boolean,
) => {
  trackingIdInterceptor();
  dateRangeInterceptor();
  if (store.getState().login.environmentVariables.adminsFlag) {
    updateHeaderInterceptor(store);
  }
  authTokenInterceptor(store, setSessionExpiredCallback, updateToken);
  if (!store.getState().login.isRefreshTokenLoading) {
    Axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (responseError) => {
        if (Axios.isCancel(responseError)) {
          return Axios.CancelToken.source().cancel('duplicate request cancelled');
        }
        /* Intercept and retry request after inactivity and with updated refresh token for 401.006 and 401.007 error codes.*/
        if (
          responseError?.response?.data?.code === '401.006' ||
          responseError?.response?.data?.code === '401.007'
        ) {
          // eslint-disable-next-line no-param-reassign

          responseError.response.data.description = 'Retry';
          responseError.config.headers.Authorization = `Bearer ${
            store.getState().login.authInfo.token
          }`;
          return Axios.request(responseError.config);
        }
        if (responseError?.response?.data?.code === '401.007') {
          // eslint-disable-next-line no-param-reassign
          responseError.response.data.description =
            'Your current session has expired. Please log back in.';
        }
        return Promise.reject(responseError);
      },
    );
  }
};

export default setUpInterceptor;
