import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  Badge,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState, useDispatchTs } from '../../../../store';
import StepperButton from '../../../../components/stepper-button';
import FilterIcon from '../../../../assets/icons/filter-icon';
import useStyles from './styles';
import Dropdown from '../../../../components/dropdown';
import FilterButtons from '../../../../components/filter-buttons';
import {
  accessRequestActionNames,
  presetFilterValue,
} from '../../../../store/access-request/types';
import { PermissionsHashMapForUserBased } from '../../../../entities/entitlements-v1/permissionsHashMapForUserBased';
import { OrganizationResponse } from '../../../../entities/customer-master-v1/models';
import { UserBasedPermission } from '../../../../entities/entitlements-v1/userBasedPermission';
import { selectDataPermissionsByAccount } from '../../../../store/permissions/selectors';
import { accessRequestFiltersSchema } from '../../../../utils/validations';
import SelectCustomers from '../../../../components/select-customers';
import FilterTags from '../../../../components/filter-tags';
import { PartyType } from '../../../../entities/user-onboarding-v1/partyType';
import DropdownMultiSelect from '../../../../components/dropdown-multi-select';
import { UserAccessRequestStatus } from '../../../../entities/user-onboarding-v1/userAccessRequestStatus';
import { capitalizeString, getViewAccessRequestPermissions } from '../../../../utils/util';
import {
  getUserOnboardingApps,
  hasTestAdminRole,
  userOnboardingDomain,
} from '../../../../utils/user-onboading.util';
import { PartiesResponse } from '../../../../entities/entitlements-v1/partiesResponse';
import { Trans, useTranslation } from 'react-i18next';
import TEXT from '../../../../globals/translation-map';
import globalStyles from '../../../../globals/styles';
import DateRangePicker from '../../../../components/date-range-picker';
import dayjs from 'dayjs';
import { ApplicationName } from '../../../../constants';
import { hasStandardTest } from '../../../../utils/parties.util';

type FiltersProperties = {
  disabled?: boolean;
  open: boolean;
  setOpen: Function;
  accountDropdownValuesDCN?: Array<{
    title: string;
    code: string;
    index?: any;
  }>;
};

const formatAccessRequestStatus = () => {
  const formattedInvitationStatus: string[] = [];
  for (const status of Object.values(UserAccessRequestStatus)) {
    formattedInvitationStatus.push(capitalizeString(status));
  }
  return formattedInvitationStatus.sort((a, b) => a.localeCompare(b));
};

const Filters: FC<FiltersProperties> = ({ disabled, open, setOpen, accountDropdownValuesDCN }) => {
  const { classes } = useStyles();
  const { classes: globalClasses } = globalStyles();
  const dispatch = useDispatchTs();
  const theme = useTheme();
  const [openSelectCustomers, setOpenSelectCustomers] = useState<boolean>(false);
  const { filters } = useSelector((state: AppState) => state.accessRequest);
  const { permissionsResponse, partiesResponse, environmentVariables } = useSelector(
    (state: AppState) => state.login,
  );
  const [customerFilterTags, setCustomerFilterTags] = useState<
    Array<{ code: string; removeFunction: Function }> | undefined
  >();
  const { applications: applicationsMetadata } = useSelector(
    (state: AppState) => state.shared.metadata,
  );
  const [formattedApplications, setFormattedApplications] = useState<string[]>([]);
  const { t } = useTranslation();
  useMemo(() => {
    const applications = getUserOnboardingApps(
      environmentVariables,
      partiesResponse as PartiesResponse,
      applicationsMetadata,
      userOnboardingDomain.ACCESSREQUEST,
    );

    if (!formattedApplications.includes(ApplicationName.CAT_CENTRAL)) {
      let newFormattedApplications = formattedApplications;
      for (const application of applications) {
        application.mobileApplicationName
          ? newFormattedApplications.push(
              application.mobileApplicationName,
              application.applicationName,
            )
          : newFormattedApplications.push(application.applicationName);
      }
      setFormattedApplications(newFormattedApplications.sort((a, b) => a.localeCompare(b)));
    }
  }, [environmentVariables, partiesResponse, applicationsMetadata]);

  const viewPermissonAccounts = getViewAccessRequestPermissions(
    permissionsResponse as UserBasedPermission,
  );

  const viewDataPermissionAccounts = selectDataPermissionsByAccount(
    (permissionsResponse as UserBasedPermission)?.dataPermissions as PermissionsHashMapForUserBased,
  );

  const formik = useFormik({
    initialValues: {
      presetValue: filters?.presetValue ?? '',
      selectedAccount: viewPermissonAccounts.includes(filters?.selectedAccount)
        ? filters?.selectedAccount
        : '',
      selectedCustomers: filters?.selectedCustomers ?? [],
      application: filters?.application ?? [],
      applicationRequestStatus: filters?.applicationRequestStatus ?? [],
      startDate: filters?.startDate,
      endDate: filters?.endDate,
      selectedTimeRangeButton: filters?.selectedTimeRangeButton,
    },
    validationSchema: accessRequestFiltersSchema,

    onSubmit: () => {},
    enableReinitialize: true,
  });

  const selectedAccount = formik.values.selectedAccount || formik.initialValues.selectedAccount;

  const applicationUserManagementRole =
    selectedAccount &&
    viewDataPermissionAccounts[selectedAccount]?.userRequests?.view?.applications?.length === 1;

  const hasPresetValue = formik.values.selectedAccount === 'CAT';
  const hasSelectedCustomer =
    hasPresetValue && formik.values.presetValue === presetFilterValue.APPLICATION_ACCESS;
  const handleSaveCustomers = (customers: Array<OrganizationResponse>) => {
    setOpenSelectCustomers(false);
    if (customers) {
      formik.setFieldValue('selectedCustomers', customers);
    }
  };
  const handlePresetChange = (value: string) => {
    formik.setFieldValue('presetValue', value);
  };
  const handleFiltersDialogClose = (resetForm = true) => {
    setOpen(false);
    if (resetForm) {
      formik.resetForm();
    }
  };
  const removeFilterbyType = (filterValue: string) => {
    const newFilters = formik?.values?.selectedCustomers?.filter(
      (customer: OrganizationResponse) =>
        customer?.customerOrganizationDetails?.customerOrganizationIdentifier !== filterValue,
    );
    formik.setFieldValue('selectedCustomers', newFilters);
  };

  const setupCustomerFilterTags = (customers: Array<OrganizationResponse>) => {
    let newFilterTags: any = [];
    if (customers && customers.length > 0) {
      for (const customer of customers) {
        if (customer?.customerOrganizationDetails?.customerOrganizationIdentifier) {
          newFilterTags.push({
            code: `Application Access Request: ${customer?.customerOrganizationDetails?.customerOrganizationIdentifier}`,
            removeFunction: () =>
              removeFilterbyType(
                customer?.customerOrganizationDetails?.customerOrganizationIdentifier ?? '',
              ),
          });
        }
      }
    }
    newFilterTags && setCustomerFilterTags(newFilterTags);
  };

  const handleDateSelect = (value: number, unit: dayjs.ManipulateType, selectedButton: number) => {
    const startDate = new Date(dayjs().subtract(value, unit).toDate().setUTCHours(0, 0, 0, 0));
    const endDate = new Date(dayjs().toDate().setUTCHours(23, 59, 59, 999));
    formik.setFieldValue('startDate', startDate);
    formik.setFieldValue('endDate', endDate);
    formik.setFieldValue('selectedTimeRangeButton', selectedButton);
  };

  const handleApplyCalenderChange = ({
    startDate,
    endDate,
  }: {
    startDate: Date;
    endDate: Date;
  }) => {
    formik.setFieldValue('startDate', startDate);
    formik.setFieldValue('endDate', endDate);
    formik.setFieldValue('selectedTimeRangeButton', undefined);
  };

  const onCloseCalendar = () => {
    formik.setFieldValue('startDate', '');
    formik.setFieldValue('endDate', '');
    formik.setFieldValue('selectedTimeRangeButton', undefined);
  };

  useEffect(() => {
    if (formik.values.selectedCustomers) setupCustomerFilterTags(formik.values.selectedCustomers);
  }, [formik.values.selectedCustomers]);
  useEffect(() => {
    if (!hasSelectedCustomer) formik.setFieldValue('selectedCustomers', []);
  }, [formik.values.presetValue]);

  const customerSelectionText =
    formik.values?.selectedCustomers && formik.values.selectedCustomers.length > 0
      ? t(TEXT.COMMON.FILTERS.LINKS.EDIT_CUSTOMER_SELECTION)
      : t(TEXT.COMMON.FILTERS.LINKS.ADD_CUSTOMER_SELECTION);
  const hasFilters = !!filters.selectedAccount;

  const asterisk = (
    <Typography className={classes.validationColor} component="span">
      *
    </Typography>
  );

  const accountDropdownValues = viewPermissonAccounts.map((account: string, index: number) => {
    return {
      title: account,
      code: account,
      index: index,
    };
  });
  return (
    <form>
      <Box data-testid="access-request-filter-container" className={classes.filterButtonContainer}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Box display="flex" alignItems="center">
            <Button
              disabled={disabled}
              data-testid="access-request-filter-button"
              className={classes.filterButton}
              variant="contained"
              color="secondary"
              startIcon={
                <Badge
                  color="secondary"
                  variant="dot"
                  data-testid="selected-filter-badge"
                  invisible={!hasFilters}
                >
                  <FilterIcon width="16px" height="16px" stroke="#43485C" />
                </Badge>
              }
              onClick={() => {
                setOpen(true);
              }}
            >
              {t(TEXT.COMMON.FILTERS_BUTTON)}
            </Button>
            <Typography className={classes.dividerText} alignSelf="baseline" />
          </Box>
        </Box>
        <Dialog
          fullWidth
          maxWidth="md"
          data-testid="access-request-filter-dialog"
          aria-labelledby="advance-filters-dialog"
          open={open}
          onClose={() => handleFiltersDialogClose(true)}
          PaperProps={{
            className: globalClasses.dynamicDialog,
          }}
        >
          <DialogTitle id="access-request-modal-title">
            <Typography variant="h3" component="span" className={classes.filterByText}>
              {t(TEXT.COMMON.FILTERS.TITLES.ACCESS_REQUESTS)}
            </Typography>
            <IconButton
              className={classes.iconPosition}
              data-testid="cancel-notification-button"
              onClick={() => handleFiltersDialogClose(true)}
              size="large"
              color="primary"
            >
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </DialogTitle>

          <Divider />
          <DialogContent className={classes.dialogContent}>
            <Box className={classes.row} width="50%">
              <Typography variant="h4" className={classes.filterByText}>
                {t(TEXT.COMMON.FILTERS.SECTIONS.ACCOUNT_SELECTION)}
              </Typography>
              <Dropdown
                required
                label={t(TEXT.COMMON.FILTERS.INPUT_LABELS.ACCOUNT)}
                dropdownValues={
                  environmentVariables.cuobTestFlag &&
                  hasTestAdminRole(partiesResponse) !== undefined
                    ? accountDropdownValuesDCN
                    : accountDropdownValues
                }
                callbackFunction={(value: string) =>
                  formik.setValues({
                    presetValue: '',
                    selectedCustomers: [],
                    application: [],
                    applicationRequestStatus: [],
                    selectedAccount: value,
                    startDate: formik.values.startDate,
                    endDate: formik.values.endDate,
                    selectedTimeRangeButton: formik.values.selectedTimeRangeButton,
                  })
                }
                showNoneOption={false}
                selectedValue={formik.values.selectedAccount}
              />
            </Box>
            {hasPresetValue && (
              <Box className={classes.row}>
                <Typography className={classes.filterByText}>
                  {t(TEXT.COMMON.FILTERS.SECTIONS.PRE_SET_SELECTION)}
                </Typography>
                <Box pb={1}>
                  <Typography className={classes.requestTypeText}>
                    {t(TEXT.COMMON.FILTERS.BUTTONS_LABELS.REQUEST_TYPE)} {asterisk}
                  </Typography>
                </Box>
                <Box pb={2}>
                  <FilterButtons
                    formikProps={formik}
                    handleChange={handlePresetChange}
                    defaultValue={formik.values.presetValue}
                    buttonValues={[
                      {
                        title: t(TEXT.COMMON.FILTERS.BUTTON_OPTIONS.APPLICATION_ACCESS_REQUEST),
                        code: presetFilterValue.APPLICATION_ACCESS,
                      },
                      {
                        title: t(
                          TEXT.COMMON.FILTERS.BUTTON_OPTIONS.ASSOCIATION_APPLICATION_ACCESS_REQUEST,
                        ),
                        code: `${presetFilterValue.ASSOCIATION_REQUEST} & ${presetFilterValue.APPLICATION_ACCESS}`,
                      },
                    ]}
                  />
                </Box>
                {formik.values.presetValue.length === 0 && (
                  <Box>
                    <Typography className={`${classes.validationText} ${classes.validationColor}`}>
                      {t(TEXT.COMMON.FILTERS.VALIDATIONS.REQUEST_TYPE_REQUIRED)}
                    </Typography>
                  </Box>
                )}

                {formik.values.presetValue === presetFilterValue.APPLICATION_ACCESS && (
                  <>
                    <Typography variant="h4" className={classes.selectionText}>
                      <Trans
                        i18nKey={TEXT.COMMON.FILTERS.ITALIC_LABELS.CUSTOMER_SELECTION}
                        components={[
                          <Typography component="span" className={classes.requestTypeText} />,
                          <Typography className={classes.validationColor} component="span" />,
                        ]}
                        values={{ number: 10 }}
                      />
                    </Typography>
                    <Box pt={1} pb={1}>
                      {customerFilterTags && customerFilterTags.length > 0 && (
                        <Box className={classes.filterTags}>
                          <FilterTags
                            filterTags={customerFilterTags}
                            expandable={false}
                            tagLength={40}
                            tagMaxWidth={theme.spacing(50)}
                          />
                        </Box>
                      )}
                    </Box>
                    <Typography
                      className={classes.customerSelectionText}
                      onClick={() => setOpenSelectCustomers(true)}
                      data-testid="add-customer-selection"
                    >
                      {customerSelectionText}
                    </Typography>

                    {formik?.values?.selectedCustomers?.length === 0 && (
                      <Typography
                        pt={2}
                        className={`${classes.validationText} ${classes.validationColor}`}
                      >
                        {t(TEXT.COMMON.FILTERS.VALIDATIONS.ONE_OR_MORE_CUSTOMER_REQUIRED)}
                      </Typography>
                    )}
                    <Dialog
                      open={openSelectCustomers}
                      fullWidth
                      maxWidth="md"
                      data-testid="filter-dialog"
                      aria-labelledby="select-customers-dialog"
                      PaperProps={{
                        className: globalClasses.dynamicDialog,
                      }}
                    >
                      <SelectCustomers
                        handleContinue={handleSaveCustomers}
                        selectedCustomers={formik.values.selectedCustomers}
                        limit={10}
                        isDialog
                        applyText={t(TEXT.COMMON.APPLY)}
                      />
                    </Dialog>
                  </>
                )}
              </Box>
            )}
            {formik.values.selectedAccount && formik.values.selectedAccount.length > 0 && (
              <>
                <Box className={classes.row}>
                  <Typography className={classes.filterByText}>
                    {t(TEXT.COMMON.FILTERS.SECTIONS.CATEGORY)}
                  </Typography>
                  {!applicationUserManagementRole && (
                    <Box className={classes.inlineRow}>
                      <DropdownMultiSelect
                        label={t(TEXT.COMMON.FILTERS.INPUT_LABELS.APPLICATION)}
                        dropdownValues={formattedApplications}
                        dataTestID="application-multi-select-dropdown"
                        prevSelectedValue={
                          formik.values.application || formik.initialValues.application
                        }
                        onSelectCallbackFunction={(value: string) => {
                          formik.setFieldValue('application', value);
                        }}
                      />
                    </Box>
                  )}

                  <Box className={classes.inlineRow}>
                    <DropdownMultiSelect
                      label={t(TEXT.COMMON.FILTERS.INPUT_LABELS.ACCESS_REQUEST_STATUS)}
                      dataTestID="access-request-status-multi-select-dropdown"
                      prevSelectedValue={
                        formik.values.applicationRequestStatus ||
                        formik.initialValues.applicationRequestStatus
                      }
                      dropdownValues={formatAccessRequestStatus()}
                      onSelectCallbackFunction={(value: string) => {
                        formik.setFieldValue('applicationRequestStatus', value);
                      }}
                    />
                  </Box>
                </Box>
                {(environmentVariables.accessRequestsDateFilterFlag ||
                  hasStandardTest(environmentVariables, partiesResponse)) && (
                  <Box className={classes.row}>
                    <Typography variant="h4" className={classes.filterByText}>
                      {t(TEXT.COMMON.FILTERS.SECTIONS.LAST_UPDATE_DATE)}
                    </Typography>
                    <Box mt={2} fontStyle="italic">
                      <Typography variant="body2">
                        {t(TEXT.COMMON.FILTERS.ITALIC_LABELS.PRE_SELECTED_RANGE)}
                      </Typography>
                    </Box>
                    <Box alignItems="center" data-testid="pre-selected-date-range" mt={1}>
                      <Button
                        className={
                          formik.values.selectedTimeRangeButton === 2
                            ? classes.dateRangeActiveBtn
                            : classes.dateRangeBtn
                        }
                        variant="contained"
                        color="secondary"
                        size="small"
                        data-testid="last-month-id"
                        onClick={() => handleDateSelect(1, 'month', 2)}
                      >
                        {t(TEXT.COMMON.FILTERS.BUTTON_OPTIONS.LAST_MONTH)}
                      </Button>
                      <Button
                        className={
                          formik.values.selectedTimeRangeButton === 3
                            ? classes.dateRangeActiveBtn
                            : classes.dateRangeBtn
                        }
                        variant="contained"
                        color="secondary"
                        size="small"
                        data-testid="last-three-month-id"
                        onClick={() => handleDateSelect(3, 'month', 3)}
                      >
                        {t(TEXT.COMMON.FILTERS.BUTTON_OPTIONS.LAST_THREE_MONTHS)}
                      </Button>
                      <Button
                        className={
                          formik.values.selectedTimeRangeButton === 4
                            ? classes.dateRangeActiveBtn
                            : classes.dateRangeBtn
                        }
                        variant="contained"
                        color="secondary"
                        size="small"
                        data-testid="last-six-month-id"
                        onClick={() => handleDateSelect(6, 'month', 4)}
                      >
                        {t(TEXT.COMMON.FILTERS.BUTTON_OPTIONS.LAST_SIX_MONTHS)}
                      </Button>
                      <Button
                        className={
                          formik.values.selectedTimeRangeButton === 5
                            ? classes.dateRangeActiveBtn
                            : classes.dateRangeBtn
                        }
                        onClick={() => handleDateSelect(1, 'year', 5)}
                        variant="contained"
                        color="secondary"
                        size="small"
                        data-testid="last-year-id"
                      >
                        {t(TEXT.COMMON.FILTERS.BUTTON_OPTIONS.LAST_TWELVE_MONTHS)}
                      </Button>
                    </Box>
                    <Box mt={4} fontStyle="italic">
                      <Typography variant="body2">
                        {t(TEXT.COMMON.FILTERS.ITALIC_LABELS.CUSTOM_RANGE)}
                      </Typography>
                      <DateRangePicker
                        selectedDate={{
                          startDate: formik.values.startDate || formik.initialValues.startDate,
                          endDate: formik.values.endDate || formik.initialValues.endDate,
                        }}
                        handleApply={handleApplyCalenderChange}
                        handleClose={onCloseCalendar}
                        focused
                        minDateSelection={dayjs().subtract(1, 'year').toDate()}
                        maxDate={new Date()}
                      />
                    </Box>
                  </Box>
                )}
              </>
            )}
          </DialogContent>
          <Divider />
          <DialogActions className={classes.footerSpacing}>
            <Box display="flex" justifyContent="flex-end">
              <Box mr={2}>
                <StepperButton
                  buttonText={t(TEXT.COMMON.CLOSE)}
                  id="cancel-advance-filter"
                  color="secondary"
                  onClick={() => handleFiltersDialogClose(true)}
                />
              </Box>
              <Box mr={2}>
                <StepperButton
                  buttonText={t(TEXT.COMMON.APPLY)}
                  id="apply-access-request-filters"
                  color="primary"
                  disabled={!formik.dirty || !!Object.entries(formik.errors).length}
                  onClick={() => {
                    if (formik.values.selectedAccount !== filters?.selectedAccount) {
                      dispatch({
                        type: accessRequestActionNames.SET_INITIAL_STATE,
                      });
                    }
                    dispatch({
                      type: accessRequestActionNames.SET_STALE_ACCESS_REQUEST,
                      payload: true,
                    });
                    dispatch({
                      type: accessRequestActionNames.SET_ACCESS_REQUEST_FILTERS,
                      payload: {
                        ...formik.values,
                        selectedCustomers:
                          formik.values.selectedAccount === PartyType.CAT
                            ? formik.values.selectedCustomers
                            : [],
                        presetValue:
                          formik.values.selectedAccount === PartyType.CAT
                            ? formik.values.presetValue
                            : '',
                        sortByRules: filters?.sortByRules.toString(),
                        orderByRules: filters?.orderByRules.toString(),
                        manualSelectedAccountEntry: true,
                      },
                    });
                    handleFiltersDialogClose(false);
                  }}
                />
              </Box>
            </Box>
          </DialogActions>
        </Dialog>
      </Box>
    </form>
  );
};

export default Filters;
