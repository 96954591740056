/**
 * Customer Asset Workflow
 * Customer Asset Workflow service allows to make changes to customer asset associations  through recommendations and ownership change requests.
 *
 * OpenAPI spec version: 1.2.2
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Configuration asset recommendation properties that can be used for sorting.
 */
export type AssetRecommendationSortingProperties =
  | 'recommendationTypeLabel'
  | 'customerOrganizationBusinessName'
  | 'recommendationGroupIdentifier'
  | 'initiatedTime';

export const AssetRecommendationSortingProperties = {
  RecommendationTypeLabel: 'recommendationTypeLabel' as AssetRecommendationSortingProperties,
  CustomerOrganizationBusinessName:
    'customerOrganizationBusinessName' as AssetRecommendationSortingProperties,
  RecommendationGroupIdentifier:
    'recommendationGroupIdentifier' as AssetRecommendationSortingProperties,
  InitiatedTime: 'initiatedTime' as AssetRecommendationSortingProperties,
};
