import { default as Axios, AxiosResponse, CancelToken } from 'axios';
import { AuthInfo } from '../model/types';
import { GetAssociatedUniversalCustomersRequestType } from '../entities/universal-customer-v1/get-associated-universal-customers-request-type';
import { AssociateUniversalCustomerRequestType } from '../entities/universal-customer-v1/associated-universal-customer-request';

class UniversalCustomers {
  private authInfo: AuthInfo | null;

  private universalCustomerUrl: string;

  constructor(authInfo: AuthInfo | null, universalCustomerUrl: string) {
    this.authInfo = authInfo;
    this.universalCustomerUrl = universalCustomerUrl;
  }

  getUniversalCustomerByUCID(UCID: string, cancelToken?: CancelToken): Promise<AxiosResponse<any>> {
    const token = this.authInfo ? this.authInfo.token : '';
    return Axios.get(`${this.universalCustomerUrl}/customers/${UCID}`, {
      cancelToken,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'text/xml',
      },
    });
  }

  getAssocitedUniversalCustomers(
    input: GetAssociatedUniversalCustomersRequestType,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<any>> {
    const { UCID, ...queryParameters } = input;
    const token = this.authInfo ? this.authInfo.token : '';
    return Axios.get(`${this.universalCustomerUrl}/customers/${UCID}/users`, {
      cancelToken,
      params: queryParameters,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'text/xml',
      },
    });
  }

  associateUniversalCustomers(
    parameters: AssociateUniversalCustomerRequestType,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<any>> {
    const token = this.authInfo ? this.authInfo.token : '';
    return Axios.post(`${this.universalCustomerUrl}/userAssignment`, null, {
      cancelToken,
      params: parameters,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'text/xml',
      },
    });
  }

  deleteUniversalCustomers(
    parameters: AssociateUniversalCustomerRequestType,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<any>> {
    const token = this.authInfo ? this.authInfo.token : '';
    return Axios.post(`${this.universalCustomerUrl}/userAssignment`, null, {
      cancelToken,
      params: parameters,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'text/xml',
      },
    });
  }

  getUsersAssignedCustomer(
    input: AssociateUniversalCustomerRequestType,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<any>> {
    const { logonId } = input;
    const token = this.authInfo ? this.authInfo.token : '';
    return Axios.get(`${this.universalCustomerUrl}/users/${logonId}`, {
      cancelToken,

      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'text/xml',
      },
    });
  }
}

export default UniversalCustomers;
