import { FinancialProductDetails } from '../../entities/customer-master-v1/financialProductDetails';
import { GetAuditLogsByOrganizationResponseItem } from '../../entities/customer-master-v1/getAuditLogsByOrganizationResponseItem';
import {
  AssociateDealerCustomerDetails,
  AuditLogRecommendationAssociateDcnToExistingCatAssociationItem,
  AuditLogRecommendationAssociateMultipleCatAssociationItem,
  AuditLogRecommendationItemBase,
  RecommendationDetails,
  SearchOrganizationResponse,
} from '../../entities/customer-master-v1/models';
import { User } from '../../entities/user-management-v1/user';
import { DealerCustomerResponse } from '../../services/customer-organization';

export enum customerDetailsByIDActionNames {
  SET_HISTORY = 'SET_HISTORY',
  SET_CUSTOMER_ORGANIZATIONS_COUNT = 'SET_CUSTOMER_ORGANIZATIONS_COUNT',
  SET_DEALER_CUSTOMER_COUNT = 'SET_DEALER_CUSTOMER_COUNT',
  SET_DEALER_CUSTOMERS = 'SET_DEALER_CUSTOMERS',
  SET_ASSOCIATED_USERS = 'SET_ASSOCIATED_USERS',
  SET_CUSTOMER_ORGANIZATIONS = 'SET_CUSTOMER_ORGANIZATIONS',
  UPDATE_ASSOCIATED_USERS = 'UPDATE_ASSOCIATED_USERS',
  SET_DETAILS = 'SET_DETAILS',
  SET_ACTIVE_ROW = 'SET_ACTIVE_ROW',
  CLEAR_HISTORY = 'CLEAR_HISTORY',
  SET_HISTORY_LOADING = 'SET_HISTORY_LOADING',
  SET_DETAILS_LOADING = 'SET_DETAILS_LOADING',
  CLEAR_DETAILS = 'CLEAR_DETAILS',
  SET_RECOMMENDATIONS_LOG = 'SET_RECOMMENDATIONS_LOG',
  SET_ASSOCIATIONS_LOG = 'SET_ASSOCIATIONS_LOG',
  CREATE_HISTORY = 'CREATE_HISTORY',
  CLEAR_TAB_DATA_BY_CUSTOMER_ORG_ID = 'CLEAR_TAB_DATA_BY_CUSTOMER_ORG_ID',
  SET_STALE_DATA_BY_CUSTOMER_ORG_ID = 'SET_STALE_DATA_BY_CUSTOMER_ORG_ID',
  SET_FINANCE_PRODUCTS = 'SET_FINANCE_PRODUCTS',
  SET_FINANCE_PRODUCTS_COUNT = 'SET_FINANCE_PRODUCTS_COUNT',
  CLEAR_ALL_HISTORY = 'CLEAR_ALL_HISTORY',
}

export interface CustomerDetailsByIDState {
  [key: string]: {
    users: Array<User>;
    customerOrganizations: Array<SearchOrganizationResponse>;
    customerOrganizationsCount: number;
    dealerCustomersCount: number;
    stale?: boolean;
    dealerCustomers: Array<DealerCustomerResponse>;
    financialProducts: {
      products: Array<FinancialProductDetails>;
      count: number;
      nextCursor: string | undefined;
    };
    history: {
      historyRows: Array<GetAuditLogsByOrganizationResponseItem>;
      details: any | undefined;
      activeRow: number | undefined;
      nextCursor: string | undefined;
      associationsLogs: Array<string>;
      historyLoading: boolean;
      detailsLoading: boolean;
      recommendationsLogs:
        | Array<
            AssociateDealerCustomerDetails &
              AuditLogRecommendationAssociateDcnToExistingCatAssociationItem &
              RecommendationDetails
          >
        | Array<
            AssociateDealerCustomerDetails &
              AuditLogRecommendationAssociateMultipleCatAssociationItem &
              RecommendationDetails
          >
        | Array<
            AssociateDealerCustomerDetails & AuditLogRecommendationItemBase & RecommendationDetails
          >;
    };
  };
}

export interface CustomerOrganziationCreatedAddress {
  addressType: string;
  address1: string;
  address2: string;
  address3: string;
  cityName: string;
  stateOrProvinceCode: string;
  postalCode: string | number;
  countryCode: string;
}
export interface CustomerOrganziationCreatedEmail {
  emailType: string;
  emailAddress: string;
}
export interface CustomerOrganizationCreatedPhone {
  phoneType: string;
  phoneNumber: string;
}
export interface CustomerOrganizationCreatedRecord {
  customerOrganizationBusinessName: string;
  customerOrganizationAlternateName: string;
  topLevelIndicator: boolean;
  customerAddresses: [CustomerOrganziationCreatedAddress];
  customerEmails: [CustomerOrganziationCreatedEmail];
  customerPhones: [CustomerOrganizationCreatedPhone];
}
export interface CustomerOrganizationCreatedDetail {
  id: string;
  category: string;
  eventTime: string;
  updatedBy: string;
  details: {
    from: CustomerOrganizationCreatedRecord;
    to: CustomerOrganizationCreatedRecord;
  };
}

export interface CustomerOrganizationAssociationsDetail {
  id: string;
  category: string;
  eventTime: string;
  updatedBy: string;
  details: {
    dealerCustomerAssociations: [
      {
        dealerCustomerIdentifier: string;
      },
    ];
    customerOrganizationAssociations: [
      {
        customerOrganizationIdentifier: string;
        associationType: string;
      },
    ];
    dealerCustomerDisassociations: [
      {
        dealerCustomerIdentifier: string;
      },
    ];
    customerOrganizationDisassociations: [
      {
        customerOrganizationIdentifier: string;
        associationType: string;
      },
    ];
  };
}
export interface CustomerOrganizationConsolidationDetail {
  id: string;
  category: string;
  eventTime: string;
  updatedBy: string;
  details: {
    consolidatedFrom: string;
    consolidatedTo: string;
    dealerCustomerAssociations: [
      {
        dealerCustomerIdentifier: string;
      },
    ];
    customerOrganizationAssociations: [
      {
        customerOrganizationIdentifier: string;
        associationType: string;
      },
    ];
    dealerCustomerDisassociations: [
      {
        dealerCustomerIdentifier: string;
      },
    ];
    customerOrganizationDisassociations: [
      {
        customerOrganizationIdentifier: string;
        associationType: string;
      },
    ];
  };
}
interface SetHistory {
  type: typeof customerDetailsByIDActionNames.SET_HISTORY;
  payload: {
    id: string;
    data: Array<GetAuditLogsByOrganizationResponseItem>;
  };
}
interface SetAssociatedCiamUsers {
  type: typeof customerDetailsByIDActionNames.SET_ASSOCIATED_USERS;
  payload: {
    id: string;
    data: Array<User>;
  };
}
interface SetDealerCustomers {
  type: typeof customerDetailsByIDActionNames.SET_DEALER_CUSTOMERS;
  payload: {
    id: string;
    data: Array<DealerCustomerResponse>;
  };
}
interface SetCustomerOrganizations {
  type: typeof customerDetailsByIDActionNames.SET_CUSTOMER_ORGANIZATIONS;
  payload: {
    id: string;
    data: Array<SearchOrganizationResponse>;
  };
}
interface SetCustomerOrganizationsCount {
  type: typeof customerDetailsByIDActionNames.SET_CUSTOMER_ORGANIZATIONS_COUNT;
  payload: {
    id: string;
    data: number;
  };
}
interface SetDealerCustomerCount {
  type: typeof customerDetailsByIDActionNames.SET_DEALER_CUSTOMER_COUNT;
  payload: {
    id: string;
    data: number;
  };
}
interface ClearTabData {
  type: typeof customerDetailsByIDActionNames.CLEAR_TAB_DATA_BY_CUSTOMER_ORG_ID;
  payload: {
    id: string;
  };
}

interface SetStaleTabData {
  type: typeof customerDetailsByIDActionNames.SET_STALE_DATA_BY_CUSTOMER_ORG_ID;
  payload: {
    id: string;
    stale: boolean;
  };
}
interface CreateHistory {
  type: typeof customerDetailsByIDActionNames.CREATE_HISTORY;
  payload: {
    id: string;
    data: Array<GetAuditLogsByOrganizationResponseItem>;
  };
}
interface SetDetails {
  type: typeof customerDetailsByIDActionNames.SET_DETAILS;
  payload: {
    id: string;
    data: Array<string>;
  };
}
interface SetActiveRow {
  type: typeof customerDetailsByIDActionNames.SET_ACTIVE_ROW;
  payload: {
    id: string;
    data: number;
  };
}
interface SetHistoryLoading {
  type: typeof customerDetailsByIDActionNames.SET_HISTORY_LOADING;
  payload: {
    id: string;
    data: boolean;
  };
}
interface SetDetailsLoading {
  type: typeof customerDetailsByIDActionNames.SET_DETAILS_LOADING;
  payload: {
    id: string;
    data: boolean;
  };
}
interface ClearHistory {
  type: typeof customerDetailsByIDActionNames.CLEAR_HISTORY;
  payload: {
    id: string;
  };
}

interface ClearDetails {
  type: typeof customerDetailsByIDActionNames.CLEAR_DETAILS;
  payload: {
    id: string;
  };
}
interface ClearAllHistory {
  type: typeof customerDetailsByIDActionNames.CLEAR_ALL_HISTORY;
}
interface SetRecommendationsLog {
  type: typeof customerDetailsByIDActionNames.SET_RECOMMENDATIONS_LOG;
  payload: {
    id: string;
    data:
      | Array<
          AssociateDealerCustomerDetails &
            AuditLogRecommendationAssociateDcnToExistingCatAssociationItem &
            RecommendationDetails
        >
      | Array<
          AssociateDealerCustomerDetails &
            AuditLogRecommendationAssociateMultipleCatAssociationItem &
            RecommendationDetails
        >;
  };
}

interface SetAssociationsLog {
  type: typeof customerDetailsByIDActionNames.SET_ASSOCIATIONS_LOG;
  payload: {
    id: string;
    data: Array<string>;
  };
}

interface SetFinancialProducts {
  type: typeof customerDetailsByIDActionNames.SET_FINANCE_PRODUCTS;
  payload: {
    id: string;
    data: {
      products: Array<FinancialProductDetails>;
      cursor: string;
    };
  };
}
interface SetFinancialProductsCount {
  type: typeof customerDetailsByIDActionNames.SET_FINANCE_PRODUCTS_COUNT;
  payload: {
    id: string;
    data: number;
  };
}
export type customerDetailsByIDActionTypes =
  | ClearHistory
  | ClearTabData
  | ClearAllHistory
  | ClearDetails
  | SetActiveRow
  | SetAssociationsLog
  | SetDetails
  | SetHistory
  | CreateHistory
  | SetHistoryLoading
  | SetDetailsLoading
  | SetCustomerOrganizations
  | SetAssociatedCiamUsers
  | SetDealerCustomers
  | SetCustomerOrganizationsCount
  | SetDealerCustomerCount
  | SetRecommendationsLog
  | SetStaleTabData
  | SetFinancialProducts
  | SetFinancialProductsCount;
