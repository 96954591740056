import React, { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

export type noDataComponentType = {
  line1: string | React.ReactFragment | JSX.Element;
  line2?: string | React.ReactFragment | JSX.Element;
  searchWarningImage?: string;
  maxWidth?: number;
};

const NoDataComponent: FC<noDataComponentType> = (props: noDataComponentType) => {
  const { line1, line2, searchWarningImage, maxWidth } = props;
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width={maxWidth?? 'auto'} textAlign='center'>
      {searchWarningImage && <img src={searchWarningImage} alt="No data available." />}

      <Typography variant="h6" fontWeight="bold" mt={theme.spacing(2)}>
        {line1}
      </Typography>
      {line2 && (
        <Typography variant="h6" fontWeight="bold" mt={theme.spacing(2)}>
          {line2}
        </Typography>
      )}
    </Box>
  );
};

export default NoDataComponent;
