import {
  AntiForgeryToken,
  AuthenticationError,
  TokenInformation,
  OAuth2Client,
} from '@kolorado/oauth';
import qs from 'qs';
import { loginActionNames } from '../../store/login/types';
import { AuthInfo } from '../../model/types';
import { AppThunkDispatch } from '../../store';
import { EnvironmentVariables } from '../../environments';
import Entitlements from '../../services/entitlements';
import DealerCode, { DealerCodeSearchResponse } from '../../services/dealer-code';
import { showNotification } from '../../utils/util';
import TEXT from '../translation-map';
import i18n from 'i18next';

const Authentication = (
  search: string,
  client: OAuth2Client,
  dispatch: AppThunkDispatch,
  environmentVariables: EnvironmentVariables,
) => {
  // const { t } = useTranslation();
  const setError = (errorPayload: string) => {
    dispatch({
      type: loginActionNames.SET_LOGIN_ERROR,
      payload: errorPayload,
    });
    dispatch({
      type: loginActionNames.SET_PERMISSIONS_FETCHING,
      payload: false,
    });
  };

  const searchObject = qs.parse(search, { delimiter: /[&?]/ });
  const { code, state } = searchObject;
  dispatch({
    type: loginActionNames.SET_PERMISSIONS_FETCHING,
    payload: true,
  });

  if (code === undefined) {
    client.authenticate({ clientToken: 'CustomerMasterUI_ac_client' });
  }
  if (state && environmentVariables) {
    const antiForgeryToken = state as string;
    const { clientToken, synk } = AntiForgeryToken.parse(antiForgeryToken);
    try {
      client.validateAntiForgeryToken({ antiForgeryToken, clientToken, synk });
      if (code) {
        client
          .authorize(code as string, { synk })
          .then(async (tokenInformation: TokenInformation) => {
            const {
              loginId,
              metadata: { catrecid, client_id: clientId, catafltncode },
              token,
              refreshToken,
              expiresIn,
            } = tokenInformation;

            const authInfo: AuthInfo = {
              loginId,
              catrecid,
              clientId,
              token,
              catafltncode,
              refreshToken,
              expiresIn,
            };
            dispatch({
              type: loginActionNames.SET_LOGIN_TIME,
              payload: Date.now(),
            });
            dispatch({ type: loginActionNames.SET_CLIENT, payload: authInfo });
            const entitlements = new Entitlements(
              authInfo.token,
              environmentVariables.entitlementsV1,
            );
            try {
              const permissionsResponse = await entitlements.getUserPermissions({
                includeAppPermissions: 'true',
              });
              if (permissionsResponse?.data) {
                dispatch({
                  type: loginActionNames.SET_PERMISSIONS_RESPONSE,
                  payload: permissionsResponse.data,
                });
                if (permissionsResponse?.data?.dataPermissions?.customerMaster?.create) {
                  const partyNumbersWithCreateCustomerPermission = (permissionsResponse.data
                    .dataPermissions?.customerMaster.create.filterConditions || [])[0].partyNumbers;
                  if (partyNumbersWithCreateCustomerPermission.includes('CAT')) {
                    dispatch({
                      type: loginActionNames.SET_INTEGRATED_DEALER_FLG,
                      payload: true,
                    });
                    dispatch({
                      type: loginActionNames.SET_INTEGRATED_DEALER_CODES,
                      payload: ['CAT'],
                    });
                  } else {
                    const dealerCodeService = new DealerCode(
                      authInfo,
                      environmentVariables.customerMasterURI,
                    );
                    try {
                      const dealerCodeSearchResponse = await dealerCodeService.searchDealerCodes({
                        body: {
                          logicalExpression: '$0 & $1',
                          filters: [
                            {
                              propertyName: 'dealerCode',
                              type: 'stringEquals',
                              values: [...partyNumbersWithCreateCustomerPermission],
                            },
                            {
                              propertyName: 'isIntegrated',
                              type: 'booleanEquals',
                              value: true,
                            },
                          ],
                        },
                      });
                      const dealerCodes = (
                        dealerCodeSearchResponse.data as DealerCodeSearchResponse
                      ).dealerCodes;
                      if (dealerCodes.length > 0) {
                        dispatch({
                          type: loginActionNames.SET_INTEGRATED_DEALER_CODES,
                          payload: dealerCodes,
                        });
                        dispatch({
                          type: loginActionNames.SET_INTEGRATED_DEALER_FLG,
                          payload: true,
                        });
                      } else {
                        dispatch({
                          type: loginActionNames.SET_INTEGRATED_DEALER_FLG,
                          payload: false,
                        });
                      }
                    } catch {
                      dispatch({
                        type: loginActionNames.SET_INTEGRATED_DEALER_FLG,
                        payload: false,
                      });
                      showNotification(
                        dispatch,
                        'error',
                        i18n.t(TEXT.GLOBALS.ERRORS.FETCHING_DEALER_CODES_FAILED),
                      );
                    }
                  }
                }
              }
            } catch {
              setError(i18n.t(TEXT.GLOBALS.ERRORS.FETCH_PERMISSION));
            }

            try {
              const isLastLoginAlreadyCalled = window.localStorage.getItem(
                'last-login-already-called',
              );
              if (!isLastLoginAlreadyCalled || isLastLoginAlreadyCalled === 'false') {
                await entitlements.updateLastLoginTime();
                window.localStorage.setItem('last-login-already-called', 'true');
              }
            } catch (error) {
              console.error(error);
            }

            try {
              const partiesResponse = await entitlements.getParties();
              if (partiesResponse?.data) {
                dispatch({
                  type: loginActionNames.SET_PARTIES_RESPONSE,
                  payload: partiesResponse.data,
                });
              }
            } catch {
              dispatch({
                type: loginActionNames.SET_PARTIES_RESPONSE,
                payload: undefined,
              });
            }

            dispatch({
              type: loginActionNames.SET_PERMISSIONS_FETCHING,
              payload: false,
            });
          });
      }
    } catch (error) {
      let message: string = 'Failure in anti-forgery validation';
      if (error instanceof AuthenticationError) {
        const authError: AuthenticationError = error as AuthenticationError;
        message = authError.message;
      }
      dispatch({ type: loginActionNames.SET_LOGIN_ERROR, payload: message });
    }
  }
};
export default Authentication;
