import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  formControl: {
    width: '100%',
  },
  textField: {
    width: '75%',
  },
}));
