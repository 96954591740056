export const CustomerData = {
  PERKINS: 'PKN',
  CAT: 'CAT',
};
export type CustomerDataType = keyof typeof CustomerData;

export const Brand = {
  Caterpillar: 'Caterpillar',
  Perkins: 'Perkins',
};
