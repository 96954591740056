import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../../../globals/color-properties';

export default makeStyles()((theme) => ({
  footerActions: {
    backgroundColor: CAT_COLOR.WHITE,
  },
  divider: {
    marginTop: theme.spacing(1),
  },
  widthContainer: {
    width: theme.spacing(100),
  },
  customerProfile: {
    marginTop: theme.spacing(0.625),
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    rowGap: theme.spacing(1.5),
    flexWrap: 'wrap',
    justifyItems: 'center',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  customerAddress: {
    width: theme.spacing(28.75),
    marginLeft: theme.spacing(),
    paddingLeft: theme.spacing(10),
    marginTop: theme.spacing(2.125),
  },
  companyHeader: {
    color: CAT_COLOR.LIGHT_SLATE_GREY,
    fontWeight: 400,
    marginBottom: theme.spacing(1),
    fontSize: '14px',
    lineHeight: theme.spacing(1.75),
  },
  companyInfo: {
    fontWeight: 700,
  },
  sentInvitesTitle: {
    marginTop: theme.spacing(6),
  },
  noInvitesMessage: {
    padding: theme.spacing(2),
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  accordionContainer: {
    marginTop: theme.spacing(3),
  },
  accordionTitle: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: theme.spacing(3.25),
    marginLeft: theme.spacing(2.5),
  },
  chipContainer: {
    marginLeft: 'auto',
    borderTop: 0,
    display: 'flex',
  },
  accordionList: {
    width: theme.spacing(37.5),
    marginLeft: theme.spacing(-2),
  },
  accordionListTitle: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: theme.spacing(2.5),
    color: CAT_COLOR.LIGHT_SLATE_GREY,
    marginBottom: theme.spacing(1.5),
  },
  accordionDetailsContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    rowGap: theme.spacing(1.5),
    flexWrap: 'wrap',
    justifyItems: 'center',
    justifyContent: 'space-around',
    fontSize: 14,
    lineHeight: theme.spacing(1.75),
    fontWeight: 400,
    color: CAT_COLOR.BLACK,
    maxHeight: theme.spacing(31.75),
    overflow: 'auto',
    border: 0,
  },
  customerEmail: {
    fontWeight: 700,
  },
  successfulInvitesCustomerInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  failedInvitesCustomerInfo: {
    display: 'flex',
    marginTop: theme.spacing(1.25),
  },
  errorDescription: {
    flex: '1',
  },
  inlineToastBox: {
    marginTop: theme.spacing(1),
    display: 'block',
  },
  inviteLinkBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.5rem',
    borderBottom: `1px solid ${CAT_COLOR.TRANSFER_TABLE_BORDER_GRAY}`,
  },
  inviteLinkTitle: {
    fontWeight: 'bold',
  },
  inviteLinkInfo: {
    display: 'flex',
    gap: '0.5rem',
    marginTop: '1rem',
    alignItems: 'center',
  },
  inviteLinkInfoText: {
    color: CAT_COLOR.LAVA,
  },
})) as any;
