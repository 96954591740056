import {
  UserIdentityManagementState,
  userIdentityManagementActionNames,
  UserIdentityManagementActionTypes,
} from './types';

const initialState: UserIdentityManagementState = {
  isLoadingUserIdentityManagement: false,
  recentlyDisassociatedUser: '',
};

const userIdentityManagementReducer = (
  state: UserIdentityManagementState = initialState,
  action: UserIdentityManagementActionTypes,
): UserIdentityManagementState => {
  switch (action.type) {
    case userIdentityManagementActionNames.SET_NEWLY_ASSOCIATED_USERS: {
      return {
        ...state,
        newlyAssociatedUsers: action.payload,
      };
    }
    case userIdentityManagementActionNames.CLEAR_ASSOCIATED_USER: {
      return {
        ...state,
        newlyAssociatedUsers: initialState.newlyAssociatedUsers,
      };
    }
    case userIdentityManagementActionNames.SET_LOADING_FOR_USER_IDENTITY_MANAGEMENT: {
      return {
        ...state,
        isLoadingUserIdentityManagement: action.payload,
      };
    }
    case userIdentityManagementActionNames.SET_RECENTLY_DISASSOCIATED_USER: {
      return {
        ...state,
        recentlyDisassociatedUser: action.payload,
      };
    }
    default:
      return state;
  }
};

export default userIdentityManagementReducer;
