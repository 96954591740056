import React from 'react';
import { iconType } from './icon-types';

const HomeIcon = (props: iconType) => {
  const { stroke = '#3F3F3F' } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5333 2.18237C11.7969 1.93921 12.2031 1.93921 12.4667 2.18237L21.3213 10.3492C21.5371 10.5494 21.7089 10.7923 21.8258 11.0625C21.9423 11.332 22.0016 11.6226 22 11.9161C22 11.917 22 11.9179 22 11.9187L21.3116 11.914H22V11.9161V19.8791C22 20.4416 21.7765 20.981 21.3788 21.3788C20.981 21.7765 20.4415 22 19.879 22H4.12097C3.55846 22 3.01899 21.7765 2.62124 21.3788C2.22349 20.981 2.00003 20.4416 2.00003 19.8791V11.9161C2.00004 11.917 2.00004 11.9179 2.00005 11.9187L2.68842 11.914H2.00003V11.9161C1.9984 11.6226 2.05771 11.332 2.17422 11.0625C2.29107 10.7923 2.46289 10.5494 2.67875 10.3492L2.68012 10.3479L11.5333 2.18237ZM3.61434 11.3592C3.61415 11.3594 3.61453 11.3591 3.61434 11.3592C3.53888 11.4294 3.47879 11.5144 3.4379 11.609C3.3969 11.7038 3.37609 11.8061 3.37679 11.9094L3.37682 11.914L3.37681 19.8791C3.37681 20.0764 3.45521 20.2657 3.59477 20.4053C3.73433 20.5448 3.92361 20.6232 4.12097 20.6232H19.879C20.0764 20.6232 20.2657 20.5448 20.4052 20.4053C20.5448 20.2657 20.6232 20.0764 20.6232 19.8791V11.9094C20.6239 11.8061 20.6031 11.7038 20.5621 11.609C20.5212 11.5144 20.4611 11.4294 20.3856 11.3592C20.3854 11.359 20.3858 11.3594 20.3856 11.3592L12 3.62487L3.61434 11.3592Z"
        fill={stroke}
      />
    </svg>
  );
};

export default HomeIcon;
