import { CustomerAssociationResponse } from '../../entities/user-identity-management-v1/customerAssociationResponse';

export enum userIdentityManagementActionNames {
  CLEAR_USER_IDENTITY = 'CLEAR_USER_IDENTITY',
  SET_LOADING_FOR_USER_IDENTITY_MANAGEMENT = 'SET_LOADING_FOR_USER_IDENTITY_MANAGEMENT',
  SET_ASSOCIATE_USER_ERROR = 'SET_ASSOCIATE_USER_ERROR',
  SET_NEWLY_ASSOCIATED_USERS = 'SET_NEWLY_ASSOCIATED_USERS',
  CLEAR_ASSOCIATED_USER = 'CLEAR_ASSOCIATED_USER',
  SET_RECENTLY_DISASSOCIATED_USER = 'SET_RECENTLY_DISASSOCIATED_USER',
}

export type ErrorType = {
  code: number;
  message: string;
};

export interface UserIdentityManagementState {
  newlyAssociatedUsers?: CustomerAssociationResponse;
  isLoadingUserIdentityManagement: boolean;
  associateUserError?: ErrorType;
  newlyAssociatedUserSuccess?: boolean;
  recentlyDisassociatedUser?: string;
}

interface ClearAssociatedUser {
  type: typeof userIdentityManagementActionNames.CLEAR_ASSOCIATED_USER;
}

interface SetNewlyAssociatedUsers {
  type: typeof userIdentityManagementActionNames.SET_NEWLY_ASSOCIATED_USERS;
  payload: CustomerAssociationResponse;
}

interface SetAssociateUserError {
  type: typeof userIdentityManagementActionNames.SET_ASSOCIATE_USER_ERROR;
  payload: ErrorType;
}

interface SetUserIdentityManagmentIsLoading {
  type: typeof userIdentityManagementActionNames.SET_LOADING_FOR_USER_IDENTITY_MANAGEMENT;
  payload: boolean;
}

interface SetRecentlyDisassociatedUser {
  type: typeof userIdentityManagementActionNames.SET_RECENTLY_DISASSOCIATED_USER;
  payload: string;
}

export type UserIdentityManagementActionTypes =
  | SetNewlyAssociatedUsers
  | ClearAssociatedUser
  | SetUserIdentityManagmentIsLoading
  | SetAssociateUserError
  | SetRecentlyDisassociatedUser;
