/* eslint-disable unicorn/no-array-for-each */
import { Box, Typography, Link, useTheme } from '@mui/material';
import React, { ReactElement, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AppPagination from '../../components/app-pagination';
import SubHeader from '../../components/sub-header';
import SearchInput from '../../components/search-input';
import { AppState, useDispatchTs } from '../../store';
import accessRequestWarning from '../../assets/images/access-request-warning.png';
import filtersRequired from '../../assets/images/filters-required.png';
import {
  OrganizationResponse,
  SearchOrganizationResponse,
} from '../../entities/customer-master-v1/models';
import getColumnsForAccessRequest from './columns';
import VariableRowInfiniteScrollTable from '../../components/variable-row-infinite-scroll-table';
import RowDetails from './components/row-details';
import getScreenWidth from '../../hooks/get-screen-width';
import { MODULE_CODES, loginActionNames } from '../../store/login/types';
import FilterTags from '../../components/filter-tags';
import {
  countAccessRequest,
  fetchAccessRequests,
  toggleStaleAccessRequest,
} from '../../store/access-request/actions';
import {
  AccessRequestSearchCountRequest,
  AccessRequestSearchRequest,
} from '../../services/access-requests';
import NoDataComponent from '../../components/variable-row-infinite-scroll-table/components/no-data-component';
import { getApplicationsCache } from '../../store/metadata/actions';
import searchwarninglogo from '../../assets/images/search-warning.png';
import Filters from './components/request-filters';
import {
  accessRequestActionNames,
  accessRequestFields,
  AccessRequestFilters,
  accessRequestPermissionMethods,
  accessRequestPermissionName,
  presetFilterValue,
} from '../../store/access-request/types';
import {
  createLogicalExpressionByGroupType,
  getViewAccessRequestPermissions,
} from '../../utils/util';
import { FiltersAndSearchAccessRequest } from '../../entities/user-onboarding-v1/filtersAndSearchAccessRequest';
import DenyAccessRequest from './components/deny-access-request';
import { UserAccessRequestResponse } from '../../entities/user-onboarding-v1/userAccessRequestResponse';
import filterlogo from '../../assets/images/filter.png';
import useStyles from './styles';
import { selectAccountsByPermissionType } from '../../store/permissions/selectors';
import { UserBasedPermission } from '../../entities/entitlements-v1/userBasedPermission';
import { PermissionsHashMapForUserBased } from '../../entities/entitlements-v1/permissionsHashMapForUserBased';
import { UserRequestPropertiesForSorting } from '../../entities/user-onboarding-v1/userRequestPropertiesForSorting';
import { PartyType } from '../../entities/user-onboarding-v1/partyType';
import { AccessRequestTimeRangeFilter } from '../../entities/user-onboarding-v1/accessRequestTimeRangeFilter';
import { filterTagsType } from '../../globals/generic-types';
import { getUserOnboardingApps, userOnboardingDomain } from '../../utils/user-onboading.util';
import { PartiesResponse } from '../../entities/entitlements-v1/partiesResponse';
import { Trans, useTranslation } from 'react-i18next';
import TEXT from '../../globals/translation-map';
import dayjs from 'dayjs';
import { AccessRequestsStatusValues } from './utils/access-requests-enums';

const nonExpandedRowSizeBasedOnScreenRes = { xl: 80, lg: 80, md: 96, sm: 160, xs: 160 };

const AccessRequest = () => {
  const dispatch = useDispatchTs();
  const { classes } = useStyles();
  const theme = useTheme();
  const [sortBy, setSortBy] = useState<string>(UserRequestPropertiesForSorting.CreatedTime);
  const [orderBy, setOrderBy] = useState<string>('DESC');
  const [limit] = useState<number>(100);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [sortOptionsOpen, setSortOptionsOpen] = useState<boolean>(false);
  const [sortingSelected, setSortingSelected] = useState<boolean>(false);
  const [denyAccessRequestOpen, setDenyAccessRequestOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const [selectedAccessRequest, setSelectedAccessRequest] = useState<UserAccessRequestResponse>();

  const [openFiltersModal, setOpenFiltersModal] = useState<boolean>(false);
  const { permissionsResponse, customSettings, partiesResponse, environmentVariables } =
    useSelector((state: AppState) => state.login);
  const screenWidth = getScreenWidth();
  const {
    accessRequests,
    accessRequestSearchValue,
    filters,
    totalAccessRequestCount,
    isLoadingAccessRequestCount,
    isLoadingAccessRequests,
  } = useSelector((state: AppState) => state.accessRequest);

  const {
    metadata: { applications: applicationsMetadata },
  } = useSelector((state: AppState) => state.shared);
  const viewPermissionAccounts = getViewAccessRequestPermissions(
    permissionsResponse as UserBasedPermission,
  );

  const applications = getUserOnboardingApps(
    environmentVariables,
    partiesResponse as PartiesResponse,
    applicationsMetadata,
    userOnboardingDomain.ACCESSREQUEST,
  );

  useEffect(() => {
    dispatch({
      type: loginActionNames.SET_MODULE_CODE,
      payload: MODULE_CODES.USER_ONBOARDING,
    });
    const sortingHistory = window.sessionStorage.getItem('sortedByAccessRequest');
    const sortingOrder = window.sessionStorage.getItem('directionOfSortAccessRequest');
    if (sortingHistory) {
      setSortBy(sortingHistory);
    }
    if (sortingOrder) {
      setOrderBy(sortingOrder);
    }
    if (applications.length === 0) {
      dispatch(getApplicationsCache());
    }
    dispatch({
      type: loginActionNames.SET_NAVIGATION_PATH,
      payload: 'my-work-queue/access-request',
    });
    if (
      filters.selectedAccount !== '' &&
      !viewPermissionAccounts.includes(filters.selectedAccount)
    ) {
      dispatch({
        type: accessRequestActionNames.SET_ACCESS_REQUEST_FILTERS,
        payload: { ...filters, selectedAccount: '' },
      });
    }
  }, []);
  useEffect(() => {
    window.sessionStorage.setItem('sortedByAccessRequest', sortBy);
    window.sessionStorage.setItem('directionOfSortAccessRequest', orderBy);
    setCurrentPage(0);
  }, [sortBy, orderBy]);

  useEffect(() => {
    setCurrentPage(0);
    dispatch({ type: accessRequestActionNames.RESET_CURSOR_FOR_ACCESS_REQUEST });
    if (
      filters.selectedAccount === '' ||
      viewPermissionAccounts.includes(filters.selectedAccount)
    ) {
      if (viewPermissionAccounts.length === 1) {
        if (
          viewPermissionAccounts[0] === PartyType.CAT &&
          filters &&
          filters.selectedCustomers.length === 0 &&
          filters.presetValue === ''
        ) {
          setOpenFiltersModal(true);
        } else if (filters?.selectedAccount !== '') {
          fetchData({
            sortBy: filters?.sortByRules.toString(),
            orderBy: filters?.orderByRules.toString(),
          });
        }
        if (filters?.selectedAccount !== viewPermissionAccounts[0]) {
          dispatch({
            type: accessRequestActionNames.SET_ACCESS_REQUEST_FILTERS,
            payload: {
              ...filters,
              selectedAccount: viewPermissionAccounts[0],
            },
          });
        }
      } else if (viewPermissionAccounts.length > 1) {
        if (
          filters &&
          applications.length &&
          (filters.selectedAccount === '' ||
            (filters.selectedAccount === PartyType.CAT && filters.presetValue === ''))
        ) {
          setOpenFiltersModal(true);
        } else if (applications.length) {
          fetchData({
            sortBy: filters?.sortByRules.toString(),
            orderBy: filters?.orderByRules.toString(),
          });
        }
      }
    }
  }, [filters, applications, accessRequestSearchValue]);

  const removeFilter = (key: string, value: string) => {
    dispatch(toggleStaleAccessRequest(true));
    switch (key) {
      case accessRequestFields.selectedCustomers:
        if (filters?.selectedCustomers.length === 1) {
          dispatch({
            type: accessRequestActionNames.SET_INITIAL_STATE,
          });
        } else {
          const newSelectedCustomers = filters?.selectedCustomers.filter(
            (customer) =>
              customer?.customerOrganizationDetails?.customerOrganizationIdentifier !== value,
          );
          dispatch({
            type: accessRequestActionNames.SET_ACCESS_REQUEST_FILTERS,
            payload: {
              ...filters,
              selectedCustomers: newSelectedCustomers,
            },
          });
        }
        break;
      case accessRequestFields.selectedAccount:
      case accessRequestFields.presetValue:
        dispatch({
          type: accessRequestActionNames.SET_INITIAL_STATE,
        });
        break;
      default:
        dispatch({
          type: accessRequestActionNames.CLEAR_SPECIFIC_ACCESS_REQUEST_FILTER,
          payload: key,
        });
    }
  };

  const selectedCustomersBreakdown = () =>
    filters?.selectedCustomers.map((customer: OrganizationResponse) => {
      return {
        code: `Application Access Request: ${customer?.customerOrganizationDetails?.customerOrganizationIdentifier}`,
        removeFunction: () =>
          removeFilter(
            accessRequestFields.selectedCustomers,
            customer?.customerOrganizationDetails?.customerOrganizationIdentifier ?? '',
          ),
      };
    }) as filterTagsType;

  const removeSelectedApplication = (filterValue: string) => {
    dispatch(toggleStaleAccessRequest(true));
    dispatch({
      type: accessRequestActionNames.SET_ACCESS_REQUEST_FILTERS,
      payload: {
        ...filters,
        application: filters?.application?.filter((application) => application !== filterValue),
      },
    });
  };

  const removeSelectedAccessRequestStatus = (filterValue: string) => {
    dispatch(toggleStaleAccessRequest(true));
    dispatch({
      type: accessRequestActionNames.SET_ACCESS_REQUEST_FILTERS,
      payload: {
        ...filters,
        applicationRequestStatus: filters?.applicationRequestStatus?.filter(
          (status) => status !== filterValue,
        ),
      },
    });
  };

  const createFilterRequest = () => {
    if (filters) {
      let filtersBody: {
        propertyName: string;
        type: string;
        values?: string[];
        range?: { left: Date; right: Date };
      }[] = [];
      Object.keys(filters as AccessRequestFilters).forEach((key: string) => {
        if (
          typeof filters[key as keyof AccessRequestFilters] !== 'boolean' &&
          (
            filters[key as keyof AccessRequestFilters] as
              | string
              | string[]
              | Array<SearchOrganizationResponse>
          )?.length > 0
        ) {
          switch (key) {
            case accessRequestFields.selectedAccount:
              break;
            case accessRequestFields.selectedCustomers:
              let customerIds: Array<string> = [];
              (filters[key as keyof AccessRequestFilters] as SearchOrganizationResponse[]).forEach(
                (node: OrganizationResponse) => {
                  if (node.customerOrganizationDetails?.customerOrganizationIdentifier)
                    customerIds.push(
                      node.customerOrganizationDetails.customerOrganizationIdentifier,
                    );
                },
              );
              filtersBody.push({
                propertyName: 'partyNumber',
                type: 'stringEquals',
                values: customerIds,
              });
              break;
            case accessRequestFields.presetValue:
              filtersBody.push({
                propertyName: 'type',
                type: 'stringEquals',
                values:
                  filters[key as keyof AccessRequestFilters] ===
                  presetFilterValue.APPLICATION_ACCESS
                    ? [presetFilterValue.APPLICATION_ACCESS]
                    : [presetFilterValue.APPLICATION_ACCESS, presetFilterValue.ASSOCIATION_REQUEST],
              });
              break;
            case accessRequestFields.application:
              const applicationIdValues = (
                (filters[key as keyof AccessRequestFilters] as Array<string>).map(
                  (item: string) =>
                    applications.find(
                      (application) =>
                        application.applicationName === item ||
                        application.mobileApplicationName === item,
                    )?.applicationId,
                ) as Array<string>
              )?.reduce((accumulator: Array<string>, value: string) => {
                return accumulator.includes(value) ? [...accumulator] : [...accumulator, value];
              }, []);

              filtersBody.push({
                propertyName: 'applicationId',
                type: 'stringEquals',
                values: applicationIdValues,
              });

              const excludeWebApplicationValues = (
                filters[key as keyof AccessRequestFilters] as Array<string>
              ).reduce((acc: Array<string>, value: string) => {
                const app = applications.find(
                  (application) =>
                    application.mobileApplicationName === value &&
                    !(filters[key as keyof AccessRequestFilters] as Array<string>).includes(
                      application.applicationName,
                    ),
                );
                return app ? [...acc, app.applicationId] : [...acc];
              }, []);

              if (excludeWebApplicationValues.length > 0) {
                filtersBody.push({
                  propertyName: 'excludeWebApplication',
                  type: 'stringEquals',
                  values: excludeWebApplicationValues,
                });
              }

              const excludeMobileApplicationValues = (
                filters[key as keyof AccessRequestFilters] as Array<string>
              ).reduce((acc: Array<string>, value: string) => {
                const app = applications.find(
                  (application) =>
                    application.mobileApplicationName &&
                    (filters[key as keyof AccessRequestFilters] as Array<string>).includes(
                      application.applicationName,
                    ) &&
                    !(filters[key as keyof AccessRequestFilters] as Array<string>).includes(
                      application.mobileApplicationName,
                    ),
                );
                return app && !acc.includes(app.applicationId)
                  ? [...acc, app.applicationId]
                  : [...acc];
              }, []);

              if (excludeMobileApplicationValues.length > 0) {
                filtersBody.push({
                  propertyName: 'excludeMobileApplication',
                  type: 'stringEquals',
                  values: excludeMobileApplicationValues,
                });
              }
              break;
            default:
          }
        }
      });

      if (accessRequestSearchValue && accessRequestSearchValue.length > 0) {
        filtersBody.push({
          propertyName: 'email',
          type: 'stringEquals',
          values: [accessRequestSearchValue as string],
        });
      }
      if (filters.selectedAccount !== PartyType.CAT) {
        filtersBody.push({
          propertyName: 'type',
          type: 'stringEquals',
          values: [presetFilterValue.APPLICATION_ACCESS, presetFilterValue.ASSOCIATION_REQUEST],
        });
      }

      if (!filters?.application || filters?.application?.length === 0) {
        filtersBody.push({
          propertyName: 'applicationId',
          type: 'stringEquals',
          values: applications.map((app) => app.applicationId),
        });
      }

      const { PropertyNameEnum } = AccessRequestTimeRangeFilter;
      const accessRequestsStatusFilters = {
        [PropertyNameEnum.ApprovedRequests]: {
          propertyName: PropertyNameEnum.ApprovedRequests,
          type: 'timeRange',
          range: {
            left: filters.startDate
              ? filters.startDate
              : new Date(dayjs().subtract(7, 'days').toDate().setUTCHours(0, 0, 0, 0)),
            right: filters.endDate
              ? filters.endDate
              : new Date(dayjs().toDate().setUTCHours(23, 59, 59, 999)),
          },
        },
        [PropertyNameEnum.DeniedRequests]: {
          propertyName: PropertyNameEnum.DeniedRequests,
          type: 'timeRange',
          range: {
            left: filters.startDate
              ? filters.startDate
              : new Date(dayjs().subtract(7, 'days').toDate().setUTCHours(0, 0, 0, 0)),
            right: filters.endDate
              ? filters.endDate
              : new Date(dayjs().toDate().setUTCHours(23, 59, 59, 999)),
          },
        },
        [PropertyNameEnum.FailedRequests]: {
          propertyName: PropertyNameEnum.FailedRequests,
          type: 'timeRange',
          range: {
            left: filters.startDate
              ? filters.startDate
              : new Date(dayjs().subtract(7, 'days').toDate().setUTCHours(0, 0, 0, 0)),
            right: filters.endDate
              ? filters.endDate
              : new Date(dayjs().toDate().setUTCHours(23, 59, 59, 999)),
          },
        },
        [PropertyNameEnum.PendingRequests]: {
          propertyName: PropertyNameEnum.PendingRequests,
          type: 'timeRange',
          range: {
            left: filters.startDate
              ? filters.startDate
              : new Date(dayjs().subtract(365, 'days').toDate().setUTCHours(0, 0, 0, 0)),
            right: filters.endDate
              ? filters.endDate
              : new Date(dayjs().toDate().setUTCHours(23, 59, 59, 999)),
          },
        },
        [PropertyNameEnum.ProcessingRequests]: {
          propertyName: PropertyNameEnum.ProcessingRequests,
          type: 'timeRange',
          range: {
            left: filters.startDate
              ? filters.startDate
              : new Date(dayjs().subtract(1, 'days').toDate().setUTCHours(0, 0, 0, 0)),
            right: filters.endDate
              ? filters.endDate
              : new Date(dayjs().toDate().setUTCHours(23, 59, 59, 999)),
          },
        },
      };

      if (filters?.applicationRequestStatus?.length > 0) {
        for (const accessRequestsStatus of filters.applicationRequestStatus) {
          const filterStatusValue = AccessRequestsStatusValues[accessRequestsStatus.toUpperCase()];
          if (accessRequestsStatusFilters[filterStatusValue]) {
            filtersBody.push(accessRequestsStatusFilters[filterStatusValue]);
          }
        }
      } else {
        Object.values(accessRequestsStatusFilters).forEach((statusFilter) =>
          filtersBody.push(statusFilter),
        );
      }

      if (filtersBody.length === 0) return {};

      return {
        logicalExpression: createLogicalExpressionByGroupType(filtersBody, 'timeRange'),
        filters: filtersBody,
      };
    }
  };

  const removeDateRange = () => {
    dispatch(toggleStaleAccessRequest(true));
    dispatch({
      type: accessRequestActionNames.SET_ACCESS_REQUEST_FILTERS,
      payload: {
        ...filters,
        startDate: undefined,
        endDate: undefined,
        selectedTimeRangeButton: undefined,
      },
    });
  };

  const buildFilterTags = () => {
    let newFilterTags: filterTagsType = [];
    if (filters.selectedAccount !== PartyType.CAT || filters.manualSelectedAccountEntry) {
      Object.keys(filters as AccessRequestFilters)
        .sort((a, b) => {
          if (a === accessRequestFields.selectedAccount) return -1;
          if (b === accessRequestFields.selectedAccount) return 1;
          if (a > b) return 1;
          return -1;
        })
        .forEach((key: string) => {
          if (
            filters &&
            typeof filters[key as keyof AccessRequestFilters] !== 'boolean' &&
            ((filters[key as keyof AccessRequestFilters] as string | string[])?.length > 0 ||
              (filters[key as keyof AccessRequestFilters] as Date))
          ) {
            switch (key) {
              case accessRequestFields.selectedAccount:
                if (
                  selectAccountsByPermissionType(
                    (permissionsResponse as UserBasedPermission)
                      ?.dataPermissions as PermissionsHashMapForUserBased,
                    accessRequestPermissionName,
                    accessRequestPermissionMethods.view,
                  ).length > 1
                )
                  environmentVariables.cuobTestFlag
                    ? newFilterTags.push({
                        removeFunction: () =>
                          removeFilter(key, filters[key as keyof AccessRequestFilters] as string),
                        code: t(TEXT.COMMON.FILTERS.FILTER_TAGS.ACCOUNT, {
                          account: accountDropdownValuesDCN[0].title,
                        }),
                      })
                    : newFilterTags.push({
                        removeFunction: () =>
                          removeFilter(key, filters[key as keyof AccessRequestFilters] as string),
                        code: t(TEXT.COMMON.FILTERS.FILTER_TAGS.ACCOUNT, {
                          account: filters[key as keyof AccessRequestFilters] as string,
                        }),
                      });
                break;
              case accessRequestFields.presetValue:
                if (
                  (filters[key as keyof AccessRequestFilters] as string) !==
                  presetFilterValue.APPLICATION_ACCESS
                ) {
                  newFilterTags.push({
                    code: t(
                      TEXT.COMMON.FILTERS.BUTTON_OPTIONS.ASSOCIATION_APPLICATION_ACCESS_REQUEST,
                    ),
                    removeFunction: () =>
                      removeFilter(key, filters[key as keyof AccessRequestFilters] as string),
                  });
                }
                break;
              case accessRequestFields.selectedCustomers:
                newFilterTags.push(...selectedCustomersBreakdown());
                break;
              case accessRequestFields.application:
                filters.application.forEach((application) => {
                  newFilterTags.push({
                    code: application,
                    removeFunction: () => removeSelectedApplication(application),
                  });
                });

                break;
              case accessRequestFields.applicationRequestStatus:
                filters.applicationRequestStatus.forEach((status) => {
                  newFilterTags.push({
                    code: status,
                    removeFunction: () => removeSelectedAccessRequestStatus(status),
                  });
                });

                break;
              case accessRequestFields.startDate || accessRequestFields.endDate:
                const dateString = `${filters.startDate
                  ?.toDateString()
                  .slice(4)
                  .toLocaleUpperCase()} - ${filters.endDate
                  ?.toDateString()
                  .slice(4)
                  .toLocaleUpperCase()}`;
                newFilterTags.push({
                  code: dateString,
                  removeFunction: () => removeDateRange(),
                });
                break;
              default:
            }
          }
        });
      return newFilterTags;
    }
    return [];
  };

  const handleSearchInput = (input: string) => {
    dispatch({ type: accessRequestActionNames.SET_ACCESS_REQUEST_SEARCH_VALUE, payload: input });
    dispatch(toggleStaleAccessRequest(true));
    setCurrentPage(0);
  };
  const clearSearchField = () => {
    dispatch({
      type: accessRequestActionNames.SET_ACCESS_REQUEST_SEARCH_VALUE,
      payload: '',
    });
  };
  const hasFilters = Object.keys(filters as AccessRequestFilters)
    .map(
      (key) =>
        filters &&
        filters[key as keyof AccessRequestFilters] &&
        typeof filters[key as keyof AccessRequestFilters] !== 'boolean' &&
        (filters[key as keyof AccessRequestFilters] as string | string[])?.length > 0,
    )
    .includes(true);

  const accountDropdownValuesDCNPrimary =
    (partiesResponse?.parties !== undefined &&
      partiesResponse?.parties.filter((item) => item.isPrimary)) ||
    [];

  const accountDropdownValuesDCNNotPrimary = partiesResponse?.parties?.[1]?.roles?.[0]
    ? partiesResponse?.parties?.filter(
        (item) => !item.isPrimary && item.roles[0].roleName === 'User Management Test Admin',
      )
    : [];

  const accountDropdownValuesDCN =
    accountDropdownValuesDCNNotPrimary !== undefined &&
    accountDropdownValuesDCNPrimary !== undefined &&
    accountDropdownValuesDCNNotPrimary[0] !== undefined &&
    accountDropdownValuesDCNPrimary[0] !== undefined
      ? [
          {
            title:
              accountDropdownValuesDCNPrimary[0]?.partyNumber +
              ' ' +
              '(' +
              accountDropdownValuesDCNNotPrimary[0]?.partyNumber +
              ')',
            code: accountDropdownValuesDCNNotPrimary[0]?.partyNumber,
            index: 0,
          },
        ]
      : [
          {
            title: '',
            code: '',
            index: 0,
          },
        ];

  const customComponentsForAppPagination: ReactElement[] = [
    <SearchInput
      disabled={
        !!(
          isLoadingAccessRequests ||
          isLoadingAccessRequestCount ||
          (filters && !filters.selectedAccount)
        )
      }
      onClickOfSearch={handleSearchInput}
      placeholder={t(TEXT.COMMON.METADATA.BASE.SEARCH_EMAIL)}
      searchHistory="accessRequestSearchHistory"
      clearInput={clearSearchField}
      searchFieldValue={accessRequestSearchValue}
      searchOnFieldCleared
      trimInput
    />,
    <Box>
      <Filters
        accountDropdownValuesDCN={accountDropdownValuesDCN}
        disabled={isLoadingAccessRequests || isLoadingAccessRequestCount}
        open={openFiltersModal}
        setOpen={setOpenFiltersModal}
      />
    </Box>,
    <Box>
      <Typography
        variant="body1"
        className={classes.clearAll}
        data-testid="access-request-clear-all-filters"
        onClick={() => {
          dispatch({ type: accessRequestActionNames.SET_INITIAL_STATE });
        }}
      >
        {t(TEXT.COMMON.CLEAR_ALL)}
      </Typography>
    </Box>,
  ];

  const handleClickForPrevious = () => {
    setCurrentPage(currentPage - 1);
    fetchData({
      previous: true,
      sortBy: filters?.sortByRules?.toString(),
      orderBy: filters?.orderByRules?.toString(),
    });
  };

  const handleClickForNext = () => {
    setCurrentPage(currentPage + 1);
    fetchData({
      previous: false,
      sortBy: filters?.sortByRules.toString(),
      orderBy: filters?.orderByRules?.toString(),
    });
  };

  const fetchData = (input: { previous?: boolean; sortBy?: string; orderBy?: string }) => {
    if (!applications.length) {
      return null;
    }
    const { sortBy: sortByInput, orderBy: orderByInput, previous } = input;
    let accessRequest: AccessRequestSearchRequest = {
      sortBy: sortByInput,
      orderBy: orderByInput,
      limit,
      previous,
    };
    accessRequest.body = {
      ...createFilterRequest(),
    } as FiltersAndSearchAccessRequest;
    const countRequest: AccessRequestSearchCountRequest = {
      body: {
        logicalExpression: accessRequest.body.logicalExpression,
        filters: accessRequest.body.filters,
      },
      partyNumber: filters?.selectedAccount ?? '',
    };

    dispatch(fetchAccessRequests(accessRequest));
    dispatch(countAccessRequest(countRequest));
  };

  const closeDenyAccessRequest = (modalResponse: boolean) => {
    if (modalResponse) {
      setCurrentPage(0);
      dispatch({ type: accessRequestActionNames.RESET_CURSOR_FOR_ACCESS_REQUEST });
      fetchData({
        sortBy: filters?.sortByRules.toString(),
        orderBy: filters?.orderByRules.toString(),
      });
    }
    setDenyAccessRequestOpen(false);
  };

  const onClickOfDenyRequest = (item: UserAccessRequestResponse) => {
    setSelectedAccessRequest(item);
    setDenyAccessRequestOpen(true);
  };
  const handleOpenFilterClick = () => {
    setOpenFiltersModal(true);
  };
  const appPagination = (customComponents: ReactElement[] = []) => (
    <AppPagination
      currentPage={currentPage}
      limit={100}
      totalRecordCount={totalAccessRequestCount ?? 0}
      onClickActionForPrevious={handleClickForPrevious}
      onClickActionForNext={handleClickForNext}
      customComponents={customComponents}
      previousButtonDisabled={
        currentPage === 0 || isLoadingAccessRequests || isLoadingAccessRequestCount
      }
      isLoading={isLoadingAccessRequests || isLoadingAccessRequestCount}
    />
  );
  const handleSortChange = (newSort: string) => {
    dispatch({
      type: accessRequestActionNames.SET_ACCESS_REQUEST_SORT_BY_VALUE,
      payload: [newSort],
    });
  };
  const handleOrderChange = (newOrder: string) => {
    dispatch({
      type: accessRequestActionNames.SET_ACCESS_REQUEST_ORDER_BY_VALUE,
      payload: [newOrder],
    });
  };

  const refreshSearchResults = () => {
    setCurrentPage(0);
    dispatch({ type: accessRequestActionNames.RESET_CURSOR_FOR_ACCESS_REQUEST });
    fetchData({
      sortBy: filters?.sortByRules.toString(),
      orderBy: filters?.orderByRules.toString(),
    });
  };
  return (
    <div>
      <Box data-testid="access-request-container">
        <SubHeader title={t(TEXT.COMMON.TITLE.ACCESS_REQUESTS)} />
        {appPagination(customComponentsForAppPagination)}
        {customSettings?.filterTags && filters && (
          <FilterTags
            expandable={true}
            filterTags={buildFilterTags()}
            tagMaxWidth={theme.spacing(40)}
            tagLength={38}
          />
        )}
        <Box>
          <VariableRowInfiniteScrollTable
            rows={accessRequests ?? []}
            nonExpandedRowSizeBasedOnScreenRes={nonExpandedRowSizeBasedOnScreenRes}
            screenWidth={screenWidth}
            columns={getColumnsForAccessRequest({
              sortOptionsOpen,
              setSortOptionsOpen,
              sortBy: filters!.sortByRules.toString(),
              orderBy: filters!.orderByRules.toString(),
              handleSortChange: handleSortChange,
              handleOrderChange: handleOrderChange,
              setSortingSelected,
              sortingSelected,
              classes,
            })}
            hasNextPage={false}
            height={window.innerHeight - 0.24 * window.innerHeight}
            isNextPageLoading={false}
            loadNextPage={() => {}}
            expandedRowFlag
            expandOneRowOnlyFlag
            expandedRowComponent={(item: UserAccessRequestResponse) => (
              <RowDetails
                row={item}
                onClickOfDenyRequest={() => onClickOfDenyRequest(item)}
                refreshSearchResults={() => refreshSearchResults()}
              />
            )}
            skipEntitlementCheck={true}
            hideNoDataComponent={!!(isLoadingAccessRequests || isLoadingAccessRequestCount)}
            displayLoadingIcon={!!(isLoadingAccessRequests || isLoadingAccessRequestCount)}
            noDataComponent={
              isLoadingAccessRequests ? null : filters &&
                (!filters.selectedAccount ||
                  (filters.selectedAccount === PartyType.CAT &&
                    filters.selectedCustomers.length === 0 &&
                    !accessRequestSearchValue)) ? (
                <NoDataComponent
                  searchWarningImage={filterlogo}
                  line1={
                    <Typography fontWeight="bold" align="center" data-testid="no-data-filter-link">
                      <Trans
                        i18nKey={TEXT.ACCESS_REQUESTS.SELECT_FILTERS}
                        components={[
                          <Link
                            underline="hover"
                            className={classes.link}
                            onClick={() => {
                              handleOpenFilterClick();
                            }}
                          />,
                        ]}
                      />
                    </Typography>
                  }
                />
              ) : accessRequestSearchValue ? (
                <NoDataComponent
                  searchWarningImage={searchwarninglogo}
                  line1={t(TEXT.ACCESS_REQUESTS.NO_RESULTS_FOUND)}
                  line2={t(TEXT.ACCESS_REQUESTS.REVISING_SEARCH_CRITERIA)}
                />
              ) : !hasFilters ? (
                <NoDataComponent
                  searchWarningImage={filtersRequired}
                  line1={
                    <Typography fontWeight="bold" align="center" data-testid="no-data-filter-link">
                      <Trans
                        i18nKey={TEXT.ACCESS_REQUESTS.SELECT_FILTERS}
                        components={[
                          <Link
                            underline="hover"
                            className={classes.link}
                            onClick={() => {
                              handleOpenFilterClick();
                            }}
                          />,
                        ]}
                      />
                    </Typography>
                  }
                />
              ) : (
                <NoDataComponent
                  searchWarningImage={accessRequestWarning}
                  line1={t(TEXT.ACCESS_REQUESTS.NO_ACCESS_REQUESTS_EXIST)}
                />
              )
            }
          />
        </Box>
        {denyAccessRequestOpen && (
          <DenyAccessRequest
            selectedAccessRequest={selectedAccessRequest as UserAccessRequestResponse}
            onCloseModal={(item: boolean) => closeDenyAccessRequest(item)}
          ></DenyAccessRequest>
        )}
      </Box>
    </div>
  );
};

export default AccessRequest;
