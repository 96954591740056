import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../../../../../globals/color-properties';

export default makeStyles()((theme) => ({
  drawer: {
    paddingTop: theme.spacing(8),
    flexShrink: 0,
    whiteSpace: 'nowrap',
    height: '100%',
    zIndex: '1301',
  },
  drawerOpen: {
    backgroundColor: theme.palette.primary.contrastText,
    width: theme.spacing(60),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  listItem: {
    borderBottom: `1px solid ${CAT_COLOR.MEDIUM_GRAY}`,
    height: 78,
    paddingLeft: 0,
  },
  closeButton: {
    float: 'right',
    right: '20px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: theme.spacing(5),
  },
  drawerFooter: {
    backgroundColor: CAT_COLOR.WHITE,
    position: 'sticky',
    bottom: 0,
    width: '100%',
  },
  truncate: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: theme.spacing(26),
  },
  truncateSecondary: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: theme.spacing(17),
  },
  leftSidePadding: {
    paddingLeft: theme.spacing(2),
  },
  subtitleBox: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  extraLeftSidePadding: {
    paddingLeft: theme.spacing(4),
  },
  headerDivider: {
    height: theme.spacing(1),
    boxShadow: '0 8px 2px -2px gray',
  },
  closeIcon: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  drawerHeader: { position: 'sticky', top: 0, width: '100%' },
  drawerHeaderShadow: {
    paddingBottom: theme.spacing(2.5),
    paddingTop: theme.spacing(2.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: CAT_COLOR.WHITE,
    boxShadow:
      '0px 22px 41px rgba(0, 0, 0, 0.05), 0px 4.43224px 15.5281px rgba(0, 0, 0, 0.0368192), 0px 3.24253px 17.4065px rgba(0, 0, 0, 0.0301405)',
  },
  tableDivider: {
    marginLeft: theme.spacing(-2),
  },
  customerHeader: {
    fontFamily: 'NotoSans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    color: CAT_COLOR.LIGHT_SLATE_GREY,
  },
  customerInfoCell: {
    fontFamily: 'NotoSans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    paddingTop: theme.spacing(1),
  },
  infoBox: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  noDataContainer: {
    padding: theme.spacing(3),
    background: CAT_COLOR.WHITE,
  },
  noData: {
    fontSize: '16px',
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
    fontFamily: 'NotoSans',
    fontWeight: 400,
    lineHeight: '24px',
  },
}));
