import { SvgIcon } from '@mui/material';
import React from 'react';
import { iconType } from './icon-types';

const SortArrow = (props: iconType) => {
  const { width, height } = props;
  return (
    <SvgIcon
      {...props}
      style={{ width: width ?? '9', height: height ?? '11' }}
      viewBox="0 0 9 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.55216 9.99965L4.49988 2M4.49988 2L1 5.52122M4.49988 2L8 5.52122"
        stroke="#127ACA"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default SortArrow;
