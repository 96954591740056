import React, { useRef, useEffect } from 'react';

export const useInputWithBlurPrevention = () => {
  const isTabSwitch = useRef(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleVisibilityChange = () => {
      isTabSwitch.current = document.visibilityState === 'hidden';
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    callback: Function,
  ) => {
    setTimeout(() => {
      if (isTabSwitch.current) {
        e.preventDefault();
        inputRef?.current?.focus();
      } else {
        callback();
      }
    }, 0);
  };

  return { inputRef, handleBlur };
};
