import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../../../globals/color-properties';

export default makeStyles()((theme) => ({
  sideNavLink: {
    textDecoration: 'none',
    color: 'white',
    zIndex: 1,
  },
  activeLink: {
    opacity: '1',
  },
  mainUrlActive: {
    opacity: '1',
    maxWidth: '150px',
    wordWrap: 'break-word',
    heigh: 'auto',
    boxSizing: 'border-box',
  },
  activeContainer: {
    backgroundColor: CAT_COLOR.SIDENAV_GRAY,
  },
  activeContainerTitle: {
    backgroundColor: CAT_COLOR.SIDENAV_GRAY,
    borderLeft: `6px solid ${CAT_COLOR.BRIGHT_GOLD}`,
  },
  openListContainer: {
    paddingBottom: 2,
    paddingTop: 2,
  },
  listContainer: {
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: CAT_COLOR.SIDENAV_GRAY,
    },
  },
  titleForClosedDrawer: {
    color: CAT_COLOR.BLACK_OLIVE,
    fontWeight: 600,
    fontSize: '10px',
    textAlign: 'center',
    paddingTop: theme.spacing(0.5),
  },
  inactiveContainer: {
    borderLeft: '6px solid transparent',
  },
  iconContainer: {
    minWidth: theme.spacing(6.75),
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
  },
  linkDisabled: {
    pointerEvents: 'none',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  listItemText: {
    color: CAT_COLOR.BLACK_OLIVE,
    padding: '7px 0',
  },
  titleConatainer: {
    maxWidth: '150px',
    wordWrap: 'break-word',
    heigh: 'auto',
    boxSizing: 'border-box',
  },
  titleConatainerActive: {
    fontWeight: 600,
  },
  expandCollapseIconButton: {
    position: 'absolute',
    marginTop: '-10xp',
    zIndex: 2,
  },
}));
