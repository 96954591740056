import { SnackbarKey } from 'notistack';
import { AppState, AppThunkDispatch } from '..';
import { snackbarActionNames, SnackBarNotification } from './types';

export const enqueueSnackbarAction = (notification: SnackBarNotification) => {
  const { key, message } = notification;
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const {
      shared: {
        snackbar: { notifications },
      },
    } = getState();

    const findIndex = notifications?.findIndex((n) => n.key === key);
    if (findIndex === -1) {
      dispatch({
        type: snackbarActionNames.ENQUEUE_SNACKBAR,
        notification: {
          ...notification,
          message: message,
          key: key || Date.now() + Math.random(),
        },
      });
    }
  };
};

export const closeSnackbarAction = (key: SnackbarKey) => {
  return async (dispatch: AppThunkDispatch) => {
    dispatch({
      type: snackbarActionNames.CLOSE_SNACKBAR,
      notification: {
        dismissAll: !key,
        key: key || Date.now() + Math.random(),
      },
    });
  };
};

export const removeSnackbarAction = (key: SnackbarKey) => {
  return async (dispatch: AppThunkDispatch) => {
    dispatch({
      type: snackbarActionNames.REMOVE_SNACKBAR,
      notification: {
        dismissAll: !key,
        key: key || Date.now() + Math.random(),
      },
    });
  };
};
