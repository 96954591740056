import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const SearchIcon = (props: iconType) => {
  const { stroke = '#78869E', width = '20px', height = '20px' } = props;
  return (
    <SvgIcon
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
      viewBox="0 0 20 20"
      style={{ fill: 'none', width, height }}
    >
      <path
        stroke={stroke}
        d="M8.16391 15.0877C12.1204 15.0877 15.3278 11.934 15.3278 8.04383C15.3278 4.15363 12.1204 1 8.16391 1C4.20739 1 1 4.15363 1 8.04383C1 11.934 4.20739 15.0877 8.16391 15.0877Z"
      />
      <path stroke={stroke} d="M13.5396 13.3252L19.3105 18.9994Z" />
    </SvgIcon>
  );
};

export default SearchIcon;
