import {
  SearchOrganizationResponse,
  OrganizationResponse,
} from '../../entities/customer-master-v1/models';
import { SetIsLoading } from '../api/loadable';

export enum customerAssociateActionNames {
  SET_NEXT_CURSOR = 'SET_NEXT_CURSOR',
  SET_FETCH_CUSTOMER_ERROR = 'SET_FETCH_CUSTOMER_ERROR',
  SET_LOADING_FOR_CUSTOMER = 'SET_LOADING_FOR_CUSTOMER',
  RESET_ASSOCIATE_CUSTOMER_STATE = 'RESET_ASSOCIATE_CUSTOMER_STATE',
  SET_LOADING_FOR_SEARCH_CUSTOMER = 'SET_LOADING_FOR_SEARCH_CUSTOMER',
  SET_ASSOCIATED_CUSTOMER_ORGANIZATIONS = 'SET_ASSOCIATED_CUSTOMER_ORGANIZATIONS',
  RESET_ASSOCIATED_CUSTOMER_ORGANIZATIONS = 'RESET_ASSOCIATED_CUSTOMER_ORGANIZATIONS',
  SET_SELECTED_ASSOCIATE_CUSTOMER = 'SET_SELECTED_ASSOCIATE_CUSTOMER',
}

export type ErrorType = {
  code: number;
  message: string;
};

export interface CustomerAssociateState {
  customers?: Array<SearchOrganizationResponse>;
  nextCursor?: string;
  fetchCustomerError?: ErrorType;
  isLoading?: boolean;
  isLoadingCustomerSearch?: boolean;
  associatedCustomerOrganizations?: Array<
    SearchOrganizationResponse & {
      associateOrganizationPartialFailureResponse?: Array<{
        message?: string;
      }>;
    }
  >;
  selectedAssociateCustomer?: OrganizationResponse;
}

interface SetSelectedAssociateCustomer {
  type: typeof customerAssociateActionNames.SET_SELECTED_ASSOCIATE_CUSTOMER;
  payload: OrganizationResponse;
}
interface SetAssociatedCustomerOrganizations {
  type: typeof customerAssociateActionNames.SET_ASSOCIATED_CUSTOMER_ORGANIZATIONS;
  payload: Array<SearchOrganizationResponse>;
}

interface ResetAssociatedCustomerOrganizations {
  type: typeof customerAssociateActionNames.RESET_ASSOCIATED_CUSTOMER_ORGANIZATIONS;
}
interface SetFetchError {
  type: typeof customerAssociateActionNames.SET_FETCH_CUSTOMER_ERROR;
  payload: ErrorType;
}

interface ResetAssociateCustomerState {
  type: typeof customerAssociateActionNames.RESET_ASSOCIATE_CUSTOMER_STATE;
}

export type CustomerAssociateActionTypes =
  | SetSelectedAssociateCustomer
  | SetAssociatedCustomerOrganizations
  | SetFetchError
  | SetIsLoading<customerAssociateActionNames.SET_LOADING_FOR_CUSTOMER>
  | ResetAssociateCustomerState
  | ResetAssociatedCustomerOrganizations
  | SetIsLoading<customerAssociateActionNames.SET_LOADING_FOR_SEARCH_CUSTOMER>;
