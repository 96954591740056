import * as yup from 'yup';
import { PartyType } from '../entities/user-onboarding-v1/partyType';
import countriesData, { countriesWithNoZipCode } from '../globals/countries-data';
import oldCountriesData from '../globals/old-countries-data';
import { getSubdivisionByCountryCache } from '../services/countries-states';
import { presetFilterValue } from '../store/access-request/types';
import i18n, { TFunction } from 'i18next';
import TEXT from '../globals/translation-map';
import { CountrySearchResponseItem } from '../entities/countries-v1/countrySearchResponseItem';
import { LoginState } from '../store/login/types';
import { REGEX } from './regex.util';
const oldFormatedDataForCountries = oldCountriesData;

const createCustomerValidationSchema = (
  countries: Array<CountrySearchResponseItem>,
  stateApiConfig: LoginState,
  disableStateField: Function,
) => {
  return yup.object({
    customerName: yup
      .string()
      .required(i18n.t(TEXT.COMMON.VALIDATIONS.REQUIRED_FIELD))
      .matches(/^(?!.* {3})/, i18n.t(TEXT.COMMON.VALIDATIONS.CONSECUTIVE_SPACES))
      .max(200, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 200 })),
    streetAddress1: yup
      .string()
      .required(i18n.t(TEXT.COMMON.VALIDATIONS.REQUIRED_FIELD))
      .matches(/^(?!.* {3})/, i18n.t(TEXT.COMMON.VALIDATIONS.CONSECUTIVE_SPACES))
      .max(360, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 360 })),
    streetAddress2: yup
      .string()
      .max(360, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 360 })),
    streetAddress3: yup
      .string()
      .max(360, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 360 })),
    emailAddress: yup
      .string()
      .email(i18n.t(TEXT.COMMON.VALIDATIONS.VALID_EMAIL))
      .max(200, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 200 })),
    businessPhone: yup
      .string()
      .max(40, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 40 }))
      .matches(/^(?!.* {3})/, i18n.t(TEXT.COMMON.VALIDATIONS.CONSECUTIVE_SPACES)),
    mobilePhone: yup
      .string()
      .max(40, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 40 }))
      .matches(/^(?!.* {3})/, i18n.t(TEXT.COMMON.VALIDATIONS.CONSECUTIVE_SPACES)),
    city: yup
      .string()
      .required(i18n.t(TEXT.COMMON.VALIDATIONS.REQUIRED_FIELD))
      .matches(/^(?!.* {3})/, i18n.t(TEXT.COMMON.VALIDATIONS.CONSECUTIVE_SPACES))
      .matches(
        /^(?!.* {3})[^!"$%*<=>?@[\]{}]+$/,
        i18n.t(TEXT.COMMON.VALIDATIONS.SPECIAL_CHARACTERS),
      )
      .max(450, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 450 })),

    zipCode: yup.string().when(['country'], {
      is: (country: string) => country && !countriesWithNoZipCode[country],
      then: () =>
        yup
          .string()
          .required(i18n.t(TEXT.COMMON.VALIDATIONS.REQUIRED_FIELD))
          .matches(/^(?!.* {3})/, i18n.t(TEXT.COMMON.VALIDATIONS.CONSECUTIVE_SPACES))
          .matches(
            /^(?!.* {3})[^!"$%*<=>?@[\]{}]+$/,
            i18n.t(TEXT.COMMON.VALIDATIONS.SPECIAL_CHARACTERS),
          )
          .max(135, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 135 })),
      otherwise: () =>
        yup
          .string()
          .matches(/^(?!.* {3})/, i18n.t(TEXT.COMMON.VALIDATIONS.CONSECUTIVE_SPACES))
          .matches(
            /^(?!.* {3})[^!"$%*<=>?@[\]{}]+$/,
            i18n.t(TEXT.COMMON.VALIDATIONS.SPECIAL_CHARACTERS),
          ),
    }),

    country: yup.number().when(['countriesApiFlag'], {
      is: (countriesApiFlag: boolean) => countriesApiFlag,
      then: () =>
        yup
          .string()

          .required(i18n.t(TEXT.COMMON.VALIDATIONS.REQUIRED_FIELD))
          .matches(
            /^(?!.* {3})[^!"$%*<=>?@[\]{}]+$/,
            i18n.t(TEXT.COMMON.VALIDATIONS.SPECIAL_CHARACTERS),
          )
          .test('is-country-valid', function (value, { createError, path }) {
            return value && !countriesData(countries)[value]
              ? createError({
                  path,
                  message: i18n.t(TEXT.COMMON.VALIDATIONS.COUNTRY_CODE, { countryCode: value }),
                })
              : true;
          }),
      otherwise: () =>
        yup
          .string()
          .required(i18n.t(TEXT.COMMON.VALIDATIONS.REQUIRED_FIELD))
          .matches(
            /^(?!.* {3})[^!"$%*<=>?@[\]{}]+$/,
            i18n.t(TEXT.COMMON.VALIDATIONS.SPECIAL_CHARACTERS),
          ),
    }),
    stateOrProvince: yup.string().when(['country'], {
      is: (country: string, countriesApiFlag: boolean) =>
        !countriesApiFlag && country && oldFormatedDataForCountries[country],
      then: () =>
        yup
          .string()
          .matches(/^(?!.* {3})/, i18n.t(TEXT.COMMON.VALIDATIONS.CONSECUTIVE_SPACES))
          .matches(
            /^(?!.* {3})[^!"$%*<=>?@[\]{}]+$/,
            i18n.t(TEXT.COMMON.VALIDATIONS.SPECIAL_CHARACTERS),
          )
          .max(450, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 450 }))
          .test('is-state-valid', function (value, { createError, path }) {
            const res = oldFormatedDataForCountries?.[this.parent.country]?.states;

            if (res?.length && res?.length > 0 && !value) {
              disableStateField(false);
              return createError({
                path,
                message: i18n.t(TEXT.COMMON.VALIDATIONS.STATE_IS_REQUIRED),
              });
            } else if (
              res?.length &&
              res.length > 0 &&
              value &&
              !res?.find((state: { title: string; code: string }) => {
                disableStateField(false);
                return (
                  state.title === value.toLocaleUpperCase() ||
                  state.code === value.toLocaleUpperCase()
                );
              })
            ) {
              return createError({
                path,
                message: i18n.t(TEXT.COMMON.VALIDATIONS.STATE_CODE, { stateCode: value }),
              });
            } else if (!res?.length) {
              disableStateField(true);
              return true;
            } else {
              return true;
            }
          }),

      otherwise: () =>
        yup
          .string()
          .matches(/^(?!.* {3})/, i18n.t(TEXT.COMMON.VALIDATIONS.CONSECUTIVE_SPACES))
          .matches(
            /^(?!.* {3})[^!"$%*<=>?@[\]{}]+$/,
            i18n.t(TEXT.COMMON.VALIDATIONS.SPECIAL_CHARACTERS),
          )
          .max(450, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 450 }))
          .test('is-state-valid', function (value, { createError, path }) {
            return getSubdivisionByCountryCache(this.parent.country, stateApiConfig).then((res) => {
              if (res.length > 0 && !value) {
                disableStateField(false);
                return createError({
                  path,
                  message: i18n.t(TEXT.COMMON.VALIDATIONS.STATE_IS_REQUIRED),
                });
              } else if (
                res.length > 0 &&
                value &&
                !res?.find((state: { title: string; code: string }) => {
                  disableStateField(false);
                  return (
                    state.title === value.toLocaleUpperCase() ||
                    state.code === value.toLocaleUpperCase()
                  );
                })
              ) {
                return createError({
                  path,
                  message: i18n.t(TEXT.COMMON.VALIDATIONS.STATE_CODE, { stateCode: value }),
                });
              } else if (res.length === 0) {
                disableStateField(true);
                return true;
              } else {
                return true;
              }
            });
          }),
    }),
    managedAccountIndicator: yup.boolean(),
    managedCustomerCode: yup.string().when('managedAccountIndicator', {
      is: (managedAccountIndicator: boolean) => !!managedAccountIndicator,
      then: () => yup.string().required(i18n.t(TEXT.COMMON.VALIDATIONS.REQUIRED_FIELD)),
      otherwise: () => yup.string(),
    }),
  });
};

const myCustomersAdvanceFiltersValidationSchema = yup.object({
  dealerCode: yup.lazy((value) =>
    Array.isArray(value)
      ? yup.array().of(yup.string())
      : yup
          .string()
          .min(4, 'minimum 4 characters required')
          .max(6, 'maximum 6 characters required'),
  ),
  country: yup.string(),
  stateOrProvince: yup.string(),
  startDate: yup.date(),
  endDate: yup.date(),
  hasAssociationToMyDealerCodes: yup.string(),
});

const myWorkQueueAdvanceFilterValidationSchema = yup.object({
  customerName: yup.array(),
  dealerCode: yup.lazy((value) =>
    Array.isArray(value)
      ? yup.array().of(yup.string())
      : yup
          .string()
          .min(4, 'minimum 4 characters required')
          .max(6, 'maximum 6 characters required'),
  ),
  workItemType: yup.string(),
  startDate: yup.date(),
  endDate: yup.date(),
});

const assetRecommendationAdvanceFilterValidationSchema = yup.object({
  dealerCode: yup.lazy((value) =>
    Array.isArray(value)
      ? yup.array().of(yup.string())
      : yup
          .string()
          .min(4, 'minimum 4 characters required')
          .max(6, 'maximum 6 characters required'),
  ),
});

const invitationsFiltersSchema = yup.object({
  selectedAccount: yup.string(),
  selectedCustomers: yup
    .array()
    .of(yup.object())
    .when('selectedAccount', {
      is: (account: string) => account === 'CAT',
      then: () =>
        yup
          .array()
          .min(1)
          .of(
            yup.object().shape({
              customerOrganizationDetails: yup
                .object()
                .shape({ customerOrganizationIdentifier: yup.string() }),
            }),
          ),
    }),
});
const accessRequestFiltersSchema = yup.object({
  selectedAccount: yup.string().required(),
  presetValue: yup.string().when('selectedAccount', {
    is: (account: string) => account === 'CAT',
    then: () => yup.string().min(1).required(),
  }),
  selectedCustomers: yup
    .array()
    .of(yup.object())
    .when(['presetValue', 'selectedAccount'], {
      is: (preset: string, account: string) =>
        preset === presetFilterValue.APPLICATION_ACCESS && account === PartyType.CAT,
      then: () =>
        yup
          .array()
          .min(1)
          .of(
            yup.object().shape({
              customerOrganizationDetails: yup
                .object()
                .shape({ customerOrganizationIdentifier: yup.string() }),
            }),
          ),
    }),
  application: yup.array().of(yup.string()),
  applicationRequestStatus: yup.array().of(yup.string()),
  startDate: yup.date(),
  endDate: yup.date(),
});

const assetIdRule = (t: TFunction<'translation', undefined>) =>
  yup
    .string()
    .strict(true)
    .matches(/^[^<>]*$/, t(TEXT.ASSETS.VALIDATIONS.ASSET_ID_UNSUPPORTED_CHARACTERS))
    .max(50, t(TEXT.ASSETS.VALIDATIONS.ASSET_ID_IS_LONGER, { maxLength: 50 }));

const dealerAssetIdSchema = (t: TFunction<'translation', undefined>) =>
  yup.object().shape({
    dealerAssetId: assetIdRule(t),
  });

const assetIdSchema = (t: TFunction<'translation', undefined>) =>
  yup.object().shape({
    assetIdValue: assetIdRule(t),
  });

const commonAssetValidationFields = () =>
  yup.object({
    model: yup
      .string()
      .required(i18n.t(TEXT.COMMON.VALIDATIONS.REQUIRED_FIELD))
      .matches(REGEX.noWhiteSpace, i18n.t(TEXT.COMMON.VALIDATIONS.NO_BLANK_SPACES))
      .max(65, i18n.t(TEXT.ASSETS.VALIDATIONS.MODEL_CHARACTER_LIMIT)),
    mfgYear: yup.string().required(i18n.t(TEXT.COMMON.VALIDATIONS.REQUIRED_FIELD)),
  });

const addAssetValidationSchema = (enableAssetsDCDCNFeature?: boolean) =>
  yup.object({
    ...commonAssetValidationFields().fields,
    serialNumber: yup
      .string()
      .required(i18n.t(TEXT.COMMON.VALIDATIONS.REQUIRED_FIELD))
      .min(4, i18n.t(TEXT.ASSETS.VALIDATIONS.SERIAL_NUMBER_IS_SHORTER))
      .max(49, i18n.t(TEXT.ASSETS.VALIDATIONS.SERIAL_NUMBER_CHARACTER_MAX_LIMIT))
      .matches(REGEX.noStartEndWhiteSpace, i18n.t(TEXT.COMMON.VALIDATIONS.NO_BLANK_SPACES)),
    make: yup
      .string()
      .required(i18n.t(TEXT.COMMON.VALIDATIONS.REQUIRED_FIELD))
      .matches(REGEX.noWhiteSpace, i18n.t(TEXT.COMMON.VALIDATIONS.NO_BLANK_SPACES)),
    productFamily: yup
      .string()
      .max(50, i18n.t(TEXT.ASSETS.VALIDATIONS.PRODUCT_FAMILY_CHARACTER_LIMIT))
      .when([], {
        is: () => !enableAssetsDCDCNFeature,
        then: (schema) =>
          schema
            .required(i18n.t(TEXT.COMMON.VALIDATIONS.REQUIRED_FIELD))
            .min(2, i18n.t(TEXT.ASSETS.VALIDATIONS.PRODUCT_FAMILY_IS_SHORTER)),
      }),
    dealerAssetID: !enableAssetsDCDCNFeature
      ? yup
          .string()
          .matches(REGEX.noWhiteSpace, i18n.t(TEXT.COMMON.VALIDATIONS.NO_BLANK_SPACES))
          .max(100, i18n.t(TEXT.ASSETS.VALIDATIONS.HUNDRED_CHARACTER_LIMIT))
      : yup.string(),
  });

const editAssetValidationSchema = () =>
  yup.object({
    ...commonAssetValidationFields().fields,
    productFamily: yup
      .string()
      .max(50, i18n.t(TEXT.COMMON.VALIDATIONS.MORE_THAN_CHARACTER, { maxLength: 50 })),
  });

const emailFormValidationSchema = () =>
  yup.object({
    fields: yup
      .array()
      .of(
        yup.object().shape({
          value: yup
            .string()
            .required(i18n.t(TEXT.COMMON.VALIDATIONS.REQUIRED_FIELD))
            .email(i18n.t(TEXT.INVITATIONS.ERRORS.INVALID_EMAIL_ENTRY))
            .matches(REGEX.isValueEmail, i18n.t(TEXT.INVITATIONS.ERRORS.INVALID_EMAIL_ENTRY)),
        }),
      )
      // @ts-ignore
      .duplicateCheck('value', i18n.t(TEXT.INVITATIONS.ERRORS.EMAIL_ENTERED_MORE_ONCE)),
  });

export {
  createCustomerValidationSchema,
  myCustomersAdvanceFiltersValidationSchema,
  myWorkQueueAdvanceFilterValidationSchema,
  assetRecommendationAdvanceFilterValidationSchema,
  invitationsFiltersSchema,
  accessRequestFiltersSchema,
  dealerAssetIdSchema,
  assetIdSchema,
  addAssetValidationSchema,
  editAssetValidationSchema,
  emailFormValidationSchema,
};
