import TEXT from '../../../../../../globals/translation-map';
import AccessRequestColumnSort from '../../../../../access-requests/components/access-request-column-sort';
import SortLabelComponent from '../../../../../../components/sort-label';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type sortOption = {
  key: string;
  value: string;
};

interface SortableTitleCellProps {
  title: string;
  columnIdentifier: string;
  sortOptions?: sortOption[];
  statusMap?: { [key: string]: string };
}

const SortableTitleCell: React.FC<SortableTitleCellProps> = ({
  title,
  columnIdentifier,
  sortOptions,
  statusMap,
}: SortableTitleCellProps) => {
  const [sortOptionsOpen, setSortOptionsOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const ascDescSortOptions: sortOption[] = [
    {
      key: 'ASC',
      value: t(TEXT.COMMON.SORT.A_TO_Z),
    },
    {
      key: 'DESC',
      value: t(TEXT.COMMON.SORT.Z_TO_A),
    },
  ];
  const sortingOptions: sortOption[] = sortOptions || ascDescSortOptions;
  return (
    <SortLabelComponent
      title={title}
      name={columnIdentifier}
      sortBy={''}
      columnIdentifier={columnIdentifier}
      sortDirection={''}
      sortingSelected={false}
      informationIconFlag={true}
      reverseSortArrow={true}
      sortOptionsOpen={sortOptionsOpen}
      setSortOptionsOpen={setSortOptionsOpen}
      statusMap={statusMap}
    >
      <AccessRequestColumnSort
        propertyName={columnIdentifier}
        options={sortingOptions}
        sortBy={''}
        orderBy={''}
        handleSortChange={() => {}}
        handleOrderByChange={() => {}}
        sortPopUpOpen={() => {}}
        setSortingSelected={() => {}}
      />
    </SortLabelComponent>
  );
};

export default SortableTitleCell;
