import { UserSearchResponseArray } from '../../entities/user-identity-v1/models';

export enum userIdentityActionNames {
  CLEAR_USER_IDENTITY = 'CLEAR_USER_IDENTITY',
  SET_LOADING_FOR_USER_IDENTITY = 'SET_LOADING_FOR_USER_IDENTITY',
  SET_USER_IDENTITY_SEARCH_ERROR = 'SET_USER_IDENTITY_SEARCH_ERROR',
  SET_IDENTITIES = 'SET_IDENTITIES',
  CLEAR_IDENTITIES = 'CLEAR_IDENTITIES',
  SET_NO_USER_FOUND_FLG = 'SET_NO_USER_FOUND_FLG',
}

export type ErrorType = {
  code: number;
  message: string;
};

export interface UserIdentityState {
  identities?: UserSearchResponseArray;
  isLoadingUserIdentity?: boolean;
  searchUserIdentityError?: ErrorType;
  noIdentitiesFound: boolean;
}

interface ClearIdentities {
  type: typeof userIdentityActionNames.CLEAR_IDENTITIES;
}

interface SetIdentities {
  type: typeof userIdentityActionNames.SET_IDENTITIES;
  payload: UserSearchResponseArray;
}

interface SetSearchUserIdentityError {
  type: typeof userIdentityActionNames.SET_USER_IDENTITY_SEARCH_ERROR;
  payload: ErrorType;
}

interface SetNoUserFoundFlag {
  type: typeof userIdentityActionNames.SET_NO_USER_FOUND_FLG;
  payload: boolean;
}

interface SetUserIdentityIsLoading {
  type: typeof userIdentityActionNames.SET_LOADING_FOR_USER_IDENTITY;
  payload: boolean;
}

export type UserIdentityActionTypes =
  | SetIdentities
  | ClearIdentities
  | SetUserIdentityIsLoading
  | SetSearchUserIdentityError
  | SetNoUserFoundFlag;
