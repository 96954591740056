import React from 'react';
import {
  Box,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';
import globalStyles from '../../globals/styles';
import TEXT from '../../globals/translation-map';
import StepperButton from '../stepper-button';

interface DialogI {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onValidate: () => void;
  children: React.ReactNode;
  primaryButtonTitle?: string;
  secondaryButtonTitle?: string;
  testId?: string;
  isPrimaryButtonDisabled?: boolean;
}
const Dialog: React.FC<DialogI> = ({
  isOpen,
  onClose,
  onValidate,
  children,
  title,
  primaryButtonTitle,
  secondaryButtonTitle,
  testId,
  isPrimaryButtonDisabled,
}: DialogI) => {
  const { t } = useTranslation();

  const { classes } = useStyles();
  const { classes: globalClasses } = globalStyles();

  return (
    <MUIDialog
      fullWidth
      maxWidth="md"
      data-testid={testId ? testId : 'custom-dialog'}
      aria-labelledby="user-leads-filters-dialog"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        className: globalClasses.dynamicDialog,
      }}
    >
      <DialogTitle
        id="access-management-dialog-title"
        component="div"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Typography variant="h3" component="span">
          {title}
        </Typography>
        <IconButton
          data-testid="cancel-notification-button"
          onClick={onClose}
          size="large"
          color="primary"
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>{children}</DialogContent>
      <Divider />
      <DialogActions className={classes.footerSpacing}>
        <Box display="flex" justifyContent="flex-end">
          <Box mr={2}>
            <StepperButton
              buttonText={secondaryButtonTitle || t(TEXT.COMMON.CLOSE)}
              id="close-dialog-button"
              color="secondary"
              onClick={onClose}
            />
          </Box>
          <Box mr={2}>
            <StepperButton
              type="submit"
              buttonText={primaryButtonTitle || t(TEXT.COMMON.OK)}
              id="ok-dialog-button"
              color="primary"
              disabled={isPrimaryButtonDisabled}
              onClick={onValidate}
            />
          </Box>
        </Box>
      </DialogActions>
    </MUIDialog>
  );
};

export default Dialog;
