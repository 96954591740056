/**
 * User Identity
 * User Identity API provides functionality to retrieve registered users (dealers, customers, Cat) and create and manage contacts (application specific, non-registered entities).  There are two identity types that are managed through these services:   - IAM users, or `users` with an existing relation with Caterpillar, that are stored in Caterpillar's IAM system. These contacts have a pre-existing identifier, known as a `catrecid`, that uniquely identifies them to Caterpillar. `users` have an `IdentityType` of `directory`, since the system of record is Caterpillar's directory.   - Non-IAM users, which are managed within this service. These `contacts` are assigned a system-generated identifier. `contacts` have an `IdentityType` of `application`, since the system of record is the application that creates the `contact`.  An `Identity` is the superclass of these two types.  In general: - `/users/` endpoints only search and retrieve Caterpillar registered users, - `/contacts/` endpoints work with application-specific, non-registered identities, and - `/identities/` endpoints search and retrieve both types. All identities are tagged with the `ownerId` of this contact, which is a registered `user`.  *NOTE*: at this time, `users`, which are stored in Caterpillar's global directory, can not be created, updated, or deleted using these endpoints. 
 *
 * OpenAPI spec version: 0.8.6
 * Contact: platformservicesteam18group@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * User properties that can be used for filtering.
 */
export type UserPropertiesForFiltering = 'catrecid' | 'username' | 'email';

export const UserPropertiesForFiltering = {
    Catrecid: 'catrecid' as UserPropertiesForFiltering,
    Username: 'username' as UserPropertiesForFiltering,
    Email: 'email' as UserPropertiesForFiltering
};