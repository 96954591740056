import { useState } from 'react';
import UniversalCustomers from '../../services/universal-customers';
import UserManagement from '../../services/user-management';
import { AxiosResponse } from 'axios';
import { UsersSearchResponse } from '../../entities/user-management-v1/usersSearchResponse';
import { UniversalCustomersUserType } from '../../pages/my-customers/components/row-details/components/associated-users';
import { UsersSearch } from '../../entities/user-management-v1/usersSearch';
import { filterUsers, parseUCIDXML, userManagementPayload } from './util';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { hasStandardTest } from '../../utils/parties.util';

export const useGetAssociateUserInfo = (properties: {
  organizationId: string;
  genericErrorCallback: Function;
  userManagementErrorCallback?: Function;
  ucidErrorCallback?: Function;
}): {
  associatedUsers: UsersSearch[];
  numberOfUsers: number | undefined;
  isLoadingAssociatedUserData: boolean;
  fetchUserData: Function;
  associatedUniversalCustomersUsers: any;
} => {
  const { authInfo, environmentVariables, partiesResponse } = useSelector(
    (state: AppState) => state.login,
  );

  const [isLoadingAssociatedUserData, setLoadingAssociatedUserDataFlag] = useState<boolean>(true);
  const [numberOfUsers, setNumberOfUsers] = useState<number>();
  const [associatedUsers, setAssociatedUsers] = useState<UsersSearch[]>([]);
  const [associatedUniversalCustomersUsers, setAssociatedUniversalCustomersUsers] = useState<
    Array<any>
  >([]);
  const { organizationId, genericErrorCallback, userManagementErrorCallback, ucidErrorCallback } =
    properties;
  const fetchUserData = async (organizationIdAssociatedInfo: string) => {
    if (organizationIdAssociatedInfo) {
      setLoadingAssociatedUserDataFlag(true);
      try {
        const userManagement = new UserManagement(authInfo, environmentVariables.userManagementV1);
        const universalCustomers = new UniversalCustomers(
          authInfo,
          environmentVariables.universalCustomers,
        );
        const promiseList = [
          userManagement
            .searchUsers(
              userManagementPayload(
                organizationIdAssociatedInfo,
                !!(
                  environmentVariables.removeUniversalCustomerFlag ||
                  hasStandardTest(environmentVariables, partiesResponse)
                ),
              ),
            )
            .catch((error) => {
              if (userManagementErrorCallback) userManagementErrorCallback(error);
            }),
        ];
        if (
          !environmentVariables.removeUniversalCustomerFlag &&
          !hasStandardTest(environmentVariables, partiesResponse)
        ) {
          promiseList.push(
            universalCustomers
              .getAssocitedUniversalCustomers({
                UCID: organizationIdAssociatedInfo,
              })
              .catch((error: any) => {
                if (error?.response?.status !== 404 && ucidErrorCallback) ucidErrorCallback(error);
              }),
          );
        }
        const responses = await Promise.all(promiseList);
        const associatedUsersCIAMResponse: AxiosResponse<UsersSearchResponse> =
          responses[0] as AxiosResponse<UsersSearchResponse>;
        const associatedUsersCIAM = associatedUsersCIAMResponse?.data?.users;
        let associatedUsersUniversalCustomers: Array<UniversalCustomersUserType> = [];
        // delete once flag is removed
        if (
          !environmentVariables.removeUniversalCustomerFlag &&
          !hasStandardTest(environmentVariables, partiesResponse)
        ) {
          associatedUsersUniversalCustomers = parseUCIDXML(
            responses[1] as AxiosResponse<any>,
            associatedUsersCIAM || [],
          );
        }
        setAssociatedUniversalCustomersUsers(associatedUsersUniversalCustomers);
        if (associatedUsersCIAMResponse?.status === 200 && associatedUsersCIAM) {
          const filteredUsers = (associatedUsersCIAM ?? []).filter((user: UsersSearch) =>
            filterUsers({
              user,
              customerOrganizationIdentifier: organizationId,
              ucidFlag: !!(
                environmentVariables.removeUniversalCustomerFlag ||
                hasStandardTest(environmentVariables, partiesResponse)
              ),
            }),
          );
          setAssociatedUsers(filteredUsers);
          setNumberOfUsers(filteredUsers.length + associatedUsersUniversalCustomers.length);
        } else if (associatedUsersUniversalCustomers.length) {
          setNumberOfUsers(associatedUsersUniversalCustomers.length);
        }
      } catch {
        genericErrorCallback();
      }

      setLoadingAssociatedUserDataFlag(false);
    }
  };

  return {
    associatedUsers,
    numberOfUsers,
    isLoadingAssociatedUserData,
    fetchUserData,
    associatedUniversalCustomersUsers,
  };
};
