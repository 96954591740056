import { IDropDownValue } from '../../components/dropdown';
import { GlobalPreferences } from '../../entities/user-preferences-v2/globalPreferences';
import { SetIsLoading } from '../api/loadable';

export enum globalPreferenceActionNames {
  SET_GLOBAL_PREFERENCE = 'SET_GLOBAL_PREFERENCE',
  SET_USER_GLOBAL_PREFERENCE = 'SET_USER_GLOBAL_PREFERENCE',
  SET_IS_LOADING_GLOBAL_PREF = 'SET_IS_LOADING_GLOBAL_PREF',
}

export interface GlobalPreferenceState {
  userPreferences: GlobalPreferences;
  defaults?: GlobalPreferences;
  languages: Array<IDropDownValue>;
  isLoadingGlobalPref?: boolean;
}

interface SetGlobalPreferenceData {
  type: globalPreferenceActionNames.SET_GLOBAL_PREFERENCE;
  payload: { defaults?: GlobalPreferences; languages: Array<IDropDownValue> };
}

interface SetUserGlobalPreferenceData {
  type: globalPreferenceActionNames.SET_USER_GLOBAL_PREFERENCE;
  payload: { userPreferences: GlobalPreferences };
}

export type GlobalPreferenceActionTypes =
  | SetGlobalPreferenceData
  | SetIsLoading<globalPreferenceActionNames.SET_IS_LOADING_GLOBAL_PREF>
  | SetUserGlobalPreferenceData;
