import { BrandCode } from '../../entities/customer-master-v1/brandCode';
import {
  PartiesResponse,
  ServiceBasedPermission,
  UserBasedPermission,
  Permissions,
} from '../../entities/entitlements-v1/models';
import { UsersSearchResponse } from '../../entities/user-management-v1/usersSearchResponse';
import { EnvironmentVariables } from '../../environments';
import { AuthInfo } from '../../model/types';

export enum loginActionNames {
  SET_MODULE_CODE = 'SET_MODULE_CODE',
  SET_CLIENT = 'SET_CLIENT',
  SET_CUSTOM_PREFERENCES = 'SET_CUSTOM_PREFERENCES',
  SET_CUSTOM_SETTINGS = 'SET_CUSTOM_SETTINGS',
  SET_ENV_VARIABLES = 'SET_ENV_VARIABLES',
  SET_INTEGRATED_DEALER_CODES = 'SET_INTEGRATED_DEALER_CODES',
  SET_INTEGRATED_DEALER_FLG = 'SET_INTEGRATED_DEALER_FLG',
  SET_IS_DATA_STEWARD_ADMIN_USER_FLG = 'SET_IS_DATA_STEWARD_ADMIN_USER_FLG',
  SET_IS_DIGITAL_ACCOUNT_MANAGER_FLG = 'SET_IS_DIGITAL_ACCOUNT_MANAGER_FLG',
  SET_LEGAL_AGREEMENT_STATUS = 'SET_LEGAL_AGREEMENT_STATUS',
  SET_LOGIN_ERROR = 'SET_LOGIN_ERROR',
  SET_NAVIGATION_PATH = 'SET_NAVIGATION_PATH',
  SET_PARTIES_RESPONSE = 'SET_PARTIES_RESPONSE',
  SET_PERMISSIONS = 'SET_PERMISSIONS',
  SET_PERMISSIONS_FETCHING = 'SET_PERMISSIONS_FETCHING',
  SET_PERMISSIONS_RESPONSE = 'SET_PERMISSIONS_RESPONSE',
  SET_PERMISSIONS_UPDATE_FLAG = 'SET_PERMISSIONS_UPDATE_FLAG',
  SET_PRIMARY_PARTY_NUMBER = 'SET_PRIMARY_PARTY_NUMBER',
  SET_ROLES = 'SET_ROLES',
  SET_ROLES_FETCHING = 'SET_ROLES_FETCHING',
  SET_UPDATE_TOKEN_FLAG = 'SET_UPDATE_TOKEN_FLAG',
  SET_USER_INFO_RESPONSE = 'SET_USER_INFO_RESPONSE',
  SET_LOGIN_TIME = 'SET_LOGIN_TIME',
  REFRESH_TOKEN_LOADING = 'REFRESH_TOKEN_LOADING',
}
export interface customSettingsType {
  filterTags: boolean;
  defaultDealerCode: boolean;
  dealerCode?: string;
  defaultDealerUserCode: boolean;
  dealerUserCode?: string;
  customerData: BrandCode;
}
export const MODULE_CODES = {
  ASSET_MANAGEMENT: 'AM',
  CUSTOMERS: 'CM',
  USER_ONBOARDING: 'CUOB',
};
export interface LoginState {
  moduleCode?: string;
  authInfo: AuthInfo | null;
  customSettings: customSettingsType;
  environmentVariables: EnvironmentVariables;
  integratedDealerCodes: Array<string>;
  isIntegratedDealer?: boolean;
  isLoginPending: boolean;
  isLoginSuccess: boolean;
  isPermissionFetching: boolean;
  isReadOnlyUser?: boolean;
  legalAgreementTimestamp: number | null;
  legalAgreementVersion: any | null;
  loginError: any;
  partiesResponse?: PartiesResponse;
  permissions?: { [key: string]: Permissions };
  permissionsResponse?: UserBasedPermission | ServiceBasedPermission;
  permissionsUpdateFlag?: boolean;
  primaryPartyNumber?: string;
  roles?: Array<{ partyNumber: string; roles: [{ roleName: string }] }>;
  updateToken?: boolean;
  navigationPath?: string | undefined;
  userInfoResponse?: UsersSearchResponse;
  loginTime?: Date;
  isRefreshTokenLoading: boolean;
}

export interface PreferencesObject {
  legalAgreementTimestamp: number | null;
  legalAgreementVersion: boolean | null;
  customSettings: customSettingsType;
}

interface SetLoginError {
  type: loginActionNames.SET_LOGIN_ERROR;
  payload: boolean;
}
interface SetClient {
  type: typeof loginActionNames.SET_CLIENT;
  payload: AuthInfo;
}
interface SetModuleCode {
  type: typeof loginActionNames.SET_MODULE_CODE;
  payload: string;
}

interface SetEnvironmentVariables {
  type: typeof loginActionNames.SET_ENV_VARIABLES;
  payload: EnvironmentVariables;
}

interface SetPrimaryPartyNumber {
  type: typeof loginActionNames.SET_PRIMARY_PARTY_NUMBER;
  payload: string;
}
interface SetIntegratedDealerFlg {
  type: typeof loginActionNames.SET_INTEGRATED_DEALER_FLG;
  payload: boolean;
}
interface SetPermissions {
  type: typeof loginActionNames.SET_PERMISSIONS;
  payload: { [key: string]: Permissions };
}
interface SetPermissionFetching {
  type: typeof loginActionNames.SET_PERMISSIONS_FETCHING;
  payload: boolean;
}
interface SetRoles {
  type: typeof loginActionNames.SET_ROLES;
  payload: Array<{ partyNumber: string; roles: [{ roleName: string }] }>;
}
interface SetRolesFetching {
  type: typeof loginActionNames.SET_ROLES_FETCHING;
  payload: boolean;
}

interface SetPermissionsResponse {
  type: typeof loginActionNames.SET_PERMISSIONS_RESPONSE;
  payload: UserBasedPermission | ServiceBasedPermission;
}
interface SetPartiesResponse {
  type: typeof loginActionNames.SET_PARTIES_RESPONSE;
  payload: PartiesResponse;
}

interface SetUserInfoResponse {
  type: typeof loginActionNames.SET_USER_INFO_RESPONSE;
  payload: UsersSearchResponse;
}

interface SetPermissionsUpdateFlag {
  type: typeof loginActionNames.SET_PERMISSIONS_UPDATE_FLAG;
  payload: boolean;
}
interface SetUpdateTokenFlag {
  type: typeof loginActionNames.SET_UPDATE_TOKEN_FLAG;
  payload: boolean;
}

interface SetIntegratedDealerCodes {
  type: typeof loginActionNames.SET_INTEGRATED_DEALER_CODES;
  payload: Array<string>;
}
interface SetCustomPreferences {
  type: typeof loginActionNames.SET_CUSTOM_PREFERENCES;
  payload: PreferencesObject;
}
interface SetCustomSettings {
  type: typeof loginActionNames.SET_CUSTOM_SETTINGS;
  payload: customSettingsType;
}

interface SetNavigationPath {
  type: typeof loginActionNames.SET_NAVIGATION_PATH;
  payload: string;
}

interface SetLoginTime {
  type: typeof loginActionNames.SET_LOGIN_TIME;
  payload: Date;
}

interface SetRefreshTokenLoading {
  type: typeof loginActionNames.REFRESH_TOKEN_LOADING;
  payload: boolean;
}
export type LoginActionTypes =
  | SetClient
  | SetModuleCode
  | SetCustomPreferences
  | SetCustomSettings
  | SetEnvironmentVariables
  | SetIntegratedDealerCodes
  | SetIntegratedDealerFlg
  | SetLoginError
  | SetNavigationPath
  | SetPartiesResponse
  | SetPermissionFetching
  | SetPermissions
  | SetPermissionsResponse
  | SetPermissionsUpdateFlag
  | SetPrimaryPartyNumber
  | SetRoles
  | SetRolesFetching
  | SetUpdateTokenFlag
  | SetUserInfoResponse
  | SetLoginTime
  | SetRefreshTokenLoading;
