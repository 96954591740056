import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  completedIcon: {
    color: CAT_COLOR.EMERALD,
  },
  pendingSteps: {
    color: CAT_COLOR.WHITE_SMOKE,
  },
  currentStep: {
    color: CAT_COLOR.EMERALD,
  },
  stepStyle: {
    '& .MuiStep-horizontal': {
      width: '0',
    },
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      overflowWrap: 'break-word',
    },
  },
  customerDetails: {
    overflow: 'auto',
    '&>*': {
      textAlign: 'center',
    },
  },
}));
