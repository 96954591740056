import { userLeadsActionNames, UserLeadsActionTypes, UserLeadsState } from './types';

const initialState: UserLeadsState = {
  selectedUserLead: null,
  filters: {
    // orderByRules: [], Uncomment and complete once API is ready
    // sortByRules: []
    selectedAccount: '',
    status: [],
    dateRange: {
      startDate: undefined,
      endDate: undefined,
      selectedButton: undefined,
    },
  },
};

const userLeadsReducer = (state: UserLeadsState = initialState, action: UserLeadsActionTypes) => {
  switch (action.type) {
    case userLeadsActionNames.SET_ACTIVE_USER_LEAD: {
      return {
        ...state,
        selectedUserLead: action.payload,
      };
    }
    case userLeadsActionNames.SET_USER_LEADS_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case userLeadsActionNames.CLEAR_USER_LEADS_FILTERS: {
      return {
        ...state,
        filters: initialState.filters,
      };
    }
    default:
      return state;
  }
};

export default userLeadsReducer;
