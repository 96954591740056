import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState, useDispatchTs } from '../../store';
import useCancelNotification from '../../hooks/cancel-notification';
import { unsavedChangesNotificationActionNames } from '../../store/unsaved-changes-notification/types';

const UnsavedChangesConfirmation = () => {
  const dispatch = useDispatchTs();
  const { handleContinueNotification } = useSelector((state: AppState) => {
    return state.shared.unsavedChangesNotification;
  });

  const handleContinue = () => {
    if (handleContinueNotification) {
      handleContinueNotification();
    }
  };

  const handelCancelNotification = () => {
    dispatch({
      type: unsavedChangesNotificationActionNames.RESET_UNSAVEDCHANGES_STORE,
    });
  };
  const setVisibility = useCancelNotification({
    handleContinue,
    handleCancel: handelCancelNotification,
  });

  useEffect(() => {
    if (handleContinueNotification) {
      setVisibility(true);
    }
  }, [handleContinueNotification, setVisibility]);
  return null;
};
export default UnsavedChangesConfirmation;
