import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  dateRangeBtn: {
    lineHeight: 1,
    marginRight: theme.spacing(2),
    borderRadius: 4,
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
    '&:hover': {
      backgroundColor: CAT_COLOR.MEDIUM_GRAY,
      cursor: 'pointer',
    },
  },
  dateRangeActiveBtn: {
    lineHeight: 1,
    marginRight: theme.spacing(2),
    borderRadius: 4,
    backgroundColor: CAT_COLOR.DARK_GRAYISH_BLUE,
    color: CAT_COLOR.WHITE,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: CAT_COLOR.LIGHT_SLATE_GREY,
    },
  },
  filterButton: {
    paddingTop: theme.spacing(0.75),
    border: 'none',
    width: theme.spacing(8),
    fontWeight: 100,
    '&:disabled': {
      border: 'none',
      backgroundColor: CAT_COLOR.WHITE,
    },
    '&:hover': {
      border: 'none',
      backgroundColor: CAT_COLOR.WHITE_SMOKE,
    },
  },
}));
