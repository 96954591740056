import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../../../globals/color-properties';

export default makeStyles()((theme) => ({
  footerSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  filterByText: {
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
    fontWeight: 600,
  },
  iconPosition: {
    position: 'absolute',
    right: theme.spacing(1),
    color: 'black',
    top: theme.spacing(1),
  },
  dialogContent: {
    padding: 0,
  },
}));
