import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../../../globals/color-properties';

export default makeStyles()((theme) => ({
  footerGradient: {
    background: 'linear-gradient(to top, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 100%)',
  },
  tooltip: {
    marginTop: theme.spacing(1),
  },
  text: {
    '& p': {
      fontWeight: 'bold',
      color: CAT_COLOR.BLACK,
    },
  },
  titleTextSmall: {
    fontWeight: '400',
  },
  reviewPageContainer: {
    overflowY: 'auto',
  },
  customerProfile: {
    marginTop: '5px',
    padding: '24px',
    display: 'flex',
    flexDirection: 'row',
    rowGap: '12px',
    flexWrap: 'wrap',
    justifyItems: 'center',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  divider: {
    marginTop: 8,
  },
  footerActions: {
    backgroundColor: 'white',
  },
  widthContainer: {
    width: '1000px',
  },
  customerAddress: {
    width: '230px',
    marginLeft: '30px',
    paddingLeft: theme.spacing(10),
  },
  companyHeader: {
    color: CAT_COLOR.LIGHT_SLATE_GREY,
    fontWeight: 400,
    marginBottom: theme.spacing(1),
    fontSize: '14px',
    lineHeight: '14px',
  },
  companyInfo: {
    fontWeight: 700,
  },
  noAssociationsMessage: {
    padding: theme.spacing(2),
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  associationsTitle: {
    marginTop: theme.spacing(6),
  },
  accordionContainer: {
    marginTop: theme.spacing(3),
  },
  accordionTitle: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '26px',
  },
  chipContainer: {
    marginLeft: 'auto',
    borderTop: '0px',
    display: 'flex',
  },
  associationList: {
    width: '300px',
    marginRight: '15%',
  },
  associationListTitle: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    color: CAT_COLOR.LIGHT_SLATE_GREY,
    marginBottom: theme.spacing(1.5),
  },
  associationContainer: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    rowGap: '12px',
    flexWrap: 'wrap',
    justifyItems: 'center',
    paddingLeft: theme.spacing(5),
    fontSize: 14,
    lineHeight: '14px',
    fontWeight: 400,
    color: CAT_COLOR.BLACK,
    maxHeight: 254,
    overflow: 'auto',
    border: '0px',
  },
  dealerCustomerName: {
    fontWeight: 700,
  },
  successfulAssociationsCustomerInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  failedAssociationsCustomerInfo: {
    display: 'flex',
    marginTop: '10px',
  },
  errorIcon: {
    marginRight: '8px',
    marginTop: '2px',
  },
  errorDescription: {
    flex: '1',
  },
}));
