import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const ErrorIcon = (props: iconType) => (
  <SvgIcon {...props} viewBox="0 0 24 24" style={{ fill: 'none', width: '24px', height: '24px' }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#ED1C24"
      d="M0 12C0 18.627 5.3715 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0C5.3715 0 0 5.373 0 12Z"
    />
    <path
      d="M12 15C11.172 15 10.5 15.672 10.5 16.5C10.5 17.328 11.172 18 12 18C12.828 18 13.5 17.328 13.5 16.5C13.5 15.672 12.828 15 12 15Z"
      fill="white"
    />
    <path
      d="M13.5 12C13.5 12.8284 12.8284 13.5 12 13.5C11.1716 13.5 10.5 12.8284 10.5 12L10.5 7.5C10.5 6.67157 11.1716 6 12 6C12.8284 6 13.5 6.67157 13.5 7.5L13.5 12Z"
      fill="white"
    />
  </SvgIcon>
);

export default ErrorIcon;
