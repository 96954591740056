import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const MyCustomers = (props: iconType) => {
  const { stroke = '#666565' } = props;
  return (
    <SvgIcon
      {...props}
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="0 0 18 18"
      style={{ fill: stroke }}
    >
      <g clipPath="url(#clip0_1267_791)">
        <path
          strokeWidth="0.01"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.87621 0.828764C3.55404 0.826921 3.23772 0.91494 2.96279 1.08295C2.68724 1.25134 2.46424 1.49338 2.31893 1.78176L0.89009 4.65114C0.851303 4.72903 0.831116 4.81486 0.831116 4.90188V7.24228C0.831116 7.54665 0.952023 7.83854 1.16724 8.05376C1.38246 8.26898 1.67435 8.38988 1.97872 8.38988H2.00134V16.0188C2.00134 16.3232 2.12225 16.6151 2.33747 16.8303C2.55269 17.0455 2.84458 17.1664 3.14894 17.1664H10.0956C10.12 17.1696 10.1449 17.1713 10.1702 17.1713C10.1955 17.1713 10.2204 17.1696 10.2448 17.1664H14.851C15.1553 17.1664 15.4472 17.0455 15.6625 16.8303C15.8777 16.6151 15.9986 16.3232 15.9986 16.0188V8.38988H16.0212C16.3255 8.38988 16.6174 8.26898 16.8326 8.05376C17.0479 7.83854 17.1688 7.54664 17.1688 7.24228V4.90188C17.1688 4.81486 17.1486 4.72903 17.1098 4.65114L15.6821 1.78414L15.681 1.78176C15.5356 1.49338 15.3126 1.25134 15.0371 1.08295C14.7622 0.91494 14.4458 0.826921 14.1237 0.828764H3.87621ZM3.87053 1.95375C3.75728 1.95296 3.64606 1.98384 3.54942 2.0429C3.45309 2.10176 3.37507 2.18629 3.3241 2.287L2.30217 4.33923H15.6977L14.6763 2.28799L14.6758 2.287C14.6248 2.18629 14.5468 2.10176 14.4505 2.0429C14.3538 1.98384 14.2426 1.95296 14.1293 1.95375L14.1254 1.95376L3.87053 1.95375ZM1.95612 7.24228V5.44415C1.99935 5.45724 2.04487 5.46423 2.09188 5.46423H15.9054C15.9533 5.46423 15.9997 5.45695 16.0438 5.44334V7.24228C16.0438 7.24828 16.0414 7.25403 16.0371 7.25826C16.0329 7.2625 16.0272 7.26488 16.0212 7.26488H1.97872C1.97272 7.26488 1.96697 7.2625 1.96274 7.25826C1.9585 7.25403 1.95612 7.24828 1.95612 7.24228ZM14.8736 8.38988H10.7327L10.7327 16.0414H14.851C14.857 16.0414 14.8627 16.039 14.867 16.0348C14.8712 16.0305 14.8736 16.0248 14.8736 16.0188V8.38988ZM9.60773 8.38988H3.12634V11.9456H9.60773V8.38988ZM3.12634 16.0188V13.0706H9.60773V16.0414H3.14894C3.14295 16.0414 3.1372 16.039 3.13296 16.0348C3.12872 16.0305 3.12634 16.0248 3.12634 16.0188Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_1267_791">
          <rect width="24" height="24" fill="none" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default MyCustomers;
