import React from 'react';
import { FieldArray } from 'formik';
import { Button, Box, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseSharp';
import FormInput from '../../../../components/form-input';
import AddItemIcon from '../../../../assets/icons/add-item';
import useStyles from './styles';
import TEXT from '../../../../globals/translation-map';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';

type Field = {
  value: string;
};
type FormValues = {
  fields: Field[];
};
interface FieldArrayRenderProps {
  form: FormikProps<FormValues>;
  push: (obj: Field) => void;
  remove: (index: number) => void;
}

const EmailFields = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <FieldArray name="fields" data-testId="email-fields">
      {(fieldArrayProps: FieldArrayRenderProps) => {
        const { form } = fieldArrayProps;
        const { values } = form;
        const { fields } = values;

        return (
          <>
            {fields.map((field: Field, index: number) => {
              return (
                <Grid
                  container
                  data-testid={`email${index}`}
                  className={classes.emailRow}
                  spacing={0}
                  key={`email${index}`}
                >
                  <Grid item xs={11}>
                    <FormInput
                      fullWidth
                      customClass={classes.inputField}
                      label={t(TEXT.COMMON.USER_EMAIL_ADDRESS)}
                      inputAccessorKey={`fields.${index}.value`}
                      requiredField={false}
                      autoComplete={false}
                      nestedInputKey
                      submitOnEnter={false}
                      {...form}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    {fields.length > 1 && (
                      <IconButton
                        data-testid={`remove-row${index}`}
                        onClick={() => {
                          fieldArrayProps.remove(index);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              );
            })}
            {fields.length < 20 && (
              <Box className={classes.addItemIconContainer}>
                <Button
                  data-testid="add-new-row"
                  startIcon={
                    <AddItemIcon data-testid="add-item-icon" className={classes.addItemIcon} />
                  }
                  onClick={() => {
                    fieldArrayProps.push({ value: '' });
                  }}
                >
                  {t(TEXT.COMMON.ADD_NEW_ROW)}
                </Button>
              </Box>
            )}
          </>
        );
      }}
    </FieldArray>
  );
};
export default EmailFields;
