import {
  UnsavedChangesNotificationState,
  UnsavedChangesNotificationActionTypes,
  unsavedChangesNotificationActionNames,
} from './types';

const initialState: UnsavedChangesNotificationState = {
  handleContinueNotification: undefined,
  currentPageStatus: false,
};

const unsavedChangesNotificationReducer = (
  state: UnsavedChangesNotificationState = initialState,
  action: UnsavedChangesNotificationActionTypes,
): UnsavedChangesNotificationState => {
  switch (action.type) {
    case unsavedChangesNotificationActionNames.SET_CURRENT_FORM_IS_DIRTY: {
      return {
        ...state,
        currentPageStatus: action.payload,
      };
    }
    case unsavedChangesNotificationActionNames.SET_HANDLE_CONTINUE: {
      return {
        ...state,
        handleContinueNotification: action.payload,
      };
    }
    case unsavedChangesNotificationActionNames.RESET_UNSAVEDCHANGES_STORE: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default unsavedChangesNotificationReducer;
