import { EnvironmentName } from './environments';

const checkEnvironment = (): EnvironmentName => {
  const baseUrl = window.location.href;
  let environment: EnvironmentName = EnvironmentName.local;
  if (process.env.REACT_APP_FAKE_ENV === 'true') {
    environment = EnvironmentName.fake;
  } else if (baseUrl.includes('mycustomer.platform.dev.helios-internal.cat.com')) {
    environment = EnvironmentName.development;
  } else if (baseUrl.includes('mycustomer.platform.int.helios-internal.cat.com')) {
    environment = EnvironmentName.integration;
  } else if (baseUrl.includes('mycustomer.cat.com')) {
    environment = EnvironmentName.production;
  }
  return environment;
};

export default checkEnvironment;
