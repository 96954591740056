import { FinancialProductDetails } from '../../entities/customer-master-v1/financialProductDetails';

export enum associateCatFiProductsActionNames {
  SET_IS_LOADING_CAT_FI_ASSOCIATIONS = 'SET_IS_LOADING_CAT_FI_ASSOCIATIONS',
  SET_ASSOCIATE_CAT_FI_PRODUCTS_RESPONSE = 'SET_ASSOCIATE_CAT_FI_PRODUCTS_RESPONSE',
}

export interface AssociateCatFiProductsState {
  isLoadingCatFiAssociations: boolean;
  associateCatFiProductsResponse: Array<FinancialProductsDetailsResponse>;
}
export interface SetLoadingCatFiAssociations {
  type: typeof associateCatFiProductsActionNames.SET_IS_LOADING_CAT_FI_ASSOCIATIONS;
  payload: boolean;
}
export interface SetAssociateCatFiProductsResponse {
  type: typeof associateCatFiProductsActionNames.SET_ASSOCIATE_CAT_FI_PRODUCTS_RESPONSE;
  payload: Array<FinancialProductsDetailsResponse> | Array<FinancialProductDetails>;
}

export type associateCatFiProductsActionTypes =
  | SetLoadingCatFiAssociations
  | SetAssociateCatFiProductsResponse;

export type FinancialProductsDetailsResponse = FinancialProductDetails & any; // any as specified in entity associateFinancialProductPartialFailureResponse.ts

export enum FinancialProductName {
  CatCard = 'Cat Card',
  CatCredit = 'Cat Credit',
  CatVantage = 'Cat Vantage',
  CVA = 'CVA',
  Retail = 'Retail',
}
