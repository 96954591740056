import React from 'react';
import { Box, Typography } from '@mui/material';
import { generate } from 'shortid';
import TextWithToolTip from '../text-with-tooltip';
import { removeLastComma } from '../../utils/util';

export type CustomerAddressDisplayPropertyTypes = {
  fontSize?: string | number;
  fontWeight?: number;
  cityName?: string;
  stateOrProvinceCode?: string;
  countryCode?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  postalCode?: string;
  uniqueIdforOrg?: string;
  className?: string;
  showFullAddress?: boolean;
  width?: string;
  color?: string;
};

const CustomerAddressDisplay = (properties: CustomerAddressDisplayPropertyTypes) => {
  const defaultFontSize = 14;
  const defaultFontWeight = 400;
  const defaultColor = '#000000';
  const {
    cityName = '',
    stateOrProvinceCode = '',
    countryCode = '',
    address1,
    address2,
    address3,
    postalCode,
    uniqueIdforOrg,
    showFullAddress,
    className,
    width,
    fontSize,
    fontWeight,
    color,
  } = properties;
  let address;
  if (showFullAddress) {
    address = (
      <>
        {address1 && (
          <TextWithToolTip
            width={width ? width : '200px'}
            fontSize={fontSize ?? defaultFontSize}
            longText={address1}
            color={color ?? defaultColor}
            fontWeight={fontWeight ?? defaultFontWeight}
          />
        )}
        {(address2 || address3) && (
          <TextWithToolTip
            fontWeight={fontWeight ?? defaultFontWeight}
            width={width ? width : '200px'}
            fontSize={fontSize ?? defaultFontSize}
            color={color ?? defaultColor}
            longText={`${`${address2 ? `${address2}, ` : ''}${address3 ? `${address3}` : ''}`
              .replace(/[ ,]+$/, '')
              .trim()}`}
          />
        )}
      </>
    );
  }
  return (
    <Box data-testid="address-container" key={generate()} fontSize={fontSize ?? defaultFontSize}>
      <Typography component="div" className={className ? className : ''}>
        {showFullAddress ? (
          <>
            <Box
              data-testid={`customer-address-${uniqueIdforOrg}`}
              fontWeight={fontWeight ?? defaultFontWeight}
              color={color ?? defaultColor}
            >
              {address}
            </Box>
            <Box
              data-testid={`other-detail-${uniqueIdforOrg}`}
              fontWeight={fontWeight ?? defaultFontWeight}
              color={color ?? defaultColor}
            >
              {(cityName || stateOrProvinceCode || countryCode || postalCode) && (
                <TextWithToolTip
                  width={width ? width : '200px'}
                  fontSize={fontSize ?? defaultFontSize}
                  fontWeight={fontWeight ?? defaultFontWeight}
                  color={color ?? defaultColor}
                  longText={`${`${cityName ? `${cityName}, ` : ''}${
                    stateOrProvinceCode ? `${stateOrProvinceCode}, ` : ''
                  }${countryCode ? `${countryCode}, ` : ''}${postalCode ? `${postalCode}` : ''}`
                    .replace(/[ ,]+$/, '')
                    .trim()}`}
                />
              )}
            </Box>
          </>
        ) : (
          <>
            <Box
              data-testid={`customer-address1-${uniqueIdforOrg}`}
              fontSize={fontSize ?? defaultFontSize}
              color={color ?? defaultColor}
              fontWeight={fontWeight ?? defaultFontWeight}
            >
              {address1}
            </Box>
            {address2 && (
              <Box
                data-testid={`customer-address2-${uniqueIdforOrg}`}
                fontSize={fontSize ?? defaultFontSize}
                color={color ?? defaultColor}
                fontWeight={fontWeight ?? defaultFontWeight}
              >
                {address2}
              </Box>
            )}
            {address3 && (
              <Box
                data-testid={`customer-address3-${uniqueIdforOrg}`}
                fontSize={fontSize ?? defaultFontSize}
                color={color ?? defaultColor}
                fontWeight={fontWeight ?? defaultFontWeight}
              >
                {address3}
              </Box>
            )}
            <Box
              data-testid={`other-details-${uniqueIdforOrg}`}
              fontSize={fontSize ?? defaultFontSize}
              color={color ?? defaultColor}
              fontWeight={fontWeight ?? defaultFontWeight}
            >
              {`${cityName ? `${removeLastComma(cityName.trim())}, ` : ''}
            ${stateOrProvinceCode ? `${stateOrProvinceCode}, ` : ''}
            ${countryCode ? `${countryCode}, ` : ''}
            ${postalCode ? `${postalCode}` : ''}
            `}
            </Box>
          </>
        )}
      </Typography>
    </Box>
  );
};

export default CustomerAddressDisplay;
