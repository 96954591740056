import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const ChangeAccountIcon = (props: iconType) => {
  const { width = '24px', height = '24px', stroke = '#78869E' } = props;
  return (
    <SvgIcon
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
      viewBox="0 0 24 24"
      style={{ fill: 'none', width, height }}
    >
      <path
        d="M1 20.1667H20.0837"
        stroke="#78869E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2761 4.33325H6.80859V20.1666H14.2761V4.33325Z"
        stroke="#78869E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2545 11.8333H15.9355V20.1666H19.2545V11.8333Z"
        stroke="#78869E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.14898 11.8333H1.83008V20.1666H5.14898V11.8333Z"
        stroke="#78869E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.31836 11.8333V11"
        stroke="#78869E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7656 11.8333V11"
        stroke="#78869E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7861 2.66675H9.29688V4.33341H11.7861V2.66675Z"
        stroke="#78869E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.957 2.66667V1"
        stroke="#78869E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.4668 6.83325H12.6154"
        stroke="#78869E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.4668 9.33325H12.6154"
        stroke="#78869E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.4668 11.8333H12.6154"
        stroke="#78869E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.4668 14.3333H12.6154"
        stroke="#78869E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.4668 16.8333H12.6154"
        stroke="#78869E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default ChangeAccountIcon;
