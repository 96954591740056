import { DeleteFilterActionPayloadType } from '../../components/filter-tags/types';
import {
  BooleanEqualsFilter,
  ContainsFilter,
  StringEqualsFilter,
  TimeRangeFilter,
  FuzzyMatchFilter,
} from '../../entities/customer-master-v1/models';
import { SecondaryFilterType } from '../../pages/my-customers/components/advance-filters/types';

export enum filtersActionNames {
  SET_SORT_BY_VALUE = 'SET_SORT_BY_VALUE',
  SET_ORDER_BY_VALUE = 'SET_ORDER_BY_VALUE',
  SET_GLOBAL_SEARCH_FLAG = 'SET_GLOBAL_SEARCH_FLAG',
  SET_PRIMARY_FILTER_VALUE = 'SET_PRIMARY_FILTER_VALUE',
  SET_SECONDARY_FILTER_VALUE = 'SET_SECONDARY_FILTER_VALUE',
  CLEAR_PRIMARY_FILTER_VALUE = 'CLEAR_PRIMARY_FILTER_VALUE',
  DELETE_SECONDARY_FILTER_VALUE_BY_TYPE = 'DELETE_SECONDARY_FILTER_VALUE_BY_TYPE',
  CLEAR_SECONDARY_FILTER_VALUE = 'CLEAR_SECONDARY_FILTER_VALUE',
  SET_PRIMARY_CONDITIONAL_OPERATOR = 'SET_PRIMARY_CONDITIONAL_OPERATOR',
  CLEAR_PRIMARY_CONDITIONAL_OPERATOR = 'CLEAR_PRIMARY_CONDITIONAL_OPERATOR',
}

export type RulesRow = {
  propertyName: string;
  type: string;
  value: string | boolean;
};
export interface FiltersState {
  primaryFilterRules: Array<
    ContainsFilter | StringEqualsFilter | BooleanEqualsFilter | TimeRangeFilter | FuzzyMatchFilter
  >;
  secondaryFilterRules: Array<
    ContainsFilter | StringEqualsFilter | BooleanEqualsFilter | TimeRangeFilter
  >;
  searchBarText: string | undefined;
  sortByRules: Array<string>;
  orderByRules: Array<string>;
  hasAssociationToMyDealerCodes: boolean;
  primaryConditionalOperator: string;
}
interface SetPrimaryFilterValue {
  type: typeof filtersActionNames.SET_PRIMARY_FILTER_VALUE;
  payload: Array<
    ContainsFilter | StringEqualsFilter | BooleanEqualsFilter | TimeRangeFilter | FuzzyMatchFilter
  >;
}


export interface DeleteSecondaryFilterValueByType {
  type: typeof filtersActionNames.DELETE_SECONDARY_FILTER_VALUE_BY_TYPE;
  payload : DeleteFilterActionPayloadType<SecondaryFilterType>
}


interface SetSecondaryFilterValue {
  type: typeof filtersActionNames.SET_SECONDARY_FILTER_VALUE;
  payload: Array<ContainsFilter | StringEqualsFilter | BooleanEqualsFilter | TimeRangeFilter>;
}
interface ClearPrimaryFilters {
  type: typeof filtersActionNames.CLEAR_PRIMARY_FILTER_VALUE;
}
interface ClearSecondaryFilters {
  type: typeof filtersActionNames.CLEAR_SECONDARY_FILTER_VALUE;
}
interface SetPrimaryConditionalOperator {
  type: typeof filtersActionNames.SET_PRIMARY_CONDITIONAL_OPERATOR;
  payload: string;
}
interface ClearPrimaryConditionalOperator {
  type: typeof filtersActionNames.CLEAR_PRIMARY_CONDITIONAL_OPERATOR;
}

interface SetSortByRules {
  type: typeof filtersActionNames.SET_SORT_BY_VALUE;
  payload: Array<string>;
}

interface SetOrderByRules {
  type: typeof filtersActionNames.SET_ORDER_BY_VALUE;
  payload: Array<string>;
}

interface SetGlobalSearchFlag {
  type: typeof filtersActionNames.SET_GLOBAL_SEARCH_FLAG;
  payload: boolean;
}

export type FiltersActionTypes =
  | SetSortByRules
  | SetOrderByRules
  | SetGlobalSearchFlag
  | SetPrimaryFilterValue
  | SetSecondaryFilterValue
  | ClearPrimaryFilters
  | ClearSecondaryFilters
  | SetPrimaryConditionalOperator
  | ClearPrimaryConditionalOperator
  | DeleteSecondaryFilterValueByType;
