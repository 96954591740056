import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  leftSidePadding: {
    paddingLeft: theme.spacing(2),
  },
  arrowPadding: {
    paddingLeft: theme.spacing(0.5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  tableDivider: {
    marginLeft: theme.spacing(-2),
  },
  tableOpenTitle: {
    backgroundColor: CAT_COLOR.WHITE_SMOKE,
  },
  row: {
    display: 'flex',
    borderBottom: `1px solid ${CAT_COLOR.MERCURY}`,
    fontFamily: 'NotoSans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '18px',
  },
  cell: {
    margin: 'auto',
    marginRight: theme.spacing(1),
  },
  width3col: {
    width: '33%',
    display: 'flex',
    flexWrap: 'nowrap',
  },
  width2col: {
    width: '50%',
  },
  headerCell: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: 'auto',
    marginLeft: 0,
  },
  floatLeft: {
    float: 'left',
  },
  tableContainer: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(5),
  },
  address: {
    fontFamily: 'NotoSans',
    fontStyle: 'normal',
  },
  username: {
    fontWeight: '700',
    paddingRight: '3px',
  },
  empty: {
    fontFamily: 'NotoSans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
    margin: 'auto',
    padding: theme.spacing(5),
  },
}));
