import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  filterButton: {
    padding: theme.spacing(0.75),
    border: 'none',
    fontWeight: 100,
    '&:disabled': {
      border: 'none',
      backgroundColor: CAT_COLOR.WHITE,
    },
    '&:hover': {
      border: 'none',
      backgroundColor: CAT_COLOR.WHITE_SMOKE,
    },
  },
  rightButtonContainer: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
  },
  firstColValue: {
    paddingLeft: '17px',
    display: 'flex',
  },
  firstColTitle: {
    paddingLeft: '15px',
  },
  buttonBar: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2.5),
  },
  dividerText: {
    marginTop: theme.spacing(1.25),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
}));
