import { Role } from '../../entities/user-onboarding-v1/role';
import { ApplicationsType } from '../../pages/invitations/components/invitations-drawer/types';
import { SearchManufacturerCrossReferenceResponseItem } from '../../entities/manufacturerMetadata-v1/searchManufacturerCrossReferenceResponseItem';

export enum metadataActionNames {
  SET_APPLICATIONS = 'SET_APPLICATIONS',
  SET_APPLICATIONS_PARTIES = 'SET_APPLICATIONS_PARTIES',
  SET_APPLICATION_ROLES = 'SET_APPLICATION_ROLES',
  SET_APPLICATIONS_LOADING_DC = 'SET_APPLICATIONS_LOADING_DC',
  SET_APPLICATIONS_LOADING_ROLE = 'SET_APPLICATIONS_LOADING_ROLE',
  SET_APPLICATIONS_MANUFACTURERS = 'SET_APPLICATIONS_MANUFACTURERS',
  SET_APPLICATIONS_LOADING_FAILED = 'SET_APPLICATIONS_LOADING_FAILED',
}

export type MetadataState = {
  applications: ApplicationsType[];
  applicationRoles: Role[];
  applicationParties: Record<string, ApplicationsType[]>;
  isLoadingDC: boolean;
  isLoadingRole: boolean;
  manufacturers: SearchManufacturerCrossReferenceResponseItem[];
  isLoadingRoleFailed: boolean;
};

export interface SetApplications {
  type: typeof metadataActionNames.SET_APPLICATIONS;
  payload: ApplicationsType[];
}

export interface SetApplicationsParties {
  type: typeof metadataActionNames.SET_APPLICATIONS_PARTIES;
  payload: Record<string, ApplicationsType[]>;
}

export interface SetApplicationRoles {
  type: typeof metadataActionNames.SET_APPLICATION_ROLES;
  payload: Role[];
}

export interface SetApplicationsLoadingDC {
  type: typeof metadataActionNames.SET_APPLICATIONS_LOADING_DC;
  payload: boolean;
}

export interface SetApplicationsLoadingRoles {
  type: typeof metadataActionNames.SET_APPLICATIONS_LOADING_ROLE;
  payload: boolean;
}

export interface SetApplicationsManufacturers {
  type: typeof metadataActionNames.SET_APPLICATIONS_MANUFACTURERS;
  payload: SearchManufacturerCrossReferenceResponseItem[];
}

export interface SetApplicationsLoadingFailed {
  type: typeof metadataActionNames.SET_APPLICATIONS_LOADING_FAILED;
  payload: boolean;
}

export type MetadataActionTypes =
  | SetApplications
  | SetApplicationRoles
  | SetApplicationsLoadingDC
  | SetApplicationsLoadingRoles
  | SetApplicationsManufacturers
  | SetApplicationsLoadingFailed
  | SetApplicationsParties;
