import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../../../globals/color-properties';

export default makeStyles()((theme) => ({
  card: {
    border: `1px solid ${CAT_COLOR.TRANSFER_TABLE_BORDER_GRAY}`,
    boxShadow: 'none',
    [theme.breakpoints.up('lg')]: {
      width: 450,
    },
    [theme.breakpoints.only('md')]: {
      width: 400,
    },
    [theme.breakpoints.down('lg')]: {
      width: 300,
    },
    height: 450,
  },
  cardHeader: {
    padding: theme.spacing(1.9, 2.5),
    borderBottom: `1px solid ${CAT_COLOR.TRANSFER_TABLE_BORDER_GRAY}`,
    textAlign: 'left',
  },
  list: {
    height: 350,
    backgroundColor: theme.palette.background.paper,
    overflow: 'hidden',
    paddingTop: 0,
  },
  tooltip: {
    marginTop: theme.spacing(1),
  },
  text: {
    '& p': {
      fontWeight: 'bold',
      color: 'black',
    },
  },
  noMatchFoundText: {
    fontWeight: 600,
  },
  nonCustomerIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));
