import { FC, ReactNode, lazy } from 'react';
import { iconType } from '../assets/icons/icon-types';
import MyCustomersIcon from '../assets/icons/my-customers';
import ClipboardIcon from '../assets/icons/clipboard-icon';
import AdminIcon from '../assets/icons/admin-icon';
import UsersIcon from '../assets/icons/users';
import { AppPermissionsHashMap, PartiesResponse } from '../entities/entitlements-v1/models';
import { EnvironmentVariables } from '../environments';
import AccessRequest from '../pages/access-requests';
import MachineIcon from '../assets/icons/machine-icon';
import AssetRecommendations from '../pages/asset-recommendations';
import OwnershipRequests from '../pages/ownership-requests';
import { customSettingsType } from '../store/login/types';
import { CustomerData } from '../pages/my-settings/constants';
import TEXT from './translation-map';
import HomeIcon from '../assets/icons/home-icon';
import { Roles } from '../store/invitations/types';
import i18n from 'i18next';
import { pageKeys } from '../utils/nav.util';
import { findRoleByParties } from '../utils/parties.util';
import UserLeads from '../pages/user-leads';

const AssetManagement = lazy(() => import('../pages/asset-management'));
const AssetDCDCN = lazy(() => import('../pages/asset-management-dcdcn'));
const MyWorkQueue = lazy(() => import('../pages/my-work-queue'));
const MyCustomers = lazy(() => import('../pages/my-customers'));
const ManageAccess = lazy(() => import('../pages/manage-access'));
const Invitations = lazy(() => import('../pages/invitations'));
const AccessManagement = lazy(() => import('../pages/access-management'));
const HomePage = lazy(() => import('../pages/home-page'));
const Admins = lazy(() => import('../pages/admins'));
const EprAssetList = lazy(() => import('../pages/epr-asset-list'));

export type NavSection = {
  [key: string]: NavObject;
};

export type ICustomRoles = { [key in Roles]: boolean };

export type SubNavItem = {
  key: string;
  title: string;
  uri: string;
  defaultForParent?: boolean;
  icon?: FC<iconType>;
  component?: React.ComponentClass<any> | React.FunctionComponent<any> | ReactNode | any;
  displayTest?: Function;
};
export type NavObject = {
  key: string;
  title: string;
  uri?: string;
  relatedPages: string[];
  mainNavOnSingleSubNavItem?: boolean; // allows to display only main nav if only single subnav item present
  icon?: FC<iconType>;
  component?: React.ComponentClass<any> | React.FunctionComponent<any> | ReactNode | any;
  subNavItems?: Array<SubNavItem>;
  displayTest?: Function;
};
const createNavList = (
  environmentVariables: EnvironmentVariables,
  partiesResponse: PartiesResponse,
  t: Function,
) => {
  const needsReview = {
    key: pageKeys.workQueue,
    title: t(TEXT.COMMON.TITLE.NEEDS_REVIEW),
    uri: '/my-work-queue',
    icon: ClipboardIcon,
    component: MyWorkQueue,
    mainNavOnSingleSubNavItem: true,
    displayTest: (
      env: EnvironmentVariables,
      customRolesFlag: ICustomRoles,
      customSettings?: customSettingsType,
    ) => {
      return customSettings?.customerData !== CustomerData.PERKINS;
    },
    relatedPages: [
      '/my-work-queue',
      '/items-feedback',
      '/my-work-queue/access-request',
      '/asset-recommendations',
      '/ownership-requests',
      '/ownership-details',
      '/ownership-change',
      '/user-leads',
      '/user-lead-details',
      '/erp-asset-list',
    ],
    subNavItems: [
      {
        key: 'accessRequest',
        title: t(TEXT.COMMON.TITLE.ACCESS_REQUESTS),
        uri: '/my-work-queue/access-request',
        defaultForParent: false,
        component: AccessRequest,
      },
      {
        key: 'ownershipRequest',
        title: t(TEXT.COMMON.TITLE.OWNERSHIP_REQUESTS),
        uri: '/ownership-requests',
        defaultForParent: true,
        component: OwnershipRequests,
        displayTest: (
          env: EnvironmentVariables,
          customRolesFlag: ICustomRoles,
          customSettings?: customSettingsType,
        ) => {
          return (
            env?.ownershipRequestsFlag ||
            (customRolesFlag && customRolesFlag[Roles.STANDARDTEST] && env?.standardTest)
          );
        },
      },
      {
        key: 'userLeads',
        title: t(TEXT.COMMON.TITLE.USER_LEADS),
        uri: '/user-leads',
        defaultForParent: false,
        component: UserLeads,
        displayTest: (env: EnvironmentVariables) => {
          return env?.cvrUserLeads;
        },
      },
      {
        key: 'erpAssetList',
        title: t(TEXT.COMMON.TITLE.ERP_ASSET_LIST),
        uri: '/erp-asset-list',
        defaultForParent: false,
        component: EprAssetList,
        displayTest: (env: EnvironmentVariables) => {
          return env?.erpAssetListFlag;
        },
      },
      {
        key: 'recommendation',
        title: t(TEXT.COMMON.TITLE.CUSTOMER_RECOMMENDATIONS),
        uri: '/my-work-queue',
        defaultForParent: true,
        component: MyWorkQueue,
      },
      {
        key: 'assetRecommendation',
        title: t(TEXT.COMMON.TITLE.ASSET_RECOMMENDATIONS),
        uri: '/asset-recommendations',
        relatedPages: ['/ownership-change'],
        defaultForParent: true,
        component: AssetRecommendations,
        displayTest: (
          env: EnvironmentVariables,
          customRolesFlag: ICustomRoles,
          customSettings?: customSettingsType,
        ) => {
          return (
            env?.assetRecommendationsFlag ||
            (customRolesFlag && customRolesFlag[Roles.STANDARDTEST] && env?.standardTest)
          );
        },
      },
    ],
  };
  return [
    needsReview,
    {
      key: pageKeys.home,
      title: t(TEXT.COMMON.TITLE.HOME),
      uri: '/home-page',
      icon: HomeIcon,
      relatedPages: ['/home-page'],
      component: HomePage,
      displayTest: (
        env: EnvironmentVariables,
        customRolesFlag: ICustomRoles,
        customSettings?: customSettingsType,
      ) => {
        return customSettings?.customerData !== CustomerData.PERKINS;
      },
    },
    {
      key: pageKeys.customers,
      title: t(TEXT.COMMON.TITLE.CUSTOMERS),
      uri: '/my-customers',
      icon: MyCustomersIcon,
      relatedPages: [
        '/add-customer',
        '/associate-user',
        '/my-customers',
        '/edit-customer',
        '/associate-dealer-customer',
        '/customers',
      ],
      component: MyCustomers,
    },
    {
      key: pageKeys.admin,
      title: t(TEXT.COMMON.TITLE.IMPERSONATION),
      uri: '/admin',
      icon: AdminIcon,
      component: ManageAccess,
      relatedPages: ['/admin', '/manage-access'],
      subNavItems: [
        {
          key: 'manageAccess',
          title: i18n.t(TEXT.ACCESS_MANAGEMENT.TITLE.MANAGE_ACCESS),
          uri: '/admin/manage-access',
          defaultForParent: false,
          component: ManageAccess,
        },
      ],
    },
    {
      key: pageKeys.users,
      title: t(TEXT.COMMON.TITLE.USERS),
      uri: '/invitations',
      icon: UsersIcon,
      component: Invitations,
      relatedPages: ['/invitations', '/invitations/select-company', '/access-management'],
      displayTest: (
        env: EnvironmentVariables,
        customRolesFlag: ICustomRoles,
        customSettings?: customSettingsType,
      ) => {
        return customSettings?.customerData !== CustomerData.PERKINS;
      },
      subNavItems: [
        {
          key: 'invitations',
          title: t(TEXT.COMMON.TITLE.INVITATIONS),
          uri: '/invitations',
          defaultForParent: true,
          component: Invitations,
        },
        {
          key: 'accessManagement',
          title: t(TEXT.COMMON.TITLE.ACCESS_MANAGEMENT),
          uri: '/access-management',
          defaultForParent: false,
          component: AccessManagement,
        },
      ],
    },
    {
      key: pageKeys.assets,
      title: t(TEXT.COMMON.TITLE.ASSETS),
      uri: '/assets',
      icon: MachineIcon,
      component:
        findRoleByParties(partiesResponse?.parties, [Roles.STANDARDTEST]) ||
        environmentVariables?.assetsDCDCNFlag
          ? AssetDCDCN
          : AssetManagement,
      relatedPages: ['/assets', '/add-asset', '/update-ownership', '/manage-assets'],
      displayTest: (
        env: EnvironmentVariables,
        customRolesFlag?: ICustomRoles,
        customSettings?: customSettingsType,
      ) => {
        return (
          (env.assetManagementFlag ||
            (customRolesFlag && customRolesFlag[Roles.STANDARDTEST] && env?.standardTest)) &&
          customSettings?.customerData !== CustomerData.PERKINS
        );
      },
    },
    {
      key: pageKeys.admins,
      title: t(TEXT.COMMON.TITLE.ADMINS),
      uri: '/admins',
      icon: AdminIcon,
      relatedPages: ['/admins'],
      component: Admins,
      displayTest: (env: EnvironmentVariables) => {
        return env?.adminsFlag;
      },
    },
  ];
};

export const navListBasedOnEntitlements = (
  applicationPermissions: AppPermissionsHashMap,
  t: Function,
  environmentVariables?: EnvironmentVariables,
  partiesResponse?: PartiesResponse,
  customRolesFlag?: ICustomRoles,
  customSettings?: customSettingsType,
): NavSection => {
  const navList: NavObject[] = createNavList(
    environmentVariables as EnvironmentVariables,
    partiesResponse as PartiesResponse,
    t,
  );
  const authorizedNavList: NavSection = {};
  let defaultUrlSetFlag = false;
  for (const key of Object.keys(applicationPermissions)) {
    const navItemIndex = navList.findIndex((navItem) => navItem.key === key);
    if (navItemIndex !== -1) {
      const subNavItems = navList[navItemIndex].subNavItems ?? [];
      if (subNavItems.length > 0) {
        // eslint-disable-next-line unicorn/no-useless-spread
        authorizedNavList[key] = { ...navList[navItemIndex], ...{ subNavItems: [] } };
        for (const subKey of Object.keys(applicationPermissions[key])) {
          const subNavItemIndex = subNavItems.findIndex((subNavItem) => subNavItem.key === subKey);
          if (subNavItemIndex !== -1) {
            if (
              (subNavItems[subNavItemIndex].defaultForParent &&
                !subNavItems[subNavItemIndex]?.displayTest) ||
              (subNavItems[subNavItemIndex].defaultForParent &&
                typeof subNavItems[subNavItemIndex]?.displayTest === 'function' &&
                (subNavItems[subNavItemIndex]?.displayTest as Function)(
                  environmentVariables,
                  customRolesFlag,
                  customSettings,
                ))
            ) {
              defaultUrlSetFlag = true;
              authorizedNavList[key].uri = subNavItems[subNavItemIndex].uri;
            }
            if (
              !subNavItems[subNavItemIndex]?.displayTest ||
              (typeof subNavItems[subNavItemIndex]?.displayTest === 'function' &&
                (subNavItems[subNavItemIndex]?.displayTest as Function)(
                  environmentVariables,
                  customRolesFlag,
                  customSettings,
                ))
            ) {
              authorizedNavList[key].subNavItems?.push(subNavItems[subNavItemIndex]);
            }
          }
        }

        authorizedNavList[key].subNavItems = authorizedNavList[key].subNavItems?.sort(
          (a: SubNavItem, b: SubNavItem) =>
            subNavItems.findIndex((item) => item.key === a.key) -
            subNavItems.findIndex((item) => item.key === b.key),
        );

        if (
          authorizedNavList[key].mainNavOnSingleSubNavItem &&
          authorizedNavList[key]?.subNavItems?.length === 1 &&
          (authorizedNavList[key]?.subNavItems as Array<SubNavItem>)[0].defaultForParent === true
        ) {
          delete authorizedNavList[key].subNavItems;
        }
      } else {
        authorizedNavList[key] = navList[navItemIndex];
      }
      if (
        !defaultUrlSetFlag &&
        (authorizedNavList[key]?.subNavItems as Array<SubNavItem>)?.length >= 1
      ) {
        const findKeyinSubNavItem = authorizedNavList[key]?.subNavItems?.find(
          (item) => item.uri === authorizedNavList[key].uri,
        );
        // if main nav url is not in subnavlist use first subnav details
        if (!findKeyinSubNavItem) {
          const subNavItemsForAttributes = (
            authorizedNavList[key]?.subNavItems as Array<SubNavItem>
          ).sort((a, b) => (a.title > b.title ? 1 : -1))[0];

          authorizedNavList[key].uri = subNavItemsForAttributes.uri;
          authorizedNavList[key].component = subNavItemsForAttributes.component;
        }
      }

      if (
        navList[navItemIndex]?.displayTest &&
        typeof navList[navItemIndex]?.displayTest === 'function' &&
        !(navList[navItemIndex]?.displayTest as Function)(
          environmentVariables,
          customRolesFlag,
          customSettings,
        )
      ) {
        delete authorizedNavList[key];
      }
    }
  }

  return authorizedNavList;
};
