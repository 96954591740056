import { Box, Button, Badge, Link, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState, useDispatchTs } from '../../../../store';
import {
  DealerCodeSearchItem,
  SearchRecommendationResponse,
} from '../../../../entities/customer-master-v1/models';
import FilterDialog from '../filter-dialog';
import { assetRecommendationAdvanceFilterValidationSchema } from '../../../../utils/validations';
import FilterIcon from '../../../../assets/icons/filter-icon';
import useStyles from './styles';
import DealerCode, { DealerCodeSearchResponse } from '../../../../services/dealer-code';
import { showNotification } from '../../../../utils/util';
import { PartyType, UserBasedPermission } from '../../../../entities/entitlements-v1/models';
import i18n from 'i18next';
import TEXT from '../../../../globals/translation-map';
import { useTranslation } from 'react-i18next';

type RecommendationFilters = {
  dealerCode?: string[];
};
export type FiltersProperties = {
  onClickOfApply: Function;
  disabled?: boolean;
  selectedFilters: boolean;
  clearFilters: Function;
  userSelectedFilters?: RecommendationFilters;
  recommendations?: Array<SearchRecommendationResponse>;
};

const Filters: FC<FiltersProperties> = (props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatchTs();
  const { onClickOfApply, disabled, selectedFilters, clearFilters, userSelectedFilters } = props;
  const [open, setOpen] = useState<boolean>(false);

  const { authInfo, environmentVariables } = useSelector((state: AppState) => state.login);
  const { permissionsResponse } = useSelector((state: AppState) => state.login);
  const [catDealerCodes, setCatSearchDealerCodes] = useState<Array<string>>([]);

  const formik = useFormik({
    initialValues: {
      dealerCode: userSelectedFilters?.dealerCode ?? [],
    },
    validationSchema: assetRecommendationAdvanceFilterValidationSchema,
    onSubmit: () => {},
    enableReinitialize: true,
  });
  const handleFiltersDialogClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      fetchDealerCodes();
    }
  }, [open]);

  const fetchDealerCodes = async () => {
    const dealerCodesForDealerPartyType =
      permissionsResponse?.dataPermissions?.customerMasterRecomendations?.view
        ?.filterConditions?.[0]?.partyNumbers;
    const isDealerAdmin =
      (permissionsResponse as UserBasedPermission) &&
      (permissionsResponse as UserBasedPermission).primaryPartyType &&
      (permissionsResponse as UserBasedPermission).primaryPartyType === PartyType.DLR;
    if (isDealerAdmin && dealerCodesForDealerPartyType !== undefined) {
      setCatSearchDealerCodes(dealerCodesForDealerPartyType);
    } else {
      try {
        const dealerCodeService = new DealerCode(authInfo, environmentVariables.customerMasterURI);
        const fetchDealerCodesResponse = await dealerCodeService.searchDealerCodes({});
        if (fetchDealerCodesResponse.status === 200) {
          const catDealerCodesItems = (
            fetchDealerCodesResponse.data as DealerCodeSearchResponse
          ).dealerCodes.map((dealerCodeItem: DealerCodeSearchItem) => {
            return dealerCodeItem.dealerCode || '';
          });
          setCatSearchDealerCodes(catDealerCodesItems);
        } else {
          showNotification(
            dispatch,
            'error',
            i18n.t(TEXT.GLOBALS.ERRORS.FETCHING_DEALER_CODES_FAILED),
          );
        }
      } catch {
        showNotification(
          dispatch,
          'error',
          i18n.t(TEXT.GLOBALS.ERRORS.FETCHING_DEALER_CODES_FAILED),
        );
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box data-testid="asset-recommendation-filter-container">
        <Box width="100%" display="flex" justifyContent="flex-start" alignItems="center">
          <Box display="flex" alignItems="center">
            <Button
              disabled={disabled}
              data-testid="filter-button"
              className={classes.filterButton}
              variant="contained"
              color="secondary"
              startIcon={
                <Badge
                  color="secondary"
                  variant="dot"
                  data-testid="selected-filter-badge"
                  invisible={!selectedFilters}
                >
                  <FilterIcon width="16px" height="16px" stroke="#43485C" />
                </Badge>
              }
              onClick={() => setOpen(true)}
            >
              {t(TEXT.COMMON.FILTERS_BUTTON)}
            </Button>
            <Typography className={classes.dividerText} alignSelf="baseline">
              |
            </Typography>
            <Link onClick={() => clearFilters()} data-testid="clear-all-button" underline="hover">
              {t(TEXT.COMMON.CLEAR_ALL)}
            </Link>
          </Box>
        </Box>
        {open && (
          <FilterDialog
            authInfo={authInfo}
            environmentVariables={environmentVariables}
            onClickOfApply={onClickOfApply}
            clearFilters={clearFilters}
            userSelectedFilters={userSelectedFilters}
            handleFiltersDialogClose={handleFiltersDialogClose}
            dealerCodes={catDealerCodes}
            formik={formik}
          />
        )}
      </Box>
    </form>
  );
};

export default Filters;
