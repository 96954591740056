import { Count } from '../../entities/customer-master-v1/count';
import { SearchOrganizationResponse } from '../../entities/customer-master-v1/searchOrganizationResponse';
import { UserAccessRequestResponse } from '../../entities/user-onboarding-v1/userAccessRequestResponse';
import { SetIsLoading } from '../api/loadable';

export enum accessRequestActionNames {
  CLEAR_ACCESS_REQUEST_FILTERS = 'CLEAR_ACCESS_REQUEST_FILTERS',
  RESET_CURSOR_FOR_ACCESS_REQUEST = 'RESET_CURSOR_FOR_ACCESS_REQUEST',
  CLEAR_SPECIFIC_ACCESS_REQUEST_FILTER = 'CLEAR_SPECIFIC_ACCESS_REQUEST_FILTER',
  SET_ACCESS_REQUEST = 'SET_ACCESS_REQUEST',
  SET_ACCESS_REQUEST_FILTERS = 'SET_ACCESS_REQUEST_FILTERS',
  SET_ACCESS_REQUEST_ORDER_BY_VALUE = 'SET_ACCESS_REQUEST_ORDER_BY_VALUE',
  SET_ACCESS_REQUEST_SEARCH_VALUE = 'SET_ACCESS_REQUEST_SEARCH_VALUE',
  SET_ACCESS_REQUEST_SORT_BY_VALUE = 'SET_ACCESS_REQUEST_SORT_BY_VALUE',
  SET_CURSOR_FOR_ACCESS_REQUEST = 'SET_CURSOR_FOR_ACCESS_REQUEST',
  SET_INITIAL_STATE = 'SET_INITIAL_STATE',
  SET_IS_LOADING_ACCESS_REQUEST_COUNT = 'SET_IS_LOADING_ACCESS_REQUEST_COUNT',
  SET_ACCESS_REQUEST_TOTAL_COUNT = 'SET_ACCESS_REQUEST_TOTAL_COUNT',
  SET_IS_LOADING_ACCESS_REQUEST = 'SET_IS_LOADING_ACCESS_REQUEST',
  SET_STALE_ACCESS_REQUEST = 'SET_STALE_ACCESS_REQUEST',
}

export enum AdditionalInfoType {
  DEALER_CUSTOMER_NUMBERS = 'dealerCustomerNumbers',
  SERIAL_NUMBERS = 'serialNumbers',
  NOTES = 'notes',
}

export enum RolesType {
  USER_MANAGEMENT_ADMINISTRATOR = 'User Management Administrator',
}

type CursorType = {
  nextCursors: Array<string> | [];
  currentPage?: number;
};

export type AccessRequestFilters = {
  orderByRules: Array<string>;
  sortByRules: Array<string>;
  presetValue: string;
  selectedAccount: string;
  manualSelectedAccountEntry: boolean;
  selectedCustomers: Array<SearchOrganizationResponse>;
  application: string[];
  applicationRequestStatus: string[];
  startDate?: Date;
  endDate?: Date;
  selectedTimeRangeButton?: number;
};

export const accessRequestFields = {
  presetValue: 'presetValue',
  selectedAccount: 'selectedAccount',
  selectedCustomers: 'selectedCustomers',
  application: 'application',
  applicationRequestStatus: 'applicationRequestStatus',
  startDate: 'startDate',
  endDate: 'endDate',
  selectedTimeRangeButton: 'selectedTimeRangeButton',
};
export const accessRequestPermissionName = 'userRequests';
export const accessRequestPermissionMethods = {
  update: 'update',
  view: 'view',
};

export const presetFilterValue = {
  APPLICATION_ACCESS: 'APPLICATION_ACCESS',
  ASSOCIATION_REQUEST: 'ASSOCIATION_REQUEST',
};

export interface AccessRequestState {
  staleAccessRequest: boolean;
  filters: AccessRequestFilters;
  cursorsForAccessRequest: CursorType;
  accessRequests?: Array<UserAccessRequestResponse>;
  isLoadingAccessRequests: boolean;
  accessRequestSearchValue: string;
  totalAccessRequestCount: Count;
  isLoadingAccessRequestCount: boolean;
  startDate?: Date;
  endDate?: Date;
  selectedTimeRangeButton?: number;
}

export interface SetIsAccessRequestsLoading {
  type: typeof accessRequestActionNames.SET_IS_LOADING_ACCESS_REQUEST;
  payload: boolean;
}
export interface SetAccessRequests {
  type: typeof accessRequestActionNames.SET_ACCESS_REQUEST;
  payload: Array<UserAccessRequestResponse>;
}
export interface SetAccessRequestSearchValue {
  type: typeof accessRequestActionNames.SET_ACCESS_REQUEST_SEARCH_VALUE;
  payload: string;
}
export interface SetAccessRequestTotalCount {
  type: typeof accessRequestActionNames.SET_ACCESS_REQUEST_TOTAL_COUNT;
  payload: Count;
}

export interface SetAccessRequestFilters {
  type: typeof accessRequestActionNames.SET_ACCESS_REQUEST_FILTERS;
  payload: AccessRequestFilters;
}

export interface ClearAccessRequestFilters {
  type: typeof accessRequestActionNames.CLEAR_ACCESS_REQUEST_FILTERS;
}
export interface ClearSpecificAccessRequestFilter {
  type: typeof accessRequestActionNames.CLEAR_SPECIFIC_ACCESS_REQUEST_FILTER;
  payload: string;
}
export interface SetInitialState {
  type: typeof accessRequestActionNames.SET_INITIAL_STATE;
}

interface SetCursorsForAccessRequests {
  type: typeof accessRequestActionNames.SET_CURSOR_FOR_ACCESS_REQUEST;
  payload: { cursor: string; currentPage: number };
}
interface ResetCursorsForInvitations {
  type: typeof accessRequestActionNames.RESET_CURSOR_FOR_ACCESS_REQUEST;
}
export interface SetSortByRules {
  type: typeof accessRequestActionNames.SET_ACCESS_REQUEST_SORT_BY_VALUE;
  payload: Array<string>;
}
export interface SetOrderByRules {
  type: typeof accessRequestActionNames.SET_ACCESS_REQUEST_ORDER_BY_VALUE;
  payload: Array<string>;
}
export interface SetStaleAccessRequest {
  type: typeof accessRequestActionNames.SET_STALE_ACCESS_REQUEST;
  payload: boolean;
}

export type AccessRequestActionTypes =
  | SetIsAccessRequestsLoading
  | SetAccessRequestSearchValue
  | SetAccessRequestFilters
  | SetAccessRequests
  | SetCursorsForAccessRequests
  | ResetCursorsForInvitations
  | ClearAccessRequestFilters
  | ClearSpecificAccessRequestFilter
  | SetIsLoading<accessRequestActionNames.SET_IS_LOADING_ACCESS_REQUEST_COUNT>
  | SetAccessRequestTotalCount
  | SetSortByRules
  | SetOrderByRules
  | SetInitialState
  | SetStaleAccessRequest;
