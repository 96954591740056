import React, { ComponentType } from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';

type InfiniteLoaderWrapperProperties<DataType> = {
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  items: Array<DataType>;
  loadNextPage: (startIndex: number, stopIndex: number) => Promise<void> | void;
  ItemComponent: ComponentType<ListChildComponentProps<DataType>>;
  height: string | number;
  itemSize: number;
  width: string | number;
};

// const InfiniteLoaderWrapper: FC<InfiniteLoaderWrapperProperties> = <DataType,>(
const InfiniteLoaderWrapper = <DataType,>(props: InfiniteLoaderWrapperProperties<DataType>) => {
  const {
    hasNextPage,
    isNextPageLoading,
    items,
    loadNextPage,
    ItemComponent,
    height,
    itemSize,
    width,
  } = props;
  const itemCount = hasNextPage ? items.length + 1 : items.length;
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  return (
    <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
      {({ onItemsRendered, ref }) => (
        <List
          height={height}
          itemCount={itemCount}
          itemSize={itemSize}
          onItemsRendered={onItemsRendered}
          ref={ref}
          width={width}
          overscanCount={1}
          style={{ overflowY: 'auto' }}
        >
          {ItemComponent}
        </List>
      )}
    </InfiniteLoader>
  );
};

export default InfiniteLoaderWrapper;
