import { UserManagementState, userManagementActionNames, UserManagementActionTypes } from './types';

const initialState: UserManagementState = {
  noUsersFound: false,
};

const userManagementReducer = (
  state: UserManagementState = initialState,
  action: UserManagementActionTypes,
): UserManagementState => {
  switch (action.type) {
    case userManagementActionNames.SET_USERS: {
      return {
        ...state,
        users: action.payload,
      };
    }
    case userManagementActionNames.SET_NO_USER_FOUND_FLG: {
      return {
        ...state,
        noUsersFound: action.payload,
      };
    }
    case userManagementActionNames.SET_UPDATE_USER_ERROR: {
      return {
        ...state,
        updateUserError: action.payload,
      };
    }
    case userManagementActionNames.CLEAR_USERS: {
      return {
        ...state,
        users: initialState.users,
      };
    }

    case userManagementActionNames.SET_USER_SEARCH_ERROR: {
      return {
        ...state,
        searchUserError: action.payload,
      };
    }
    case userManagementActionNames.SET_LOADING_FOR_USER_MANAGEMENT: {
      return {
        ...state,
        isLoadingUserManagement: action.payload,
      };
    }
    case userManagementActionNames.SET_ADMIN_USER: {
      return {
        ...state,
        selectedAdmin: action.payload,
      };
    }
    case userManagementActionNames.SET_UPDATED_USER: {
      return {
        ...state,
        updatedAdmin: action.payload,
      };
    }
    default:
      return state;
  }
};
export default userManagementReducer;
