import dayjs from 'dayjs';
import { Box, Button, Typography } from '@mui/material';
import TEXT from '../../globals/translation-map';
import DateRangePicker from '../date-range-picker';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

export interface DateRangePickerValues {
  startDate: Date | undefined;
  endDate: Date | undefined;
  selectedButton?: number | undefined;
}
interface FilterByDateProps {
  startDateValue: Date | undefined;
  endDateValue: Date | undefined;
  selectedTimeRangeButtonValue: number | undefined;
  onDateChange: ({ startDate, endDate, selectedButton }: DateRangePickerValues) => void;
}

const FilterByDate = ({
  startDateValue,
  endDateValue,
  selectedTimeRangeButtonValue,
  onDateChange,
}: FilterByDateProps) => {
  const { t } = useTranslation();

  const { classes } = useStyles();
  const handleDateSelect = (
    timeValue: number,
    unit: dayjs.ManipulateType,
    selectedButton: number,
  ) => {
    const newStartDate = new Date(
      dayjs().subtract(timeValue, unit).toDate().setUTCHours(0, 0, 0, 0),
    );
    const newEndDate = new Date(dayjs().toDate().setUTCHours(23, 59, 59, 999));
    onDateChange({ startDate: newStartDate, endDate: newEndDate, selectedButton });
  };

  const onCustomRangeChange = ({ startDate, endDate }: DateRangePickerValues) => {
    onDateChange({ startDate, endDate, selectedButton: undefined });
  };

  const handleCloseCalendar = () => {
    onDateChange({ startDate: undefined, endDate: undefined, selectedButton: undefined });
  };

  return (
    <>
      <Typography variant="h4">{t(TEXT.COMMON.FILTERS.SECTIONS.DATE)}</Typography>
      <Box mt={2} fontStyle="italic">
        <Typography variant="body2">
          {t(TEXT.COMMON.FILTERS.ITALIC_LABELS.PRE_SELECTED_RANGE)}
        </Typography>
      </Box>
      <Box alignItems="center" data-testid="pre-selected-date-range" mt={1}>
        <Button
          className={
            selectedTimeRangeButtonValue === 1 ? classes.dateRangeActiveBtn : classes.dateRangeBtn
          }
          variant="contained"
          color="secondary"
          size="small"
          data-testid="last-month-id"
          onClick={() => handleDateSelect(1, 'month', 1)}
        >
          {t(TEXT.COMMON.FILTERS.BUTTON_OPTIONS.LAST_MONTH)}
        </Button>
        <Button
          className={
            selectedTimeRangeButtonValue === 2 ? classes.dateRangeActiveBtn : classes.dateRangeBtn
          }
          variant="contained"
          color="secondary"
          size="small"
          data-testid="last-three-month-id"
          onClick={() => handleDateSelect(3, 'month', 2)}
        >
          {t(TEXT.COMMON.FILTERS.BUTTON_OPTIONS.LAST_THREE_MONTHS)}
        </Button>
        <Button
          className={
            selectedTimeRangeButtonValue === 3 ? classes.dateRangeActiveBtn : classes.dateRangeBtn
          }
          variant="contained"
          color="secondary"
          size="small"
          data-testid="last-six-month-id"
          onClick={() => handleDateSelect(6, 'month', 3)}
        >
          {t(TEXT.COMMON.FILTERS.BUTTON_OPTIONS.LAST_SIX_MONTHS)}
        </Button>
        <Button
          className={
            selectedTimeRangeButtonValue === 4 ? classes.dateRangeActiveBtn : classes.dateRangeBtn
          }
          onClick={() => handleDateSelect(1, 'year', 4)}
          variant="contained"
          color="secondary"
          size="small"
          data-testid="last-year-id"
        >
          {t(TEXT.COMMON.FILTERS.BUTTON_OPTIONS.LAST_TWELVE_MONTHS)}
        </Button>
      </Box>
      <Box mt={4} fontStyle="italic">
        <Typography variant="body2">{t(TEXT.COMMON.FILTERS.ITALIC_LABELS.CUSTOM_RANGE)}</Typography>
        <DateRangePicker
          selectedDate={{
            startDate: startDateValue,
            endDate: endDateValue,
          }}
          handleApply={onCustomRangeChange}
          handleClose={handleCloseCalendar}
          focused
          minDateSelection={dayjs().subtract(1, 'year').toDate()}
          maxDate={new Date()}
        />
      </Box>
    </>
  );
};

export default FilterByDate;
