import {
  MergeOrganizationState,
  MergeOrganizationActionTypes,
  mergeOrganizationActionNames,
} from './types';

const initialState: MergeOrganizationState = {};

const mergeOrganizationReducer = (
  state: MergeOrganizationState = initialState,
  action: MergeOrganizationActionTypes,
): MergeOrganizationState => {
  switch (action.type) {
    case mergeOrganizationActionNames.SET_FROM_ORGANIZATION: {
      return {
        ...state,
        fromOrganization: action.payload,
      };
    }
    case mergeOrganizationActionNames.SET_TO_ORGANIZATION: {
      return {
        ...state,
        toOrganization: action.payload,
      };
    }
    case mergeOrganizationActionNames.CLEAR_ORGANIZATIONS: {
      return {
        ...state,
        fromOrganization: initialState.fromOrganization,
        toOrganization: initialState.toOrganization,
      };
    }
    default:
      return state;
  }
};

export default mergeOrganizationReducer;
