import { Box, IconButton, useTheme } from '@mui/material';
import React, { useRef } from 'react';
import CloseFilterTagChipIcon from '../../assets/icons/close-filter-tag-chip-icon';
import TextWithToolTip from '../text-with-tooltip';
import useStyles from './styles';

type FilterTagChipProperties = {
  text: string;
  removeFilter?: Function;
  fontSize?: string;
  height?: string;
  maxWidth?: string;
  tagPrefix?: string;
  tagLength?: number;
  parentOffset?: number;
  parentHeight?: number;
};

const FilterTagChip = (props: FilterTagChipProperties) => {
  const {
    text,
    removeFilter,
    fontSize,
    height,
    maxWidth,
    tagLength,
    tagPrefix = 'default',
    parentOffset,
    parentHeight,
  } = props;
  const { classes } = useStyles();
  const filterTagChipRef = useRef<HTMLDivElement>(null);
  const offsetTop = filterTagChipRef.current?.offsetTop ?? 0;
  const theme = useTheme();
  return (
    <Box
      className={`${classes.chipBox} ${
        !removeFilter ? classes.chipBoxPaddingWithoutRemove : classes.chipBoxPadding
      }`}
      data-testid={`${tagPrefix}-filter-tag-chip`}
      ref={filterTagChipRef}
    >
      <Box className={classes.truncate}>
        <TextWithToolTip
          width="100%"
          fontSize={fontSize ?? '14px'}
          longText={text}
          maxWidth={maxWidth ?? theme.spacing(21.25)}
          customDataTestId="filter-tag-chip-text"
          numberOfCharsToDisplay={tagLength ?? 20}
          height={height ?? theme.spacing(2.125)}
        />
      </Box>
      {removeFilter && (
        <Box
          display="flex"
          ml={0.5}
          onClick={() => removeFilter()}
          className={
            offsetTop && parentOffset && parentHeight && parentOffset + parentHeight < offsetTop
              ? `offScreenTag ${tagPrefix}-offScreenTag`
              : `onScreenTag ${tagPrefix}-onScreenTag`
          }
        >
          <IconButton size="small" data-testid="close-filter-tag-chip">
            <CloseFilterTagChipIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default FilterTagChip;
