import notosansbolditalicwoff from '../../assets/fonts/noto-sans/noto-sans-bold-italic.woff2';
import notosansboldwoff from '../../assets/fonts/noto-sans/noto-sans-bold.woff2';
import notosansitalicwoff from '../../assets/fonts/noto-sans/noto-sans-italic.woff2';
import notosansregularwoff from '../../assets/fonts/noto-sans/noto-sans.woff2';

const notosansbold = {
  fontFamily: 'NotoSans',
  fontStyle: 'bold',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `
      local('noto-sans'),
      local('noto-sans-bold'),
      url(${notosansboldwoff}) format('woff2')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const notosansbolditalic = {
  fontFamily: 'NotoSans',
  fontStyle: 'bold-italic',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `
      local('noto-sans'),
      local('noto-sans-bold-italic'),
      url(${notosansbolditalicwoff}) format('woff2')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const notosansitalic = {
  fontFamily: 'NotoSans',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `
      local('noto-sans'),
      local('noto-sans-italic'),
      url(${notosansitalicwoff}) format('woff2')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const notosansregular = {
  fontFamily: 'NotoSans',
  fontStyle: 'regular',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `
      local('noto-sans'),
      local('noto-sans-regular'),
      url(${notosansregularwoff}) format('woff2')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export { notosansbold, notosansbolditalic, notosansitalic, notosansregular };
