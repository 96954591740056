import React from 'react';
import { iconType } from './icon-types';

const LodingIcon = (props: iconType) => {
  const { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 126 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect opacity="0.2" x="0" y="26" width="20" height="39" rx="5" fill="#FFCC11" />
        <rect opacity="0.2" x="18" y="26" width="20" height="39" rx="5" fill="#FFCC11" />
        <rect opacity="0.2" x="36" y="26" width="20" height="39" rx="5" fill="#FFCC11" />
        <rect opacity="0.2" x="54" y="26" width="20" height="39" rx="5" fill="#FFCC11" />
        <rect opacity="0.2" x="72" y="26" width="20" height="39" rx="5" fill="#FFCC11" />
        <rect opacity="0.2" x="90" y="26" width="20" height="39" rx="5" fill="#FFCC11" />
        <rect opacity="0.2" x="108" y="26" width="20" height="39" rx="5" fill="#FFCC11" />
      </g>
    </svg>
  );
};

export default LodingIcon;
