import { AssetRecommendationSortingProperties } from '../../entities/customerAssetWorkflow-v1/assetRecommendationSortingProperties';
import {
  AssetRecommendationsState,
  assetRecommendationsActionNames,
  assetRecommendationsActionTypes,
  AssetRecommendationFilterType,
} from './types';

const initialState: AssetRecommendationsState = {
  assetRecommendationsData: [],
  isLoadingAssetRecommendations: false,
  filters: {
    sortByRules: [AssetRecommendationSortingProperties.RecommendationTypeLabel],
    orderByRules: ['DESC'],
  },
  cursors: [],
};

const assetRecommendationsReducer = (
  state: AssetRecommendationsState = initialState,
  action: assetRecommendationsActionTypes,
): AssetRecommendationsState => {
  switch (action.type) {
    case assetRecommendationsActionNames.SET_ASSET_RECOMMENDATIONS_DATA: {
      return {
        ...state,
        assetRecommendationsData: action.payload,
      };
    }

    case assetRecommendationsActionNames.SET_ASSET_RECOMMENDATIONS_SORT_BY_VALUE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByRules: action.payload,
        },
      };
    }
    case assetRecommendationsActionNames.SET_ASSET_RECOMMENDATIONS_ORDER_BY_VALUE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          orderByRules: action.payload,
        },
      };
    }
    case assetRecommendationsActionNames.SET_ASSET_RECOMMENDATIONS_COUNT: {
      return {
        ...state,
        totalCount: action.payload,
      };
    }
    case assetRecommendationsActionNames.SET_ASSET_RECOMMENDATIONS_CURSOR: {
      return {
        ...state,
        cursors: action.payload,
      };
    }
    case assetRecommendationsActionNames.SET_IS_LOADING_ASSET_RECOMMENDATIONS_DATA: {
      return {
        ...state,
        isLoadingAssetRecommendations: action.payload,
      };
    }
    case assetRecommendationsActionNames.SET_ASSET_RECOMMENDATION_FILTERS: {
      return {
        ...state,
        assetRecommendationFilters: action.payload,
      };
    }
    case assetRecommendationsActionNames.DELETE_ASSET_RECOMMENDATION_FILTER_VALUE_BY_TYPE: {
      let assetRecommendationFilters = { ...state.assetRecommendationFilters };
      if (state?.assetRecommendationFilters?.[action.payload.propertyName]) {
        switch (action.payload.propertyName) {
          case AssetRecommendationFilterType.dealerCode:
            assetRecommendationFilters.dealerCode =
              state.assetRecommendationFilters?.dealerCode?.filter(
                (value: string) => value !== action.payload.propertyValue,
              );

            if (assetRecommendationFilters.dealerCode?.length === 0) {
              delete assetRecommendationFilters[action.payload.propertyName];
            }

            break;
          default:
            delete assetRecommendationFilters[action.payload.propertyName];
        }
      }

      return {
        ...state,
        assetRecommendationFilters,
      };
    }
    default:
      return state;
  }
};

export default assetRecommendationsReducer;
