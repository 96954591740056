/**
 * User Onboarding
 * User Onboarding API provides functionality to manage users access, requests and invites. 
 *
 * OpenAPI spec version: 1.3.0
 * Contact: Platform_Core_Services_-_CAT@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TimeRange } from './timeRange';

/**
 * Allows to filter date values within a certain range. See range models for more info about boundary inclusion rules. 
 */
export interface TimeRangeFilter { 
    /**
     * Enum that defines all possible time range filter types.
     */
    type: TimeRangeFilter.TypeEnum;
    /**
     * Invite properties that can be used for time range filtering. 
     */
    propertyName: TimeRangeFilter.PropertyNameEnum;
    range: TimeRange;
}
export namespace TimeRangeFilter {
    export type TypeEnum = 'timeRange';
    export const TypeEnum = {
        TimeRange: 'timeRange' as TypeEnum
    };
    export type PropertyNameEnum = 'acceptedInvites' | 'revokedInvites' | 'pendingInvites';
    export const PropertyNameEnum = {
        AcceptedInvites: 'acceptedInvites' as PropertyNameEnum,
        RevokedInvites: 'revokedInvites' as PropertyNameEnum,
        PendingInvites: 'pendingInvites' as PropertyNameEnum
    };
}