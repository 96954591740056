import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../../../globals/color-properties';

export default makeStyles()((theme) => ({
  iconButton: {
    padding: 0,
    width: '1%',
  },
  bold: {
    fontWeight: 600,
  },
  popoverBorder: {
    borderRadius: 0,
  },
  textRed: {
    color: CAT_COLOR.LAVA,
  },
}));
