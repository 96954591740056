import { default as Axios, AxiosResponse, CancelToken } from 'axios';
import { ErrorResponse, Count } from '../entities/customer-master-v1/models';
import { AuthInfo } from '../model/types';
import {
  AccessRequestFilters,
  FiltersAndSearchAccessRequest,
  UpdateUserAccessRequestApproveAssociationRequest,
  UpdateUserAccessRequestDenyAccess,
  UserAccessRequestsGetResponseObj,
} from '../entities/user-onboarding-v1/models';

export type AccessRequestSearchCountRequest = {
  body: AccessRequestFilters;
  partyNumber?: string;
};

export type AccessRequestSearchCountResponse = {
  count: Count;
};

export type AccessRequestSearchRequest = {
  body?: FiltersAndSearchAccessRequest;
  xCatUserPreferences?: string;
  cursor?: string;
  limit?: number;
  skip?: number;
  sortBy?: string;
  orderBy?: string;
  previous?: boolean;
};

class AccessRequestsService {
  private authInfo: AuthInfo | null;
  private baseUrl: string;
  constructor(authInfo: AuthInfo | null, baseUrl: string) {
    this.authInfo = authInfo;
    this.baseUrl = baseUrl;
  }

  getAccessRequests(
    input: AccessRequestSearchRequest,
    partyNumber: string,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<UserAccessRequestsGetResponseObj | ErrorResponse>> {
    const { token } = this.authInfo as AuthInfo;
    const { body, ...queryParameters } = input;

    return Axios.post<UserAccessRequestsGetResponseObj | ErrorResponse>(
      `${this.baseUrl}/userRequests/search?partyNumber=` + partyNumber,
      body,
      {
        cancelToken,
        params: queryParameters,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  editAccessRequest(
    requestId: string,
    accessReqInfo:
      | UpdateUserAccessRequestApproveAssociationRequest
      | UpdateUserAccessRequestDenyAccess,
    partyNumber?: string,
  ): Promise<AxiosResponse<any | ErrorResponse>> {
    const { token } = this.authInfo as AuthInfo;

    return Axios.patch<UserAccessRequestsGetResponseObj | ErrorResponse>(
      `${this.baseUrl}/userRequests/${requestId}?partyNumber=${partyNumber}`,
      accessReqInfo,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  countAccessRequest(
    input: AccessRequestSearchCountRequest,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<AccessRequestSearchCountResponse | ErrorResponse>> {
    const { body, ...queryParameters } = input;
    const token = this.authInfo ? this.authInfo.token : '';
    return Axios.post<AccessRequestSearchCountResponse | ErrorResponse>(
      `${this.baseUrl}/userRequests/COUNT`,
      body,
      {
        cancelToken,
        params: queryParameters,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
}

export default AccessRequestsService;
