import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const ObsoleteAccountIcon = ({
  marginTop,
  marginLeft,
  stroke = '#78869E',
  ...restProps
}: iconType) => (
  <SvgIcon
    stroke={stroke}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...restProps}
    viewBox="0 2 15 15"
    style={{
      height: 'auto',
      fill: 'none',
      width: restProps.width ?? '20px',
      marginTop: marginTop ?? 0,
      marginLeft: marginLeft ?? 0,
    }}
  >
    <path
      d="M5.1084 12.4457L10.391 7.16309"
      stroke="#43485C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.1084 7.16309L10.391 12.4457"
      stroke="#43485C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.34783 2.17383H1V14.4999H14.5V2.17383H12.1522"
      stroke="#43485C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.10828 1H3.34741V3.34783H5.10828V1Z"
      stroke="#43485C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1525 1H10.3916V3.34783H12.1525V1Z"
      stroke="#43485C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.1084 2.17383H10.391"
      stroke="#43485C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 5.10864H14.5"
      stroke="#43485C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    {/* </svg> */}
  </SvgIcon>
);

export default ObsoleteAccountIcon;
