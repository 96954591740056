import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  text: {
    display: '-webkit-box',

    // line-clamp determins how many lines should be displayed before going to overflow, only works with <p>
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  line1: {
    WebkitLineClamp: '1',
  },
  line2: {
    WebkitLineClamp: '2',
  },
  line3: {
    WebkitLineClamp: '3',
  },
}));
