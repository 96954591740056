import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Typography,
  useTheme,
  Button,
  Box,
  Grid,
  Divider,
  IconButton,
} from '@mui/material';
import useStyles from './styles';
import CustomerOrganization, {
  AssociatedCustomerOrganizationsRequest,
  CustomerOrganizationCountResponse,
  CustomerOrganizationSearchResponse,
  DealerCustomerResponse,
  OrganizationDealerCustomersResponse,
} from '../../../../../../services/customer-organization';
import CloseIcon from '@mui/icons-material/Close';
import ShowHideTable, { ShowHideTableType } from '../../../../../../components/show-hide-table';
import CustomerAddressDisplay from '../../../../../../components/customer-address';
import { useSelector } from 'react-redux';
import { AppState, useDispatchTs } from '../../../../../../store';
import { SearchOrganizationResponse } from '../../../../../../entities/customer-master-v1/searchOrganizationResponse';
import { AxiosResponse } from 'axios';
import { OrganizationResponse } from '../../../../../../entities/customer-master-v1/models';
import TextWithToolTip from '../../../../../../components/text-with-tooltip';
import { formatDetailedErrorMessage, showNotification } from '../../../../../../utils/util';
import UserIdentity, { IdentitySearchRequest } from '../../../../../../services/user-identity';
import AccountTypes from '../../../../../../components/account-types';
import { UsersSearch } from '../../../../../../entities/user-management-v1/usersSearch';
import TEXT from '../../../../../../globals/translation-map';
import { useTranslation } from 'react-i18next';
import { useGetAssociateUserInfo } from '../../../../../../hooks/use-get-associate-user-info';

type AssociateCustomerDetailsDrawerProperties = {
  item: OrganizationResponse;
  displayType?: Array<ShowHideTableType>;
  onClose: Function;
};

const AssociateCustomerDetailsDrawer = ({
  item,
  onClose,
  displayType = [
    ShowHideTableType.CUSTOMERS,
    ShowHideTableType.DEALERCUSTOMERS,
    ShowHideTableType.USERS,
  ],
}: AssociateCustomerDetailsDrawerProperties) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { authInfo, environmentVariables } = useSelector((state: AppState) => state.login);
  const [dealerData, setDealerData] = useState<Array<DealerCustomerResponse>>([]);
  const [customerData, setCustomerData] = useState<Array<SearchOrganizationResponse>>([]);

  const [dealerCount, setDealerCount] = useState<number>(0);
  const [customerCount, setCustomerCount] = useState<number>(0);

  const [dealerLoading, setDealerLoading] = useState<boolean>(false);
  const [dealerCountLoading, setDealerCountLoading] = useState<boolean>(false);

  const [dealerCursor, setDealerCursor] = useState<string>('');
  const [customerLoading, setCustomerLoading] = useState<boolean>(false);
  const [customerCountLoading, setCustomerCountLoading] = useState<boolean>(false);

  const [userLoading, setUserLoading] = useState<boolean>(false);

  const [customerCursor, setCustomerCursor] = useState<string>('');
  const [open, setOpen] = useState<boolean>(true);
  // when ucid flag is remove this variable and replace with associatedUsers from useGetAssociatedUsersInfo hook
  const [associatedCIAMUsers, setAssociatedCIAMUsers] = useState<Array<UsersSearch>>([]);
  const [startIndexForUCUsersFetch, setStartIndexForUCUsersFetch] = useState<number>(0);

  const dispatch = useDispatchTs();

  const customerOrganization = new CustomerOrganization(environmentVariables.customerMasterURI);
  const showGenericError = (error: any) => {
    showNotification(
      dispatch,
      'error',
      formatDetailedErrorMessage(error) || t(TEXT.MY_CUSTOMERS.ERRORS.SEARCH_ASSOCIATED_CUSTOMERS),
    );
  };
  const showUserManagementError = () => {
    showNotification(dispatch, 'error', t(TEXT.USER_MANAGEMENT.ERRORS.SEARCH_USERS));
  };
  const showUcidError = (error: any) => {
    if (error.response.status !== 404) {
      showNotification(dispatch, 'error', t(TEXT.MY_CUSTOMERS.ERRORS.SEARCH_ASSOCIATED_CUSTOMERS));
    }
  };
  const {
    associatedUsers,
    numberOfUsers = 0,
    isLoadingAssociatedUserData,
    fetchUserData,
    associatedUniversalCustomersUsers,
  } = useGetAssociateUserInfo({
    organizationId: item.customerOrganizationDetails?.customerOrganizationIdentifier ?? '',
    genericErrorCallback: showGenericError,
    userManagementErrorCallback: showUserManagementError,
    ucidErrorCallback: showUcidError,
  });
  const getDealerData = async () => {
    setDealerLoading(true);
    if (item.customerOrganizationDetails?.customerOrganizationIdentifier)
      try {
        const inputRequest: AssociatedCustomerOrganizationsRequest = {
          customerOrganizationIdentifier:
            item.customerOrganizationDetails.customerOrganizationIdentifier,
          limit: 100,
        };
        if (dealerCursor !== '') inputRequest.cursor = dealerCursor;
        const results =
          await customerOrganization.getAssociatedDealerCustomersByCustomerIdentifier(inputRequest);
        setDealerData([
          ...dealerData,
          ...(results as AxiosResponse<OrganizationDealerCustomersResponse>).data.dealerCustomers,
        ]);
        setDealerLoading(false);
        setDealerCursor(
          (results as AxiosResponse<OrganizationDealerCustomersResponse>).data.responseMetadata
            ?.nextCursor ?? '',
        );
      } catch {
        setDealerData([]);
        setDealerLoading(false);
      }
    else {
      setDealerData([]);
      setDealerLoading(false);
    }
  };
  const getCustomerData = async () => {
    setCustomerLoading(true);
    if (item.customerOrganizationDetails?.customerOrganizationIdentifier)
      try {
        const inputRequest: AssociatedCustomerOrganizationsRequest = {
          customerOrganizationIdentifier:
            item.customerOrganizationDetails.customerOrganizationIdentifier,
          limit: 100,
        };
        if (customerCursor !== '') {
          inputRequest.cursor = customerCursor;
        }
        const results =
          await customerOrganization.getAssociatedOrganizationsByCustomerIdentifier(inputRequest);
        setCustomerData([
          ...customerData,
          ...(results as AxiosResponse<CustomerOrganizationSearchResponse>).data
            .customerOrganizations,
        ]);
        setCustomerLoading(false);
        setCustomerCursor(
          (results as AxiosResponse<CustomerOrganizationSearchResponse>).data.responseMetadata
            ?.nextCursor ?? '',
        );
      } catch {
        setCustomerData([]);
        setCustomerLoading(false);
      }
    else {
      setCustomerData([]);
      setCustomerLoading(false);
    }
  };
  // delete when removeUniversalCustomerFlag is removed
  const getUserData = async () => {
    setUserLoading(true);
    const userIdentity = new UserIdentity(authInfo, environmentVariables.userIdentityV1);
    try {
      const requestInput: IdentitySearchRequest = {
        body: {
          filter: {
            propertyName: 'username',
            type: 'stringEquals',
            values: (associatedUniversalCustomersUsers ?? [])?.slice(
              startIndexForUCUsersFetch,
              startIndexForUCUsersFetch + 300,
            ),
          },
          responseAttributes: [
            'catrecid',
            'email',
            'username',
            'firstName',
            'lastName',
            'primaryPartyNumber',
            'primaryPartyName',
            'primaryPartyType',
            'catAffiliationCode',
            'mobilePhone',
            'delegatedCustomerOrganizations',
          ],
        },
      };
      const response = await userIdentity.getIdentities(requestInput);
      if (response.status === 200) {
        if (response.data) {
          setAssociatedCIAMUsers([
            ...(associatedUsers || []),
            ...((response.data as Array<UsersSearch>) || []),
          ]);
        }
      } else if (response.status !== 404) {
        showNotification(dispatch, 'error', t(TEXT.COMMON.ERRORS.FETCH_USERS));
      }
    } catch (error: any) {
      if (error?.response?.status !== 404) {
        showNotification(
          dispatch,
          'error',
          formatDetailedErrorMessage(error) ?? t(TEXT.COMMON.ERRORS.FETCH_USERS),
        );
      }
    }

    setStartIndexForUCUsersFetch(startIndexForUCUsersFetch + 300);
    setUserLoading(false);
  };
  const getCounts = async () => {
    if (item.customerOrganizationDetails?.customerOrganizationIdentifier) {
      const inputRequest: AssociatedCustomerOrganizationsRequest = {
        customerOrganizationIdentifier:
          item.customerOrganizationDetails.customerOrganizationIdentifier,
      };
      const getDealerCount = async () => {
        setDealerCountLoading(true);

        try {
          const countResponse =
            await customerOrganization.getCountForAssociatedDealerCustomersByCustomerIdentifier(
              inputRequest,
            );

          if (countResponse.status === 200) {
            setDealerCount(
              (countResponse as AxiosResponse<CustomerOrganizationCountResponse>).data.count,
            );
          }
        } catch {
          setDealerCount(0);
        }
        setDealerCountLoading(false);
      };
      const getCustomerCount = async () => {
        setCustomerCountLoading(true);

        try {
          const countResponse =
            await customerOrganization.getCountForAssociatedOrganizationsByCustomerIdentifier(
              inputRequest,
            );
          if (countResponse.status === 200) {
            setCustomerCount(
              (countResponse as AxiosResponse<CustomerOrganizationCountResponse>).data.count,
            );
          }
        } catch {
          setCustomerCount(0);
        }
        setCustomerCountLoading(false);
      };

      if (displayType.includes(ShowHideTableType.DEALERCUSTOMERS)) {
        getDealerCount();
      }
      if (displayType.includes(ShowHideTableType.CUSTOMERS)) {
        getCustomerCount();
      }
      if (displayType.includes(ShowHideTableType.USERS)) {
        fetchUserData(inputRequest.customerOrganizationIdentifier);
      }
    } else {
      setDealerCount(0);
      setCustomerCount(0);
    }
  };
  useEffect(() => {
    getCounts();
  }, []);
  useEffect(() => {
    if (!open) onClose();
  }, [open]);

  const fullAddress = item.customerAddresses
    ? item.customerAddresses?.find((address) => address.addressType === 'PRIMARY') ||
      item.customerAddresses[0]
    : undefined;
  return (
    <Drawer
      anchor="right"
      data-testid="customer-information-drawer"
      open={open}
      className={classes.drawer}
      PaperProps={{
        sx: { width: theme.spacing(94) },
      }}
    >
      <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
        <Box className={classes.drawerHeader}>
          <Box className={classes.drawerHeaderShadow}>
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h3" fontWeight="bold">
                  {t(TEXT.MY_CUSTOMERS.CUSTOMER_INFORMATION)}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  data-testid="x-associate-customer-details-button"
                  onClick={() => {
                    setOpen(false);
                  }}
                  size="large"
                  className={classes.closeIcon}
                >
                  <CloseIcon style={{ color: 'black' }} />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container pb={1}>
              <Grid item xs={5} className={classes.customerHeader}>
                {t(TEXT.COMMON.TITLE.NAME)}
              </Grid>
              <Grid item xs={3} className={classes.customerHeader}>
                {t(TEXT.COMMON.ID)}
              </Grid>
              <Grid item xs={4} className={classes.customerHeader}>
                {t(TEXT.COMMON.ADDRESS)}
              </Grid>
              <Grid
                item
                xs={5}
                display="flex"
                alignItems="flex-start"
                className={classes.customerInfoCell}
                data-testid="customer-infobox-name"
                gap="2px"
              >
                <TextWithToolTip
                  longText={
                    item.customerOrganizationDetails?.customerOrganizationBusinessName ?? ''
                  }
                  bold={true}
                  fontSize={14}
                  maxWidth="70%"
                  marginTop={theme.spacing(0.125)}
                ></TextWithToolTip>
                <AccountTypes
                  size={theme.spacing(1.75)}
                  marginLeft={2}
                  row={{ ...item.customerOrganizationDetails }}
                  containerHeight={theme.spacing(2.5)}
                />
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.customerInfoCell}
                data-testid="customer-infobox-id"
              >
                <TextWithToolTip
                  longText={item.customerOrganizationDetails?.customerOrganizationIdentifier ?? ''}
                  bold={true}
                  fontSize={14}
                  width="100%"
                  marginTop={theme.spacing(0.125)}
                ></TextWithToolTip>
              </Grid>
              <Grid
                item
                xs={4}
                className={classes.customerInfoCell}
                data-testid="customer-infobox-address"
                marginTop={theme.spacing(0.125)}
              >
                {fullAddress !== undefined && (
                  <CustomerAddressDisplay
                    address1={fullAddress.address1}
                    cityName={fullAddress.cityName}
                    stateOrProvinceCode={fullAddress.stateOrProvinceCode}
                    countryCode={fullAddress.countryCode}
                    postalCode={fullAddress.postalCode}
                    fontWeight={600}
                    showFullAddress={true}
                  ></CustomerAddressDisplay>
                )}
              </Grid>
            </Grid>
            <Box></Box>
          </Box>
          <Box className={classes.subtitleBox} pt={1}>
            <Typography variant="h4" fontWeight="bold">
              {t(TEXT.COMMON.FILTERS.BUTTONS_LABELS.ASSOCIATIONS)}
            </Typography>
          </Box>
        </Box>
        <Box style={{ background: 'white', overflowY: 'scroll', height: '100%' }}>
          {((displayType.includes(ShowHideTableType.DEALERCUSTOMERS) && dealerCount === 0) ||
            !displayType.includes(ShowHideTableType.DEALERCUSTOMERS)) &&
            ((displayType.includes(ShowHideTableType.CUSTOMERS) && customerCount === 0) ||
              !displayType.includes(ShowHideTableType.CUSTOMERS)) &&
            ((displayType.includes(ShowHideTableType.USERS) && numberOfUsers === 0) ||
              !displayType.includes(ShowHideTableType.USERS)) && (
              <Box className={classes.noDataContainer}>
                <Typography data-testid="no-data-text" align="center" className={classes.noData}>
                  {t(TEXT.MY_CUSTOMERS.BLANK_STATE.NO_ASSOCAITIONS_EXIST)}
                </Typography>
              </Box>
            )}
          {((displayType.includes(ShowHideTableType.DEALERCUSTOMERS) && dealerCount > 0) ||
            (displayType.includes(ShowHideTableType.CUSTOMERS) && customerCount > 0) ||
            (displayType.includes(ShowHideTableType.USERS) && numberOfUsers > 0)) && (
            <Box pt={1}>
              {displayType.includes(ShowHideTableType.DEALERCUSTOMERS) && (
                <Box data-testid="dealer-table-container">
                  <ShowHideTable
                    headerTitle={t(TEXT.MY_CUSTOMERS.ASSOCIATED_DEALER_CUSTOMERS)}
                    count={dealerCount}
                    data={dealerData}
                    getData={getDealerData}
                    emptyMessage={t(TEXT.MY_CUSTOMERS.BLANK_STATE.NO_DEALER_ASSOCIATIONS)}
                    loading={dealerLoading}
                    hideBottomBorder={true}
                    countLoading={dealerCountLoading}
                    id="dealer"
                  ></ShowHideTable>
                </Box>
              )}
              {displayType.includes(ShowHideTableType.USERS) && (
                <Box data-testid="user-table-container">
                  <ShowHideTable
                    hasNextPage={
                      startIndexForUCUsersFetch < (associatedUniversalCustomersUsers ?? []).length
                    }
                    headerTitle={t(TEXT.MY_CUSTOMERS.ASSOCIATED_USERS)}
                    count={numberOfUsers}
                    data={associatedCIAMUsers.length ? associatedCIAMUsers : associatedUsers}
                    getData={getUserData}
                    emptyMessage={t(TEXT.MY_CUSTOMERS.BLANK_STATE.NO_USER_ASSOCIATIONS)}
                    loading={userLoading}
                    countLoading={isLoadingAssociatedUserData}
                    id="user"
                    hideBottomBorder={true}
                    type={ShowHideTableType.USERS}
                  ></ShowHideTable>
                </Box>
              )}

              {displayType.includes(ShowHideTableType.CUSTOMERS) && (
                <Box data-testid="customer-table-container">
                  <ShowHideTable
                    headerTitle={t(TEXT.MY_CUSTOMERS.ASSOCIATED_CUSTOMERS)}
                    count={customerCount}
                    data={customerData}
                    getData={getCustomerData}
                    emptyMessage={t(TEXT.MY_CUSTOMERS.BLANK_STATE.NO_CUSTOMER_ASSOCIATIONS)}
                    loading={customerLoading}
                    countLoading={customerCountLoading}
                    id="customer"
                    tableHeight={300}
                  ></ShowHideTable>
                </Box>
              )}
            </Box>
          )}
        </Box>

        <Box className={classes.drawerFooter}>
          <Divider />
          <Button
            className={classes.closeButton}
            color="primary"
            variant="contained"
            data-testid="close-associate-customer-details-button"
            onClick={() => {
              setOpen(false);
            }}
          >
            {t(TEXT.COMMON.CLOSE)}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default AssociateCustomerDetailsDrawer;
