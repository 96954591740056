import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const SignOut = (props: iconType) => {
  const { stroke = '#78869E' } = props;
  return (
    <SvgIcon
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
      viewBox="0 0 20 20"
      style={{ fill: 'none', width: '24px', height: '24px' }}
    >
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.43573 2.15218H15.0444V4.21739"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0444 16.6087V17.8478H8.43573"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.00098 17.8478L8.43576 19.5V0.5L1.00098 2.15217V17.8478Z"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.54437 11.6522C6.22872 11.6522 6.7835 11.0974 6.7835 10.413C6.7835 9.72867 6.22872 9.1739 5.54437 9.1739C4.86001 9.1739 4.30524 9.72867 4.30524 10.413C4.30524 11.0974 4.86001 11.6522 5.54437 11.6522Z"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.501 10.4196H20.001"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6967 7.10867L20.0011 10.413L16.6967 13.7174"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default SignOut;
