import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  customDatePickerContainer: {
    width: theme.spacing(40),
    borderRadius: 0,
    padding: 0,
  },
  customDatePicker: {
    marginTop: 15,
  },
  datePickerCalendarIcon: {
    fontSize: 15,
    marginRight: 10,
    border: 0,
  },
  datePickerCloseIcon: {
    fontSize: 20,
  },
  buttonContainer: {
    border: '1px solid #DDDEDF',
    padding: 7,
  },
}));
