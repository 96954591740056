import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  button: {
    color: CAT_COLOR.AZURE,
    underline: 'none',
  },
  container: {
    display: 'inline-flex',
  },
}));
