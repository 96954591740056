import TEXT from './translation-map';
const errorMessages = (t: Function): any => {
  return {
    entitlements: {
      heading: t(TEXT.GLOBALS.APP_ERRORS.ENTITLEMENTS_HEADING),
      description: t(TEXT.GLOBALS.APP_ERRORS.ENTITLEMENTS_DESCRIPTION),
      advice: '',
    },
    userManagement: {
      heading: '',
      description: t(TEXT.GLOBALS.APP_ERRORS.USER_MANAGEMENT_DESCRIPTION),
      advice: t(TEXT.GLOBALS.APP_ERRORS.USER_MANAGEMENT_ADVICE),
    },
  };
};

export default errorMessages;
