import React from 'react';
import { Button } from '@mui/material';
import LinkIcon from '../../assets/icons/link-icon';
import useStyles from './styles';

interface CopyButtonProps {
  text: string;
  copy: string;
}

const CopyButton = ({ text, copy }: CopyButtonProps) => {
  const { classes: styles } = useStyles();
  const handleCopy = () => {
    navigator?.clipboard?.writeText(copy);
  };

  return (
    <Button
      data-testid="copy-link"
      className={styles.copyButton}
      variant="outlined"
      startIcon={<LinkIcon />}
      onClick={handleCopy}
    >
      {text}
    </Button>
  );
};

export default CopyButton;
