/**
 * User Onboarding
 * User Onboarding API provides functionality to manage users access, requests and invites. 
 *
 * OpenAPI spec version: 1.3.0
 * Contact: Platform_Core_Services_-_CAT@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Represents a single filtering criteria, i.e., the name of the object field to filter by and an integer value. 
 */
export interface AccessRequestIntegerEqualsFilter { 
    /**
     * Enum that defines all possible string filter types.
     */
    type: AccessRequestIntegerEqualsFilter.TypeEnum;
    /**
     * User properties that can be used for integer filtering. 
     */
    propertyName: AccessRequestIntegerEqualsFilter.PropertyNameEnum;
    /**
     * Array of possible filter values.
     */
    values: Array<number>;
}
export namespace AccessRequestIntegerEqualsFilter {
    export type TypeEnum = 'integerEquals';
    export const TypeEnum = {
        IntegerEquals: 'integerEquals' as TypeEnum
    };
    export type PropertyNameEnum = 'accessApprovedDaysOld' | 'accessDeniedDaysOld';
    export const PropertyNameEnum = {
        AccessApprovedDaysOld: 'accessApprovedDaysOld' as PropertyNameEnum,
        AccessDeniedDaysOld: 'accessDeniedDaysOld' as PropertyNameEnum
    };
}