import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../../../globals/color-properties';

export default makeStyles()((theme) => ({
  icon: {
    marginLeft: 10,
  },
  dateRangeBtn: {
    lineHeight: 1,
    marginRight: theme.spacing(2),
    borderRadius: 4,
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
    '&:hover': {
      backgroundColor: CAT_COLOR.MEDIUM_GRAY,
      cursor: 'pointer',
    },
  },
  dateRangeActiveBtn: {
    lineHeight: 1,
    marginRight: theme.spacing(2),
    borderRadius: 4,
    backgroundColor: CAT_COLOR.DARK_GRAYISH_BLUE,
    color: CAT_COLOR.WHITE,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: CAT_COLOR.LIGHT_SLATE_GREY,
    },
  },
  filterButtonContainer: {
    width: theme.spacing(8),
  },
  filterButton: {
    paddingTop: theme.spacing(0.75),
    border: 'none',
    width: theme.spacing(8),
    fontWeight: 100,
    '&:disabled': {
      border: 'none',
      backgroundColor: CAT_COLOR.WHITE,
    },
    '&:hover': {
      border: 'none',
      backgroundColor: CAT_COLOR.WHITE_SMOKE,
    },
  },
  filterByText: {
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
    fontWeight: 600,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  footerSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconPosition: {
    position: 'absolute',
    right: '1%',
    top: theme.spacing(1),
  },
  dividerText: {
    marginTop: theme.spacing(1.25),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  closeIcon: {
    color: CAT_COLOR.BLACK,
  },
  dialogContent: {
    padding: 0,
  },
  row: {
    paddingLeft: theme.spacing(8),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  inlineRow: {
    width: '50%',
    display: 'inline-block',
  },
  customerSelectionText: {
    cursor: 'pointer',
    color: CAT_COLOR.WATER_BLUE,
    fontWeight: 'bold',
  },
  requirementText: {
    color: CAT_COLOR.PIGMENT_RED,
    paddingTop: theme.spacing(2),
  },
  filterTags: {
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  selectionText: {
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '14px',
    paddingTop: theme.spacing(1),
  },
  validationColor: {
    color: CAT_COLOR.PIGMENT_RED,
  },
  validationText: {
    fontSize: '11px',
    fontWeight: 400,
  },
  requestTypeText: {
    fontSize: '12px',
    fontWeight: 400,
  },
}));
