/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs, get financial account details, associate/disassociate financial products to a customer organization. 
 *
 * OpenAPI spec version: 1.41.2
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomerOrganizationIdentifier } from './customerOrganizationIdentifier';
import { FeedbackReason } from './feedbackReason';
import { RecommendationFeedback } from './recommendationFeedback';
import { RecommendationTypeLabel } from './recommendationTypeLabel';

/**
 * Object used in request to associate a customer organization to a parent customer organization.
 */
export interface RecommendedSecondaryCustomerOrganizationDetails { 
    /**
     * The recommendation relationship type represents the various types of recommendation. The field is required when request type supports various types of items. 
     */
    recommendationType?: RecommendedSecondaryCustomerOrganizationDetails.RecommendationTypeEnum;
    recommendationTypeLabel?: RecommendationTypeLabel;
    customerOrganizationIdentifier: CustomerOrganizationIdentifier;
    selectParentOrganizationIdentifier: CustomerOrganizationIdentifier;
    feedbackStatus: RecommendationFeedback;
    feedbackReason?: FeedbackReason;
}
export namespace RecommendedSecondaryCustomerOrganizationDetails {
    export type RecommendationTypeEnum = 'ASSOCIATE MULTIPLE CAT ASSOCIATION';
    export const RecommendationTypeEnum = {
        ASSOCIATEMULTIPLECATASSOCIATION: 'ASSOCIATE MULTIPLE CAT ASSOCIATION' as RecommendationTypeEnum
    };
}