import React, { useState } from 'react';
import { ListItem, Box, Typography, Link } from '@mui/material';
import { OrganizationResponse } from '../../../../../../entities/customer-master-v1/models';
import TextWithToolTip from '../../../../../../components/text-with-tooltip';
import theme from '../../../../../../globals/theme';
import { getValueFromObject } from '../../../../../../utils/util';
import AccountTypes from '../../../../../../components/account-types';
import useStyles from './styles';
import AssociateCustomerDetailsDrawer from '../associate-customer-details';
import TEXT from '../../../../../../globals/translation-map';
import { useTranslation } from 'react-i18next';

type TransferTableItemProperties = {
  item: OrganizationResponse;
};

const TransferTableItem = (properties: TransferTableItemProperties) => {
  const { item } = properties;
  const { t } = useTranslation();
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState<boolean>(false);
  const { classes } = useStyles();
  return (
    <ListItem data-testid="transfer-table-list-item" key={JSON.stringify(item)} role="listitem">
      <Box display="flex" width="100%">
        <Box flexGrow={1} overflow="hidden">
          <Box mb={1}>
            <Box fontStyle="italic" color={theme.palette.primary.light}>
              {t(TEXT.COMMON.TITLE.CUSTOMER)}
            </Box>
            <Box className={classes.textHeight} width="80%">
              <TextWithToolTip
                bold
                longText={
                  getValueFromObject(
                    item,
                    'customerOrganizationDetails.customerOrganizationBusinessName',
                    '',
                  ) ?? ''
                }
                width="unset"
                fontSize={14}
              />
              <AccountTypes
                size={theme.spacing(2)}
                marginTop={2}
                marginLeft={2}
                row={{ ...item.customerOrganizationDetails }}
              />
            </Box>
            <Box className={classes.textHeight}>
              <Typography fontWeight="bold" className={classes.id}>
                ID:
              </Typography>
              <TextWithToolTip
                width="unset"
                longText={
                  getValueFromObject(
                    item,
                    'customerOrganizationDetails.customerOrganizationIdentifier',
                    '',
                  ) ?? ''
                }
                fontSize={14}
              />
              <Link className={classes.seeMoreDetails} onClick={() => setOpenDetailsDrawer(true)}>
                ({t(TEXT.COMMON.SEE_MORE_DETAILS)})
              </Link>
              {openDetailsDrawer && (
                <AssociateCustomerDetailsDrawer
                  onClose={() => {
                    setOpenDetailsDrawer(false);
                  }}
                  item={item}
                ></AssociateCustomerDetailsDrawer>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
};

export default TransferTableItem;
