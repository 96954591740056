/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs, get financial account details, associate/disassociate financial products to a customer organization. 
 *
 * OpenAPI spec version: 1.41.2
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DaysLeft } from './daysLeft';
import { RecommendationConfidenceRating } from './recommendationConfidenceRating';
import { RecommendationDetailsBase } from './recommendationDetailsBase';
import { RecommendationGroupId } from './recommendationGroupId';
import { RecommendationTypeLabel } from './recommendationTypeLabel';

/**
 * Details of a recommendation to be returned for a dealer.
 */
export interface RecommendationDetails extends RecommendationDetailsBase { 
    /**
     * The recommendation relationship type represents the various types of recommendation like \"ASSOCIATE DCN TO EXISTING CAT ASSOCIATION\" , \"ASSOCIATE MULTIPLE CAT ASSOCIATION\" and \"ASSOCIATE MULTIPLE CAT ASSOCIATION & ASSOCIATE DCN TO EXISTING CAT ASSOCIATION\". 
     */
    recommendationType?: RecommendationDetails.RecommendationTypeEnum;
    recommendationTypeLabel?: RecommendationTypeLabel;
}
export namespace RecommendationDetails {
    export type RecommendationTypeEnum = 'ASSOCIATE DCN TO EXISTING CAT ASSOCIATION' | 'ASSOCIATE MULTIPLE CAT ASSOCIATION' | 'ASSOCIATE MULTIPLE CAT ASSOCIATION & ASSOCIATE DCN TO EXISTING CAT ASSOCIATION';
    export const RecommendationTypeEnum = {
        DCNTOEXISTINGCATASSOCIATION: 'ASSOCIATE DCN TO EXISTING CAT ASSOCIATION' as RecommendationTypeEnum,
        MULTIPLECATASSOCIATION: 'ASSOCIATE MULTIPLE CAT ASSOCIATION' as RecommendationTypeEnum,
        MULTIPLECATASSOCIATIONASSOCIATEDCNTOEXISTINGCATASSOCIATION: 'ASSOCIATE MULTIPLE CAT ASSOCIATION & ASSOCIATE DCN TO EXISTING CAT ASSOCIATION' as RecommendationTypeEnum
    };
}