/**
 * User Onboarding
 * User Onboarding API provides functionality to manage users access, requests and invites. 
 *
 * OpenAPI spec version: 1.3.0
 * Contact: Platform_Core_Services_-_CAT@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Status of the user access request applicable during update.
 */
export type UserAccessRequestUpdateApproveStatus = 'APPROVED';

export const UserAccessRequestUpdateApproveStatus = {
    APPROVED: 'APPROVED' as UserAccessRequestUpdateApproveStatus
};