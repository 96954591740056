import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  parent: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignContent: 'flexStart',
  },

  childClearAll: {
    width: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkbox: {
    marginRight: theme.spacing(1),
  },
  select: {
    width: theme.spacing(25.625),
  },
  placeholder: {
    fontStyle: 'italic',
    color: CAT_COLOR.LIGHT_SLATE_GREY,
  },

  input: {
    borderBottom: 'none',
    '&:hover': {
      borderBottom: 'none',
    },
    '&:focus': {
      borderBottom: 'none',
      paddingRight: 0,
    },
  },
  paper: {
    boxShadow: '0px 0px 10px rgb(80 95 122 / 30%)',
    '& .MuiAutocomplete-listbox': {
      paddingTop: 1,
      paddingBottom: 1,
    },
    '& .MuiAutocomplete-option': {
      backgroundColor: CAT_COLOR.WHITE,
      '&[aria-selected=true]': {
        backgroundColor: 'transparent',
        '& .MuiTypography-root': {
          color: CAT_COLOR.DARK_GRAYISH_BLUE,
        },
        '&:hover': {
          backgroundColor: CAT_COLOR.DARK_GRAYISH_BLUE,
          '& .MuiTypography-root': {
            color: CAT_COLOR.WHITE,
          },
        },
      },
      '& .Mui-focused': {
        backgroundColor: 'transparent',
      },
      '& .MuiCheckbox-root': {
        color: CAT_COLOR.DARK_GRAYISH_BLUE,
      },
      '& .MuiTypography-root': {
        color: CAT_COLOR.DARK_GRAYISH_BLUE,
      },
      '&:hover': {
        backgroundColor: CAT_COLOR.DARK_GRAYISH_BLUE,
        '& .MuiCheckbox-root': {
          color: CAT_COLOR.WHITE,
        },
        '& .MuiTypography-root': {
          color: CAT_COLOR.WHITE,
        },
      },
    },
  },
  textField: {
    marginTop: '2px',
    width: '100%',
    '& div': {
      fontSize: 14,
      textAlign: 'left',
    },
    '& input::placeholder': {
      color: CAT_COLOR.LIGHT_SLATE_GREY,
      fontStyle: 'italic',
      opacity: 1,
    },
  },
  option: {
    display: 'flex',
  },
  noOptions: {
    maxWidth: 300,
  },
}));
