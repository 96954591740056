/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs, get financial account details, associate/disassociate financial products to a customer organization. 
 *
 * OpenAPI spec version: 1.41.2
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AssociateDcnToExistingCatAssociationResponseArray } from './associateDcnToExistingCatAssociationResponseArray';
import { RecommendationTypeLabel } from './recommendationTypeLabel';
import { ResponseMetadata } from './responseMetadata';

/**
 * Response with details of recommendations available for recommendation type \"ASSOCIATE DCN TO EXISTING CAT ASSOCIATIONS\".
 */
export interface AssociateDcnToExistingCatAssociationResponse { 
    /**
     * The recommendation relationship type represents the various types of recommendation.
     */
    recommendationType: AssociateDcnToExistingCatAssociationResponse.RecommendationTypeEnum;
    recommendationTypeLabel?: RecommendationTypeLabel;
    recommendationItems?: AssociateDcnToExistingCatAssociationResponseArray;
    responseMetadata?: ResponseMetadata;
}
export namespace AssociateDcnToExistingCatAssociationResponse {
    export type RecommendationTypeEnum = 'ASSOCIATE DCN TO EXISTING CAT ASSOCIATION';
    export const RecommendationTypeEnum = {
        ASSOCIATEDCNTOEXISTINGCATASSOCIATION: 'ASSOCIATE DCN TO EXISTING CAT ASSOCIATION' as RecommendationTypeEnum
    };
}