/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs, get financial account details, associate/disassociate financial products to a customer organization. 
 *
 * OpenAPI spec version: 1.41.2
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * The address type represents dealer customer organization's address type. 
 */
export type DealerCustomerOrgAddressType = 'MAIN OFFICE' | 'SITE' | 'BILLING';

export const DealerCustomerOrgAddressType = {
    MAINOFFICE: 'MAIN OFFICE' as DealerCustomerOrgAddressType,
    SITE: 'SITE' as DealerCustomerOrgAddressType,
    BILLING: 'BILLING' as DealerCustomerOrgAddressType
};