import React, { memo } from 'react';
import { Box, Tooltip, useTheme } from '@mui/material';
import useStyles from './styles';
import ManagedAccountIcon from '../../assets/icons/managed-account';
import TopLevelIcon from '../../assets/icons/top-level';
import InactiveAccountIcon from '../../assets/icons/inactive-account';
import ObsoleteAccountIcon from '../../assets/icons/obsolete-account';
import ProspectAccountIcon from '../../assets/icons/prospect-account-icon';
import { CustomerStatus } from '../../entities/customer-master-v1/customerStatus';
import { isString } from '../../utils/util';
import ChildLevelIcon from '../../assets/icons/child-level';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { CustomerData } from '../../pages/my-settings/constants';
import TEXT from '../../globals/translation-map';
import { useTranslation } from 'react-i18next';
export enum AccountEntityType {
  CustomerOrg = 'Customer Organization',
  DealerCustomer = 'Dealer Customer',
}
export type AccountRowType = {
  topLevelIndicator?: boolean;
  managedAccountIndicator?: boolean;
  managedCustomerCode?: string;
  parentOrganizationIdentifier?: string;
  status?: string;
  type?: string;
  managedCustomerBusinessName?: string;
};
type AccountTypesProp = {
  row: {
    topLevelIndicator?: boolean;
    managedAccountIndicator?: boolean;
    managedCustomerCode?: string;
    parentOrganizationIdentifier?: string;
    status?: string;
    type?: string;
    managedCustomerBusinessName?: string;
  };
  entityType?: AccountEntityType;
  size?: string | number;
  marginLeft?: string | number;
  marginTop?: string | number;
  managedIconMarginTop?: string | number;
  containerHeight?: string | number;
  justify?: 'left' | 'right' | 'center';
};

const AccountTypes = ({
  row,
  entityType = AccountEntityType.CustomerOrg,
  size,
  marginLeft,
  marginTop,
  managedIconMarginTop,
  containerHeight,
  justify,
}: AccountTypesProp) => {
  const { classes } = useStyles();
  // The ManagedAccountIcon is shorter than others so it needs to be slightly wider to be the same height
  let managedIconWidth: string = size as string;
  if (isString(managedIconWidth) && managedIconWidth.length > 2) {
    const newWidth = Number.parseInt(managedIconWidth);
    if (!Number.isNaN(newWidth)) {
      managedIconWidth = `${newWidth + 6}px`;
    }
  }
  const theme = useTheme();
  const { t } = useTranslation();
  const { customSettings } = useSelector((state: AppState) => state.login);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent={justify}
      className={classes.iconSection}
      height={containerHeight}
    >
      {row.topLevelIndicator && (
        <Tooltip
          disableInteractive
          title={t(TEXT.MY_CUSTOMERS.ACCOUNT_TYPES.TOP_LEVEL)}
          arrow
          placement="top"
          classes={classes}
        >
          <Box mr={0.3} ml={0}>
            <TopLevelIcon width={size ?? 18} marginLeft={marginLeft} marginTop={marginTop} />
          </Box>
        </Tooltip>
      )}

      {!row.topLevelIndicator &&
        entityType !== AccountEntityType.DealerCustomer &&
        row.parentOrganizationIdentifier && (
          <Tooltip
            disableInteractive
            title={`${t(TEXT.COMMON.PARENT_ID)}: ${row.parentOrganizationIdentifier}`}
            arrow
            placement="top"
            classes={classes}
          >
            <Box mr={0.3} ml={0}>
              <ChildLevelIcon width={size ?? 18} marginLeft={marginLeft} marginTop={marginTop} />
            </Box>
          </Tooltip>
        )}
      {row.managedAccountIndicator && customSettings?.customerData !== CustomerData.PERKINS && (
        <Tooltip
          disableInteractive
          title={
            <>
              {t(TEXT.MY_CUSTOMERS.ACCOUNT_TYPES.MANAGED)}
              {`${
                (row?.managedCustomerCode && row.managedCustomerCode !== '') ||
                (row?.managedCustomerBusinessName && row?.managedCustomerBusinessName !== '')
                  ? ' - '
                  : ''
              }`}
              <strong>{row.managedCustomerCode}</strong>
              {`${
                row?.managedCustomerCode &&
                row.managedCustomerCode !== '' &&
                row?.managedCustomerBusinessName &&
                row?.managedCustomerBusinessName !== ''
                  ? ' | '
                  : ''
              }`}
              {row?.managedCustomerBusinessName}
            </>
          }
          arrow
          placement="top"
          classes={classes}
        >
          <Box mr={0} ml={0} mt={0.25} data-testid="managed-account-icon">
            <ManagedAccountIcon
              width={managedIconWidth ?? 22}
              marginLeft={marginLeft}
              marginTop={managedIconMarginTop}
            />
          </Box>
        </Tooltip>
      )}
      {row.status === 'INACTIVE' && (
        <Tooltip
          disableInteractive
          title={t(TEXT.MY_CUSTOMERS.ACCOUNT_TYPES.INACTIVE)}
          arrow
          placement="top"
          classes={classes}
        >
          <Box mr={0} ml={0.5}>
            <InactiveAccountIcon width={size} marginLeft={marginLeft} marginTop={marginTop} />
          </Box>
        </Tooltip>
      )}
      {row.status === CustomerStatus.OBSOLETE && (
        <Tooltip
          disableInteractive
          title={t(TEXT.MY_CUSTOMERS.ACCOUNT_TYPES.OBSOLETE)}
          arrow
          placement="top"
          classes={classes}
        >
          <Box mt={0} ml={0} marginTop={theme.spacing(0.25)}>
            <ObsoleteAccountIcon width={size} marginLeft={marginLeft} marginTop={marginTop} />
          </Box>
        </Tooltip>
      )}
      {(row.status === CustomerStatus.DRAFT || row.type === 'PROSPECT') && (
        <Tooltip
          disableInteractive
          title={t(TEXT.MY_CUSTOMERS.ACCOUNT_TYPES.PROSPECT)}
          arrow
          placement="top"
          classes={classes}
        >
          <Box mt={0} ml={0}>
            <ProspectAccountIcon width={size} marginLeft={marginLeft} marginTop={marginTop} />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

export default memo(AccountTypes);
