import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  text: {
    '& p': {
      fontWeight: 'bold',
      color: 'black',
    },
  },
  id: {
    marginRight: theme.spacing(0.25),
  },
  seeMoreDetails: {
    marginLeft: theme.spacing(0.25),
    marginTop: '-4px',
    textDecoration: 'none',
  },
  textHeight: {
    height: theme.spacing(2),
    display: 'flex',
  },
}));
