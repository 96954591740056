import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../../globals/color-properties';

export default makeStyles()((theme) => ({
  bold: {
    fontWeight: 'bold',
  },
  underline: {
    textDecoration: 'underline',
  },
  cookieLink: {
    '& span': {
      color: 'rgb(0, 0, 238)',
      '&:hover': {
        textDecoration: 'underline',
        background: CAT_COLOR.WHITE,
      },
    },
  },
}));
