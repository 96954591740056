import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  paginationText: {
    paddingTop: theme.spacing(1.25),
    paddingRight: theme.spacing(1),
  },
  dividerText: {
    paddingTop: theme.spacing(1.25),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  navigationIconPervious: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(2),
  },
  navigationIconNext: {
    padding: theme.spacing(0.5),
  },
}));
