import { useTheme } from '@mui/material';
import { SearchOrganizationResponse } from '../../entities/customer-master-v1/searchOrganizationResponse';
import CustomerInfo from '../associate-dealer-customer/pages/recommendations/components/customer-info';
import React from 'react';

export const CustomerInfoWrapper = (properties: {
  selectedCompany: SearchOrganizationResponse | undefined;
  customerNameDataTestId?: string;
  customerIdDataTestId?: string;
  customerTitle?: string;
  customerIdTitle?: string;
}) => {
  const theme = useTheme();
  const {
    selectedCompany,
    customerNameDataTestId,
    customerIdDataTestId,
    customerTitle,
    customerIdTitle,
  } = properties;
  return (
    <CustomerInfo
      selectedCompany={selectedCompany}
      customerNameDataTestId={customerNameDataTestId}
      customerIdDataTestId={customerIdDataTestId}
      customerTitle={customerTitle}
      customerIdTitle={customerIdTitle}
      containerStyle={{
        marginLeft: theme.spacing(5),
        marginBottom: theme.spacing(2),
      }}
      companyItemStyle={{
        minWidth: theme.spacing(40),
        paddingRight: theme.spacing(5),
      }}
      customerIdStyle={{
        minWidth: theme.spacing(14),
      }}
      maxWidth="unset"
      numberOfCharsToDisplay={20}
    />
  );
};
