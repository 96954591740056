import { AxiosResponse } from 'axios';
import { SearchUsersRequest } from '../../services/user-management';
import { UniversalCustomersUserType } from '../../pages/my-customers/components/row-details/components/associated-users';
import xml2js from 'xml2js';
import { UsersSearch } from '../../entities/user-management-v1/usersSearch';
import { UserSearchResponse } from '../../entities/user-identity-v1/userSearchResponse';
import { PrimaryPartyType } from '../../entities/entitlements-v1/primaryPartyType';
import { PartyType } from '../../entities/entitlements-v1/partyType';

export const userManagementPayload = (
  organizationIdAssociatedInfo: string,
  worksWith: boolean,
): SearchUsersRequest => {
  return {
    body: {
      logicalExpression: '$0 & $1 & $2',
      filters: [
        {
          propertyName: 'partyType',
          type: 'stringEquals',
          values: ['CUST'],
        },
        {
          propertyName: 'partyNumbers',
          type: 'stringEquals',
          values: [organizationIdAssociatedInfo],
        },
        {
          propertyName: 'userCategory',
          type: 'stringEquals',
          values: worksWith
            ? ['WORKS_FOR', 'APP_ENROLLED', 'WORKS_WITH']
            : ['WORKS_FOR', 'APP_ENROLLED'],
        },
      ],
      responseAttributes: [
        'catrecid',
        'email',
        'username',
        'firstName',
        'lastName',
        'primaryPartyDetails',
        'delegatedCustomerOrganizations',
        'mainCustomerOrganization',
      ],
    },
  };
};

export const associateUserPayload = (properties: {
  customerPartyNumber: string;
  user: UserSearchResponse;
}) => {
  const { customerPartyNumber, user } = properties;
  const delegatedCustomer = {
    delegatedCustomerOrganizations: [
      customerPartyNumber,
      ...(user?.delegatedCustomerOrganizations || []),
    ],
  };
  if (
    user?.primaryPartyType === PrimaryPartyType.CUST ||
    user?.primaryPartyDetails?.partyType === PrimaryPartyType.CUST
  ) {
    if (!!user?.delegatedCustomerOrganizations && user.delegatedCustomerOrganizations.length > 1) {
      return { ...delegatedCustomer, mainCustomerOrganization: customerPartyNumber };
    } else {
      return { mainCustomerOrganization: customerPartyNumber };
    }
  } else {
    return delegatedCustomer;
  }
};

export const parseUCIDXML = (
  associatedUsersUniversalCustomersResponse: AxiosResponse<any>,
  associatedUsersCIAM: UsersSearch[],
) => {
  let associatedUsersUniversalCustomers: Array<UniversalCustomersUserType> = [];
  if (associatedUsersUniversalCustomersResponse?.data) {
    const associatedUsersUniversalCustomersXML = associatedUsersUniversalCustomersResponse.data;
    xml2js.parseString(
      associatedUsersUniversalCustomersXML,
      { explicitRoot: false, explicitArray: false, preserveChildrenOrder: true },
      (error, parsedResult: any) => {
        const result = Array.isArray(parsedResult.user) ? parsedResult.user : [parsedResult.user];
        associatedUsersUniversalCustomers =
          associatedUsersCIAM && associatedUsersCIAM?.length > 0
            ? result.filter((user: any) => {
                return (
                  associatedUsersCIAM.findIndex((ciamUser) => {
                    return (
                      ciamUser?.username?.localeCompare(user.logonId, undefined, {
                        sensitivity: 'accent',
                      }) === 0
                    );
                  }) === -1
                );
              })
            : result;
      },
    );
  }
  return associatedUsersUniversalCustomers;
};

export const filterUsers = (properties: {
  user: UsersSearch;
  customerOrganizationIdentifier?: string;
  ucidFlag: boolean;
}) => {
  const { customerOrganizationIdentifier, ucidFlag, user } = properties;
  if (ucidFlag) {
    return (
      user?.username &&
      (user?.primaryPartyDetails?.partyNumber === customerOrganizationIdentifier ||
        user?.mainCustomerOrganization === customerOrganizationIdentifier ||
        user.primaryPartyDetails?.partyType !== PartyType.CUST)
    );
  } else {
    return (
      user?.username && user?.primaryPartyDetails?.partyNumber === customerOrganizationIdentifier
    );
  }
};
