/**
 * User Onboarding
 * User Onboarding API provides functionality to manage users access, requests and invites. 
 *
 * OpenAPI spec version: 1.3.0
 * Contact: Platform_Core_Services_-_CAT@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * This represents the access type.
 */
export type AccessType = 'ACCESS_LEVEL' | 'DCI';

export const AccessType = {
    ACCESSLEVEL: 'ACCESS_LEVEL' as AccessType,
    DCI: 'DCI' as AccessType
};