import {
  customerAssociateActionNames,
  CustomerAssociateActionTypes,
  CustomerAssociateState,
} from './types';

const initialState: CustomerAssociateState = {};

const associateCustomersReducer = (
  state: CustomerAssociateState = initialState,
  action: CustomerAssociateActionTypes,
): CustomerAssociateState => {
  switch (action.type) {
    case customerAssociateActionNames.SET_LOADING_FOR_CUSTOMER: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case customerAssociateActionNames.SET_SELECTED_ASSOCIATE_CUSTOMER: {
      return {
        ...state,
        selectedAssociateCustomer: action.payload,
      };
    }
    case customerAssociateActionNames.SET_FETCH_CUSTOMER_ERROR: {
      return {
        ...state,
        fetchCustomerError: action.payload,
      };
    }

    case customerAssociateActionNames.SET_ASSOCIATED_CUSTOMER_ORGANIZATIONS: {
      return {
        ...state,
        associatedCustomerOrganizations: action.payload,
      };
    }
    case customerAssociateActionNames.RESET_ASSOCIATED_CUSTOMER_ORGANIZATIONS: {
      return {
        ...state,
        associatedCustomerOrganizations: undefined,
      };
    }
    case customerAssociateActionNames.SET_LOADING_FOR_SEARCH_CUSTOMER: {
      return {
        ...state,
        isLoadingCustomerSearch: action.payload,
      };
    }

    case customerAssociateActionNames.RESET_ASSOCIATE_CUSTOMER_STATE: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default associateCustomersReducer;
