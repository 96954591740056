import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../../../globals/color-properties';

export default makeStyles()((theme) => ({
  root: {
    paddingTop: theme.spacing(8),
    boxShadow: '0px 15px 40px rgb(67 72 92 / 20%)',
  },
  user: {
    paddingLeft: theme.spacing(2.5),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: CAT_COLOR.WHITE_SMOKE,
  },
  userOptions: {
    backgroundColor: theme.palette.primary.contrastText,
  },
  listRoot: {
    width: '100%',
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
  },
  link: {
    textDecoration: 'none',
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
  },
  listItemRoot: {
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      cursor: 'pointer',
    },
  },
  moreOptions: {
    fontSize: 30,
    color: theme.palette.primary.light,
    paddingBottom: 2,
  },
  userName: {
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
  },
  userEmail: {
    color: CAT_COLOR.MULLED_WINE,
  },
  userCompany: {
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
  },
  linkStyle: {
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
  },
}));
