import { Badge, Box, Button, Link, Typography } from '@mui/material';
import FilterIcon from '../../../../../assets/icons/filter-icon';
import TEXT from '../../../../../globals/translation-map';
import React, { useEffect, useState } from 'react';
import useStyles from '../styles';
import { useTranslation } from 'react-i18next';
import Dialog from '../../../../../components/dialog';
import FilterDialogForm from '../../filter-dialog-form';
import { UserBasedPermission } from '../../../../../entities/entitlements-v1/userBasedPermission';
import { PartyType } from '../../../../../entities/entitlements-v1/partyType';
import { useSelector } from 'react-redux';
import { AppState, useDispatchTs } from '../../../../../store';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FiltersDateType, userLeadsActionNames } from '../../../../../store/user-leads/types';

export type FormInputs = {
  selectedAccount: string;
  status: string[];
  dateRange: FiltersDateType;
};

const HeaderRightButtons = () => {
  const { t } = useTranslation();
  const dispatch = useDispatchTs();
  const { classes } = useStyles();
  const permissionsResponse = useSelector((state: AppState) => state.login.permissionsResponse);
  const filters = useSelector((state: AppState) => state.userLeads.filters);
  const isCatAdmin =
    (permissionsResponse as UserBasedPermission)?.primaryPartyType === PartyType.CAT;
  const [isUserLeadsFiltersOpen, setIsUserLeadsFiltersOpen] = useState<boolean>(isCatAdmin);

  const { handleSubmit, reset, watch, control, formState } = useForm<FormInputs>({
    defaultValues: {
      selectedAccount: filters?.selectedAccount || '',
      status: filters?.status || [],
      dateRange: {
        startDate: filters?.dateRange?.startDate,
        endDate: filters?.dateRange?.endDate,
        selectedButton: filters?.dateRange?.selectedButton,
      },
    },
  });

  useEffect(() => {
    reset(filters, { keepDefaultValues: true });
    if (!filters?.selectedAccount && !formState.isValid) setIsUserLeadsFiltersOpen(true);
  }, [filters]);

  const onSubmit: SubmitHandler<FormInputs> = (values: FormInputs) => {
    setIsUserLeadsFiltersOpen(false);
    dispatch({
      type: userLeadsActionNames.SET_USER_LEADS_FILTERS,
      payload: values,
    });
  };

  const handleDialogClose = () => {
    setIsUserLeadsFiltersOpen(false);
    reset(filters);
  };

  const handleClearAllFilters = () => {
    dispatch({ type: userLeadsActionNames.CLEAR_USER_LEADS_FILTERS });
  };

  return (
    <Box display="flex">
      <Box className={classes.rightButtonContainer}>
        <Box display="flex" alignItems="center">
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Box display="flex" alignItems="center">
              <Button
                disabled={false}
                data-testid="user-leads-filter-button"
                className={classes.filterButton}
                variant="contained"
                color="secondary"
                startIcon={
                  <Badge
                    color="secondary"
                    variant="dot"
                    data-testid="selected-filter-badge"
                    invisible={true}
                  >
                    <FilterIcon width="16px" height="16px" stroke="#43485C" />
                  </Badge>
                }
                onClick={() => setIsUserLeadsFiltersOpen(true)}
              >
                {t(TEXT.COMMON.FILTERS_BUTTON)}
              </Button>
            </Box>
          </Box>
          <Typography className={classes.dividerText} alignSelf="baseline">
            |
          </Typography>
          <Link
            variant="body2"
            data-testid="user-leads-clear-all-filters"
            onClick={handleClearAllFilters}
            underline="always"
          >
            {t(TEXT.COMMON.CLEAR_ALL)}
          </Link>
        </Box>
      </Box>
      <Dialog
        title={t(TEXT.USER_LEADS.FILTER_USER_LEADS)}
        isOpen={isUserLeadsFiltersOpen}
        onClose={handleDialogClose}
        onValidate={handleSubmit(onSubmit)}
        primaryButtonTitle={t(TEXT.COMMON.SAVE)}
        secondaryButtonTitle={t(TEXT.COMMON.CLOSE)}
        isPrimaryButtonDisabled={!formState.isValid}
      >
        <form>
          <FilterDialogForm control={control} watch={watch} />
        </form>
      </Dialog>
    </Box>
  );
};

export default HeaderRightButtons;
