/**
 * Entitlements
 * The API allows you to maintain data entitlements. Entitlements are granted  to users identified by `catrecid` through roles. Roles define allowed actions  for specific data domains and further down to individual fields based on party.  For Caterpillar users, entitlements can be restricted by region code. 
 *
 * OpenAPI spec version: 1.23.1
 * Contact: Platform_Core_Services_-_CAT@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Status of the invite.
 */
export type InviteStatus = 'Pending' | 'Accepted' | 'Revoked';

export const InviteStatus = {
    Pending: 'Pending' as InviteStatus,
    Accepted: 'Accepted' as InviteStatus,
    Revoked: 'Revoked' as InviteStatus
};