/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs, get financial account details, associate/disassociate financial products to a customer organization. 
 *
 * OpenAPI spec version: 1.41.2
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Dealer customer type.
 */
export type DealerCustomerType = 'REVENUE' | 'PROSPECT' | 'WARRANTY' | 'INTERNAL' | 'OTHER';

export const DealerCustomerType = {
    REVENUE: 'REVENUE' as DealerCustomerType,
    PROSPECT: 'PROSPECT' as DealerCustomerType,
    WARRANTY: 'WARRANTY' as DealerCustomerType,
    INTERNAL: 'INTERNAL' as DealerCustomerType,
    OTHER: 'OTHER' as DealerCustomerType
};