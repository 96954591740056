import React from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from './styles';

export type FooterProperties = {
  footerCount: number;
};

const Footer = (props: FooterProperties) => {
  const { footerCount } = props;
  const { classes } = useStyles();

  return (
    <Box component="div" display="flex" justifyContent="center" mt={2}>
      <Typography
        variant="h4"
        data-testid="remaining-items-count-as-footer"
        component="div"
        className={classes.remainingCountText}
      >
        {footerCount} items remaining
      </Typography>
    </Box>
  );
};

export default Footer;
