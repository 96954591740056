import { Box, Divider, IconButton, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SubHeader from '../../../../components/sub-header';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import StepperButton from '../../../../components/stepper-button';
import useStyles from './styles';
// uncomment when language selection reimplemented
// import Dropdown from '../../../../components/dropdown';
import { AppState, useDispatchTs } from '../../../../store';
import { useSelector } from 'react-redux';
import ToggleSwitch from '../../../../components/toggle-switch';
import { fetchGlobalPreferencesCache } from '../../../../store/global-preferences/actions';
import InformationIconPopOver from '../../../../components/information-icon-popover';
import AppStepper from '../../../../components/app-stepper';
import WarningModal, {
  MultipleUserWarningData,
} from '../../components/multiple-users-warning-modal';
import * as yup from 'yup';
import { Formik, FormikProps } from 'formik';
import EmailFields from '../../components/email-fields';
import UserIdentity, { IdentitySearchRequest } from '../../../../services/user-identity';
import { UserPropertiesForFiltering } from '../../../../entities/user-identity-v1/userPropertiesForFiltering';
import { IdentityCommonResponse } from '../../../../entities/user-identity-v1/identityCommonResponse';
import { invitationsActionNames } from '../../../../store/invitations/types';
import { getApplicationsCache } from '../../../../store/metadata/actions';
import { PermissionsHashMapForUserBased } from '../../../../entities/entitlements-v1/permissionsHashMapForUserBased';
import { UserBasedPermission } from '../../../../entities/entitlements-v1/userBasedPermission';
import { selectDataPermissionsByAccount } from '../../../../store/permissions/selectors';
import { INVITATION_APPLICATIONS } from '../../../../services/invitations';
import useCancelNotification from '../../../../hooks/cancel-notification';
import Dropdown from '../../../../components/dropdown';
import CAT_COLOR from '../../../../globals/color-properties';
import { showNotification } from '../../../../utils/util';
import { useTranslation } from 'react-i18next';
import TEXT from '../../../../globals/translation-map';
import { CustomerInfoWrapper } from '../../../my-customers/wrappers';
import { stepObject } from '../../../../globals/authentication/utils';
import { emailFormValidationSchema } from '../../../../utils/validations';

type AddUserEmailsProperties = {
  steps: Array<stepObject>;
};

type Field = {
  value: string;
};
type FormValues = {
  fields: Field[];
};

type PermissionsTypeUpdate = {
  [key: string]: { [key: string]: { applications: string[] } };
};

const AddUserEmails = (props: AddUserEmailsProperties) => {
  const { steps } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { languages, userPreferences, defaults, isLoadingGlobalPref } = useSelector(
    (state: AppState) => state.globalPreferences,
  );

  const dispatch = useDispatchTs();
  const { authInfo, environmentVariables, permissionsResponse, partiesResponse } = useSelector(
    (state: AppState) => state.login,
  );

  const {
    metadata: { applications },
  } = useSelector((state: AppState) => state.shared);

  const { invitationsFilters } = useSelector((state: AppState) => state.invitation);

  const { selectedCustomer } = useSelector((state: AppState) => state.invitation.createInvitations);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en-US');

  const viewPermissionAccounts = selectDataPermissionsByAccount(
    (permissionsResponse as UserBasedPermission)?.dataPermissions as PermissionsHashMapForUserBased,
  );

  const [switchToggle, setSwitchToggle] = useState<boolean>(true);
  const [isPccOnly, setIsPccOnly] = useState<boolean>(false);
  const [permissionSelectedAccount, setPermissionSelectedAccount] = useState<
    PermissionsHashMapForUserBased & PermissionsTypeUpdate
  >({});

  const [duplicateEmails, setDuplicateEmails] = useState<MultipleUserWarningData>({});

  const showAppStepper = location.pathname === '/invitations/add-emails';

  const handleCancel = () => {
    navigate('/invitations');
  };
  const cancelNotification = useCancelNotification({
    handleContinue: handleCancel,
  });

  useEffect(() => {
    dispatch(getApplicationsCache());
    if (invitationsFilters?.selectedAccount !== undefined) {
      setPermissionSelectedAccount(
        viewPermissionAccounts[
          invitationsFilters?.selectedAccount
        ] as unknown as PermissionsHashMapForUserBased & PermissionsTypeUpdate,
      );
    }
  }, []);

  useEffect(() => {
    const notPccApps = applications.filter(
      (app) =>
        app.applicationId !== INVITATION_APPLICATIONS.PCC &&
        app.applicationId !== INVITATION_APPLICATIONS.NONPRODPCC,
    );

    const isPCCView =
      permissionSelectedAccount?.invitations?.view?.applications?.length === 1 &&
      permissionSelectedAccount?.invitations?.view?.applications?.[0] ===
        INVITATION_APPLICATIONS.PCC;

    if (notPccApps.length === 0 || isPCCView) {
      setIsPccOnly(true);
    } else {
      setIsPccOnly(false);
    }
    setSwitchToggle(true);
  }, [permissionSelectedAccount, partiesResponse, applications]);

  useEffect(() => {
    if (userPreferences?.language) {
      setSelectedLanguage(userPreferences.language);
    } else if (defaults?.language) {
      setSelectedLanguage(defaults.language);
    }
  }, [userPreferences, defaults]);

  useEffect(() => {
    dispatch(fetchGlobalPreferencesCache());
    dispatch({
      type: invitationsActionNames.SET_IS_USERS_PRIMARY_PARTY,
      payload: switchToggle,
    });
  }, []);

  const handleLanguageChange = (selectedValue: string) => {
    setSelectedLanguage(selectedValue);
  };

  const handleToggleSwitch = () => {
    dispatch({
      type: invitationsActionNames.SET_IS_USERS_PRIMARY_PARTY,
      payload: !switchToggle,
    });
    setSwitchToggle(!switchToggle);
  };

  yup.addMethod(yup.array, 'duplicateCheck', function (field, message) {
    return this.test('duplicateCheck', message, function (array: any) {
      // eslint-disable-next-line unicorn/prefer-spread
      const uniqueData = Array.from(
        new Set(array.map((row: any) => row[field]?.toLocaleLowerCase())),
      );
      const isUnique = array.length === uniqueData.length;
      if (isUnique) {
        return true;
      }
      const index = array.findIndex(
        (row: any, i: number) => row[field]?.toLocaleLowerCase() !== uniqueData[i],
      );
      if (array[index]?.field === '') {
        return true;
      }
      return this.createError({
        path: `${this.path}.${index}.${field}`,
        message,
      });
    });
  });

  const handleNext = () => {
    let selectAccessUrl = '/invitations/select-application-access';
    if (location.pathname.includes('my-customer')) {
      selectAccessUrl = '/my-customers/invitations/select-application-access';
    }
    dispatch({
      type: invitationsActionNames.SET_LANGUAGE,
      payload: selectedLanguage,
    });
    navigate(selectAccessUrl);
  };

  const handleContinue = async (emails: string[]) => {
    const userIdentity = new UserIdentity(authInfo, environmentVariables.userIdentityV1);
    const input: IdentitySearchRequest = {
      body: {
        filter: {
          propertyName: UserPropertiesForFiltering.Email,
          type: 'stringEquals',
          values: emails,
        },
        responseAttributes: ['email', 'username'],
      },
    };
    try {
      const response = await userIdentity.getIdentities(input);

      const result: MultipleUserWarningData = {};
      /* eslint-disable unicorn/no-array-for-each */
      response.data.forEach((user: { username?: string } & IdentityCommonResponse) => {
        // filter out items with empty username
        if (!!user.username) {
          const email = user.email?.toLocaleLowerCase() as string;
          const {
            username: cwsId,
            // eslint-disable-next-line @typescript-eslint/no-shadow
            ...userIdentity
          }: { username?: string } & IdentityCommonResponse = {
            ...user,
          };
          if (!result[email]) {
            result[email] = [];
          }

          result[email].push({ ...userIdentity, cwsId });
        }
      });
      const filtered: MultipleUserWarningData = {};
      const filteredEmails = Object.keys(result).filter((email) => result[email].length > 1);
      for (const email of filteredEmails) {
        filtered[email] = result[email];
      }
      const emailDetails = emails.map((key) => {
        const lowercaseKey = key.toLocaleLowerCase();
        const details = result[lowercaseKey];
        return {
          emailAddress: lowercaseKey,
          username: details && details.length > 0 ? details[0].cwsId : undefined,
        };
      });
      dispatch({
        type: invitationsActionNames.SET_ACCOUNTS,
        payload: emailDetails,
      });
      if (Object.keys(filtered).length > 0) {
        setDuplicateEmails(filtered);
      } else {
        handleNext();
      }
    } catch (error: any) {
      if (400 === error?.response?.status) {
        showNotification(dispatch, 'error', t(TEXT.COMMON.ERRORS.FETCH_USERS));
      }
      dispatch({
        type: invitationsActionNames.SET_ACCOUNTS,
        payload: emails.map((key) => {
          const lowercaseKey = key.toLocaleLowerCase();
          return {
            emailAddress: lowercaseKey,
          };
        }),
      });
      handleNext();
    }
  };

  const getStepContent = () => {
    return (
      <Box className={classes.stepContainer} data-testid="step-content-container">
        <CustomerInfoWrapper
          selectedCompany={selectedCustomer}
          customerNameDataTestId="add-user-emails-customer-name"
          customerIdDataTestId="add-user-emails-customer-id"
        />
        <Box className={classes.selectLanguage}>
          <Typography variant="h4" className={classes.sectionHeader}>
            {t(TEXT.COMMON.SELECT_LANGUAGE)}
          </Typography>
          <Typography variant="h5" className={classes.sectionSubheader}>
            {t(TEXT.INVITATIONS.ADD_USER_EMAILS.INVITE_LANGUAGE)}
          </Typography>
          <Box mt={4}>
            <Dropdown
              label={t(TEXT.COMMON.LANGUAGE)}
              dropdownValues={languages}
              callbackFunction={handleLanguageChange}
              showNoneOption={false}
              selectedValue={selectedLanguage}
              customClass={classes.languageDropdown}
            />
          </Box>
        </Box>
        <Box className={classes.enterEmailSection}>
          <Typography variant="h4" className={classes.sectionHeader}>
            {t(TEXT.INVITATIONS.ADD_USER_EMAILS.ENTER_EMAILS)}
          </Typography>
          <Typography variant="h6" className={classes.sectionSubheader}>
            {t(TEXT.INVITATIONS.ADD_USER_EMAILS.MAX_EMAILS)}
          </Typography>
          <Box mb={3} fontStyle="italic">
            <Typography variant="body2" textAlign="left">
              {t(TEXT.INVITATIONS.ADD_USER_EMAILS.DISCLAIMER)}
            </Typography>
          </Box>
          <Box className={classes.toggleSwitchContainer}>
            <Box className={classes.infoIconContainer}>
              <InformationIconPopOver
                width="15px"
                height="15px"
                title={t(TEXT.INVITATIONS.ADD_USER_EMAILS.WORK_FOR_ABOVE_ASSOCIATED)}
                shape="info"
                marginTop="2px"
                iconColor={CAT_COLOR.BLACK_OLIVE}
              />
            </Box>
            <ToggleSwitch
              label={
                <Box color={CAT_COLOR.BLACK_OLIVE}>
                  {t(TEXT.INVITATIONS.ADD_USER_EMAILS.WORK_FOR_ABOVE)}
                </Box>
              }
              toggleSwitchCallback={handleToggleSwitch}
              disabled={isPccOnly}
              checked={switchToggle}
              labelPlacement="start"
              customClass={classes.customLabel}
              blocks={environmentVariables.blocksComponents}
            />
          </Box>
          <Box className={classes.emailContainer}>
            <Formik
              initialValues={{
                fields: [{ value: '' }],
              }}
              validationSchema={emailFormValidationSchema}
              validateOnChange
              validateOnBlur
              onSubmit={(values) => {
                handleContinue(values.fields.map(({ value }) => value));
              }}
            >
              {(formProps: FormikProps<FormValues>) => {
                const isApplyDisabled = formProps.isValid
                  ? !formProps.dirty && formProps.isValid
                  : !formProps.isValid;

                return (
                  <form onSubmit={formProps.handleSubmit}>
                    <EmailFields />
                    <Box
                      bottom={0}
                      right={0}
                      width="100%"
                      height={theme.spacing(10)}
                      mr={2}
                      pb={2}
                      className={classes.footerActions}
                      position="fixed"
                    >
                      <Divider className={classes.divider} />
                      <Box display="flex" justifyContent="flex-end" mt={2} mb={1} width="100%">
                        <Box mr={2}>
                          <StepperButton
                            buttonText={t(TEXT.COMMON.CANCEL)}
                            id="cancel-invite-users"
                            color="secondary"
                            type="button"
                            onClick={() => cancelNotification(true)}
                          />
                        </Box>
                        <Box mr={2}>
                          <StepperButton
                            buttonText={t(TEXT.COMMON.NEXT)}
                            id="next-invite-users"
                            color="primary"
                            type="submit"
                            disabled={isApplyDisabled}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </form>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box data-testid="add-user-emails-page">
      <SubHeader
        title={showAppStepper ? t(TEXT.INVITATIONS.TITLE.INVITE_USER) : ''}
        titleText={showAppStepper ? t(TEXT.INVITATIONS.STEPS.ADD_USER_EMAILS) : ''}
        data-testid="add-user-emails-header"
        actionComponents={[
          <IconButton
            onClick={() => cancelNotification(true)}
            data-testid="add-user-emails-close-icon"
            size="large"
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
      <Divider />
      {steps && isLoadingGlobalPref === false && (
        <Box mt={theme.spacing(1)}>
          <AppStepper
            steps={steps}
            getStepContent={getStepContent}
            activeStep={1}
            blocks={environmentVariables.blocksComponents}
          />
        </Box>
      )}
      <WarningModal
        data={duplicateEmails}
        onCancel={() => setDuplicateEmails({})}
        onContinue={() => handleNext()}
      />
    </Box>
  );
};

export default AddUserEmails;
