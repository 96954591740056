import React, { ReactElement, FC } from 'react';
import { Box, Grid, Step, StepLabel, Stepper } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { stepObject } from '../../globals/authentication/utils';

export type AppStepperProperties = {
  steps: Array<stepObject>;
  getStepContent: (stepIndex?: number) => string | ReactElement;
  activeStep: number;
  noResults?: boolean;
  blocks?: boolean;
};

const AppStepper: FC<AppStepperProperties> = (props: AppStepperProperties) => {
  const { activeStep, steps, getStepContent, noResults } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const StepIcon = (index: number) => {
    if (
      index === activeStep &&
      activeStep !== steps.length - 1 &&
      !(index === activeStep && noResults)
    ) {
      return <FiberManualRecordOutlinedIcon className={classes.currentStep} />;
    } else if (
      (index === activeStep && noResults) ||
      index < activeStep ||
      activeStep === steps.length - 1
    ) {
      return <CheckCircleIcon className={classes.completedIcon} />;
    }

    return <FiberManualRecordIcon className={classes.pendingSteps} />;
  };

  return (
    <>
      <Grid container alignItems="center" data-testid="app-stepper-label">
        <Grid xs={2} item />
        <Grid xs={8} item>
          <Stepper
            style={{ padding: 24 }}
            activeStep={activeStep}
            alternativeLabel
            className={classes.stepStyle}
          >
            {steps.map((step: stepObject, index: number) => (
              <Step key={t(step.stepLabel)}>
                <StepLabel icon={StepIcon(index)}>{t(step.stepLabel)}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid xs={2} item />
      </Grid>
      <Box data-testid="app-stepper-content-container">
        <Grid container className={classes.customerDetails}>
          <Grid xs={1} item />
          <Grid xs={10} item>
            <Box display="flex" justifyContent="center">
              {getStepContent(activeStep)}
            </Box>
          </Grid>
          <Grid xs={1} item />
        </Grid>
      </Box>
    </>
  );
};
export default AppStepper;
