import { default as Axios, AxiosResponse, CancelToken } from 'axios';
import { AuthInfo } from '../model/types';

class UserPreferences {
  private authInfo: AuthInfo | null;

  private baseUrl: string;

  constructor(authInfo: AuthInfo | null, baseUrl: string) {
    this.authInfo = authInfo;
    this.baseUrl = baseUrl;
  }

  getCustomPreferences(cancelToken?: CancelToken): Promise<AxiosResponse<any>> {
    const token = this.authInfo ? this.authInfo.token : '';
    return Axios.get(
      `${this.baseUrl}/customUserPreferences/namespace/customerMaster?privacyLevel=private`,
      {
        cancelToken,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  setCustomPreferences(input: any, cancelToken?: CancelToken): Promise<AxiosResponse<any>> {
    const token = this.authInfo ? this.authInfo.token : '';
    return Axios.patch(
      `${this.baseUrl}/customUserPreferences/namespace/customerMaster?privacyLevel=private`,
      input,
      {
        cancelToken,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/merge-patch+json',
        },
      },
    );
  }

  getGlobalPreferences(cancelToken?: CancelToken): Promise<AxiosResponse<any>> {
    const token = this.authInfo ? this.authInfo.token : '';
    return Axios.get(`${this.baseUrl}/globalPreferences?countryCode=US`, {
      cancelToken,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getUserGlobalPreferences(cancelToken?: CancelToken): Promise<AxiosResponse<any>> {
    const token = this.authInfo ? this.authInfo.token : '';
    return Axios.get(`${this.baseUrl}/globalPreferences/user`, {
      cancelToken,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default UserPreferences;
