import { default as Axios, AxiosResponse } from 'axios';
import { ErrorResponse } from '../entities/customer-master-v1/models';
import { AuthInfo } from '../model/types';
import { FiltersIncludingResponseAttr } from '../entities/dealer-details-v1/filtersIncludingResponseAttr';
import { DealerSearchResponse } from '../entities/dealer-details-v1/dealerSearchResponse';

class DealerDetails {
  private authInfo: AuthInfo | null;

  private baseUrl: string;

  constructor(authInfo: AuthInfo | null, baseUrl: string) {
    this.authInfo = authInfo;
    this.baseUrl = baseUrl;
  }
  searchDealerDetails(
    input: DealerDetailsSearchRequest,
  ): Promise<AxiosResponse<DealerSearchResponse | ErrorResponse>> {
    const { body, ...queryParameters } = input;
    const token = this.authInfo ? this.authInfo.token : '';

    return Axios.post<DealerSearchResponse | ErrorResponse>(
      `${this.baseUrl}/dealers/search`,
      body,
      {
        params: queryParameters,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
}
export type DealerDetailsSearchRequest = {
  body: FiltersIncludingResponseAttr;
  sortBy?: string;
  orderBy?: string;
  cursor?: string;
  limit?: number;
  skip?: number;
};
export default DealerDetails;
