import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  chipBox: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(0.5),
    backgroundColor: CAT_COLOR.WHITE_SMOKE,
    height: '29px',
    borderRadius: '4px',
  },
  chipBoxPadding: {
    padding: '6px 0px 6px 7px',
  },
  chipBoxPaddingWithoutRemove: {
    padding: '6px 6px 6px 7px',
    marginLeft: 0,
    marginRight: theme.spacing(0.5),
  },
  truncate: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginTop: '4px',
  },
}));
