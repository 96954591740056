import { UserAccessRequestStatus } from '../../../entities/user-onboarding-v1/userAccessRequestStatus';
import { AccessRequestTimeRangeFilter } from '../../../entities/user-onboarding-v1/accessRequestTimeRangeFilter';

const { PropertyNameEnum } = AccessRequestTimeRangeFilter;
export const AccessRequestsStatusValues = {
  [UserAccessRequestStatus.APPROVED]: PropertyNameEnum.ApprovedRequests,
  [UserAccessRequestStatus.DENIED]: PropertyNameEnum.DeniedRequests,
  [UserAccessRequestStatus.FAILED]: PropertyNameEnum.FailedRequests,
  [UserAccessRequestStatus.PENDING]: PropertyNameEnum.PendingRequests,
  [UserAccessRequestStatus.PROCESSING]: PropertyNameEnum.ProcessingRequests,
};
