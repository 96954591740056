import { SecondaryFilterType } from '../../pages/my-customers/components/advance-filters/types';
import { FiltersState, FiltersActionTypes, filtersActionNames } from './types';

const initialState: FiltersState = {
  searchBarText: undefined,
  sortByRules: ['updateTime'],
  orderByRules: ['DESC'],
  hasAssociationToMyDealerCodes: true,
  primaryFilterRules: [],
  secondaryFilterRules: [],
  primaryConditionalOperator: '|',
};

const filtersReducer = (
  state: FiltersState = initialState,
  action: FiltersActionTypes,
): FiltersState => {
  switch (action.type) {
    case filtersActionNames.SET_PRIMARY_FILTER_VALUE: {
      return {
        ...state,
        primaryFilterRules: action.payload,
      };
    }
    case filtersActionNames.SET_SECONDARY_FILTER_VALUE: {
      return {
        ...state,
        secondaryFilterRules: action.payload,
      };
    }
    case filtersActionNames.DELETE_SECONDARY_FILTER_VALUE_BY_TYPE: {
      let updatedSecondaryFilters;
      // custom filter removal based on types
      switch (action.payload.propertyName) {
        case SecondaryFilterType.countryCode:
          updatedSecondaryFilters = state.secondaryFilterRules.filter(
            (item) =>
              item.propertyName !== action.payload.propertyName &&
              item.propertyName !== SecondaryFilterType.stateOrProvinceCode,
          );
          break;
        case SecondaryFilterType.dealerCode:
          updatedSecondaryFilters = state.secondaryFilterRules
            .map((item: any) =>
              item.propertyName === action.payload.propertyName
                ? {
                    ...item,
                    values: item.values.filter(
                      (value: string) => value !== action.payload.propertyValue,
                    ),
                  }
                : item,
            )
            .filter((item) => !item.values || (item.values && item.values.length !== 0));
          break;
        case SecondaryFilterType.status:
          updatedSecondaryFilters = state.secondaryFilterRules
            .map((item: any) =>
              item.propertyName === action.payload.propertyName
                ? {
                    ...item,
                    values: item.values.filter(
                      (value: string) => value !== action.payload.propertyValue,
                    ),
                  }
                : item,
            )
            .filter((item) => !item.values || (item.values && item.values.length !== 0));
          break;
        default:
          updatedSecondaryFilters = state.secondaryFilterRules.filter(
            (item) => item.propertyName !== action.payload.propertyName,
          );
      }

      return {
        ...state,
        secondaryFilterRules: updatedSecondaryFilters,
      };
    }

    case filtersActionNames.CLEAR_PRIMARY_FILTER_VALUE: {
      return {
        ...state,
        primaryFilterRules: [],
      };
    }
    case filtersActionNames.CLEAR_SECONDARY_FILTER_VALUE: {
      return {
        ...state,
        secondaryFilterRules: [],
      };
    }
    case filtersActionNames.SET_PRIMARY_CONDITIONAL_OPERATOR: {
      return {
        ...state,
        primaryConditionalOperator: action.payload,
      };
    }
    case filtersActionNames.CLEAR_PRIMARY_CONDITIONAL_OPERATOR: {
      return {
        ...state,
        primaryConditionalOperator: initialState.primaryConditionalOperator,
      };
    }
    case filtersActionNames.SET_SORT_BY_VALUE: {
      return {
        ...state,
        sortByRules: action.payload,
      };
    }
    case filtersActionNames.SET_ORDER_BY_VALUE: {
      return {
        ...state,
        orderByRules: action.payload,
      };
    }
    case filtersActionNames.SET_GLOBAL_SEARCH_FLAG: {
      return {
        ...state,
        hasAssociationToMyDealerCodes: action.payload,
      };
    }
    default:
      return state;
  }
};

export default filtersReducer;
