import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  title: {
    marginTop: theme.spacing(0.38),
  },
  titleText: {
    fontSize: 16,
    lineHeight: 1.7,
    marginLeft: 4,
  },
  container: {
    borderBottom: `1px solid ${CAT_COLOR.MEDIUM_GRAY}`,
  },
  backButton: {
    display: 'flex',
    color: CAT_COLOR.WATER_BLUE,
    marginTop: theme.spacing(0.38),
    marginRight: theme.spacing(1),
  },
  iconContainer: {
    color: CAT_COLOR.WATER_BLUE,
    display: 'inline-block',
    marginTop: theme.spacing(0.25),
  },
  spacer: {
    fontWeight: 'bold',
    marginTop: theme.spacing(0.48),
    marginRight: theme.spacing(1),
  },
  backContainer: {
    display: 'flex',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  flex: { display: 'flex' },
}));
