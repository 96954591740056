/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs, get financial account details, associate/disassociate financial products to a customer organization.
 *
 * OpenAPI spec version: 1.41.2
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OrganizationResponse } from './organizationResponse';
import { PartialFailureCode } from './partialFailureCode';
import { PartialFailureDescription } from './partialFailureDescription';
import { RecommendationDealerCustomerPartialResponse } from './recommendationDealerCustomerPartialResponse';
import { RecommendationItemPartialFailureErrorAttributes } from './recommendationItemPartialFailureErrorAttributes';
import { RecommendationTypeLabel } from './recommendationTypeLabel';
import { RecommendedDealerCustomerDetails } from './recommendedDealerCustomerDetails';

/**
 * Partial failure response for recommendation item of type \"ASSOCIATE MULTIPLE DCN\".
 */
export interface AssociateMultipleDcnPartialFailureResponse {
  code?: PartialFailureCode;
  description?: PartialFailureDescription;
  recommendedCustomerOrganization?: OrganizationResponse;
  /**
   * The recommendation relationship type represents the various types of recommendation.
   */
  recommendationType?: AssociateMultipleDcnPartialFailureResponse.RecommendationTypeEnum;
  recommendationTypeLabel?: RecommendationTypeLabel;
  /**
   * A collection of configs with successful items and additional error details for failures.
   */
  recommendedAssociations?: Array<
    | RecommendedDealerCustomerDetails
    | (RecommendationDealerCustomerPartialResponse &
        RecommendationItemPartialFailureErrorAttributes)
  >;
}
export namespace AssociateMultipleDcnPartialFailureResponse {
  export type RecommendationTypeEnum = 'ASSOCIATE MULTIPLE DCN';
  export const RecommendationTypeEnum = {
    ASSOCIATEMULTIPLEDCN: 'ASSOCIATE MULTIPLE DCN' as RecommendationTypeEnum,
  };
}
