/* eslint-disable unicorn/no-array-push-push */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Divider,
  IconButton,
  useTheme,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AppState, useDispatchTs } from '../../store';
import StepperButton from '../stepper-button';
import useCancelNotification from '../../hooks/cancel-notification';
import SearchCustomer from '../../pages/associate-customer/pages/manual/form-components/search-customer';
import {
  BooleanEqualsFilter,
  ContainsFilter,
  CustomerStatus,
  EndsWithFilter,
  FuzzyMatchFilter,
  OrganizationResponse,
  SearchOrganizationResponse,
  StartsWithFilter,
  StringEqualsFilter,
  TimeRangeFilter,
} from '../../entities/customer-master-v1/models';
import TransferTable, { WarningType } from '../transfer-table';
import TransferTableItem from '../../pages/associate-customer/pages/manual/components/transfer-table-item';
import CustomerOrganization, {
  CustomerOrganizationSearchRequest,
  CustomerOrganizationSearchResponse,
} from '../../services/customer-organization';
import useStyles from './styles';
import { PartyType } from '../../entities/entitlements-v1/partyType';
import { useTranslation } from 'react-i18next';
import { showNotification } from '../../utils/util';
import TEXT from '../../globals/translation-map';

type SelectCustomerProperties = {
  applyText: string;
  cancelCallback?: Function;
  customTitleText?: string;
  handleContinue: Function;
  isDialog?: boolean;
  limit: number;
  selectedAccount?: string;
  selectedCompany?: OrganizationResponse;
  selectedCustomers?: Array<OrganizationResponse>;
  setShowCancelNotification?: Function;
  suppressCancelNotification?: boolean;
};

const SelectCustomers = (properties: SelectCustomerProperties) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    applyText,
    cancelCallback,
    customTitleText,
    handleContinue,
    isDialog,
    limit,
    selectedAccount,
    selectedCompany,
    selectedCustomers,
    setShowCancelNotification,
    suppressCancelNotification,
  } = properties;
  const dispatch = useDispatchTs();
  const theme = useTheme();

  const { environmentVariables } = useSelector((state: AppState) => state.login);
  const [customerName, setCustomerName] = useState<string>();
  const [customerNumber, setCustomerNumber] = useState<string>();
  const [customers, setCustomers] = useState<Array<OrganizationResponse>>([]);
  const [openWarningFlag, setOpenWarningFlag] = useState<boolean>(false);
  const [warning, setWarning] = useState<WarningType>();
  const [nextCursor, setNextCursor] = useState<string>();
  const [isLoadingData, setIsLoadingDataFlag] = useState<boolean>(false);
  const [searchCriterionPresent, setSearchCriterionPresent] = useState<boolean>(false);
  const [customersToSelect, setCustomersToSelect] = useState<Array<OrganizationResponse>>(
    selectedCustomers || [],
  );

  const revertSelectedCustomers = () => {
    handleContinue(selectedCustomers);
  };
  const setVisibility = useCancelNotification({
    handleContinue: revertSelectedCustomers,
  });
  const cancelNotification = () => {
    if (!suppressCancelNotification) {
      if (customersToSelect.length > 0) {
        setVisibility(true);
      } else if (searchCriterionPresent) {
        setVisibility(true);
      } else {
        (cancelCallback && cancelCallback()) || handleContinue();
      }
    } else {
      handleContinue();
    }
  };

  useEffect(() => {
    if (setShowCancelNotification) {
      if (customersToSelect.length > 0) {
        setShowCancelNotification(true);
      } else if (searchCriterionPresent) {
        setShowCancelNotification(true);
      } else {
        setShowCancelNotification(false);
      }
    }
  }, [customersToSelect, searchCriterionPresent]);

  const handleCustomerSearch = async (input: {
    inputCustomerName?: string;
    inputCustomerNumber?: string;
    isNewSearch?: boolean;
  }) => {
    const { inputCustomerName, inputCustomerNumber, isNewSearch } = input;
    const conditions: Array<
      | ContainsFilter
      | FuzzyMatchFilter
      | StringEqualsFilter
      | StartsWithFilter
      | EndsWithFilter
      | TimeRangeFilter
      | BooleanEqualsFilter
    > = [];
    let sortBy = '';
    let orderBy = '';
    let logicExpression = '';
    setCustomerNumber(inputCustomerNumber);
    setCustomerName(inputCustomerName);

    if (selectedAccount && selectedAccount !== PartyType.CAT) {
      conditions.push(
        {
          propertyName: 'dealerCode',
          type: 'stringEquals',
          values: [selectedAccount],
        },
        {
          propertyName: 'hasAssociationToMyDealerCodes',
          type: 'booleanEquals',
          value: true,
        },
      );
      logicExpression = '$0 & $1 & $2 | $3 | $4 | $5';
    } else {
      logicExpression = '$0 | $1 | $2 | $3';
    }

    if (inputCustomerName) {
      setSearchCriterionPresent(true);

      conditions.push(
        {
          propertyName: 'customerOrganizationBusinessName',
          type: 'contains',
          value: `${inputCustomerName}`,
        },
        {
          propertyName: 'customerOrganizationIdentifier',
          type: 'contains',
          value: `${inputCustomerName}`,
        },
        {
          propertyName: 'associatedDealerCustomerBusinessName',
          type: 'contains',
          value: `${inputCustomerName}`,
        },
        {
          propertyName: 'associatedDealerCustomerNumber',
          type: 'contains',
          value: `${inputCustomerName}`,
        },
      );

      sortBy += 'customerOrganizationBusinessName,customerOrganizationIdentifier,updateTime';
      orderBy += 'PARTIAL_MATCH_RELEVANCE,PARTIAL_MATCH_RELEVANCE,DESC';
    }

    let logicalExpression = '';
    /* eslint-disable unicorn/no-array-for-each */
    conditions.forEach((condition, index) => {
      index + 1 < conditions.length
        ? (logicalExpression = `${logicalExpression}$${index} | `)
        : (logicalExpression = `${logicalExpression}$${index}`);
    });
    setIsLoadingDataFlag(true);
    const customerOrganization = new CustomerOrganization(environmentVariables.customerMasterURI);
    const searchCustomerOrganizationRequest: CustomerOrganizationSearchRequest = {
      body: {
        logicalExpression: logicExpression,
        filters: conditions,
      },
      sortBy: sortBy,
      orderBy: orderBy,
      limit: 50,
    };

    if (nextCursor && nextCursor !== '' && !isNewSearch) {
      searchCustomerOrganizationRequest.cursor = nextCursor;
    }

    try {
      const response = await customerOrganization.searchByCustomerOrganization(
        searchCustomerOrganizationRequest,
      );
      if (response.status === 200) {
        if (
          (response.data as CustomerOrganizationSearchResponse).customerOrganizations.length > 0
        ) {
          if (isNewSearch) {
            setCustomers(
              (response.data as CustomerOrganizationSearchResponse).customerOrganizations,
            );
          } else {
            setCustomers([
              ...customers,
              ...(response.data as CustomerOrganizationSearchResponse).customerOrganizations,
            ]);
          }
          setNextCursor(
            (response.data as CustomerOrganizationSearchResponse).responseMetadata?.nextCursor,
          );
        }
      } else {
        showNotification(dispatch, 'error', t(TEXT.COMMON.ERRORS.CUSTOMER_SEARCH));
      }
    } catch {
      showNotification(dispatch, 'error', t(TEXT.COMMON.ERRORS.CUSTOMER_SEARCH));
    }
    setIsLoadingDataFlag(false);
  };

  const handleAddCustomer = async (item: OrganizationResponse, hideWarningFlag?: boolean) => {
    return new Promise<boolean>(async (resolve) => {
      let continueExecutionFlag = true;
      if (
        item?.customerOrganizationDetails?.locked === true ||
        [CustomerStatus.OBSOLETE, CustomerStatus.DRAFT, CustomerStatus.INACTIVE].includes(
          item?.customerOrganizationDetails?.status as CustomerStatus,
        )
      ) {
        setOpenWarningFlag(true);
        let warningText: string = '';
        if (item?.customerOrganizationDetails?.locked) {
          warningText =
            'You are not authorized to perform this customer association because it is locked (due to previous consolidation).';
        } else {
          switch (item?.customerOrganizationDetails?.status) {
            case CustomerStatus.OBSOLETE:
              warningText = 'You cannot add this Customer because it is marked as Obsolete status.';
              break;
            case CustomerStatus.DRAFT:
              warningText = 'You cannot add this Customer because it is marked as Prospect status.';
              break;
            case CustomerStatus.INACTIVE:
              warningText = 'You cannot add this Customer because it is marked as Inactive status.';
              break;
          }
        }
        setWarning({
          text: warningText,
          cancelButtonText: t(TEXT.COMMON.OK),
          cancelCallback: () => {
            setOpenWarningFlag(false);
          },
        });

        setOpenWarningFlag(true);
        continueExecutionFlag = false;
      } else if (item?.customerOrganizationDetails?.updateTime) {
        setCustomersToSelect([...customersToSelect, item]);
      }
      resolve(continueExecutionFlag);
    });
  };

  const handleRemoveCustomer = (item: SearchOrganizationResponse) => {
    return new Promise<boolean>((resolve) => {
      const indexOfRemovedCustomer = customersToSelect.findIndex(
        (customer) =>
          customer.customerOrganizationDetails?.customerOrganizationIdentifier ===
          item?.customerOrganizationDetails?.customerOrganizationIdentifier,
      );

      if (indexOfRemovedCustomer !== -1) {
        setCustomersToSelect([
          ...customersToSelect.slice(0, indexOfRemovedCustomer),
          ...customersToSelect.slice(indexOfRemovedCustomer + 1),
        ]);
      }
      resolve(true);
    });
  };
  const handleRemoveAllCustomers = () => {
    return new Promise<boolean>((resolve) => {
      setCustomersToSelect([]);
      resolve(true);
    });
  };
  const handleSelectCustomers = () => {
    handleContinue(customersToSelect);
  };
  return (
    <Box>
      {isDialog && (
        <DialogTitle id="select-customers-dialog-title">
          <Typography variant="h3" component="span" className={classes.titleText}>
            {customTitleText ?? t(TEXT.COMMON.SELECT_CUSTOMERS)}
          </Typography>
          <IconButton
            className={classes.iconPosition}
            data-testid="cancel-notification-button"
            onClick={() => cancelNotification()}
            size="large"
            color="primary"
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </DialogTitle>
      )}

      <DialogContent>
        <SearchCustomer
          selectedCompany={selectedCompany || {}}
          handleCustomerSearch={handleCustomerSearch}
          setCustomers={setCustomers}
          setNextCursor={setNextCursor}
          isLoadingForData={isLoadingData}
          hideParentCustomer={true}
        />
        <Box alignItems="center" data-testid="select-customer-container">
          <Box mt={4}>
            <TransferTable
              ItemComponent={TransferTableItem}
              itemComponentHeight={70}
              items={customers}
              alreadyAddedItems={customersToSelect}
              primaryKeyForItem="customerOrganizationDetails.customerOrganizationIdentifier"
              hasNextPageForItems={!!(nextCursor && nextCursor !== '')}
              nextPageCallbackFunction={() =>
                handleCustomerSearch({
                  inputCustomerName: customerName,
                  inputCustomerNumber: customerNumber,
                })
              }
              onClickOfActionOnSearchedItem={handleAddCustomer}
              onClickOfActionOnAddedItem={handleRemoveCustomer}
              onClickOfClearAllOnSearchedItemsList={() => {
                setCustomers([]);
                setNextCursor('');
              }}
              onClickOfClearAllOnAddedItemsList={handleRemoveAllCustomers}
              isLoadingDataForItems={isLoadingData}
              noSearchItemFoundText={t(TEXT.COMMON.NO_MATCHING_CUSTOMERS_FOUND)}
              maxNumberOfAddedItems={limit}
              openWarning={openWarningFlag}
              warning={warning}
              listWidth="unset"
              containerClass=""
            />
          </Box>
        </Box>
      </DialogContent>
      <Box
        bottom={0}
        right={0}
        width="100%"
        height={theme.spacing(9)}
        className={isDialog ? classes.dialogfooterActions : classes.footerActions}
      >
        <Divider className={classes.divider} />
        <DialogActions>
          <Box display="flex" justifyContent="flex-end" mt={1} mb={1} width="100%">
            <Box mr={2}>
              <StepperButton
                buttonText={t(TEXT.COMMON.CANCEL)}
                id="cancel-select-customer"
                color="secondary"
                onClick={() => cancelNotification()}
              />
            </Box>
            <Box mr={2}>
              <StepperButton
                buttonText={applyText}
                id="save-button"
                disabled={customersToSelect.length === 0}
                color="primary"
                type="button"
                onClick={() => handleSelectCustomers()}
              />
            </Box>
          </Box>
        </DialogActions>
      </Box>
    </Box>
  );
};
export default SelectCustomers;
