import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../../../globals/color-properties';

export default makeStyles()((theme) => ({
  filterButton: {
    padding: theme.spacing(0.75),
    border: 'none',
    fontWeight: 100,
    '&:disabled': {
      border: 'none',
      backgroundColor: CAT_COLOR.WHITE,
    },
    '&:hover': {
      border: 'none',
      backgroundColor: CAT_COLOR.WHITE_SMOKE,
    },
  },
  dividerText: {
    marginTop: theme.spacing(1.25),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  filterByText: {
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
    fontWeight: 600,
  },
  iconPosition: {
    position: 'absolute',
    right: '1%',
    top: theme.spacing(1),
  },
  closeIcon: {
    color: CAT_COLOR.BLACK,
  },
  dialogContent: {
    padding: 0,
  },
  footerSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  header: {
    fontSize: '19px',
    fontStyle: 'normal',
    fontWeight: 600,
  },
  subheader: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
  },
}));
