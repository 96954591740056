import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  textField: {
    width: '75%',
    '& input': {
      fontSize: '12px',
    },
    '& textarea': {
      fontSize: '12px',
      height: '1.4375em',
    },
  },
  counter: {
    textAlign: 'left',
    marginTop: '10px',
    fontSize: '12px',
  },
  clearIcon: {
    color: CAT_COLOR.LIGHT_SLATE_GREY,
    width: '.5em',
    height: '.5em',
  },
  searchIcon: {
    color: CAT_COLOR.MULLED_WINE,
  },
  cancelIcon: {
    color: CAT_COLOR.BLACK,
  },
}));
