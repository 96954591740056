import { useMemo } from 'react';
import { AppState } from '../../store';
import { useSelector } from 'react-redux';

export enum UI_BLOCKS {
  SPINNER = 'SPINNER',
  BUTTON = 'BUTTON',
  BREADCRUMBS = 'BREADCRUMBS',
  TOAST = 'TOAST',
  RADIO = 'RADIO',
}

export const useUIBlocks = (blockName: UI_BLOCKS): boolean => {
  const uiBlocksComponents = useSelector(
    (state: AppState) => state.login.environmentVariables?.uiBlocksComponents,
  );

  return useMemo(() => {
    return uiBlocksComponents?.includes(blockName);
  }, [blockName, uiBlocksComponents]);
};
