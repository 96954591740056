import { default as Axios,AxiosResponse, CancelToken } from 'axios';
import { IdentitySearchResponse, UserSearchFilters } from '../entities/user-identity-v1/models';
import { AuthInfo } from '../model/types';

class UserIdentity {
  private authInfo: AuthInfo | null;

  private baseUrl: string;

  constructor(authInfo: AuthInfo | null, baseUrl: string) {
    this.authInfo = authInfo;
    this.baseUrl = baseUrl;
  }

  getIdentities(
    input: IdentitySearchRequest,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<IdentitySearchResponse>> {
    const { body } = input;
    const token = this.authInfo ? this.authInfo.token : '';

    return Axios.post(`${this.baseUrl}/users/search`, body, {
      cancelToken,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export type IdentitySearchRequest = {
  body: UserSearchFilters;
};

export default UserIdentity;
