import { AxiosResponse, CancelToken, default as Axios } from 'axios';
import { Count } from '../entities/customerAssetWorkflow-v1/count';
import { InlineResponse200 } from '../entities/customerAssetWorkflow-v1/inlineResponse200';
import { AuthInfo } from '../model/types';
import { UpdateOwnershipRequest } from '../entities/customerAssetWorkflow-v1/updateOwnershipRequest';
import { OwnershipRequestCreateRequest } from '../entities/customerAssetWorkflow-v1/ownershipRequestCreateRequest';
import { OwnershipRequestsGlobalSearchRequest } from './../entities/customerAssetWorkflow-v1/ownershipRequestsGlobalSearchRequest';
import { CreateOwnershipRequestResponse } from '../entities/customerAssetWorkflow-v1/createOwnershipRequestResponse';
import { OwnershipChangeRecommendationResponse } from '../entities/customerAssetWorkflow-v1/ownershipChangeRecommendationResponse';
import { UpdateAssetRecommendation } from '../entities/customerAssetWorkflow-v1/updateAssetRecommendation';
import { InlineResponse2002 } from '../entities/customer-master-v1/inlineResponse2002';

class CustomerAssetWorkflow {
  private authInfo: AuthInfo | null;

  private baseUrl: string;

  constructor(authInfo: AuthInfo | null, baseUrl: string) {
    this.authInfo = authInfo;
    this.baseUrl = baseUrl;
  }

  searchAssetRecommendations(
    input: AssetRecommendationsRequest,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<InlineResponse200>> {
    const { token } = this.authInfo as AuthInfo;
    const { body, ...queryParameters } = input;
    return Axios.post<InlineResponse200>(`${this.baseUrl}/assetRecommendations/search`, body, {
      cancelToken,
      params: queryParameters,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  countAssetRecommendations(input: AssetRecommendationsRequest, cancelToken?: CancelToken) {
    const { token } = this.authInfo as AuthInfo;
    const { body } = input;
    return Axios.post<{ count: Count }>(`${this.baseUrl}/assetRecommendations/COUNT`, body, {
      cancelToken,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  searchOwnershipRequests(input: OwnershipRequestsRequest, cancelToken?: CancelToken) {
    const { token } = this.authInfo as AuthInfo;
    const { body, ...queryParameters } = input;
    return Axios.post<AxiosResponse<any>>(`${this.baseUrl}/ownershipRequests/search`, body, {
      cancelToken,
      params: queryParameters,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  globalSearchOwnershipRequests(input: OwnershipRequestsGlobalRequest) {
    const { token } = this.authInfo as AuthInfo;
    const { body, ...queryParameters } = input;
    return Axios.post<AxiosResponse<any>>(`${this.baseUrl}/ownershipRequests/globalSearch`, body, {
      params: queryParameters,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  countOwnershipRequests(input: OwnershipRequestsRequest, cancelToken?: CancelToken) {
    const { token } = this.authInfo as AuthInfo;
    const { body, ...queryParameters } = input;
    return Axios.post<InlineResponse2002>(`${this.baseUrl}/ownershipRequests/COUNT`, body, {
      cancelToken,
      params: queryParameters,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getOwnershipRequest(ownershipRequestIdentifier: string) {
    const { token } = this.authInfo as AuthInfo;
    return Axios.get<AxiosResponse<any>>(
      `${this.baseUrl}/ownershipRequests/${ownershipRequestIdentifier}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  deleteOwnershipRequest(ownershipRequestIdentifier: string) {
    const { token } = this.authInfo as AuthInfo;
    return Axios.delete<AxiosResponse<any>>(
      `${this.baseUrl}/ownershipRequests/${ownershipRequestIdentifier}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  getAssetRecommendationItem(recommendationGroupIdentifier: string): Promise<AxiosResponse> {
    const { token } = this.authInfo as AuthInfo;
    return Axios.get<AxiosResponse<OwnershipChangeRecommendationResponse>>(
      `${this.baseUrl}/assetRecommendations/${recommendationGroupIdentifier}/items`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  updateOwnershipRequest(ownershipRequestIdentifier: string, body: UpdateOwnershipRequest) {
    const { token } = this.authInfo as AuthInfo;
    return Axios.post<AxiosResponse<any>>(
      `${this.baseUrl}/ownershipRequests/${ownershipRequestIdentifier}/feedback`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  createOwnershipRequest(input: OwnershipRequestCreateRequest) {
    const { token } = this.authInfo as AuthInfo;
    return Axios.post<CreateOwnershipRequestResponse>(`${this.baseUrl}/ownershipRequests`, input, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  updateAssetRecommendationItem(input: {
    body: UpdateAssetRecommendation;
    recommendationGroupIdentifier: string;
  }) {
    const { token } = this.authInfo as AuthInfo;
    const { body, recommendationGroupIdentifier } = input;
    return Axios.post<AxiosResponse<Count>>(
      `${this.baseUrl}/assetRecommendations/${recommendationGroupIdentifier}/items/feedback`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
}

export interface OwnershipRequestsRequest {
  ownershipRequestType: string;
  body?: any;
  xCatUserPreferences?: string;
  cursor?: string;
  limit?: number;
  skip?: number;
  sortBy?: string;
  orderBy?: string;
}

export interface OwnershipRequestsGlobalRequest extends AssetRecommendationsRequest {
  body?: OwnershipRequestsGlobalSearchRequest;
}

export interface AssetRecommendationsRequest {
  body?: any;
  xCatUserPreferences?: string;
  cursor?: string;
  limit?: number;
  skip?: number;
  sortBy?: string;
  orderBy?: string;
}

export default CustomerAssetWorkflow;
