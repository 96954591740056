/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs, get financial account details, associate/disassociate financial products to a customer organization. 
 *
 * OpenAPI spec version: 1.41.2
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Audit log category.
 */
export type AuditLogCategory = 'customer_organization_details_update' | 'customer_organization_create' | 'customer_organization_association' | 'customer_organization_consolidation' | 'recommendation_group_update';

export const AuditLogCategory = {
    CustomerOrganizationDetailsUpdate: 'customer_organization_details_update' as AuditLogCategory,
    CustomerOrganizationCreate: 'customer_organization_create' as AuditLogCategory,
    CustomerOrganizationAssociation: 'customer_organization_association' as AuditLogCategory,
    CustomerOrganizationConsolidation: 'customer_organization_consolidation' as AuditLogCategory,
    RecommendationGroupUpdate: 'recommendation_group_update' as AuditLogCategory
};