import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  bold: {
    fontWeight: '700',
  },

  iconPosition: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));
