/* eslint-disable unicorn/no-invalid-remove-event-listener */
import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { Provider, useDispatch } from 'react-redux';
import configureStore from './store';
import theme from './globals/theme';
import './application.css';
import AuthLayer from './globals/auth-layer';
import SnackbarNotifier from './components/snackbar-notifier';
import InitialLoading from './components/initial-loading';
import UnsavedChangesConfirmation from './globals/unsaved-changes-confirmation';
import RoutesComponent from './globals/routes';
import setUpInterceptor from './globals/axios/request-interceptor';
import Warning from './components/warning';
import { sessionExpiredText } from './constants';
import SnackbarContainer from './components/snackbar-container';

const store = configureStore();
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch();

const App = () => {
  const [sessionExpired, setSessionExpired] = useState(false);
  useEffect(() => {
    document.body.style.backgroundColor = 'white';
    window.addEventListener('beforeunload', () => {
      const isSessionRefreshed = window.sessionStorage.getItem('is-refreshed');
      if (!isSessionRefreshed || isSessionRefreshed === 'false') {
        window.sessionStorage.setItem('is-refreshed', 'true');
      }
    });
    return () => {
      window.removeEventListener('beforeunload', () => {});
    };
  }, []);
  const logoutUser = () => {
    window.localStorage.removeItem('saved-path');
    window.location.href = store.getState().login.environmentVariables.logoutURI;
  };

  setUpInterceptor(
    store,
    setSessionExpired,
    store.getState().login.environmentVariables.refreshSession,
  );

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <AuthLayer>
            <CssBaseline>
              <SnackbarContainer>
                <BrowserRouter>
                  <Suspense fallback={<InitialLoading />}>
                    <RoutesComponent />
                    <UnsavedChangesConfirmation />
                  </Suspense>
                </BrowserRouter>
                <Warning
                  open={sessionExpired}
                  setOpen={setSessionExpired}
                  titleText="Session Expired"
                  messageText={sessionExpiredText}
                  primaryText="Refresh"
                  secondaryText="Sign Out"
                  primaryCallback={() => {
                    window.location.reload();
                  }}
                  secondaryCallback={() => logoutUser()}
                />
                <SnackbarNotifier />
              </SnackbarContainer>
            </CssBaseline>
          </AuthLayer>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};

export default App;
