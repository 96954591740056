import React, { Dispatch, SetStateAction } from 'react';

import SortLabelComponent from '../../components/sort-label';
import { UserAccessRequestResponse } from '../../entities/user-onboarding-v1/userAccessRequestResponse';
import TextWithToolTip from '../../components/text-with-tooltip';
import { Box } from '@mui/material';
import { AccessType } from '../../entities/user-onboarding-v1/accessType';
import { Applications } from '../../entities/user-onboarding-v1/applications';
import AccessRequestStatusText from '../../components/access-request-status';
import { UserAccessRequestStatus } from '../../entities/user-onboarding-v1/userAccessRequestStatus';
import dayjs from 'dayjs';
import AccessRequestColumnSort from './components/access-request-column-sort';
import { UserRequestPropertiesForSorting } from '../../entities/user-onboarding-v1/models';
import i18n from 'i18next';
import TEXT from '../../globals/translation-map';
import MultilineTextWithToolTip from '../../components/multiline-text-with-tooltip';

export type ApplicationsType = Applications & {
  accessType?: AccessType;
};

const getColumnsForAccessRequest = (options: {
  sortOptionsOpen: boolean;
  setSortOptionsOpen: Dispatch<SetStateAction<boolean>>;
  sortBy: string;
  orderBy: string;
  handleSortChange: Function;
  handleOrderChange: Function;
  sortingSelected: boolean;
  setSortingSelected: Dispatch<SetStateAction<boolean>>;
  classes: any;
}): Array<{
  name: string;
  title: React.JSX.Element | string;
  getCellValue: (row: UserAccessRequestResponse) => React.JSX.Element | undefined | string;
  width: string;
}> => {
  const {
    handleOrderChange,
    handleSortChange,
    orderBy,
    setSortingSelected,
    setSortOptionsOpen,
    sortBy,
    sortingSelected,
    sortOptionsOpen,
    classes,
  } = options;

  return [
    {
      name: 'user',
      title: i18n.t(TEXT.ACCESS_REQUESTS.LABELS.USER),
      getCellValue: (row: UserAccessRequestResponse) => (
        <>
          <Box>
            <TextWithToolTip
              bold
              fontSize="14px"
              longText={`${row.firstName ?? '--'} ${row.lastName ?? '--'}`}
              maxWidth="200px"
            />
          </Box>
          <Box>
            <TextWithToolTip
              fontSize="14px"
              longText={i18n.t(TEXT.ACCESS_REQUESTS.LABELS.USERNAME, {
                username: row.username ?? '--',
              })}
              maxWidth="200px"
            />
          </Box>
        </>
      ),
      width: '17%',
    },
    {
      name: 'email',
      title: (
        <SortLabelComponent
          title={i18n.t(TEXT.COMMON.TITLE.EMAIL)}
          name={UserRequestPropertiesForSorting.Email}
          sortBy={sortBy}
          columnIdentifier={UserRequestPropertiesForSorting.Email}
          sortDirection={orderBy}
          sortOptionsOpen={sortOptionsOpen}
          setSortOptionsOpen={setSortOptionsOpen}
          sortingSelected={sortingSelected}
          reverseSortArrow={true}
        >
          <AccessRequestColumnSort
            propertyName={UserRequestPropertiesForSorting.Email}
            options={[
              {
                key: 'ASC',
                value: i18n.t(TEXT.COMMON.SORT.A_TO_Z),
              },
              {
                key: 'DESC',
                value: i18n.t(TEXT.COMMON.SORT.Z_TO_A),
              },
            ]}
            sortBy={sortBy}
            orderBy={orderBy}
            handleSortChange={handleSortChange}
            handleOrderByChange={handleOrderChange}
            sortPopUpOpen={setSortOptionsOpen}
            setSortingSelected={setSortingSelected}
          />
        </SortLabelComponent>
      ),
      getCellValue: (row: UserAccessRequestResponse) => (
        <TextWithToolTip fontSize="14px" longText={row.email ?? '--'} maxWidth="200px" />
      ),
      width: '15%',
    },
    {
      name: 'primaryPartyDetails',
      title: (
        <SortLabelComponent
          title={i18n.t(TEXT.ACCESS_REQUESTS.LABELS.CUSTOMER_ASSOCIATION)}
          name={UserRequestPropertiesForSorting.PartyName}
          sortBy={sortBy}
          columnIdentifier={UserRequestPropertiesForSorting.PartyName}
          sortDirection={orderBy}
          sortOptionsOpen={sortOptionsOpen}
          setSortOptionsOpen={setSortOptionsOpen}
          sortingSelected={sortingSelected}
          reverseSortArrow={true}
        >
          <AccessRequestColumnSort
            propertyName={UserRequestPropertiesForSorting.PartyName}
            options={[
              {
                key: 'ASC',
                value: i18n.t(TEXT.COMMON.SORT.A_TO_Z),
              },
              {
                key: 'DESC',
                value: i18n.t(TEXT.COMMON.SORT.Z_TO_A),
              },
            ]}
            sortBy={sortBy}
            orderBy={orderBy}
            handleSortChange={handleSortChange}
            handleOrderByChange={handleOrderChange}
            sortPopUpOpen={setSortOptionsOpen}
            setSortingSelected={setSortingSelected}
          />
        </SortLabelComponent>
      ),
      getCellValue: (row: UserAccessRequestResponse) => (
        <Box>
          <Box>
            <MultilineTextWithToolTip
              className={classes.boldRowText}
              text={`${row.primaryPartyDetails?.partyName ?? '--'}`}
              numberOfLines={1}
            />
          </Box>
          <Box>
            <MultilineTextWithToolTip
              className={classes.smallRowText}
              text={`ID: ${row.primaryPartyDetails?.partyNumber ?? '--'}`}
              numberOfLines={1}
            />
          </Box>
        </Box>
      ),
      width: '15%',
    },
    {
      name: 'application',
      title: (
        <SortLabelComponent
          title={i18n.t(TEXT.COMMON.FILTERS.INPUT_LABELS.APPLICATION)}
          name={UserRequestPropertiesForSorting.ApplicationName}
          sortBy={sortBy}
          columnIdentifier={UserRequestPropertiesForSorting.ApplicationName}
          sortDirection={orderBy}
          sortOptionsOpen={sortOptionsOpen}
          setSortOptionsOpen={setSortOptionsOpen}
          sortingSelected={sortingSelected}
          reverseSortArrow={true}
        >
          <AccessRequestColumnSort
            propertyName={UserRequestPropertiesForSorting.ApplicationName}
            options={[
              {
                key: 'ASC',
                value: i18n.t(TEXT.COMMON.SORT.A_TO_Z),
              },
              {
                key: 'DESC',
                value: i18n.t(TEXT.COMMON.SORT.Z_TO_A),
              },
            ]}
            sortBy={sortBy}
            orderBy={orderBy}
            handleSortChange={handleSortChange}
            handleOrderByChange={handleOrderChange}
            sortPopUpOpen={setSortOptionsOpen}
            setSortingSelected={setSortingSelected}
          />
        </SortLabelComponent>
      ),
      getCellValue: (row: UserAccessRequestResponse) => {
        return (
          (row.application?.isMobile
            ? row.application?.mobileApplicationName
            : row.application?.applicationName) ?? '--'
        );
      },
      width: '20%',
    },
    {
      name: 'createdTime',
      title: (
        <SortLabelComponent
          title={i18n.t(TEXT.ACCESS_REQUESTS.LABELS.REQUEST_PENDING)}
          name={UserRequestPropertiesForSorting.CreatedTime}
          sortBy={sortBy}
          columnIdentifier={UserRequestPropertiesForSorting.CreatedTime}
          sortDirection={orderBy}
          sortOptionsOpen={sortOptionsOpen}
          setSortOptionsOpen={setSortOptionsOpen}
          sortingSelected={sortingSelected}
        >
          <AccessRequestColumnSort
            propertyName={UserRequestPropertiesForSorting.CreatedTime}
            options={[
              {
                key: 'DESC',
                value: i18n.t(TEXT.COMMON.SORT.MOST_RECENT),
              },
              {
                key: 'ASC',
                value: i18n.t(TEXT.COMMON.SORT.LEAST_RECENT),
              },
            ]}
            sortBy={sortBy}
            orderBy={orderBy}
            handleSortChange={handleSortChange}
            handleOrderByChange={handleOrderChange}
            sortPopUpOpen={setSortOptionsOpen}
            setSortingSelected={setSortingSelected}
          />
        </SortLabelComponent>
      ),
      getCellValue: (row: UserAccessRequestResponse) => {
        return (
          <div data-testid="request-pending">
            {i18n.t(TEXT.ACCESS_REQUESTS.LABELS.DAYS, {
              days: dayjs(new Date()).diff(row.createdTime, 'days'),
            })}
          </div>
        );
      },
      width: '20%',
    },
    {
      name: 'status',
      title: (
        <SortLabelComponent
          title={i18n.t(TEXT.COMMON.TITLE.STATUS)}
          name="status"
          sortBy={sortBy}
          columnIdentifier={UserRequestPropertiesForSorting.Status}
          sortDirection={orderBy}
          sortOptionsOpen={sortOptionsOpen}
          setSortOptionsOpen={setSortOptionsOpen}
          sortingSelected={sortingSelected}
          informationIconFlag={true}
          statusMap={{
            [i18n.t(TEXT.ACCESS_REQUESTS.STATUS.PENDING)]: i18n.t(
              TEXT.ACCESS_REQUESTS.STATUS.PENDING_EXPLAINED,
            ),
            [i18n.t(TEXT.ACCESS_REQUESTS.STATUS.PROCESSING)]: i18n.t(
              TEXT.ACCESS_REQUESTS.STATUS.PROCESSING_EXPLAINED,
            ),
            [i18n.t(TEXT.ACCESS_REQUESTS.STATUS.APPROVED)]: i18n.t(
              TEXT.ACCESS_REQUESTS.STATUS.APPROVED_EXPLAINED,
            ),
            [i18n.t(TEXT.ACCESS_REQUESTS.STATUS.DENIED)]: i18n.t(
              TEXT.ACCESS_REQUESTS.STATUS.DENIED_EXPLAINED,
            ),
            [i18n.t(TEXT.ACCESS_REQUESTS.STATUS.FAILED)]: i18n.t(
              TEXT.ACCESS_REQUESTS.STATUS.FAILED_EXPLAINED,
            ),
          }}
          reverseSortArrow={true}
        >
          <AccessRequestColumnSort
            propertyName={UserRequestPropertiesForSorting.Status}
            options={[
              {
                key: 'ASC',
                value: i18n.t(TEXT.COMMON.SORT.A_TO_Z),
              },
              {
                key: 'DESC',
                value: i18n.t(TEXT.COMMON.SORT.Z_TO_A),
              },
            ]}
            sortBy={sortBy}
            orderBy={orderBy}
            handleSortChange={handleSortChange}
            handleOrderByChange={handleOrderChange}
            sortPopUpOpen={setSortOptionsOpen}
            setSortingSelected={setSortingSelected}
          />
        </SortLabelComponent>
      ),
      getCellValue: (row: UserAccessRequestResponse) =>
        row.status && (
          <AccessRequestStatusText
            status={row.status as UserAccessRequestStatus}
          ></AccessRequestStatusText>
        ),
      width: '13%',
    },
  ];
};

export default getColumnsForAccessRequest;
