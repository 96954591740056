import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  toastTitle: {
    fontWeight: 600,
    fontSize: '0.75rem',
    lineHeight: '16px',
    color: CAT_COLOR.BLACK_OLIVE,
    verticalAlign: 'sub',
  },
}));
