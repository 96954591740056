import React from 'react';
import { Typography } from '@mui/material';
import useStyles from './styles';

const CookieNotice = () => {
  const { classes } = useStyles();
  return (
    <>
      <Typography className={classes.bold}>Cookie Notice for Customer Admin Tool </Typography>
      <Typography className={classes.bold}>Cookies</Typography>
      <Typography>
        This application uses cookies, web beacons (also known as action tags or single-pixel gifs),
        and other technologies (collectively, &quot;cookies&quot;).
      </Typography>
      <Typography>
        Cookies are small files that are placed on your computer and retrieved by the application in
        order to make this application work more efficiently and to better enable processing of
        information. Cookies enable us to learn about what content you see, what ads you click, and
        other actions you take in this application and other applications or websites. This allows
        us to provide you with more useful and relevant content.
      </Typography>
      <Typography>
        If you do not allow this application to use cookies, then certain features or sections of
        this application may not be available to you or may not function as expected. Cookies can
        also be removed or blocked by following the help directions in your browser.
      </Typography>
      <Typography className={classes.bold}>CHANGING YOUR COOKIE SETTINGS</Typography>
      <Typography>
        Many web browser settings allow you to view and delete cookies from your computer and
        disable or block the installation of cookies on your computer. Below you can find relevant
        instructions for the different browsers:
      </Typography>
      <Typography>
        <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">
          Cookies in Mozilla Firefox
        </a>
      </Typography>
      <Typography>
        <a href="https://support.google.com/chrome/answer/95647?hl=en&topic=14666&ctx=topic">
          Cookies in Google Chrome
        </a>
      </Typography>
      <Typography>
        <a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
          Cookies in Internet Explorer
        </a>
      </Typography>
      <Typography>
        <a href="https://support.apple.com/en-euro/guide/safari/sfri11471/12.0/mac/10.14">
          Cookies in Safari
        </a>
      </Typography>

      <Typography>
        To find out more about cookies, including how to see what cookies have been set and how to
        manage and delete them, visit www.aboutcookies.org or www.allaboutcookies.org.
      </Typography>
      <Typography className={classes.bold}>FUTURE CHANGES</Typography>
      <Typography>
        We reserve the right to update and change this cookie statement from time to time, including
        as required by applicable laws and regulations.
      </Typography>
      <Typography className={classes.bold}>COOKIES USED ON THIS SITE</Typography>
      <Typography>
        The table below explains what cookies are set and used by this application and why:
      </Typography>
      <Typography className={classes.bold}>1. STRICTLY NECESSARY COOKIES</Typography>
      <Typography>
        These cookies are necessary for the website to function. These include actions such as
        setting your privacy preferences, logging in or filling out forms.
      </Typography>
      <table className={classes.border}>
        <tr>
          <th>Cookie Name</th>
          <th>Cookie Source</th>
          <th>Duration</th>
          <th>Description </th>
        </tr>
        <tr>
          <td>SSOCookie</td>
          <td>Cat.com</td>
          <td>Session</td>
          <td>Needed for initial SSO CWS based login</td>
        </tr>
        <tr>
          <td>SSO2StepVIP</td>
          <td>Cat.com</td>
          <td>Session</td>
          <td>Needed for initial SSO CWS based login</td>
        </tr>
        <tr>
          <td>SSOLogin</td>
          <td>Cat.com</td>
          <td>Session</td>
          <td>Needed for initial SSO CWS based login</td>
        </tr>
        <tr>
          <td>DSFCookieSessionCMA</td>
          <td>Cat.com</td>
          <td>Session</td>
          <td>Stores dealercode value into local machine for deep link SSO</td>
        </tr>
      </table>
      <Typography className={classes.bold}>2. PERFORMANCE COOKIES</Typography>
      <Typography>
        These cookies allow us to count visits and traffic sources, so we can measure and improve
        the performance of our site. They help us know which pages are the most and least popular
        and see how visitors move around the site. All information in these cookies is aggregated
        and therefore anonymous <Typography>None.</Typography>
      </Typography>

      <Typography className={classes.bold}>3. FUNCTIONAL COOKIES</Typography>
      <Typography>
        These cookies allow the provision of enhanced functionality and personalization, such as
        videos and live chats. They may be set by us or 3rd party providers whose services we have
        added to our pages.
      </Typography>
      <table className={classes.border}>
        <tr>
          <th>Cookie Name</th>
          <th>Cookie Source</th>
          <th>Duration</th>
          <th>Description </th>
        </tr>
        <tr>
          <td>DSFCookieSessionCMA</td>
          <td>Cat.com</td>
          <td>Persistent</td>
          <td>
            Stores dealer code value in order to use link from Customer Admin Tool to legacy UCID
            tool (Internal users only).
          </td>
        </tr>
        <tr>
          <td>Window.localStorage</td>
          <td>Cat.com</td>
          <td>Persistent</td>
          <td>
            Stored in browser memory. There is not an expiration time. To remove this data from the
            user’s machine, the user would need to manually clear the browser cache. Stored in the
            browser’s memory: - Last 10 recent searches performed by the user on both Customers and
            My Work Queue pages.
          </td>
        </tr>
      </table>
      <Typography className={classes.bold}>4. TARGETING COOKIES</Typography>
      <Typography>
        These cookies are set through our sites by our advertising partners. They may be used by
        these companies to build a profile of your interests and show you relevant ads on other
        sites. They work by uniquely identifying your browser and device.{' '}
        <Typography>None.</Typography>
      </Typography>
    </>
  );
};
export default CookieNotice;
