import React, { ReactElement } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Breakpoint, Divider, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useStyles from './styles';
import globalStyles from '../../globals/styles';
import StepperButton from '../stepper-button';

type WarningProperties = {
  open: boolean;
  customWidth?: string;
  setOpen: Function;
  titleText: string | ReactElement;
  primaryCallback?: Function;
  secondaryCallback?: Function;
  messageText: string | ReactElement;
  primaryText?: string;
  secondaryText?: string;
  maxWidth?: Breakpoint;
  testPrefix?: string;
};

const Warning = (properties: WarningProperties) => {
  const {
    open,
    customWidth,
    setOpen,
    titleText,
    messageText,
    primaryCallback,
    secondaryCallback,
    primaryText,
    secondaryText,
    maxWidth,
    testPrefix,
  } = properties;

  const { classes } = useStyles();
  const { classes: globalClasses } = globalStyles();
  const handleClose = () => {
    if (secondaryCallback) {
      secondaryCallback();
    }
    setOpen(false);
  };
  const handleConfirm = () => {
    setOpen(false);
    if (primaryCallback) {
      primaryCallback();
    }
  };
  const prefix = testPrefix ? `${testPrefix}-` : '';
  return (
    <Dialog
      data-testid={`${prefix}warning-container`}
      open={open}
      onClose={handleClose}
      keepMounted
      fullWidth
      maxWidth={maxWidth}
      sx={
        customWidth
          ? {
              '& .MuiDialog-container': {
                '& .MuiPaper-root': {
                  maxWidth: customWidth,
                },
              },
            }
          : undefined
      }
    >
      <DialogTitle>
        <Typography
          component={'span'}
          className={globalClasses.dialogTitle}
          data-testid={`${prefix}warning-title`}
        >
          {titleText}
        </Typography>
        <IconButton
          className={classes.iconPosition}
          data-testid="cancel-notification-button"
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText component="div" data-testid={`${prefix}warning-text`}>
          {messageText}
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        {secondaryText ? (
          <StepperButton
            buttonText={secondaryText}
            id={`${prefix}deny-btn`}
            color="secondary"
            onClick={() => handleClose()}
          />
        ) : undefined}
        {primaryText ? (
          <Button
            data-testid="confirm-btn"
            onClick={() => handleConfirm()}
            color="primary"
            variant="contained"
          >
            {primaryText}
          </Button>
        ) : undefined}
      </DialogActions>
    </Dialog>
  );
};

export default Warning;
