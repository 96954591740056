import { UserAccessRequestResponse } from '../../entities/user-onboarding-v1/userAccessRequestResponse';
import { OwnershipRequestDetails } from '../../entities/customerAssetWorkflow-v1/ownershipRequestDetails';

export enum ownershipRequestsActionNames {
  RESET_CURSORS_FOR_OWNERSHIP_REQUESTS = 'RESET_CURSORS_FOR_OWNERSHIP_REQUESTS',
  SET_OWNERSHIP_REQUESTS = 'SET_OWNERSHIP_REQUESTS',
  SET_OWNERSHIP_REQUESTS_ORDER_BY_VALUE = 'SET_OWNERSHIP_REQUESTS_ORDER_BY_VALUE',
  SET_OWNERSHIP_REQUESTS_SORT_BY_VALUE = 'SET_OWNERSHIP_REQUESTS_SORT_BY_VALUE',
  SET_CURSORS_FOR_OWNERSHIP_REQUEST = 'SET_CURSORS_FOR_OWNERSHIP_REQUEST',
  SET_INITIAL_STATE = 'SET_INITIAL_STATE',
  SET_OWNERSHIP_REQUESTS_COUNT = 'SET_OWNERSHIP_REQUESTS_COUNT',
  SET_IS_LOADING_OWNERSHIP_REQUESTS = 'SET_IS_LOADING_OWNERSHIP_REQUESTS',
  SET_OWNERSHIP_REQUESTS_TYPE = 'SET_OWNERSHIP_REQUESTS_TYPE',
  SET_IS_LOADING_GET_OWNERSHIP_REQUEST = 'SET_IS_LOADING_GET_OWNERSHIP_REQUEST',
  SET_IS_LOADING_DELETE_OWNERSHIP_REQUEST = 'SET_IS_LOADING_DELETE_OWNERSHIP_REQUEST',
  SET_SELECTED_OWNERSHIP_REQUEST = 'SET_SELECTED_OWNERSHIP_REQUEST',
  SET_IS_LOADING_UPDATE_OWNERSHIP_REQUEST = 'SET_IS_LOADING_UPDATE_OWNERSHIP_REQUEST',
  SET_OWNERSHIP_REQUESTS_FILTERS = 'SET_OWNERSHIP_REQUESTS_FILTERS',
  SET_OWNERSHIP_REQUESTS_FILTER_TAGS = 'SET_OWNERSHIP_REQUESTS_FILTER_TAGS',
  SET_OWNERSHIP_REQUESTS_SEARCH_VALUE = 'SET_OWNERSHIP_REQUESTS_SEARCH_VALUE',
}

export type RequestType = 'RECEIVED' | 'SENT';

export const RequestTypes = {
  RECEIVED: 'RECEIVED' as RequestType,
  SENT: 'SENT' as RequestType,
};

export type FiltersType = {
  status: Array<OwnershipRequestStatus>;
};

export type ReceivedSentType = {
  filters: FiltersType;
  filterTags: Array<string>;
  sortBy: Array<string>;
  orderBy: Array<string>;
  searchValue: string;
};

export type OwnershipRequestStatus = 'Approved' | 'Pending' | 'Denied';

export const StatusTypes = {
  APPROVED: 'Approved' as OwnershipRequestStatus,
  PENDING: 'Pending' as OwnershipRequestStatus,
  DENIED: 'Denied' as OwnershipRequestStatus,
};
export interface OwnershipRequestsState {
  cursors: Array<string>;
  ownershipRequests?: Array<UserAccessRequestResponse>;
  isLoadingOwnershipRequests: boolean;
  isLoadingGetOwnershipRequest: boolean;
  isLoadingDeleteOwnershipRequest: boolean;
  requestType: RequestType;
  count: number;
  selectedOwnershipRequest: OwnershipRequestDetails | undefined;
  isLoadingUpdateOwnershipRequest: boolean;
  received: ReceivedSentType;
  sent: ReceivedSentType;
}

export interface SetIsOwnershipRequestsLoading {
  type: typeof ownershipRequestsActionNames.SET_IS_LOADING_OWNERSHIP_REQUESTS;
  payload: boolean;
}
export interface SetOwnershipRequests {
  type: typeof ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS;
  payload: Array<UserAccessRequestResponse>;
}
export interface SetOwnershipRequestsTotalCount {
  type: typeof ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_COUNT;
  payload: number;
}

export interface SetInitialState {
  type: typeof ownershipRequestsActionNames.SET_INITIAL_STATE;
}

export interface SetCursorsForOwnershipRequests {
  type: typeof ownershipRequestsActionNames.SET_CURSORS_FOR_OWNERSHIP_REQUEST;
  payload: Array<string>;
}
export interface ResetCursorsForOwnershipRequests {
  type: typeof ownershipRequestsActionNames.RESET_CURSORS_FOR_OWNERSHIP_REQUESTS;
}
export interface SetSortByRules {
  type: typeof ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_SORT_BY_VALUE;
  payload: {
    requestType: Lowercase<RequestType>;
    sortBy: Array<string>;
  };
}
export interface SetOrderByRules {
  type: typeof ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_ORDER_BY_VALUE;
  payload: {
    requestType: Lowercase<RequestType>;
    orderBy: Array<string>;
  };
}
export interface SetRequestType {
  type: typeof ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_TYPE;
  payload: RequestType;
}
export interface SetGetRequestOwnershipRequest {
  type: typeof ownershipRequestsActionNames.SET_IS_LOADING_GET_OWNERSHIP_REQUEST;
  payload: boolean;
}
export interface SetIsLoadingDeleteOwnershipRequest {
  type: typeof ownershipRequestsActionNames.SET_IS_LOADING_DELETE_OWNERSHIP_REQUEST;
  payload: boolean;
}
export interface SetSelectedOwnershipRequest {
  type: typeof ownershipRequestsActionNames.SET_SELECTED_OWNERSHIP_REQUEST;
  payload: any;
}
export interface SetIsLoadingUpdateOwnershipRequest {
  type: typeof ownershipRequestsActionNames.SET_IS_LOADING_UPDATE_OWNERSHIP_REQUEST;
  payload: boolean;
}
export interface SetOwnershipRequestsFilters {
  type: typeof ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_FILTERS;
  payload: {
    requestType: Lowercase<RequestType>;
    filters: FiltersType;
  };
}
export interface SetOwnershipRequestsFilterTags {
  type: typeof ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_FILTER_TAGS;
  payload: {
    requestType: Lowercase<RequestType>;
    filterTags: Array<string>;
  };
}
export interface SetOwnershipRequestsSearchValue {
  type: typeof ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_SEARCH_VALUE;
  payload: {
    requestType: Lowercase<RequestType>;
    value: string;
  };
}

export type ownershipRequestsActionTypes =
  | SetIsOwnershipRequestsLoading
  | SetOwnershipRequests
  | SetCursorsForOwnershipRequests
  | ResetCursorsForOwnershipRequests
  | SetOwnershipRequestsTotalCount
  | SetSortByRules
  | SetOrderByRules
  | SetInitialState
  | SetRequestType
  | SetGetRequestOwnershipRequest
  | SetIsLoadingDeleteOwnershipRequest
  | SetSelectedOwnershipRequest
  | SetIsLoadingUpdateOwnershipRequest
  | SetOwnershipRequestsFilters
  | SetOwnershipRequestsFilterTags
  | SetOwnershipRequestsSearchValue;
