import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  filterTagContainer: {
    height: 'auto',
    overflow: 'hidden',
    justifyContent: 'flex-end',
    paddingRight: '25px',
  },
  filterTagContainerExpanded: {
    height: 'auto',
    justifyContent: 'flex-end',
    paddingRight: '25px',
  },
  tagsContainer: {
    justifyContent: 'flex-end',
  },
  noExpansionTagsContainer: {
    justifyContent: 'flex-start',
  },
  noExpansionContainer: {
    height: 'auto',
    paddingRight: theme.spacing(4),
    justifyContent: 'flex-start',
  },
  showHide: {
    textDecoration: 'none',
    fontSize: '14px',
    fontWeight: 'bold',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  showHideContainer: {
    textAlign: 'left',
  },
}));
