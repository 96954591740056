import { CatToast } from 'blocks-react/bedrock/components/Toast/Toast';
import { CatToastGroup } from 'blocks-react/bedrock/components/ToastGroup/ToastGroup';
import { ToastVariant } from 'blocks-web-components/dist/bedrock/components/toast/toast.model';
import { CatIconSuccessFilled } from 'blocks-react/bedrock/components/Icons/SuccessFilled';
import { CatIconInfoFilled } from 'blocks-react/bedrock/components/Icons/InfoFilled';
import { CatIconWarningTriangleFilled } from 'blocks-react/bedrock/components/Icons/WarningTriangleFilled';
import { CatIconWarningDiamondFilled } from 'blocks-react/bedrock/components/Icons/WarningDiamondFilled';
import React from 'react';
import { Box } from '@mui/material';
import useStyles from './styles';
import { closeSnackbar } from 'notistack';
import { closeSnackbarAction } from '../../store/snackbar/actions';
import { useDispatchTs } from '../../store';
type ToastProps = {
  message: string | Element;
  variant: ToastVariant;
  persist?: boolean;
  action?: Function;
};
const Toast = React.forwardRef<HTMLDivElement, ToastProps>((props, ref) => {
  const { message, variant, action, persist, ...other } = props;
  const { classes } = useStyles();
  const dispatch = useDispatchTs();
  let icon = null;
  switch (variant) {
    case 'success':
      icon = <CatIconSuccessFilled slot="before" color="success" />;
      break;
    case 'error':
      icon = <CatIconWarningDiamondFilled slot="before" color="error" />;
      break;
    case 'warning':
      icon = <CatIconWarningTriangleFilled slot="before" color="warning" />;
      break;
    default:
      icon = <CatIconInfoFilled slot="before" color="information" />;
  }
  return (
    <Box ref={ref} data-testid={'blocks-toast-message-' + variant}>
      <CatToastGroup isInline={true}>
        <CatToast
          autoClose={!persist}
          variant={variant}
          dismissible={action ? false : true}
          {...other}
          onBlToastClose={() => {
            const key = (other as any)?.id || '';
            closeSnackbar(key);
            dispatch(closeSnackbarAction(key));
          }}
        >
          {icon}
          <div className={classes.toastTitle}>
            {message} {action && action()}
          </div>
        </CatToast>
      </CatToastGroup>
    </Box>
  );
});

export default Toast;
