import {
  SearchDealerCustomerResponse,
  DealerCustomerOrganizationResponse,
} from '../../entities/customer-master-v1/models';
import { SetIsLoading } from '../api/loadable';

export enum dealerCustomerActionNames {
  SET_DEALER_CUSTOMER = 'SET_DEALER_CUSTOMER',
  SET_NO_DEALER_CUSTOMER_FOUND_FLG = 'SET_NO_DEALER_CUSTOMER_FOUND_FLG',
  CLEAR_DEALER_CUSTOMER = 'CLEAR_DEALER_CUSTOMER',
  SET_NEXT_CURSOR = 'SET_NEXT_CURSOR',
  SET_FETCH_DCN_ERROR = 'SET_FETCH_DCN_ERROR',
  SET_LOADING_FOR_DCN = 'SET_LOADING_FOR_DCN',
  SET_LOADING_FOR_DCN_DELETE = 'SET_LOADING_FOR_DCN_DELETE',

  SET_SEARCH_VALUE = 'SET_SEARCH_VALUE',
  RESET_STATE = 'RESET_STATE',
  SET_EDIT_DCN_ERROR = 'SET_EDIT_DCN_ERROR',
  SET_DEALER_CUSTOMER_EDIT_SUCCESS = 'SET_DEALER_CUSTOMER_EDIT_SUCCESS',
  CLEAR_EDITED_DEALER_CUSTOMER = 'CLEAR_EDITED_DEALER_CUSTOMER',
  SET_LOADING_FOR_SEARCH_DCN = 'SET_LOADING_FOR_SEARCH_DCN',
}

export enum DealerCustomerRoles {
  DIGITAL_ACCOUNT_MANAGER = 'Digital Account Manager',
  DATA_STEWARD_ADMINISTRATOR = 'Data Steward Administrator',
  DEALER_ADMINISTRATOR = 'Dealer Administrator',
  CAT_FI_DATA_STEWARD = 'CAT Finance Data Steward Administrator',
}

export type ErrorType = {
  code: number;
  message: string;
};

export interface DealerCustomerState {
  dealerCustomers?: Array<SearchDealerCustomerResponse>;
  noDealerCustomersFound: boolean;
  nextCursor?: string;
  fetchError?: ErrorType;
  isLoading?: boolean;
  isLoadingDCNDelete?: boolean;
  editDealerError?: ErrorType;
  editedDealerCustomer?: DealerCustomerOrganizationResponse;
  isLoadingDcnSearch?: boolean;
}

interface SetDealerCustomers {
  type: typeof dealerCustomerActionNames.SET_DEALER_CUSTOMER;
  payload: Array<SearchDealerCustomerResponse>;
}

interface SetDealerCustomersFoundFlag {
  type: typeof dealerCustomerActionNames.SET_NO_DEALER_CUSTOMER_FOUND_FLG;
  payload: boolean;
}

interface ClearDealerCustomers {
  type: typeof dealerCustomerActionNames.CLEAR_DEALER_CUSTOMER;
}

interface SetFetchError {
  type: typeof dealerCustomerActionNames.SET_FETCH_DCN_ERROR;
  payload: ErrorType;
}

interface SetEditError {
  type: typeof dealerCustomerActionNames.SET_EDIT_DCN_ERROR;
  payload: ErrorType;
}

interface SetEditSuccess {
  type: typeof dealerCustomerActionNames.SET_DEALER_CUSTOMER_EDIT_SUCCESS;
  payload: DealerCustomerOrganizationResponse;
}

interface ClearEditSuccess {
  type: typeof dealerCustomerActionNames.CLEAR_EDITED_DEALER_CUSTOMER;
}

interface ResetState {
  type: typeof dealerCustomerActionNames.RESET_STATE;
}

export type DealerCustomerActionTypes =
  | SetDealerCustomers
  | SetDealerCustomersFoundFlag
  | ClearDealerCustomers
  | SetFetchError
  | SetIsLoading<dealerCustomerActionNames.SET_LOADING_FOR_DCN>
  | SetIsLoading<dealerCustomerActionNames.SET_LOADING_FOR_DCN_DELETE>
  | ResetState
  | SetEditError
  | SetEditSuccess
  | ClearEditSuccess
  | SetIsLoading<dealerCustomerActionNames.SET_LOADING_FOR_SEARCH_DCN>;
