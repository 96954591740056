import { default as Axios, AxiosResponse, CancelToken } from 'axios';
import { AuthInfo } from '../model/types';
import { GetManufacturerResponse } from '../entities/manufacturerMetadata-v1/getManufacturerResponse';
import { ManufacturersSearchDetailsRequest } from '../entities/manufacturerMetadata-v1/manufacturersSearchDetailsRequest';
import { ManufacturersSearchDetailsResponse } from '../entities/manufacturerMetadata-v1/manufacturersSearchDetailsResponse';
import { ManufacturerCrossReferenceSearchFilters } from '../entities/manufacturerMetadata-v1/manufacturerCrossReferenceSearchFilters';
import { ManufacturerCrossReferenceSearchResponse } from '../entities/manufacturerMetadata-v1/manufacturerCrossReferenceSearchResponse';

class ManufacturerMetadata {
  private authInfo: AuthInfo | null;

  private baseUrl: string;

  constructor(authInfo: AuthInfo | null, baseUrl: string) {
    this.authInfo = authInfo;
    this.baseUrl = baseUrl;
  }

  getManufacturerMetadata(
    nextCursor?: string | undefined,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<GetManufacturerResponse>> {
    const token = this.authInfo ? this.authInfo.token : '';
    const params = nextCursor ? { cursor: nextCursor, limit: 2500 } : { limit: 2500 };
    return Axios.get(`${this.baseUrl}/manufacturers?orderBy=ASC`, {
      params,
      cancelToken,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  searchManufacturersDetails(
    parameters: ManufacturersSearchDetailsRequest,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<ManufacturersSearchDetailsResponse>> {
    const token = this.authInfo ? this.authInfo.token : '';
    return Axios.post(`${this.baseUrl}/manufacturers/search`, null, {
      cancelToken,
      params: parameters,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  searchManufacturersCrossReference(
    parameters: {
      cursor?: string;
      limit?: string;
      skip?: string;
      'X-Cat-API-Tracking-Id'?: string;
      sortBy?: string;
      orderBy?: string;
      returnActiveMakeCodes?: boolean;
    },
    body?: ManufacturerCrossReferenceSearchFilters,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<ManufacturerCrossReferenceSearchResponse>> {
    const token = this.authInfo ? this.authInfo.token : '';
    return Axios.post(`${this.baseUrl}/manufacturerCrossReferences/search`, body || {}, {
      cancelToken,
      params: parameters,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default ManufacturerMetadata;
