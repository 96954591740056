import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  headerContainer: {
    display: 'flex',
  },
  closeIcon: {
    color: 'black',
    marginLeft: 'auto',
    height: theme.spacing(5),
    width: theme.spacing(5),
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}));
