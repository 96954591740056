import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../../../globals/color-properties';

export default makeStyles()((theme) => ({
  filterByText: {
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
    fontWeight: 600,
  },
  typeahead: {
    width: '100%',
    paddingRight: theme.spacing(8),
  },
  row: {
    paddingLeft: theme.spacing(8),
  },
}));
