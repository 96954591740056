import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';
import CAT_COLOR from '../../globals/color-properties';

const AlertInfoIcon = ({ marginTop, ...props }: iconType) => (
  <SvgIcon
    {...props}
    style={{ width: '14', height: '13', marginTop: `${marginTop}` }}
    viewBox="0 0 14 13"
    fill="none"
  >
    <path
      d="M7.06531 8.91699C6.66755 8.91699 6.34473 9.23982 6.34473 9.63758C6.34473 10.0353 6.66755 10.3582 7.06531 10.3582C7.46308 10.3582 7.7859 10.0353 7.7859 9.63758C7.7859 9.23982 7.46308 8.91699 7.06531 8.91699Z"
      fill="#ED1C24"
    />
    <path
      d="M7.78516 7.47619C7.78516 7.87416 7.46254 8.19678 7.06457 8.19678C6.6666 8.19678 6.34398 7.87416 6.34398 7.47619L6.34398 5.31442C6.34398 4.91645 6.6666 4.59384 7.06457 4.59384C7.46254 4.59384 7.78516 4.91645 7.78516 5.31442L7.78516 7.47619Z"
      fill="#ED1C24"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99994 4.73811e-09C7.12344 -2.05685e-05 7.23781 0.0669581 7.30089 0.17625L13.9509 11.6972C14.0151 11.8085 14.0164 11.9467 13.9543 12.0593C13.8921 12.1719 13.776 12.2414 13.6502 12.2415L0.350217 12.25C0.224372 12.2501 0.108172 12.1806 0.045882 12.068C-0.0164082 11.9555 -0.0151982 11.8172 0.0490508 11.7058L6.69905 0.17635C6.7621 0.0670374 6.87645 2.05995e-05 6.99994 4.73811e-09ZM7.00011 1.06582L0.965085 11.529L13.0351 11.5213L7.00011 1.06582Z"
      fill={CAT_COLOR.PERSIAN_RED}
    />
  </SvgIcon>
);

export default AlertInfoIcon;
