import { Box, Typography } from '@mui/material';
import TEXT from '../../../../../../globals/translation-map';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface UserCellProps {
  firstName?: string;
  lastName?: string;
  username?: string;
  testId?: string;
}

const UserCell = ({ firstName, lastName, username, testId }: UserCellProps) => {
  const { t } = useTranslation();
  return (
    <Box data-testid={testId ?? 'user-cell-container'}>
      <Typography
        variant="h5"
        fontWeight={600}
        mb={1}
      >{`${firstName || '--'}, ${lastName || '--'}`}</Typography>
      <Box display="flex">
        <Typography variant="h6" fontWeight={600}>
          {`${t(TEXT.COMMON.TITLE.USERNAME)}:`}&nbsp;
        </Typography>
        <Typography variant="h6">{username || '--'}</Typography>
      </Box>
    </Box>
  );
};

export default UserCell;
