import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  divider: {
    marginTop: 8,
  },
  footerGradient: {
    background: 'linear-gradient(to top, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 100%)',
  },
  dialogfooterActions: {
    backgroundColor: 'white',
    position: 'sticky',
  },
  footerActions: {
    backgroundColor: 'white',
    position: 'fixed',
  },
  tooltip: {
    marginTop: theme.spacing(1),
  },
  text: {
    '& p': {
      fontWeight: 'bold',
      color: 'black',
    },
  },
  titleText: {
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
    fontWeight: 600,
  },
  iconPosition: {
    position: 'absolute',
    right: '1%',
    top: theme.spacing(1),
  },
  closeIcon: {
    color: CAT_COLOR.BLACK,
  },
}));
