import { SnackbarState, SnackbarActionTypes, snackbarActionNames } from './types';

const initialState: SnackbarState = {
  notifications: [],
};

const snackbarReducer = (
  state: SnackbarState = initialState,
  action: SnackbarActionTypes,
): SnackbarState => {
  switch (action.type) {
    case snackbarActionNames.ENQUEUE_SNACKBAR: {
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification,
          },
        ],
      };
    }
    case snackbarActionNames.CLOSE_SNACKBAR: {
      const notificationIndex = state.notifications.findIndex(
        (notification) => notification.key === action.notification.key,
      );
      return {
        ...state,
        notifications: [
          ...state.notifications.slice(0, notificationIndex),
          ...state.notifications.slice(notificationIndex + 1),
        ],
      };
    }
    case snackbarActionNames.REMOVE_SNACKBAR: {
      return {
        ...state,
        notifications: state.notifications.filter((notification) => notification.key),
      };
    }
    case snackbarActionNames.SET_CANCEL_NOTIFICATION_VISIBILITY_FLAG: {
      return {
        ...state,
        isCancelNotificationVisible: action.payload,
      };
    }
    default:
      return state;
  }
};

export default snackbarReducer;
