import React from 'react';
import { Box } from '@mui/material';
import useStyles from './styles';
import { UserAccessRequestStatus } from '../../entities/user-onboarding-v1/userAccessRequestStatus';
type AccessRequestStatusTextType = {
  status: UserAccessRequestStatus;
  testId?: string;
};

const AccessRequestStatusText = (props: AccessRequestStatusTextType) => {
  const { status, testId } = props;
  const { classes } = useStyles();

  let statusClass = '';
  switch (status) {
    case UserAccessRequestStatus.APPROVED:
      statusClass = classes.statusApproved;
      break;
    case UserAccessRequestStatus.DENIED:
      statusClass = classes.statusDenied;
      break;
    case UserAccessRequestStatus.PENDING:
      statusClass = classes.statusPending;
      break;
    case UserAccessRequestStatus.FAILED:
      statusClass = classes.statusFailed;
      break;
    case UserAccessRequestStatus.PROCESSING:
      statusClass = classes.statusProcessing;
      break;
  }
  return (
    <Box
      className={classes.statusContainer}
      data-testid={testId ?? 'AccessRequestStatusTextContainer'}
    >
      <Box className={`${classes.statusItem} ${statusClass}`}></Box>
      <Box className={classes.bold}>{status}</Box>
    </Box>
  );
};
export default AccessRequestStatusText;
