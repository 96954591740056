/* eslint-disable unicorn/no-array-for-each */
// Online convert link: https://www.convertcsv.com/csv-to-json.htm
// For inputCountries select Step 4: Custom output via Template and change the repeating section rule to the following:
// {f2}: {lb}
// title:'{f1}'
// {rb}

// For inputStates select Step 5: Generate Output with CSV to Keyed Json (need to replace all for 'state' key)

export type Country = {
  [key: string]: {
    title: string;
    states?: Array<{ title: string; code: string; abbreviations?: Array<string> }>;
    code?: string;
  };
};

type State = {
  [key: string]: Array<{
    state: string;
  }>;
};

type StateWithabbreviations = {
  [key: string]: {
    abbreviations: Array<string>;
  };
};

export const countriesWithNoZipCode: Country = {
  PRY: {
    title: 'PARAGUAY',
  },
  COL: {
    title: 'COLOMBIA',
  },
  DOM: {
    title: 'DOMINICAN REPUBLIC',
  },
  BHS: {
    title: 'BAHAMAS',
  },
  JAM: {
    title: 'JAMAICA',
  },
  NIC: {
    title: 'NICARAGUA',
  },
  ECU: {
    title: 'ECUADOR',
  },
  CHL: {
    title: 'CHILE',
  },
  BOL: {
    title: 'BOLIVIA (PLURINATIONAL STATE OF)',
  },
  URY: {
    title: 'URUGUAY',
  },
  ARE: {
    title: 'UNITED ARAB EMIRATES',
  },
};

export const inputCountries: Country = {
  LCA: {
    title: 'Saint Lucia',
  },
  LIE: {
    title: 'Liechtenstein',
  },
  LKA: {
    title: 'Sri Lanka',
  },
  LBR: {
    title: 'Liberia',
  },
  LSO: {
    title: 'Lesotho',
  },
  LTU: {
    title: 'Lithuania',
  },
  LUX: {
    title: 'Luxembourg',
  },
  LVA: {
    title: 'Latvia',
  },
  LBY: {
    title: 'Libya',
  },
  MAR: {
    title: 'Morocco',
  },
  MCO: {
    title: 'Monaco',
  },
  MDA: {
    title: 'Moldova (the Republic of)',
  },
  MNE: {
    title: 'Montenegro',
  },
  MAF: {
    title: 'Saint Martin (Frenchpart)',
  },
  MDG: {
    title: 'Madagascar',
  },
  SGS: {
    title: 'South Georgia and South Sandwich Islands',
  },
  GTM: {
    title: 'Guatemala',
  },
  GUM: {
    title: 'Guam',
  },
  GNB: {
    title: 'Guinea-Bissau',
  },
  GUY: {
    title: 'Guyana',
  },
  HKG: {
    title: 'Hong Kong',
  },
  HMD: {
    title: 'Heard Island and McDonald Islands',
  },
  HND: {
    title: 'Honduras',
  },
  HRV: {
    title: 'Croatia',
  },
  HTI: {
    title: 'Haiti',
  },
  HUN: {
    title: 'Hungary',
  },
  IDN: {
    title: 'Indonesia',
  },
  IRL: {
    title: 'Ireland',
  },
  ISR: {
    title: 'Israel',
  },
  IMN: {
    title: 'Isle of Man',
  },
  IND: {
    title: 'India',
  },
  IOT: {
    title: 'British Indian Ocean Territory',
  },
  IRQ: {
    title: 'Iraq',
  },
  ISL: {
    title: 'Iceland',
  },
  ITA: {
    title: 'Italy',
  },
  JEY: {
    title: 'Jersey',
  },
  JAM: {
    title: 'Jamaica',
  },
  JOR: {
    title: 'Jordan',
  },
  JPN: {
    title: 'Japan',
  },
  KEN: {
    title: 'Kenya',
  },
  KGZ: {
    title: 'Kyrgyzstan',
  },
  KHM: {
    title: 'Cambodia',
  },
  KIR: {
    title: 'Kiribati',
  },
  COM: {
    title: 'Comoros (the)',
  },
  KNA: {
    title: 'Saint Kitts and Nevis',
  },
  KOR: {
    title: 'Korea (the Republic of)',
  },
  KWT: {
    title: 'Kuwait',
  },
  CYM: {
    title: 'Cayman Islands (the)',
  },
  KAZ: {
    title: 'Kazakhstan',
  },
  LAO: {
    title: `Lao People's Democratic Republic (the)`,
  },
  MHL: {
    title: 'Marshall Islands (the)',
  },
  MKD: {
    title: 'North Macedonia',
  },
  MLI: {
    title: 'Mali',
  },
  MMR: {
    title: 'Myanmar',
  },
  MNG: {
    title: 'Mongolia',
  },
  MAC: {
    title: 'Macao',
  },
  MNP: {
    title: 'Northern Mariana Islands (the)',
  },
  MTQ: {
    title: 'Martinique',
  },
  MRT: {
    title: 'Mauritania',
  },
  MSR: {
    title: 'Montserrat',
  },
  MLT: {
    title: 'Malta',
  },
  MUS: {
    title: 'Mauritius',
  },
  MDV: {
    title: 'Maldives',
  },
  MWI: {
    title: 'Malawi',
  },
  MEX: {
    title: 'Mexico',
  },
  MYS: {
    title: 'Malaysia',
  },
  MOZ: {
    title: 'Mozambique',
  },
  NAM: {
    title: 'Namibia',
  },
  NCL: {
    title: 'New Caledonia',
  },
  NER: {
    title: 'Niger (the)',
  },
  NFK: {
    title: 'Norfolk Island',
  },
  NGA: {
    title: 'Nigeria',
  },
  NIC: {
    title: 'Nicaragua',
  },
  NLD: {
    title: 'Netherlands (the)',
  },
  NOR: {
    title: 'Norway',
  },
  NPL: {
    title: 'Nepal',
  },
  NRU: {
    title: 'Nauru',
  },
  NIU: {
    title: 'Niue',
  },
  NZL: {
    title: 'New Zealand',
  },
  OMN: {
    title: 'Oman',
  },
  PAN: {
    title: 'Panama',
  },
  PER: {
    title: 'Peru',
  },
  PYF: {
    title: 'French Polynesia',
  },
  PNG: {
    title: 'Papua New Guinea',
  },
  PHL: {
    title: 'Philippines (the)',
  },
  AND: {
    title: 'Andorra',
  },
  ARE: {
    title: 'United Arab Emirates',
  },
  AFG: {
    title: 'Afghanistan',
  },
  ATG: {
    title: 'Antigua and Barbuda',
  },
  AIA: {
    title: 'Anguilla',
  },
  ALB: {
    title: 'Albania',
  },
  ARM: {
    title: 'Armenia',
  },
  AGO: {
    title: 'Angola',
  },
  ATA: {
    title: 'Antarctica',
  },
  ARG: {
    title: 'Argentina',
  },
  ASM: {
    title: 'American Samoa',
  },
  AUT: {
    title: 'Austria',
  },
  AUS: {
    title: 'Australia',
  },
  ABW: {
    title: 'Aruba',
  },
  BRB: {
    title: 'Barbados',
  },
  ZWE: {
    title: 'Zimbabwe',
  },
  FIN: {
    title: 'Finland',
  },
  FJI: {
    title: 'Fiji',
  },
  FLK: {
    title: 'Falkland Islands (the Malvinas)',
  },
  ALA: {
    title: 'Åland Islands',
  },
  AZE: {
    title: 'Azerbaijan',
  },
  BIH: {
    title: 'Bosnia and Herzegovina',
  },
  BGD: {
    title: 'Bangladesh',
  },
  BEL: {
    title: 'Belgium',
  },
  BFA: {
    title: 'Burkina Faso',
  },
  BGR: {
    title: 'Bulgaria',
  },
  BHR: {
    title: 'Bahrain',
  },
  BDI: {
    title: 'Burundi',
  },
  BEN: {
    title: 'Benin',
  },
  BLM: {
    title: 'Saint Barthélemy',
  },
  BMU: {
    title: 'Bermuda',
  },
  BRN: {
    title: 'Brunei Darussalam',
  },
  BOL: {
    title: 'Bolivia (Plurinational State of)',
  },
  BES: {
    title: 'Bonaire, Sint Eustatius and Saba',
  },
  BRA: {
    title: 'Brazil',
  },
  BHS: {
    title: 'Bahamas (the)',
  },
  BTN: {
    title: 'Bhutan',
  },
  BVT: {
    title: 'Bouvet Island',
  },
  BWA: {
    title: 'Botswana',
  },
  BLR: {
    title: 'Belarus',
  },
  BLZ: {
    title: 'Belize',
  },
  CAN: {
    title: 'Canada',
  },
  CCK: {
    title: 'Cocos (Keeling) Islands',
  },
  COD: {
    title: 'Congo (Democratic Republic)',
  },
  CAF: {
    title: 'Central African Republic',
  },
  COG: {
    title: 'Congo (the)',
  },
  CHE: {
    title: 'Switzerland',
  },
  CIV: {
    title: `Côte d'Ivoire`,
  },
  COK: {
    title: 'Cook Islands (the)',
  },
  CHL: {
    title: 'Chile',
  },
  CMR: {
    title: 'Cameroon',
  },
  CHN: {
    title: 'China',
  },
  COL: {
    title: 'Colombia',
  },
  CRI: {
    title: 'Costa Rica',
  },
  CPV: {
    title: 'Cabo Verde',
  },
  CUW: {
    title: 'Curaçao',
  },
  CXR: {
    title: 'Christmas Island',
  },
  CYP: {
    title: 'Cyprus',
  },
  CZE: {
    title: 'Czechia',
  },
  DEU: {
    title: 'Germany',
  },
  DJI: {
    title: 'Djibouti',
  },
  DNK: {
    title: 'Denmark',
  },
  DMA: {
    title: 'Dominica',
  },
  DOM: {
    title: 'Dominican Republic (the)',
  },
  DZA: {
    title: 'Algeria',
  },
  ECU: {
    title: 'Ecuador',
  },
  EST: {
    title: 'Estonia',
  },
  EGY: {
    title: 'Egypt',
  },
  ESH: {
    title: 'Western Sahara',
  },
  ERI: {
    title: 'Eritrea',
  },
  ESP: {
    title: 'Spain',
  },
  ETH: {
    title: 'Ethiopia',
  },
  FSM: {
    title: 'Micronesia (Federated States of)',
  },
  FRO: {
    title: 'Faroe Islands (the)',
  },
  FRA: {
    title: 'France',
  },
  GAB: {
    title: 'Gabon',
  },
  GBR: {
    title: 'United Kingdom of Great Britain and Northern Ireland (the)',
  },
  GRD: {
    title: 'Grenada',
  },
  GEO: {
    title: 'Georgia',
  },
  GUF: {
    title: 'French Guiana',
  },
  GGY: {
    title: 'Guernsey',
  },
  GHA: {
    title: 'Ghana',
  },
  GIB: {
    title: 'Gibraltar',
  },
  GRL: {
    title: 'Greenland',
  },
  GMB: {
    title: 'Gambia (the)',
  },
  GIN: {
    title: 'Guinea',
  },
  GLP: {
    title: 'Guadeloupe',
  },
  GNQ: {
    title: 'Equatorial Guinea',
  },
  GRC: {
    title: 'Greece',
  },
  PSE: {
    title: 'Palestine (the State of)',
  },
  PRT: {
    title: 'Portugal',
  },
  PLW: {
    title: 'Palau',
  },
  PRY: {
    title: 'Paraguay',
  },
  QAT: {
    title: 'Qatar',
  },
  REU: {
    title: 'Réunion',
  },
  ROU: {
    title: 'Romania',
  },
  SRB: {
    title: 'Serbia',
  },
  RUS: {
    title: 'Russian Federation (the)',
  },
  RWA: {
    title: 'Rwanda',
  },
  SAU: {
    title: 'Saudi Arabia',
  },
  SLB: {
    title: 'Solomon Islands',
  },
  SYC: {
    title: 'Seychelles',
  },
  PAK: {
    title: 'Pakistan',
  },
  POL: {
    title: 'Poland',
  },
  SPM: {
    title: 'Saint Pierreand Miquelon',
  },
  PCN: {
    title: 'Pitcairn',
  },
  PRI: {
    title: 'Puerto Rico',
  },
  SDN: {
    title: 'Sudan (the)',
  },
  SWE: {
    title: 'Sweden',
  },
  SGP: {
    title: 'Singapore',
  },
  SHN: {
    title: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  SVN: {
    title: 'Slovenia',
  },
  SJM: {
    title: 'Svalbard and Jan Mayen',
  },
  SVK: {
    title: 'Slovakia',
  },
  SLE: {
    title: 'Sierra Leone',
  },
  SMR: {
    title: 'San Marino',
  },
  SEN: {
    title: 'Senegal',
  },
  SOM: {
    title: 'Somalia',
  },
  SUR: {
    title: 'Suriname',
  },
  SSD: {
    title: 'South Sudan',
  },
  STP: {
    title: 'São Tomé and Príncipe',
  },
  SLV: {
    title: 'El Salvador',
  },
  SXM: {
    title: 'Sint Maarten (Dutchpart)',
  },
  SWZ: {
    title: 'Eswatini',
  },
  TCA: {
    title: 'Turks and Caicos Islands (the)',
  },
  TCD: {
    title: 'Chad',
  },
  ATF: {
    title: 'French Southern Territories (the)',
  },
  TGO: {
    title: 'Togo',
  },
  THA: {
    title: 'Thailand',
  },
  TJK: {
    title: 'Tajikistan',
  },
  TKL: {
    title: 'Tokelau',
  },
  TLS: {
    title: 'Timor-Leste',
  },
  TKM: {
    title: 'Turkmenistan',
  },
  TUN: {
    title: 'Tunisia',
  },
  TON: {
    title: 'Tonga',
  },
  TUR: {
    title: 'Turkey',
  },
  TTO: {
    title: 'Trinidad and Tobago',
  },
  TUV: {
    title: 'Tuvalu',
  },
  TWN: {
    title: 'Taiwan (Province of China)',
  },
  TZA: {
    title: 'Tanzania',
  },
  UKR: {
    title: 'Ukraine',
  },
  UGA: {
    title: 'Uganda',
  },
  UMI: {
    title: 'United States Minor Outlying Islands',
  },
  USA: {
    title: 'United States of America',
  },
  URY: {
    title: 'Uruguay',
  },
  UZB: {
    title: 'Uzbekistan',
  },
  VAT: {
    title: 'Holy See (the)',
  },
  VCT: {
    title: 'Saint Vincent and the Grenadines',
  },
  VEN: {
    title: 'Venezuela',
  },
  VGB: {
    title: 'Virgin Islands (British)',
  },
  VIR: {
    title: 'Virgin Islands (U.S.)',
  },
  VNM: {
    title: 'Vietnam',
  },
  VUT: {
    title: 'Vanuatu',
  },
  WLF: {
    title: 'Wallis and Futuna',
  },
  WSM: {
    title: 'Samoa',
  },
  YEM: {
    title: 'Yemen',
  },
  MYT: {
    title: 'Mayotte',
  },
  ZAF: {
    title: 'South Africa',
  },
  ZMB: {
    title: 'Zambia',
  },
  LBN: {
    title: 'Lebanon',
  },
};

const inputStates: State = {
  AFG: [
    {
      state: 'Badakhshan',
    },
    {
      state: 'Badghis',
    },
    {
      state: 'Baghlan',
    },
    {
      state: 'Balkh',
    },
    {
      state: 'Bamyan',
    },
    {
      state: 'Daykundi',
    },
    {
      state: 'Farah',
    },
    {
      state: 'Faryab',
    },
    {
      state: 'Ghazni',
    },
    {
      state: 'Ghor',
    },
    {
      state: 'Helmand',
    },
    {
      state: 'Herat',
    },
    {
      state: 'Jowzjan',
    },
    {
      state: 'Kabul',
    },
    {
      state: 'Kandahar',
    },
    {
      state: 'Kapisa',
    },
    {
      state: 'Khost',
    },
    {
      state: 'Kunduz',
    },
    {
      state: 'Laghman',
    },
    {
      state: 'Logar',
    },
    {
      state: 'Nangarhar',
    },
    {
      state: 'Nimruz',
    },
    {
      state: 'Nuristan',
    },
    {
      state: 'Paktia',
    },
    {
      state: 'Paktika',
    },
    {
      state: 'Panjshir',
    },
    {
      state: 'Parwan',
    },
    {
      state: 'Samangan',
    },
    {
      state: 'Sar-e Pol',
    },
    {
      state: 'Takhar',
    },
    {
      state: 'Uruzgan',
    },
    {
      state: 'Maidan Wardak',
    },
    {
      state: 'Zabul',
    },
  ],
  ALA: [
    {
      state: 'Broendoe',
    },
    {
      state: 'Eckeroe',
    },
    {
      state: 'Finstroem',
    },
    {
      state: 'Foegloe',
    },
    {
      state: 'Geta',
    },
    {
      state: 'Hammarland',
    },
    {
      state: 'Jomala',
    },
    {
      state: 'Kumlinge',
    },
    {
      state: 'Koekar',
    },
    {
      state: 'Lemland',
    },
    {
      state: 'Lumparland',
    },
    {
      state: 'Mariehamn',
    },
    {
      state: 'Saltvik',
    },
    {
      state: 'Sottunga',
    },
    {
      state: 'Sund',
    },
    {
      state: 'Vaerdoe',
    },
  ],
  ALB: [
    {
      state: 'Berat',
    },
    {
      state: 'Diber',
    },
    {
      state: 'Durres',
    },
    {
      state: 'Elbasan',
    },
    {
      state: 'Fier',
    },
    {
      state: 'Gjirokaster',
    },
    {
      state: 'Korce',
    },
    {
      state: 'Kukes',
    },
    {
      state: 'Lezhe',
    },
    {
      state: 'Shkoder',
    },
    {
      state: 'Tirane',
    },
    {
      state: 'Vlore',
    },
  ],
  DZA: [
    {
      state: 'Adrar',
    },
    {
      state: 'Ain Defla',
    },
    {
      state: 'Ain Temouchent',
    },
    {
      state: 'Algiers',
    },
    {
      state: 'Annaba',
    },
    {
      state: 'Batna',
    },
    {
      state: 'Bechar',
    },
    {
      state: 'Bejaia',
    },
    {
      state: 'Beni Abbes',
    },
    {
      state: 'Biskra',
    },
    {
      state: 'Blida',
    },
    {
      state: 'Bordj Baji Mokhtar',
    },
    {
      state: 'Bordj Bou Arreridj',
    },
    {
      state: 'Bouira',
    },
    {
      state: 'Boumerdes',
    },
    {
      state: 'Chlef',
    },
    {
      state: 'Constantine',
    },
    {
      state: 'Djanet',
    },
    {
      state: 'Djelfa',
    },
    {
      state: 'El Bayadh',
    },
    {
      state: "El M'Ghair",
    },
    {
      state: 'El Menia',
    },
    {
      state: 'El Oued',
    },
    {
      state: 'El Tarf',
    },
    {
      state: 'Ghardaia',
    },
    {
      state: 'Guelma',
    },
    {
      state: 'Illizi',
    },
    {
      state: 'In Guezzam',
    },
    {
      state: 'In Salah',
    },
    {
      state: 'Jijel',
    },
    {
      state: 'Khenchela',
    },
    {
      state: 'Laghouat',
    },
    {
      state: "M'Sila",
    },
    {
      state: 'Mascara',
    },
    {
      state: 'Medea',
    },
    {
      state: 'Mila',
    },
    {
      state: 'Mostaganem',
    },
    {
      state: 'Naama',
    },
    {
      state: 'Oran',
    },
    {
      state: 'Ouargla',
    },
    {
      state: 'Ouled Djellal',
    },
    {
      state: 'Oum El Bouaghi',
    },
    {
      state: 'Relizane',
    },
    {
      state: 'Saida',
    },
    {
      state: 'Setif',
    },
    {
      state: 'Sidi Bel Abbes',
    },
    {
      state: 'Skikda',
    },
    {
      state: 'Souk Ahras',
    },
    {
      state: 'Tamanrasset',
    },
    {
      state: 'Tebessa',
    },
    {
      state: 'Tiaret',
    },
    {
      state: 'Timimoun',
    },
    {
      state: 'Tindouf',
    },
    {
      state: 'Tipaza',
    },
    {
      state: 'Tissemsilt',
    },
    {
      state: 'Tizi Ouzou',
    },
    {
      state: 'Tlemcen',
    },
  ],
  ASM: [
    {
      state: 'Eastern District',
    },
    {
      state: 'Western District',
    },
  ],
  AND: [
    {
      state: 'Andorra la Vella',
    },
    {
      state: 'Canillo',
    },
    {
      state: 'Encamp',
    },
    {
      state: 'Escaldes-Engordany',
    },
    {
      state: 'La Massana',
    },
    {
      state: 'Ordino',
    },
    {
      state: 'Sant Julia de Loria',
    },
  ],
  AGO: [
    {
      state: 'Bengo',
    },
    {
      state: 'Benguela',
    },
    {
      state: 'Bie',
    },
    {
      state: 'Cabinda',
    },
    {
      state: 'Cuando Cubango',
    },
    {
      state: 'Cuanza Norte',
    },
    {
      state: 'Cuanza Sul',
    },
    {
      state: 'Cunene',
    },
    {
      state: 'Huambo',
    },
    {
      state: 'Huila',
    },
    {
      state: 'Luanda',
    },
    {
      state: 'Lunda Norte',
    },
    {
      state: 'Lunda Sul',
    },
    {
      state: 'Malange',
    },
    {
      state: 'Moxico',
    },
    {
      state: 'Namibe',
    },
    {
      state: 'Uige',
    },
    {
      state: 'Zaire',
    },
  ],
  AIA: [
    {
      state: 'Anguilla',
    },
  ],
  ATG: [
    {
      state: 'Redonda',
    },
    {
      state: 'Saint George',
    },
    {
      state: 'Saint John',
    },
    {
      state: 'Saint Mary',
    },
    {
      state: 'Saint Paul',
    },
    {
      state: 'Saint Peter',
    },
    {
      state: 'Saint Philip',
    },
  ],
  ARG: [
    {
      state: 'Buenos Aires',
    },
    {
      state: 'Catamarca',
    },
    {
      state: 'Chaco',
    },
    {
      state: 'Chubut',
    },
    {
      state: 'Ciudad Autonoma de Buenos Aires',
    },
    {
      state: 'Cordoba',
    },
    {
      state: 'Corrientes',
    },
    {
      state: 'Entre Rios',
    },
    {
      state: 'Formosa',
    },
    {
      state: 'Jujuy',
    },
    {
      state: 'La Pampa',
    },
    {
      state: 'La Rioja',
    },
    {
      state: 'Mendoza',
    },
    {
      state: 'Misiones',
    },
    {
      state: 'Neuquen',
    },
    {
      state: 'Rio Negro',
    },
    {
      state: 'Salta',
    },
    {
      state: 'San Juan',
    },
    {
      state: 'San Luis',
    },
    {
      state: 'Santa Cruz',
    },
    {
      state: 'Santa Fe',
    },
    {
      state: 'Santiago del Estero',
    },
    {
      state: 'Tierra del Fuego',
    },
    {
      state: 'Tucuman',
    },
  ],
  ARM: [
    {
      state: 'Aragacotn',
    },
    {
      state: 'Ararat',
    },
    {
      state: 'Armavir',
    },
    {
      state: "Gegark'unik'",
    },
    {
      state: "Kotayk'",
    },
    {
      state: 'Lori',
    },
    {
      state: 'Shirak',
    },
    {
      state: "Syunik'",
    },
    {
      state: 'Tavush',
    },
    {
      state: 'Vayots Dzor',
    },
    {
      state: 'Yerevan',
    },
  ],
  ABW: [
    {
      state: 'Aruba',
    },
  ],
  AUS: [
    {
      state: 'Australian Capital Territory',
    },
    {
      state: 'New South Wales',
    },
    {
      state: 'Northern Territory',
    },
    {
      state: 'Queensland',
    },
    {
      state: 'South Australia',
    },
    {
      state: 'Tasmania',
    },
    {
      state: 'Victoria',
    },
    {
      state: 'Western Australia',
    },
  ],
  AUT: [
    {
      state: 'Burgenland',
    },
    {
      state: 'Karnten',
    },
    {
      state: 'Niederosterreich',
    },
    {
      state: 'Oberosterreich',
    },
    {
      state: 'Salzburg',
    },
    {
      state: 'Steiermark',
    },
    {
      state: 'Tirol',
    },
    {
      state: 'Vorarlberg',
    },
    {
      state: 'Wien',
    },
  ],
  AZE: [
    {
      state: 'Abseron',
    },
    {
      state: 'Agcabadi',
    },
    {
      state: 'Agstafa',
    },
    {
      state: 'Agsu',
    },
    {
      state: 'Astara',
    },
    {
      state: 'Baki',
    },
    {
      state: 'Balakan',
    },
    {
      state: 'Barda',
    },
    {
      state: 'Beylaqan',
    },
    {
      state: 'Bilasuvar',
    },
    {
      state: 'Calilabad',
    },
    {
      state: 'Fuzuli',
    },
    {
      state: 'Gadabay',
    },
    {
      state: 'Ganca',
    },
    {
      state: 'Goranboy',
    },
    {
      state: 'Goycay',
    },
    {
      state: 'Goygol',
    },
    {
      state: 'Haciqabul',
    },
    {
      state: 'Imisli',
    },
    {
      state: 'Ismayilli',
    },
    {
      state: 'Kurdamir',
    },
    {
      state: 'Lankaran',
    },
    {
      state: 'Masalli',
    },
    {
      state: 'Mingacevir',
    },
    {
      state: 'Naftalan',
    },
    {
      state: 'Naxcivan',
    },
    {
      state: 'Neftcala',
    },
    {
      state: 'Oguz',
    },
    {
      state: 'Qabala',
    },
    {
      state: 'Qax',
    },
    {
      state: 'Qazax',
    },
    {
      state: 'Qobustan',
    },
    {
      state: 'Quba',
    },
    {
      state: 'Qusar',
    },
    {
      state: 'Saatli',
    },
    {
      state: 'Sabirabad',
    },
    {
      state: 'Saki',
    },
    {
      state: 'Salyan',
    },
    {
      state: 'Samaxi',
    },
    {
      state: 'Samkir',
    },
    {
      state: 'Samux',
    },
    {
      state: 'Sirvan',
    },
    {
      state: 'Siyazan',
    },
    {
      state: 'Sumqayit',
    },
    {
      state: 'Tartar',
    },
    {
      state: 'Tovuz',
    },
    {
      state: 'Xacmaz',
    },
    {
      state: 'Xizi',
    },
    {
      state: 'Yardimli',
    },
    {
      state: 'Yevlax',
    },
    {
      state: 'Zaqatala',
    },
    {
      state: 'Zardab',
    },
  ],
  BHS: [
    {
      state: 'Central Andros',
    },
    {
      state: 'City of Freeport',
    },
    {
      state: 'Hope Town',
    },
    {
      state: 'Long Island',
    },
    {
      state: 'New Providence',
    },
    {
      state: 'South Eleuthera',
    },
  ],
  BHR: [
    {
      state: "Al 'Asimah",
    },
    {
      state: 'Al Janubiyah',
    },
    {
      state: 'Al Muharraq',
    },
    {
      state: 'Ash Shamaliyah',
    },
  ],
  BGD: [
    {
      state: 'Barisal',
    },
    {
      state: 'Chittagong',
    },
    {
      state: 'Dhaka',
    },
    {
      state: 'Khulna',
    },
    {
      state: 'Rajshahi',
    },
    {
      state: 'Rangpur',
    },
    {
      state: 'Sylhet',
    },
  ],
  BRB: [
    {
      state: 'Christ Church',
    },
    {
      state: 'Saint Andrew',
    },
    {
      state: 'Saint George',
    },
    {
      state: 'Saint James',
    },
    {
      state: 'Saint John',
    },
    {
      state: 'Saint Lucy',
    },
    {
      state: 'Saint Michael',
    },
    {
      state: 'Saint Peter',
    },
    {
      state: 'Saint Philip',
    },
    {
      state: 'Saint Thomas',
    },
  ],
  BLR: [
    {
      state: "Brestskaya voblasts'",
    },
    {
      state: "Homyel'skaya voblasts'",
    },
    {
      state: 'Horad Minsk',
    },
    {
      state: "Hrodzenskaya voblasts'",
    },
    {
      state: "Mahilyowskaya voblasts'",
    },
    {
      state: "Minskaya voblasts'",
    },
    {
      state: "Vitsyebskaya voblasts'",
    },
  ],
  BEL: [
    {
      state: 'Antwerpen',
    },
    {
      state: 'Brabant wallon',
    },
    {
      state: 'Brussels Hoofdstedelijk Gewest',
    },
    {
      state: 'Hainaut',
    },
    {
      state: 'Liege',
    },
    {
      state: 'Limburg',
    },
    {
      state: 'Luxembourg',
    },
    {
      state: 'Namur',
    },
    {
      state: 'Oost-Vlaanderen',
    },
    {
      state: 'Vlaams-Brabant',
    },
    {
      state: 'West-Vlaanderen',
    },
  ],
  BLZ: [
    {
      state: 'Belize',
    },
    {
      state: 'Cayo',
    },
    {
      state: 'Corozal',
    },
    {
      state: 'Orange Walk',
    },
    {
      state: 'Stann Creek',
    },
    {
      state: 'Toledo',
    },
  ],
  BEN: [
    {
      state: 'Alibori',
    },
    {
      state: 'Atacora',
    },
    {
      state: 'Atlantique',
    },
    {
      state: 'Borgou',
    },
    {
      state: 'Collines',
    },
    {
      state: 'Donga',
    },
    {
      state: 'Littoral',
    },
    {
      state: 'Mono',
    },
    {
      state: 'Oueme',
    },
    {
      state: 'Plateau',
    },
    {
      state: 'Zou',
    },
  ],
  BMU: [
    {
      state: 'Hamilton',
    },
    {
      state: 'Saint George',
    },
  ],
  BTN: [
    {
      state: 'Bumthang',
    },
    {
      state: 'Chhukha',
    },
    {
      state: 'Gasa',
    },
    {
      state: 'Haa',
    },
    {
      state: 'Lhuentse',
    },
    {
      state: 'Monggar',
    },
    {
      state: 'Paro',
    },
    {
      state: 'Pemagatshel',
    },
    {
      state: 'Punakha',
    },
    {
      state: 'Samdrup Jongkhar',
    },
    {
      state: 'Samtse',
    },
    {
      state: 'Thimphu',
    },
    {
      state: 'Trashigang',
    },
    {
      state: 'Trongsa',
    },
    {
      state: 'Tsirang',
    },
    {
      state: 'Wangdue Phodrang',
    },
  ],
  BOL: [
    {
      state: 'Chuquisaca',
    },
    {
      state: 'Cochabamba',
    },
    {
      state: 'El Beni',
    },
    {
      state: 'La Paz',
    },
    {
      state: 'Oruro',
    },
    {
      state: 'Pando',
    },
    {
      state: 'Potosi',
    },
    {
      state: 'Santa Cruz',
    },
    {
      state: 'Tarija',
    },
  ],
  BIH: [
    {
      state: 'Brcko distrikt',
    },
    {
      state: 'Federacija Bosne i Hercegovine',
    },
    {
      state: 'Republika Srpska',
    },
  ],
  BWA: [
    {
      state: 'Central',
    },
    {
      state: 'Ghanzi',
    },
    {
      state: 'Kgatleng',
    },
    {
      state: 'Kweneng',
    },
    {
      state: 'North East',
    },
    {
      state: 'North West',
    },
    {
      state: 'South East',
    },
    {
      state: 'Southern',
    },
  ],
  BRA: [
    {
      state: 'Acre',
    },
    {
      state: 'Alagoas',
    },
    {
      state: 'Amapa',
    },
    {
      state: 'Amazonas',
    },
    {
      state: 'Bahia',
    },
    {
      state: 'Ceara',
    },
    {
      state: 'Distrito Federal',
    },
    {
      state: 'Espirito Santo',
    },
    {
      state: 'Goias',
    },
    {
      state: 'Maranhao',
    },
    {
      state: 'Mato Grosso',
    },
    {
      state: 'Mato Grosso do Sul',
    },
    {
      state: 'Minas Gerais',
    },
    {
      state: 'Para',
    },
    {
      state: 'Paraiba',
    },
    {
      state: 'Parana',
    },
    {
      state: 'Pernambuco',
    },
    {
      state: 'Piaui',
    },
    {
      state: 'Rio de Janeiro',
    },
    {
      state: 'Rio Grande do Norte',
    },
    {
      state: 'Rio Grande do Sul',
    },
    {
      state: 'Rondonia',
    },
    {
      state: 'Roraima',
    },
    {
      state: 'Santa Catarina',
    },
    {
      state: 'Sao Paulo',
    },
    {
      state: 'Sergipe',
    },
    {
      state: 'Tocantins',
    },
  ],
  IOT: [
    {
      state: 'British Indian Ocean Territory',
    },
  ],
  BRN: [
    {
      state: 'Belait',
    },
    {
      state: 'Brunei-Muara',
    },
    {
      state: 'Temburong',
    },
    {
      state: 'Tutong',
    },
  ],
  BGR: [
    {
      state: 'Blagoevgrad',
    },
    {
      state: 'Burgas',
    },
    {
      state: 'Dobrich',
    },
    {
      state: 'Gabrovo',
    },
    {
      state: 'Haskovo',
    },
    {
      state: 'Kardzhali',
    },
    {
      state: 'Kyustendil',
    },
    {
      state: 'Lovech',
    },
    {
      state: 'Montana',
    },
    {
      state: 'Pazardzhik',
    },
    {
      state: 'Pernik',
    },
    {
      state: 'Pleven',
    },
    {
      state: 'Plovdiv',
    },
    {
      state: 'Razgrad',
    },
    {
      state: 'Ruse',
    },
    {
      state: 'Shumen',
    },
    {
      state: 'Silistra',
    },
    {
      state: 'Sliven',
    },
    {
      state: 'Smolyan',
    },
    {
      state: 'Sofia',
    },
    {
      state: 'Sofia (stolitsa)',
    },
    {
      state: 'Stara Zagora',
    },
    {
      state: 'Targovishte',
    },
    {
      state: 'Varna',
    },
    {
      state: 'Veliko Tarnovo',
    },
    {
      state: 'Vidin',
    },
    {
      state: 'Vratsa',
    },
    {
      state: 'Yambol',
    },
  ],
  BFA: [
    {
      state: 'Bam',
    },
    {
      state: 'Bazega',
    },
    {
      state: 'Boulgou',
    },
    {
      state: 'Boulkiemde',
    },
    {
      state: 'Comoe',
    },
    {
      state: 'Gnagna',
    },
    {
      state: 'Gourma',
    },
    {
      state: 'Houet',
    },
    {
      state: 'Kadiogo',
    },
    {
      state: 'Kourweogo',
    },
    {
      state: 'Leraba',
    },
    {
      state: 'Nayala',
    },
    {
      state: 'Oudalan',
    },
    {
      state: 'Poni',
    },
    {
      state: 'Sanmatenga',
    },
    {
      state: 'Tapoa',
    },
    {
      state: 'Tuy',
    },
    {
      state: 'Yatenga',
    },
    {
      state: 'Zoundweogo',
    },
  ],
  BDI: [
    {
      state: 'Bubanza',
    },
    {
      state: 'Bujumbura Mairie',
    },
    {
      state: 'Bururi',
    },
    {
      state: 'Cibitoke',
    },
    {
      state: 'Gitega',
    },
    {
      state: 'Kirundo',
    },
    {
      state: 'Mwaro',
    },
    {
      state: 'Ngozi',
    },
    {
      state: 'Rutana',
    },
    {
      state: 'Ruyigi',
    },
  ],
  CPV: [
    {
      state: 'Boa Vista',
    },
    {
      state: 'Brava',
    },
    {
      state: 'Porto Novo',
    },
    {
      state: 'Praia',
    },
    {
      state: 'Ribeira Grande',
    },
    {
      state: 'Ribeira Grande de Santiago',
    },
    {
      state: 'Sal',
    },
    {
      state: 'Santa Catarina',
    },
    {
      state: 'Santa Cruz',
    },
    {
      state: 'Sao Filipe',
    },
    {
      state: 'Sao Vicente',
    },
    {
      state: 'Tarrafal',
    },
  ],
  KHM: [
    {
      state: 'Baat Dambang',
    },
    {
      state: 'Banteay Mean Chey',
    },
    {
      state: 'Kampong Chaam',
    },
    {
      state: 'Kampong Chhnang',
    },
    {
      state: 'Kampong Spueu',
    },
    {
      state: 'Kampong Thum',
    },
    {
      state: 'Kampot',
    },
    {
      state: 'Kandaal',
    },
    {
      state: 'Kaoh Kong',
    },
    {
      state: 'Kracheh',
    },
    {
      state: 'Krong Kaeb',
    },
    {
      state: 'Krong Pailin',
    },
    {
      state: 'Krong Preah Sihanouk',
    },
    {
      state: 'Mondol Kiri',
    },
    {
      state: 'Phnom Penh',
    },
    {
      state: 'Pousaat',
    },
    {
      state: 'Prey Veaeng',
    },
    {
      state: 'Rotanak Kiri',
    },
    {
      state: 'Siem Reab',
    },
    {
      state: 'Svaay Rieng',
    },
    {
      state: 'Taakaev',
    },
  ],
  CMR: [
    {
      state: 'Adamaoua',
    },
    {
      state: 'Centre',
    },
    {
      state: 'Est',
    },
    {
      state: 'Extreme-Nord',
    },
    {
      state: 'Littoral',
    },
    {
      state: 'Nord',
    },
    {
      state: 'Nord-Ouest',
    },
    {
      state: 'Ouest',
    },
    {
      state: 'Sud',
    },
    {
      state: 'Sud-Ouest',
    },
  ],
  CAN: [
    {
      state: 'Alberta',
    },
    {
      state: 'British Columbia',
    },
    {
      state: 'Manitoba',
    },
    {
      state: 'New Brunswick',
    },
    {
      state: 'Newfoundland and Labrador',
    },
    {
      state: 'Northwest Territories',
    },
    {
      state: 'Nova Scotia',
    },
    {
      state: 'Nunavut',
    },
    {
      state: 'Ontario',
    },
    {
      state: 'Prince Edward Island',
    },
    {
      state: 'Quebec',
    },
    {
      state: 'Saskatchewan',
    },
    {
      state: 'Yukon',
    },
  ],
  CYM: [
    {
      state: 'Cayman Islands',
    },
  ],
  CAF: [
    {
      state: 'Bangui',
    },
    {
      state: 'Nana-Mambere',
    },
    {
      state: 'Ouham',
    },
    {
      state: 'Ouham-Pende',
    },
  ],
  TCD: [
    {
      state: 'Guera',
    },
    {
      state: 'Logone-Occidental',
    },
    {
      state: 'Mayo-Kebbi-Est',
    },
    {
      state: 'Ouaddai',
    },
    {
      state: 'Tibesti',
    },
    {
      state: 'Ville de Ndjamena',
    },
  ],
  CHL: [
    {
      state: 'Aisen del General Carlos Ibanez del Campo',
    },
    {
      state: 'Antofagasta',
    },
    {
      state: 'Arica y Parinacota',
    },
    {
      state: 'Atacama',
    },
    {
      state: 'Biobio',
    },
    {
      state: 'Coquimbo',
    },
    {
      state: 'La Araucania',
    },
    {
      state: "Libertador General Bernardo O'Higgins",
    },
    {
      state: 'Los Lagos',
    },
    {
      state: 'Los Rios',
    },
    {
      state: 'Magallanes',
    },
    {
      state: 'Maule',
    },
    {
      state: 'Nuble',
    },
    {
      state: 'Region Metropolitana de Santiago',
    },
    {
      state: 'Tarapaca',
    },
    {
      state: 'Valparaiso',
    },
  ],
  CHN: [
    {
      state: 'Anhui',
    },
    {
      state: 'Beijing',
    },
    {
      state: 'Chongqing',
    },
    {
      state: 'Fujian',
    },
    {
      state: 'Gansu',
    },
    {
      state: 'Guangdong',
    },
    {
      state: 'Guangxi',
    },
    {
      state: 'Guizhou',
    },
    {
      state: 'Hainan',
    },
    {
      state: 'Hebei',
    },
    {
      state: 'Heilongjiang',
    },
    {
      state: 'Henan',
    },
    {
      state: 'Hubei',
    },
    {
      state: 'Hunan',
    },
    {
      state: 'Jiangsu',
    },
    {
      state: 'Jiangxi',
    },
    {
      state: 'Jilin',
    },
    {
      state: 'Liaoning',
    },
    {
      state: 'Nei Mongol',
    },
    {
      state: 'Ningxia',
    },
    {
      state: 'Qinghai',
    },
    {
      state: 'Shaanxi',
    },
    {
      state: 'Shandong',
    },
    {
      state: 'Shanghai',
    },
    {
      state: 'Shanxi',
    },
    {
      state: 'Sichuan',
    },
    {
      state: 'Tianjin',
    },
    {
      state: 'Xinjiang',
    },
    {
      state: 'Xizang',
    },
    {
      state: 'Yunnan',
    },
    {
      state: 'Zhejiang',
    },
  ],
  COL: [
    {
      state: 'Amazonas',
    },
    {
      state: 'Antioquia',
    },
    {
      state: 'Arauca',
    },
    {
      state: 'Atlantico',
    },
    {
      state: 'Bolivar',
    },
    {
      state: 'Boyaca',
    },
    {
      state: 'Caldas',
    },
    {
      state: 'Caqueta',
    },
    {
      state: 'Casanare',
    },
    {
      state: 'Cauca',
    },
    {
      state: 'Cesar',
    },
    {
      state: 'Choco',
    },
    {
      state: 'Cordoba',
    },
    {
      state: 'Cundinamarca',
    },
    {
      state: 'Distrito Capital de Bogota',
    },
    {
      state: 'Guainia',
    },
    {
      state: 'Guaviare',
    },
    {
      state: 'Huila',
    },
    {
      state: 'La Guajira',
    },
    {
      state: 'Magdalena',
    },
    {
      state: 'Meta',
    },
    {
      state: 'Narino',
    },
    {
      state: 'Norte de Santander',
    },
    {
      state: 'Putumayo',
    },
    {
      state: 'Quindio',
    },
    {
      state: 'Risaralda',
    },
    {
      state: 'San Andres, Providencia y Santa Catalina',
    },
    {
      state: 'Santander',
    },
    {
      state: 'Sucre',
    },
    {
      state: 'Tolima',
    },
    {
      state: 'Valle del Cauca',
    },
    {
      state: 'Vichada',
    },
  ],
  COM: [
    {
      state: 'Anjouan',
    },
    {
      state: 'Grande Comore',
    },
  ],
  COG: [
    {
      state: 'Brazzaville',
    },
    {
      state: 'Cuvette-Ouest',
    },
    {
      state: 'Pointe-Noire',
    },
    {
      state: 'Sangha',
    },
  ],
  COK: [
    {
      state: 'Cook Islands',
    },
  ],
  CRI: [
    {
      state: 'Alajuela',
    },
    {
      state: 'Cartago',
    },
    {
      state: 'Guanacaste',
    },
    {
      state: 'Heredia',
    },
    {
      state: 'Limon',
    },
    {
      state: 'Puntarenas',
    },
    {
      state: 'San Jose',
    },
  ],
  CIV: [
    {
      state: 'Abidjan',
    },
    {
      state: 'Bas-Sassandra',
    },
    {
      state: 'Comoe',
    },
    {
      state: 'Denguele',
    },
    {
      state: 'Goh-Djiboua',
    },
    {
      state: 'Lacs',
    },
    {
      state: 'Lagunes',
    },
    {
      state: 'Montagnes',
    },
    {
      state: 'Sassandra-Marahoue',
    },
    {
      state: 'Savanes',
    },
    {
      state: 'Vallee du Bandama',
    },
    {
      state: 'Woroba',
    },
    {
      state: 'Yamoussoukro',
    },
    {
      state: 'Zanzan',
    },
  ],
  HRV: [
    {
      state: 'Bjelovarsko-bilogorska zupanija',
    },
    {
      state: 'Brodsko-posavska zupanija',
    },
    {
      state: 'Dubrovacko-neretvanska zupanija',
    },
    {
      state: 'Grad Zagreb',
    },
    {
      state: 'Istarska zupanija',
    },
    {
      state: 'Karlovacka zupanija',
    },
    {
      state: 'Koprivnicko-krizevacka zupanija',
    },
    {
      state: 'Krapinsko-zagorska zupanija',
    },
    {
      state: 'Licko-senjska zupanija',
    },
    {
      state: 'Medimurska zupanija',
    },
    {
      state: 'Osjecko-baranjska zupanija',
    },
    {
      state: 'Pozesko-slavonska zupanija',
    },
    {
      state: 'Primorsko-goranska zupanija',
    },
    {
      state: 'Sibensko-kninska zupanija',
    },
    {
      state: 'Sisacko-moslavacka zupanija',
    },
    {
      state: 'Splitsko-dalmatinska zupanija',
    },
    {
      state: 'Varazdinska zupanija',
    },
    {
      state: 'Viroviticko-podravska zupanija',
    },
    {
      state: 'Vukovarsko-srijemska zupanija',
    },
    {
      state: 'Zadarska zupanija',
    },
    {
      state: 'Zagrebacka zupanija',
    },
  ],
  CUW: [
    {
      state: 'Curacao',
    },
  ],
  CYP: [
    {
      state: 'Ammochostos',
    },
    {
      state: 'Keryneia',
    },
    {
      state: 'Larnaka',
    },
    {
      state: 'Lefkosia',
    },
    {
      state: 'Lemesos',
    },
    {
      state: 'Pafos',
    },
  ],
  CZE: [
    {
      state: 'Jihocesky kraj',
    },
    {
      state: 'Jihomoravsky kraj',
    },
    {
      state: 'Karlovarsky kraj',
    },
    {
      state: 'Kraj Vysocina',
    },
    {
      state: 'Kralovehradecky kraj',
    },
    {
      state: 'Liberecky kraj',
    },
    {
      state: 'Moravskoslezsky kraj',
    },
    {
      state: 'Olomoucky kraj',
    },
    {
      state: 'Pardubicky kraj',
    },
    {
      state: 'Plzensky kraj',
    },
    {
      state: 'Praha, Hlavni mesto',
    },
    {
      state: 'Stredocesky kraj',
    },
    {
      state: 'Ustecky kraj',
    },
    {
      state: 'Zlinsky kraj',
    },
  ],
  DNK: [
    {
      state: 'Hovedstaden',
    },
    {
      state: 'Midtjylland',
    },
    {
      state: 'Nordjylland',
    },
    {
      state: 'Sjaelland',
    },
    {
      state: 'Syddanmark',
    },
  ],
  DJI: [
    {
      state: 'Djibouti',
    },
  ],
  DMA: [
    {
      state: 'Saint Andrew',
    },
    {
      state: 'Saint George',
    },
    {
      state: 'Saint John',
    },
    {
      state: 'Saint Mark',
    },
    {
      state: 'Saint Patrick',
    },
    {
      state: 'Saint Paul',
    },
  ],
  DOM: [
    {
      state: 'Azua',
    },
    {
      state: 'Baoruco',
    },
    {
      state: 'Barahona',
    },
    {
      state: 'Dajabon',
    },
    {
      state: 'Distrito Nacional (Santo Domingo)',
    },
    {
      state: 'Duarte',
    },
    {
      state: 'El Seibo',
    },
    {
      state: 'Espaillat',
    },
    {
      state: 'Hato Mayor',
    },
    {
      state: 'Hermanas Mirabal',
    },
    {
      state: 'Independencia',
    },
    {
      state: 'La Altagracia',
    },
    {
      state: 'La Romana',
    },
    {
      state: 'La Vega',
    },
    {
      state: 'Maria Trinidad Sanchez',
    },
    {
      state: 'Monsenor Nouel',
    },
    {
      state: 'Monte Cristi',
    },
    {
      state: 'Monte Plata',
    },
    {
      state: 'Peravia',
    },
    {
      state: 'Puerto Plata',
    },
    {
      state: 'Samana',
    },
    {
      state: 'San Cristobal',
    },
    {
      state: 'San Jose de Ocoa',
    },
    {
      state: 'San Juan',
    },
    {
      state: 'San Pedro de Macoris',
    },
    {
      state: 'Sanchez Ramirez',
    },
    {
      state: 'Santiago',
    },
    {
      state: 'Santiago Rodriguez',
    },
    {
      state: 'Valverde',
    },
  ],
  ECU: [
    {
      state: 'Azuay',
    },
    {
      state: 'Bolivar',
    },
    {
      state: 'Canar',
    },
    {
      state: 'Carchi',
    },
    {
      state: 'Chimborazo',
    },
    {
      state: 'Cotopaxi',
    },
    {
      state: 'El Oro',
    },
    {
      state: 'Esmeraldas',
    },
    {
      state: 'Galapagos',
    },
    {
      state: 'Guayas',
    },
    {
      state: 'Imbabura',
    },
    {
      state: 'Loja',
    },
    {
      state: 'Los Rios',
    },
    {
      state: 'Manabi',
    },
    {
      state: 'Morona Santiago',
    },
    {
      state: 'Napo',
    },
    {
      state: 'Orellana',
    },
    {
      state: 'Pastaza',
    },
    {
      state: 'Pichincha',
    },
    {
      state: 'Santa Elena',
    },
    {
      state: 'Santo Domingo de los Tsachilas',
    },
    {
      state: 'Sucumbios',
    },
    {
      state: 'Tungurahua',
    },
    {
      state: 'Zamora Chinchipe',
    },
  ],
  EGY: [
    {
      state: 'Ad Daqahliyah',
    },
    {
      state: 'Al Bahr al Ahmar',
    },
    {
      state: 'Al Buhayrah',
    },
    {
      state: 'Al Fayyum',
    },
    {
      state: 'Al Gharbiyah',
    },
    {
      state: 'Al Iskandariyah',
    },
    {
      state: "Al Isma'iliyah",
    },
    {
      state: 'Al Jizah',
    },
    {
      state: 'Al Minufiyah',
    },
    {
      state: 'Al Minya',
    },
    {
      state: 'Al Qahirah',
    },
    {
      state: 'Al Qalyubiyah',
    },
    {
      state: 'Al Uqsur',
    },
    {
      state: 'Al Wadi al Jadid',
    },
    {
      state: 'As Suways',
    },
    {
      state: 'Ash Sharqiyah',
    },
    {
      state: 'Aswan',
    },
    {
      state: 'Asyut',
    },
    {
      state: 'Bani Suwayf',
    },
    {
      state: "Bur Sa'id",
    },
    {
      state: 'Dumyat',
    },
    {
      state: "Janub Sina'",
    },
    {
      state: 'Kafr ash Shaykh',
    },
    {
      state: 'Matruh',
    },
    {
      state: 'Qina',
    },
    {
      state: "Shamal Sina'",
    },
    {
      state: 'Suhaj',
    },
  ],
  SLV: [
    {
      state: 'Ahuachapan',
    },
    {
      state: 'Cabanas',
    },
    {
      state: 'Chalatenango',
    },
    {
      state: 'Cuscatlan',
    },
    {
      state: 'La Libertad',
    },
    {
      state: 'La Paz',
    },
    {
      state: 'La Union',
    },
    {
      state: 'Morazan',
    },
    {
      state: 'San Miguel',
    },
    {
      state: 'San Salvador',
    },
    {
      state: 'San Vicente',
    },
    {
      state: 'Santa Ana',
    },
    {
      state: 'Sonsonate',
    },
    {
      state: 'Usulutan',
    },
  ],
  GNQ: [
    {
      state: 'Bioko Norte',
    },
    {
      state: 'Bioko Sur',
    },
    {
      state: 'Litoral',
    },
    {
      state: 'Wele-Nzas',
    },
  ],
  ERI: [
    {
      state: 'Al Awsat',
    },
  ],
  EST: [
    {
      state: 'Harjumaa',
    },
    {
      state: 'Hiiumaa',
    },
    {
      state: 'Ida-Virumaa',
    },
    {
      state: 'Jarvamaa',
    },
    {
      state: 'Jogevamaa',
    },
    {
      state: 'Laane-Virumaa',
    },
    {
      state: 'Laanemaa',
    },
    {
      state: 'Parnumaa',
    },
    {
      state: 'Polvamaa',
    },
    {
      state: 'Raplamaa',
    },
    {
      state: 'Saaremaa',
    },
    {
      state: 'Tartumaa',
    },
    {
      state: 'Valgamaa',
    },
    {
      state: 'Viljandimaa',
    },
    {
      state: 'Vorumaa',
    },
  ],
  SWZ: [
    {
      state: 'Hhohho',
    },
    {
      state: 'Lubombo',
    },
    {
      state: 'Manzini',
    },
  ],
  ETH: [
    {
      state: 'Adis Abeba',
    },
    {
      state: 'Afar',
    },
    {
      state: 'Amara',
    },
    {
      state: 'Binshangul Gumuz',
    },
    {
      state: 'Dire Dawa',
    },
    {
      state: 'Hareri Hizb',
    },
    {
      state: 'Oromiya',
    },
    {
      state: 'Sumale',
    },
    {
      state: 'Tigray',
    },
    {
      state: 'YeDebub Biheroch Bihereseboch na Hizboch',
    },
  ],
  FLK: [
    {
      state: 'Falkland Islands (Malvinas)',
    },
  ],
  FRO: [
    {
      state: 'Eysturoy',
    },
    {
      state: 'Nordoyar',
    },
    {
      state: 'Streymoy',
    },
    {
      state: 'Suduroy',
    },
    {
      state: 'Vagar',
    },
  ],
  FJI: [
    {
      state: 'Central',
    },
    {
      state: 'Eastern',
    },
    {
      state: 'Northern',
    },
    {
      state: 'Rotuma',
    },
    {
      state: 'Western',
    },
  ],
  FIN: [
    {
      state: 'Etela-Karjala',
    },
    {
      state: 'Etela-Pohjanmaa',
    },
    {
      state: 'Etela-Savo',
    },
    {
      state: 'Kainuu',
    },
    {
      state: 'Kanta-Hame',
    },
    {
      state: 'Keski-Pohjanmaa',
    },
    {
      state: 'Keski-Suomi',
    },
    {
      state: 'Kymenlaakso',
    },
    {
      state: 'Lappi',
    },
    {
      state: 'Paijat-Hame',
    },
    {
      state: 'Pirkanmaa',
    },
    {
      state: 'Pohjanmaa',
    },
    {
      state: 'Pohjois-Karjala',
    },
    {
      state: 'Pohjois-Pohjanmaa',
    },
    {
      state: 'Pohjois-Savo',
    },
    {
      state: 'Satakunta',
    },
    {
      state: 'Uusimaa',
    },
    {
      state: 'Varsinais-Suomi',
    },
  ],
  FRA: [
    {
      state: 'Auvergne-Rhone-Alpes',
    },
    {
      state: 'Bourgogne-Franche-Comte',
    },
    {
      state: 'Bretagne',
    },
    {
      state: 'Centre-Val de Loire',
    },
    {
      state: 'Corse',
    },
    {
      state: 'Grand-Est',
    },
    {
      state: 'Hauts-de-France',
    },
    {
      state: 'Ile-de-France',
    },
    {
      state: 'Normandie',
    },
    {
      state: 'Nouvelle-Aquitaine',
    },
    {
      state: 'Occitanie',
    },
    {
      state: 'Pays-de-la-Loire',
    },
    {
      state: "Provence-Alpes-Cote-d'Azur",
    },
  ],
  GUF: [
    {
      state: 'Guyane',
    },
  ],
  PYF: [
    {
      state: 'Iles Marquises',
    },
    {
      state: 'Iles Sous-le-Vent',
    },
    {
      state: 'Iles Tuamotu-Gambier',
    },
    {
      state: 'Iles du Vent',
    },
  ],
  GAB: [
    {
      state: 'Estuaire',
    },
    {
      state: 'Haut-Ogooue',
    },
    {
      state: 'Ngounie',
    },
    {
      state: 'Ogooue-Ivindo',
    },
    {
      state: 'Ogooue-Lolo',
    },
    {
      state: 'Ogooue-Maritime',
    },
    {
      state: 'Woleu-Ntem',
    },
  ],
  GMB: [
    {
      state: 'Banjul',
    },
    {
      state: 'Central River',
    },
    {
      state: 'Lower River',
    },
    {
      state: 'North Bank',
    },
    {
      state: 'Upper River',
    },
    {
      state: 'Western',
    },
  ],
  GEO: [
    {
      state: 'Abkhazia',
    },
    {
      state: 'Ajaria',
    },
    {
      state: 'Guria',
    },
    {
      state: 'Imereti',
    },
    {
      state: "K'akheti",
    },
    {
      state: 'Kvemo Kartli',
    },
    {
      state: 'Mtskheta-Mtianeti',
    },
    {
      state: "Rach'a-Lechkhumi-Kvemo Svaneti",
    },
    {
      state: 'Samegrelo-Zemo Svaneti',
    },
    {
      state: 'Samtskhe-Javakheti',
    },
    {
      state: 'Shida Kartli',
    },
    {
      state: 'Tbilisi',
    },
  ],
  DEU: [
    {
      state: 'Baden-Wurttemberg',
    },
    {
      state: 'Bayern',
    },
    {
      state: 'Berlin',
    },
    {
      state: 'Brandenburg',
    },
    {
      state: 'Bremen',
    },
    {
      state: 'Hamburg',
    },
    {
      state: 'Hessen',
    },
    {
      state: 'Mecklenburg-Vorpommern',
    },
    {
      state: 'Niedersachsen',
    },
    {
      state: 'Nordrhein-Westfalen',
    },
    {
      state: 'Rheinland-Pfalz',
    },
    {
      state: 'Saarland',
    },
    {
      state: 'Sachsen',
    },
    {
      state: 'Sachsen-Anhalt',
    },
    {
      state: 'Schleswig-Holstein',
    },
    {
      state: 'Thuringen',
    },
  ],
  GHA: [
    {
      state: 'Ashanti',
    },
    {
      state: 'Brong-Ahafo',
    },
    {
      state: 'Central',
    },
    {
      state: 'Eastern',
    },
    {
      state: 'Greater Accra',
    },
    {
      state: 'Northern',
    },
    {
      state: 'Upper East',
    },
    {
      state: 'Volta',
    },
    {
      state: 'Western',
    },
  ],
  GIB: [
    {
      state: 'Gibraltar',
    },
  ],
  GRC: [
    {
      state: 'Agion Oros',
    },
    {
      state: 'Anatoliki Makedonia kai Thraki',
    },
    {
      state: 'Attiki',
    },
    {
      state: 'Dytiki Ellada',
    },
    {
      state: 'Dytiki Makedonia',
    },
    {
      state: 'Ionia Nisia',
    },
    {
      state: 'Ipeiros',
    },
    {
      state: 'Kentriki Makedonia',
    },
    {
      state: 'Kriti',
    },
    {
      state: 'Notio Aigaio',
    },
    {
      state: 'Peloponnisos',
    },
    {
      state: 'Sterea Ellada',
    },
    {
      state: 'Thessalia',
    },
    {
      state: 'Voreio Aigaio',
    },
  ],
  GRL: [
    {
      state: 'Avannaata Kommunia',
    },
    {
      state: 'Kommune Kujalleq',
    },
    {
      state: 'Kommune Qeqertalik',
    },
    {
      state: 'Kommuneqarfik Sermersooq',
    },
    {
      state: 'Qeqqata Kommunia',
    },
  ],
  GRD: [
    {
      state: 'Saint Andrew',
    },
    {
      state: 'Saint David',
    },
    {
      state: 'Saint George',
    },
    {
      state: 'Saint John',
    },
    {
      state: 'Saint Mark',
    },
    {
      state: 'Saint Patrick',
    },
    {
      state: 'Southern Grenadine Islands',
    },
  ],
  GLP: [
    {
      state: 'Guadeloupe',
    },
  ],
  GUM: [
    {
      state: 'Agat',
    },
    {
      state: 'Barrigada',
    },
    {
      state: 'Chalan Pago-Ordot',
    },
    {
      state: 'Dededo',
    },
    {
      state: 'Hagatna',
    },
    {
      state: 'Inarajan',
    },
    {
      state: 'Mangilao',
    },
    {
      state: 'Mongmong-Toto-Maite',
    },
    {
      state: 'Piti',
    },
    {
      state: 'Santa Rita',
    },
    {
      state: 'Sinajana',
    },
    {
      state: 'Tamuning-Tumon-Harmon',
    },
    {
      state: 'Yigo',
    },
    {
      state: 'Yona',
    },
  ],
  GTM: [
    {
      state: 'Alta Verapaz',
    },
    {
      state: 'Baja Verapaz',
    },
    {
      state: 'Chimaltenango',
    },
    {
      state: 'Chiquimula',
    },
    {
      state: 'El Progreso',
    },
    {
      state: 'Escuintla',
    },
    {
      state: 'Guatemala',
    },
    {
      state: 'Huehuetenango',
    },
    {
      state: 'Izabal',
    },
    {
      state: 'Jalapa',
    },
    {
      state: 'Jutiapa',
    },
    {
      state: 'Peten',
    },
    {
      state: 'Quetzaltenango',
    },
    {
      state: 'Quiche',
    },
    {
      state: 'Retalhuleu',
    },
    {
      state: 'Sacatepequez',
    },
    {
      state: 'San Marcos',
    },
    {
      state: 'Santa Rosa',
    },
    {
      state: 'Solola',
    },
    {
      state: 'Suchitepequez',
    },
    {
      state: 'Totonicapan',
    },
    {
      state: 'Zacapa',
    },
  ],
  GGY: [
    {
      state: 'Guernsey',
    },
  ],
  GIN: [
    {
      state: 'Boffa',
    },
    {
      state: 'Boke',
    },
    {
      state: 'Conakry',
    },
    {
      state: 'Coyah',
    },
    {
      state: 'Dabola',
    },
    {
      state: 'Dubreka',
    },
    {
      state: 'Fria',
    },
    {
      state: 'Kankan',
    },
    {
      state: 'Kerouane',
    },
    {
      state: 'Nzerekore',
    },
    {
      state: 'Siguiri',
    },
  ],
  GNB: [
    {
      state: 'Bissau',
    },
    {
      state: 'Gabu',
    },
  ],
  GUY: [
    {
      state: 'Barima-Waini',
    },
    {
      state: 'Demerara-Mahaica',
    },
    {
      state: 'East Berbice-Corentyne',
    },
    {
      state: 'Essequibo Islands-West Demerara',
    },
    {
      state: 'Mahaica-Berbice',
    },
    {
      state: 'Potaro-Siparuni',
    },
    {
      state: 'Upper Demerara-Berbice',
    },
  ],
  HTI: [
    {
      state: 'Artibonite',
    },
    {
      state: 'Centre',
    },
    {
      state: "Grande'Anse",
    },
    {
      state: 'Nord',
    },
    {
      state: 'Ouest',
    },
    {
      state: 'Sud',
    },
    {
      state: 'Sud-Est',
    },
  ],
  VAT: [
    {
      state: 'Vatican City',
    },
  ],
  HND: [
    {
      state: 'Atlantida',
    },
    {
      state: 'Choluteca',
    },
    {
      state: 'Colon',
    },
    {
      state: 'Comayagua',
    },
    {
      state: 'Copan',
    },
    {
      state: 'Cortes',
    },
    {
      state: 'El Paraiso',
    },
    {
      state: 'Francisco Morazan',
    },
    {
      state: 'Intibuca',
    },
    {
      state: 'Islas de la Bahia',
    },
    {
      state: 'La Paz',
    },
    {
      state: 'Lempira',
    },
    {
      state: 'Ocotepeque',
    },
    {
      state: 'Olancho',
    },
    {
      state: 'Santa Barbara',
    },
    {
      state: 'Valle',
    },
    {
      state: 'Yoro',
    },
  ],
  HKG: [
    {
      state: 'Hong Kong',
    },
  ],
  HUN: [
    {
      state: 'Bacs-Kiskun',
    },
    {
      state: 'Baranya',
    },
    {
      state: 'Bekes',
    },
    {
      state: 'Borsod-Abauj-Zemplen',
    },
    {
      state: 'Budapest',
    },
    {
      state: 'Csongrad',
    },
    {
      state: 'Fejer',
    },
    {
      state: 'Gyor-Moson-Sopron',
    },
    {
      state: 'Hajdu-Bihar',
    },
    {
      state: 'Heves',
    },
    {
      state: 'Jasz-Nagykun-Szolnok',
    },
    {
      state: 'Komarom-Esztergom',
    },
    {
      state: 'Nograd',
    },
    {
      state: 'Pest',
    },
    {
      state: 'Somogy',
    },
    {
      state: 'Szabolcs-Szatmar-Bereg',
    },
    {
      state: 'Tolna',
    },
    {
      state: 'Vas',
    },
    {
      state: 'Veszprem',
    },
    {
      state: 'Zala',
    },
  ],
  ISL: [
    {
      state: 'Austurland',
    },
    {
      state: 'Hofudborgarsvaedi',
    },
    {
      state: 'Nordurland eystra',
    },
    {
      state: 'Nordurland vestra',
    },
    {
      state: 'Sudurland',
    },
    {
      state: 'Sudurnes',
    },
    {
      state: 'Vestfirdir',
    },
    {
      state: 'Vesturland',
    },
  ],
  IND: [
    {
      state: 'Andaman and Nicobar Islands',
    },
    {
      state: 'Andhra Pradesh',
    },
    {
      state: 'Arunachal Pradesh',
    },
    {
      state: 'Assam',
    },
    {
      state: 'Bihar',
    },
    {
      state: 'Chandigarh',
    },
    {
      state: 'Chhattisgarh',
    },
    {
      state: 'Dadra and Nagar Haveli',
    },
    {
      state: 'Daman and Diu',
    },
    {
      state: 'Delhi',
    },
    {
      state: 'Goa',
    },
    {
      state: 'Gujarat',
    },
    {
      state: 'Haryana',
    },
    {
      state: 'Himachal Pradesh',
    },
    {
      state: 'Jammu and Kashmir',
    },
    {
      state: 'Jharkhand',
    },
    {
      state: 'Karnataka',
    },
    {
      state: 'Kerala',
    },
    {
      state: 'Lakshadweep',
    },
    {
      state: 'Madhya Pradesh',
    },
    {
      state: 'Maharashtra',
    },
    {
      state: 'Manipur',
    },
    {
      state: 'Meghalaya',
    },
    {
      state: 'Mizoram',
    },
    {
      state: 'Nagaland',
    },
    {
      state: 'Odisha',
    },
    {
      state: 'Puducherry',
    },
    {
      state: 'Punjab',
    },
    {
      state: 'Rajasthan',
    },
    {
      state: 'Sikkim',
    },
    {
      state: 'Tamil Nadu',
    },
    {
      state: 'Telangana',
    },
    {
      state: 'Tripura',
    },
    {
      state: 'Uttar Pradesh',
    },
    {
      state: 'Uttarakhand',
    },
    {
      state: 'West Bengal',
    },
  ],
  IDN: [
    {
      state: 'Aceh',
    },
    {
      state: 'Bali',
    },
    {
      state: 'Banten',
    },
    {
      state: 'Bengkulu',
    },
    {
      state: 'Gorontalo',
    },
    {
      state: 'Jakarta Raya',
    },
    {
      state: 'Jambi',
    },
    {
      state: 'Jawa Barat',
    },
    {
      state: 'Jawa Tengah',
    },
    {
      state: 'Jawa Timur',
    },
    {
      state: 'Kalimantan Barat',
    },
    {
      state: 'Kalimantan Selatan',
    },
    {
      state: 'Kalimantan Tengah',
    },
    {
      state: 'Kalimantan Timur',
    },
    {
      state: 'Kalimantan Utara',
    },
    {
      state: 'Kepulauan Bangka Belitung',
    },
    {
      state: 'Kepulauan Riau',
    },
    {
      state: 'Lampung',
    },
    {
      state: 'Maluku',
    },
    {
      state: 'Maluku Utara',
    },
    {
      state: 'Nusa Tenggara Barat',
    },
    {
      state: 'Nusa Tenggara Timur',
    },
    {
      state: 'Papua',
    },
    {
      state: 'Papua Barat',
    },
    {
      state: 'Riau',
    },
    {
      state: 'Sulawesi Barat',
    },
    {
      state: 'Sulawesi Selatan',
    },
    {
      state: 'Sulawesi Tengah',
    },
    {
      state: 'Sulawesi Tenggara',
    },
    {
      state: 'Sulawesi Utara',
    },
    {
      state: 'Sumatera Barat',
    },
    {
      state: 'Sumatera Selatan',
    },
    {
      state: 'Sumatera Utara',
    },
    {
      state: 'Yogyakarta',
    },
  ],
  IRQ: [
    {
      state: 'Al Anbar',
    },
    {
      state: 'Al Basrah',
    },
    {
      state: 'Al Muthanna',
    },
    {
      state: 'Al Qadisiyah',
    },
    {
      state: 'An Najaf',
    },
    {
      state: 'Arbil',
    },
    {
      state: 'As Sulaymaniyah',
    },
    {
      state: 'Babil',
    },
    {
      state: 'Baghdad',
    },
    {
      state: 'Dahuk',
    },
    {
      state: 'Dhi Qar',
    },
    {
      state: 'Diyala',
    },
    {
      state: "Karbala'",
    },
    {
      state: 'Kirkuk',
    },
    {
      state: 'Maysan',
    },
    {
      state: 'Ninawa',
    },
    {
      state: 'Salah ad Din',
    },
    {
      state: 'Wasit',
    },
  ],
  IRL: [
    {
      state: 'Carlow',
    },
    {
      state: 'Cavan',
    },
    {
      state: 'Clare',
    },
    {
      state: 'Cork',
    },
    {
      state: 'Donegal',
    },
    {
      state: 'Dublin',
    },
    {
      state: 'Galway',
    },
    {
      state: 'Kerry',
    },
    {
      state: 'Kildare',
    },
    {
      state: 'Kilkenny',
    },
    {
      state: 'Laois',
    },
    {
      state: 'Leitrim',
    },
    {
      state: 'Limerick',
    },
    {
      state: 'Longford',
    },
    {
      state: 'Louth',
    },
    {
      state: 'Mayo',
    },
    {
      state: 'Meath',
    },
    {
      state: 'Monaghan',
    },
    {
      state: 'Offaly',
    },
    {
      state: 'Roscommon',
    },
    {
      state: 'Sligo',
    },
    {
      state: 'Tipperary',
    },
    {
      state: 'Waterford',
    },
    {
      state: 'Westmeath',
    },
    {
      state: 'Wexford',
    },
    {
      state: 'Wicklow',
    },
  ],
  IMN: [
    {
      state: 'Isle of Man',
    },
  ],
  ISR: [
    {
      state: 'HaDarom',
    },
    {
      state: 'HaMerkaz',
    },
    {
      state: 'HaTsafon',
    },
    {
      state: 'Hefa',
    },
    {
      state: 'Tel Aviv',
    },
    {
      state: 'Yerushalayim',
    },
  ],
  ITA: [
    {
      state: 'Abruzzo',
    },
    {
      state: 'Basilicata',
    },
    {
      state: 'Calabria',
    },
    {
      state: 'Campania',
    },
    {
      state: 'Emilia-Romagna',
    },
    {
      state: 'Friuli-Venezia Giulia',
    },
    {
      state: 'Lazio',
    },
    {
      state: 'Liguria',
    },
    {
      state: 'Lombardia',
    },
    {
      state: 'Marche',
    },
    {
      state: 'Molise',
    },
    {
      state: 'Piemonte',
    },
    {
      state: 'Puglia',
    },
    {
      state: 'Sardegna',
    },
    {
      state: 'Sicilia',
    },
    {
      state: 'Toscana',
    },
    {
      state: 'Trentino-Alto Adige',
    },
    {
      state: 'Umbria',
    },
    {
      state: "Valle d'Aosta",
    },
    {
      state: 'Veneto',
    },
  ],
  JAM: [
    {
      state: 'Clarendon',
    },
    {
      state: 'Hanover',
    },
    {
      state: 'Kingston',
    },
    {
      state: 'Manchester',
    },
    {
      state: 'Portland',
    },
    {
      state: 'Saint Andrew',
    },
    {
      state: 'Saint Ann',
    },
    {
      state: 'Saint Catherine',
    },
    {
      state: 'Saint Elizabeth',
    },
    {
      state: 'Saint James',
    },
    {
      state: 'Saint Mary',
    },
    {
      state: 'Saint Thomas',
    },
    {
      state: 'Trelawny',
    },
    {
      state: 'Westmoreland',
    },
  ],
  JPN: [
    {
      state: 'Aichi',
    },
    {
      state: 'Akita',
    },
    {
      state: 'Aomori',
    },
    {
      state: 'Chiba',
    },
    {
      state: 'Ehime',
    },
    {
      state: 'Fukui',
    },
    {
      state: 'Fukuoka',
    },
    {
      state: 'Fukushima',
    },
    {
      state: 'Gifu',
    },
    {
      state: 'Gunma',
    },
    {
      state: 'Hiroshima',
    },
    {
      state: 'Hokkaido',
    },
    {
      state: 'Hyogo',
    },
    {
      state: 'Ibaraki',
    },
    {
      state: 'Ishikawa',
    },
    {
      state: 'Iwate',
    },
    {
      state: 'Kagawa',
    },
    {
      state: 'Kagoshima',
    },
    {
      state: 'Kanagawa',
    },
    {
      state: 'Kochi',
    },
    {
      state: 'Kumamoto',
    },
    {
      state: 'Kyoto',
    },
    {
      state: 'Mie',
    },
    {
      state: 'Miyagi',
    },
    {
      state: 'Miyazaki',
    },
    {
      state: 'Nagano',
    },
    {
      state: 'Nagasaki',
    },
    {
      state: 'Nara',
    },
    {
      state: 'Niigata',
    },
    {
      state: 'Oita',
    },
    {
      state: 'Okayama',
    },
    {
      state: 'Okinawa',
    },
    {
      state: 'Osaka',
    },
    {
      state: 'Saga',
    },
    {
      state: 'Saitama',
    },
    {
      state: 'Shiga',
    },
    {
      state: 'Shimane',
    },
    {
      state: 'Shizuoka',
    },
    {
      state: 'Tochigi',
    },
    {
      state: 'Tokushima',
    },
    {
      state: 'Tokyo',
    },
    {
      state: 'Tottori',
    },
    {
      state: 'Toyama',
    },
    {
      state: 'Wakayama',
    },
    {
      state: 'Yamagata',
    },
    {
      state: 'Yamaguchi',
    },
    {
      state: 'Yamanashi',
    },
  ],
  JEY: [
    {
      state: 'Jersey',
    },
  ],
  JOR: [
    {
      state: "'Ajlun",
    },
    {
      state: "Al 'Aqabah",
    },
    {
      state: "Al 'Asimah",
    },
    {
      state: "Al Balqa'",
    },
    {
      state: 'Al Karak',
    },
    {
      state: 'Al Mafraq',
    },
    {
      state: "Az Zarqa'",
    },
    {
      state: 'Irbid',
    },
    {
      state: 'Jarash',
    },
    {
      state: "Ma'an",
    },
    {
      state: 'Madaba',
    },
  ],
  KAZ: [
    {
      state: 'Almaty',
    },
    {
      state: 'Almaty oblysy',
    },
    {
      state: 'Aqmola oblysy',
    },
    {
      state: 'Aqtobe oblysy',
    },
    {
      state: 'Astana',
    },
    {
      state: 'Atyrau oblysy',
    },
    {
      state: 'Batys Qazaqstan oblysy',
    },
    {
      state: 'Bayqongyr',
    },
    {
      state: 'Mangghystau oblysy',
    },
    {
      state: 'Ongtustik Qazaqstan oblysy',
    },
    {
      state: 'Pavlodar oblysy',
    },
    {
      state: 'Qaraghandy oblysy',
    },
    {
      state: 'Qostanay oblysy',
    },
    {
      state: 'Qyzylorda oblysy',
    },
    {
      state: 'Shyghys Qazaqstan oblysy',
    },
    {
      state: 'Soltustik Qazaqstan oblysy',
    },
    {
      state: 'Zhambyl oblysy',
    },
  ],
  KEN: [
    {
      state: 'Baringo',
    },
    {
      state: 'Bomet',
    },
    {
      state: 'Bungoma',
    },
    {
      state: 'Busia',
    },
    {
      state: 'Elgeyo/Marakwet',
    },
    {
      state: 'Embu',
    },
    {
      state: 'Garissa',
    },
    {
      state: 'Homa Bay',
    },
    {
      state: 'Isiolo',
    },
    {
      state: 'Kajiado',
    },
    {
      state: 'Kakamega',
    },
    {
      state: 'Kericho',
    },
    {
      state: 'Kiambu',
    },
    {
      state: 'Kilifi',
    },
    {
      state: 'Kirinyaga',
    },
    {
      state: 'Kisii',
    },
    {
      state: 'Kisumu',
    },
    {
      state: 'Kitui',
    },
    {
      state: 'Kwale',
    },
    {
      state: 'Laikipia',
    },
    {
      state: 'Lamu',
    },
    {
      state: 'Machakos',
    },
    {
      state: 'Makueni',
    },
    {
      state: 'Mandera',
    },
    {
      state: 'Marsabit',
    },
    {
      state: 'Meru',
    },
    {
      state: 'Migori',
    },
    {
      state: 'Mombasa',
    },
    {
      state: "Murang'a",
    },
    {
      state: 'Nairobi City',
    },
    {
      state: 'Nakuru',
    },
    {
      state: 'Nandi',
    },
    {
      state: 'Narok',
    },
    {
      state: 'Nyamira',
    },
    {
      state: 'Nyandarua',
    },
    {
      state: 'Nyeri',
    },
    {
      state: 'Siaya',
    },
    {
      state: 'Taita/Taveta',
    },
    {
      state: 'Tharaka-Nithi',
    },
    {
      state: 'Trans Nzoia',
    },
    {
      state: 'Turkana',
    },
    {
      state: 'Uasin Gishu',
    },
    {
      state: 'Wajir',
    },
  ],
  KIR: [
    {
      state: 'Gilbert Islands',
    },
  ],
  KWT: [
    {
      state: "Al 'Asimah",
    },
    {
      state: 'Al Ahmadi',
    },
    {
      state: 'Al Farwaniyah',
    },
    {
      state: "Al Jahra'",
    },
    {
      state: 'Hawalli',
    },
    {
      state: 'Mubarak al Kabir',
    },
  ],
  KGZ: [
    {
      state: 'Batken',
    },
    {
      state: 'Bishkek',
    },
    {
      state: 'Chuy',
    },
    {
      state: 'Jalal-Abad',
    },
    {
      state: 'Naryn',
    },
    {
      state: 'Osh',
    },
    {
      state: 'Talas',
    },
    {
      state: 'Ysyk-Kol',
    },
  ],
  LAO: [
    {
      state: 'Bolikhamxai',
    },
    {
      state: 'Champasak',
    },
    {
      state: 'Houaphan',
    },
    {
      state: 'Khammouan',
    },
    {
      state: 'Louang Namtha',
    },
    {
      state: 'Louangphabang',
    },
    {
      state: 'Oudomxai',
    },
    {
      state: 'Phongsali',
    },
    {
      state: 'Savannakhet',
    },
    {
      state: 'Viangchan',
    },
    {
      state: 'Xaignabouli',
    },
    {
      state: 'Xekong',
    },
    {
      state: 'Xiangkhouang',
    },
  ],
  LVA: [
    {
      state: 'Adazu novads',
    },
    {
      state: 'Aglonas novads',
    },
    {
      state: 'Aizkraukles novads',
    },
    {
      state: 'Aizputes novads',
    },
    {
      state: 'Alojas novads',
    },
    {
      state: 'Aluksnes novads',
    },
    {
      state: 'Babites novads',
    },
    {
      state: 'Baldones novads',
    },
    {
      state: 'Balvu novads',
    },
    {
      state: 'Bauskas novads',
    },
    {
      state: 'Beverinas novads',
    },
    {
      state: 'Brocenu novads',
    },
    {
      state: 'Carnikavas novads',
    },
    {
      state: 'Cesu novads',
    },
    {
      state: 'Cesvaines novads',
    },
    {
      state: 'Daugavpils novads',
    },
    {
      state: 'Dobeles novads',
    },
    {
      state: 'Dundagas novads',
    },
    {
      state: 'Erglu novads',
    },
    {
      state: 'Gulbenes novads',
    },
    {
      state: 'Iecavas novads',
    },
    {
      state: 'Ikskiles novads',
    },
    {
      state: 'Incukalna novads',
    },
    {
      state: 'Jaunjelgavas novads',
    },
    {
      state: 'Jaunpiebalgas novads',
    },
    {
      state: 'Jaunpils novads',
    },
    {
      state: 'Jekabpils novads',
    },
    {
      state: 'Jelgava',
    },
    {
      state: 'Jelgavas novads',
    },
    {
      state: 'Jurmala',
    },
    {
      state: 'Kekavas novads',
    },
    {
      state: 'Kokneses novads',
    },
    {
      state: 'Kraslavas novads',
    },
    {
      state: 'Kuldigas novads',
    },
    {
      state: 'Lielvardes novads',
    },
    {
      state: 'Liepaja',
    },
    {
      state: 'Limbazu novads',
    },
    {
      state: 'Livanu novads',
    },
    {
      state: 'Lubanas novads',
    },
    {
      state: 'Ludzas novads',
    },
    {
      state: 'Madonas novads',
    },
    {
      state: 'Malpils novads',
    },
    {
      state: 'Nauksenu novads',
    },
    {
      state: 'Ogres novads',
    },
    {
      state: 'Olaines novads',
    },
    {
      state: 'Ozolnieku novads',
    },
    {
      state: 'Preilu novads',
    },
    {
      state: 'Rezeknes novads',
    },
    {
      state: 'Riebinu novads',
    },
    {
      state: 'Riga',
    },
    {
      state: 'Rojas novads',
    },
    {
      state: 'Ropazu novads',
    },
    {
      state: 'Rundales novads',
    },
    {
      state: 'Salacgrivas novads',
    },
    {
      state: 'Salaspils novads',
    },
    {
      state: 'Saldus novads',
    },
    {
      state: 'Saulkrastu novads',
    },
    {
      state: 'Sejas novads',
    },
    {
      state: 'Siguldas novads',
    },
    {
      state: 'Skrundas novads',
    },
    {
      state: 'Stopinu novads',
    },
    {
      state: 'Talsu novads',
    },
    {
      state: 'Tukuma novads',
    },
    {
      state: 'Valkas novads',
    },
    {
      state: 'Valmiera',
    },
    {
      state: 'Varkavas novads',
    },
    {
      state: 'Vecumnieku novads',
    },
    {
      state: 'Ventspils novads',
    },
  ],
  LBN: [
    {
      state: 'Aakkar',
    },
    {
      state: 'Baalbek-Hermel',
    },
    {
      state: 'Beqaa',
    },
    {
      state: 'Beyrouth',
    },
    {
      state: 'Liban-Nord',
    },
    {
      state: 'Liban-Sud',
    },
    {
      state: 'Mont-Liban',
    },
    {
      state: 'Nabatiye',
    },
  ],
  LSO: [
    {
      state: 'Berea',
    },
    {
      state: 'Leribe',
    },
    {
      state: 'Mafeteng',
    },
    {
      state: 'Maseru',
    },
    {
      state: 'Quthing',
    },
    {
      state: 'Thaba-Tseka',
    },
  ],
  LBR: [
    {
      state: 'Grand Bassa',
    },
    {
      state: 'Grand Gedeh',
    },
    {
      state: 'Margibi',
    },
    {
      state: 'Montserrado',
    },
    {
      state: 'Sinoe',
    },
  ],
  LBY: [
    {
      state: 'Al Butnan',
    },
    {
      state: 'Al Jabal al Akhdar',
    },
    {
      state: 'Al Jabal al Gharbi',
    },
    {
      state: 'Al Jafarah',
    },
    {
      state: 'Al Jufrah',
    },
    {
      state: 'Al Kufrah',
    },
    {
      state: 'Al Marj',
    },
    {
      state: 'Al Marqab',
    },
    {
      state: 'Al Wahat',
    },
    {
      state: 'An Nuqat al Khams',
    },
    {
      state: 'Az Zawiyah',
    },
    {
      state: 'Banghazi',
    },
    {
      state: 'Darnah',
    },
    {
      state: 'Misratah',
    },
    {
      state: 'Murzuq',
    },
    {
      state: 'Nalut',
    },
    {
      state: 'Sabha',
    },
    {
      state: 'Surt',
    },
    {
      state: 'Tarabulus',
    },
    {
      state: "Wadi ash Shati'",
    },
  ],
  LIE: [
    {
      state: 'Balzers',
    },
    {
      state: 'Eschen',
    },
    {
      state: 'Gamprin',
    },
    {
      state: 'Mauren',
    },
    {
      state: 'Planken',
    },
    {
      state: 'Ruggell',
    },
    {
      state: 'Schaan',
    },
    {
      state: 'Schellenberg',
    },
    {
      state: 'Triesen',
    },
    {
      state: 'Triesenberg',
    },
    {
      state: 'Vaduz',
    },
  ],
  LTU: [
    {
      state: 'Alytaus apskritis',
    },
    {
      state: 'Kauno apskritis',
    },
    {
      state: 'Klaipedos apskritis',
    },
    {
      state: 'Marijampoles apskritis',
    },
    {
      state: 'Panevezio apskritis',
    },
    {
      state: 'Siauliu apskritis',
    },
    {
      state: 'Taurages apskritis',
    },
    {
      state: 'Telsiu apskritis',
    },
    {
      state: 'Utenos apskritis',
    },
    {
      state: 'Vilniaus apskritis',
    },
  ],
  LUX: [
    {
      state: 'Diekirch',
    },
    {
      state: 'Grevenmacher',
    },
    {
      state: 'Luxembourg',
    },
  ],
  MAC: [
    {
      state: 'Macao',
    },
  ],
  MDG: [
    {
      state: 'Antananarivo',
    },
    {
      state: 'Antsiranana',
    },
    {
      state: 'Fianarantsoa',
    },
    {
      state: 'Mahajanga',
    },
    {
      state: 'Toamasina',
    },
    {
      state: 'Toliara',
    },
  ],
  MWI: [
    {
      state: 'Balaka',
    },
    {
      state: 'Blantyre',
    },
    {
      state: 'Dedza',
    },
    {
      state: 'Dowa',
    },
    {
      state: 'Lilongwe',
    },
    {
      state: 'Machinga',
    },
    {
      state: 'Mangochi',
    },
    {
      state: 'Mzimba',
    },
    {
      state: 'Ntchisi',
    },
    {
      state: 'Salima',
    },
    {
      state: 'Zomba',
    },
  ],
  MYS: [
    {
      state: 'Johor',
    },
    {
      state: 'Kedah',
    },
    {
      state: 'Kelantan',
    },
    {
      state: 'Melaka',
    },
    {
      state: 'Negeri Sembilan',
    },
    {
      state: 'Pahang',
    },
    {
      state: 'Perak',
    },
    {
      state: 'Perlis',
    },
    {
      state: 'Pulau Pinang',
    },
    {
      state: 'Sabah',
    },
    {
      state: 'Sarawak',
    },
    {
      state: 'Selangor',
    },
    {
      state: 'Terengganu',
    },
    {
      state: 'Wilayah Persekutuan Kuala Lumpur',
    },
    {
      state: 'Wilayah Persekutuan Labuan',
    },
    {
      state: 'Wilayah Persekutuan Putrajaya',
    },
  ],
  MDV: [
    {
      state: 'Alifu Alifu',
    },
    {
      state: 'Baa',
    },
    {
      state: 'Dhaalu',
    },
    {
      state: 'Gaafu Dhaalu',
    },
    {
      state: 'Haa Dhaalu',
    },
    {
      state: 'Kaafu',
    },
    {
      state: 'Laamu',
    },
    {
      state: 'Maale',
    },
    {
      state: 'Meemu',
    },
    {
      state: 'Noonu',
    },
    {
      state: 'Raa',
    },
    {
      state: 'Vaavu',
    },
  ],
  MLI: [
    {
      state: 'Bamako',
    },
    {
      state: 'Gao',
    },
    {
      state: 'Kayes',
    },
    {
      state: 'Kidal',
    },
    {
      state: 'Koulikoro',
    },
    {
      state: 'Mopti',
    },
    {
      state: 'Segou',
    },
    {
      state: 'Sikasso',
    },
    {
      state: 'Tombouctou',
    },
  ],
  MLT: [
    {
      state: 'Attard',
    },
    {
      state: 'Balzan',
    },
    {
      state: 'Birgu',
    },
    {
      state: 'Birkirkara',
    },
    {
      state: 'Birzebbuga',
    },
    {
      state: 'Bormla',
    },
    {
      state: 'Dingli',
    },
    {
      state: 'Fgura',
    },
    {
      state: 'Floriana',
    },
    {
      state: 'Fontana',
    },
    {
      state: 'Gharb',
    },
    {
      state: 'Gharghur',
    },
    {
      state: 'Ghasri',
    },
    {
      state: 'Ghaxaq',
    },
    {
      state: 'Gudja',
    },
    {
      state: 'Gzira',
    },
    {
      state: 'Hamrun',
    },
    {
      state: 'Iklin',
    },
    {
      state: 'Isla',
    },
    {
      state: 'Kalkara',
    },
    {
      state: 'Kercem',
    },
    {
      state: 'Kirkop',
    },
    {
      state: 'Lija',
    },
    {
      state: 'Luqa',
    },
    {
      state: 'Marsa',
    },
    {
      state: 'Marsaskala',
    },
    {
      state: 'Marsaxlokk',
    },
    {
      state: 'Mdina',
    },
    {
      state: 'Mellieha',
    },
    {
      state: 'Mgarr',
    },
    {
      state: 'Mosta',
    },
    {
      state: 'Mqabba',
    },
    {
      state: 'Msida',
    },
    {
      state: 'Mtarfa',
    },
    {
      state: 'Munxar',
    },
    {
      state: 'Nadur',
    },
    {
      state: 'Naxxar',
    },
    {
      state: 'Paola',
    },
    {
      state: 'Pembroke',
    },
    {
      state: 'Pieta',
    },
    {
      state: 'Qala',
    },
    {
      state: 'Qormi',
    },
    {
      state: 'Rabat Gozo',
    },
    {
      state: 'Rabat Malta',
    },
    {
      state: 'Saint John',
    },
    {
      state: "Saint Julian's",
    },
    {
      state: "Saint Lucia's",
    },
    {
      state: "Saint Paul's Bay",
    },
    {
      state: 'Sannat',
    },
    {
      state: 'Santa Venera',
    },
    {
      state: 'Siggiewi',
    },
    {
      state: 'Sliema',
    },
    {
      state: 'Swieqi',
    },
    {
      state: "Ta' Xbiex",
    },
    {
      state: 'Tarxien',
    },
    {
      state: 'Valletta',
    },
    {
      state: 'Xaghra',
    },
    {
      state: 'Xewkija',
    },
    {
      state: 'Xghajra',
    },
    {
      state: 'Zabbar',
    },
    {
      state: 'Zebbug Gozo',
    },
    {
      state: 'Zejtun',
    },
    {
      state: 'Zurrieq',
    },
  ],
  MHL: [
    {
      state: 'Kwajalein',
    },
    {
      state: 'Majuro',
    },
  ],
  MTQ: [
    {
      state: 'Martinique',
    },
  ],
  MRT: [
    {
      state: 'Dakhlet Nouadhibou',
    },
    {
      state: 'Gorgol',
    },
    {
      state: 'Inchiri',
    },
    {
      state: 'Nouakchott Nord',
    },
    {
      state: 'Tiris Zemmour',
    },
    {
      state: 'Trarza',
    },
  ],
  MUS: [
    {
      state: 'Black River',
    },
    {
      state: 'Flacq',
    },
    {
      state: 'Grand Port',
    },
    {
      state: 'Moka',
    },
    {
      state: 'Pamplemousses',
    },
    {
      state: 'Plaines Wilhems',
    },
    {
      state: 'Port Louis',
    },
    {
      state: 'Riviere du Rempart',
    },
    {
      state: 'Rodrigues Islands',
    },
    {
      state: 'Savanne',
    },
  ],
  MYT: [
    {
      state: 'Bandraboua',
    },
    {
      state: 'Mamoudzou',
    },
    {
      state: 'Mtsamboro',
    },
    {
      state: 'Ouangani',
    },
    {
      state: 'Pamandzi',
    },
    {
      state: 'Sada',
    },
  ],
  MEX: [
    {
      state: 'Aguascalientes',
    },
    {
      state: 'Baja California',
    },
    {
      state: 'Baja California Sur',
    },
    {
      state: 'Campeche',
    },
    {
      state: 'Chiapas',
    },
    {
      state: 'Chihuahua',
    },
    {
      state: 'Ciudad de Mexico',
    },
    {
      state: 'Coahuila de Zaragoza',
    },
    {
      state: 'Colima',
    },
    {
      state: 'Durango',
    },
    {
      state: 'Guanajuato',
    },
    {
      state: 'Guerrero',
    },
    {
      state: 'Hidalgo',
    },
    {
      state: 'Jalisco',
    },
    {
      state: 'Mexico',
    },
    {
      state: 'Michoacan de Ocampo',
    },
    {
      state: 'Morelos',
    },
    {
      state: 'Nayarit',
    },
    {
      state: 'Nuevo Leon',
    },
    {
      state: 'Oaxaca',
    },
    {
      state: 'Puebla',
    },
    {
      state: 'Queretaro',
    },
    {
      state: 'Quintana Roo',
    },
    {
      state: 'San Luis Potosi',
    },
    {
      state: 'Sinaloa',
    },
    {
      state: 'Sonora',
    },
    {
      state: 'Tabasco',
    },
    {
      state: 'Tamaulipas',
    },
    {
      state: 'Tlaxcala',
    },
    {
      state: 'Veracruz de Ignacio de la Llave',
    },
    {
      state: 'Yucatan',
    },
    {
      state: 'Zacatecas',
    },
  ],
  FSM: [
    {
      state: 'Chuuk',
    },
    {
      state: 'Kosrae',
    },
    {
      state: 'Pohnpei',
    },
    {
      state: 'Yap',
    },
  ],
  MCO: [
    {
      state: 'Fontvieille',
    },
    {
      state: 'La Condamine',
    },
    {
      state: 'Monaco-Ville',
    },
    {
      state: 'Monte-Carlo',
    },
    {
      state: 'Saint-Roman',
    },
  ],
  MNG: [
    {
      state: 'Bayan-Olgiy',
    },
    {
      state: 'Dornogovi',
    },
    {
      state: 'Govi-Altay',
    },
    {
      state: 'Omnogovi',
    },
    {
      state: 'Orhon',
    },
    {
      state: 'Ovorhangay',
    },
    {
      state: 'Selenge',
    },
    {
      state: 'Tov',
    },
    {
      state: 'Ulaanbaatar',
    },
  ],
  MNE: [
    {
      state: 'Bar',
    },
    {
      state: 'Berane',
    },
    {
      state: 'Bijelo Polje',
    },
    {
      state: 'Budva',
    },
    {
      state: 'Cetinje',
    },
    {
      state: 'Danilovgrad',
    },
    {
      state: 'Herceg-Novi',
    },
    {
      state: 'Kolasin',
    },
    {
      state: 'Kotor',
    },
    {
      state: 'Niksic',
    },
    {
      state: 'Plav',
    },
    {
      state: 'Pljevlja',
    },
    {
      state: 'Pluzine',
    },
    {
      state: 'Podgorica',
    },
    {
      state: 'Rozaje',
    },
    {
      state: 'Tivat',
    },
    {
      state: 'Ulcinj',
    },
    {
      state: 'Zabljak',
    },
  ],
  MSR: [
    {
      state: 'Saint Anthony',
    },
    {
      state: 'Saint Peter',
    },
  ],
  MAR: [
    {
      state: 'Beni-Mellal-Khenifra',
    },
    {
      state: 'Casablanca-Settat',
    },
    {
      state: 'Draa-Tafilalet',
    },
    {
      state: 'Fes- Meknes',
    },
    {
      state: 'Guelmim-Oued Noun (EH-partial)',
    },
    {
      state: "L'Oriental",
    },
    {
      state: 'Laayoune-Sakia El Hamra (EH-partial)',
    },
    {
      state: 'Marrakech-Safi',
    },
    {
      state: 'Rabat-Sale-Kenitra',
    },
    {
      state: 'Souss-Massa',
    },
    {
      state: 'Tanger-Tetouan-Al Hoceima',
    },
  ],
  MOZ: [
    {
      state: 'Cabo Delgado',
    },
    {
      state: 'Gaza',
    },
    {
      state: 'Inhambane',
    },
    {
      state: 'Manica',
    },
    {
      state: 'Maputo',
    },
    {
      state: 'Nampula',
    },
    {
      state: 'Niassa',
    },
    {
      state: 'Sofala',
    },
    {
      state: 'Tete',
    },
    {
      state: 'Zambezia',
    },
  ],
  MMR: [
    {
      state: 'Ayeyarwady',
    },
    {
      state: 'Bago',
    },
    {
      state: 'Kachin',
    },
    {
      state: 'Kayah',
    },
    {
      state: 'Kayin',
    },
    {
      state: 'Magway',
    },
    {
      state: 'Mandalay',
    },
    {
      state: 'Mon',
    },
    {
      state: 'Nay Pyi Taw',
    },
    {
      state: 'Rakhine',
    },
    {
      state: 'Sagaing',
    },
    {
      state: 'Shan',
    },
    {
      state: 'Tanintharyi',
    },
    {
      state: 'Yangon',
    },
  ],
  NAM: [
    {
      state: 'Erongo',
    },
    {
      state: 'Hardap',
    },
    {
      state: 'Karas',
    },
    {
      state: 'Kavango East',
    },
    {
      state: 'Khomas',
    },
    {
      state: 'Kunene',
    },
    {
      state: 'Ohangwena',
    },
    {
      state: 'Omaheke',
    },
    {
      state: 'Omusati',
    },
    {
      state: 'Oshana',
    },
    {
      state: 'Oshikoto',
    },
    {
      state: 'Otjozondjupa',
    },
    {
      state: 'Zambezi',
    },
  ],
  NRU: [
    {
      state: 'Yaren',
    },
  ],
  NPL: [
    {
      state: 'Bagmati',
    },
    {
      state: 'Bheri',
    },
    {
      state: 'Dhawalagiri',
    },
    {
      state: 'Gandaki',
    },
    {
      state: 'Janakpur',
    },
    {
      state: 'Karnali',
    },
    {
      state: 'Kosi',
    },
    {
      state: 'Lumbini',
    },
    {
      state: 'Mahakali',
    },
    {
      state: 'Mechi',
    },
    {
      state: 'Narayani',
    },
    {
      state: 'Rapti',
    },
    {
      state: 'Sagarmatha',
    },
    {
      state: 'Seti',
    },
  ],
  NLD: [
    {
      state: 'Drenthe',
    },
    {
      state: 'Flevoland',
    },
    {
      state: 'Fryslan',
    },
    {
      state: 'Gelderland',
    },
    {
      state: 'Groningen',
    },
    {
      state: 'Limburg',
    },
    {
      state: 'Noord-Brabant',
    },
    {
      state: 'Noord-Holland',
    },
    {
      state: 'Overijssel',
    },
    {
      state: 'Utrecht',
    },
    {
      state: 'Zeeland',
    },
    {
      state: 'Zuid-Holland',
    },
  ],
  NCL: [
    {
      state: 'Province Nord',
    },
    {
      state: 'Province Sud',
    },
  ],
  NZL: [
    {
      state: 'Auckland',
    },
    {
      state: 'Bay of Plenty',
    },
    {
      state: 'Canterbury',
    },
    {
      state: 'Chatham Islands Territory',
    },
    {
      state: 'Gisborne',
    },
    {
      state: "Hawke's Bay",
    },
    {
      state: 'Manawatū-Whanganui',
    },
    {
      state: 'Marlborough',
    },
    {
      state: 'Nelson',
    },
    {
      state: 'Northland',
    },
    {
      state: 'Otago',
    },
    {
      state: 'Southland',
    },
    {
      state: 'Taranaki',
    },
    {
      state: 'Tasman',
    },
    {
      state: 'Waikato',
    },
    {
      state: 'Wellington',
    },
    {
      state: 'West Coast',
    },
  ],
  NIC: [
    {
      state: 'Boaco',
    },
    {
      state: 'Carazo',
    },
    {
      state: 'Chinandega',
    },
    {
      state: 'Chontales',
    },
    {
      state: 'Costa Caribe Norte',
    },
    {
      state: 'Costa Caribe Sur',
    },
    {
      state: 'Esteli',
    },
    {
      state: 'Granada',
    },
    {
      state: 'Jinotega',
    },
    {
      state: 'Leon',
    },
    {
      state: 'Madriz',
    },
    {
      state: 'Managua',
    },
    {
      state: 'Masaya',
    },
    {
      state: 'Matagalpa',
    },
    {
      state: 'Nueva Segovia',
    },
    {
      state: 'Rio San Juan',
    },
    {
      state: 'Rivas',
    },
  ],
  NER: [
    {
      state: 'Agadez',
    },
    {
      state: 'Dosso',
    },
    {
      state: 'Maradi',
    },
    {
      state: 'Niamey',
    },
    {
      state: 'Tahoua',
    },
    {
      state: 'Zinder',
    },
  ],
  NGA: [
    {
      state: 'Abia',
    },
    {
      state: 'Abuja Federal Capital Territory',
    },
    {
      state: 'Adamawa',
    },
    {
      state: 'Akwa Ibom',
    },
    {
      state: 'Anambra',
    },
    {
      state: 'Bauchi',
    },
    {
      state: 'Bayelsa',
    },
    {
      state: 'Benue',
    },
    {
      state: 'Borno',
    },
    {
      state: 'Cross River',
    },
    {
      state: 'Delta',
    },
    {
      state: 'Edo',
    },
    {
      state: 'Ekiti',
    },
    {
      state: 'Enugu',
    },
    {
      state: 'Gombe',
    },
    {
      state: 'Imo',
    },
    {
      state: 'Jigawa',
    },
    {
      state: 'Kaduna',
    },
    {
      state: 'Kano',
    },
    {
      state: 'Katsina',
    },
    {
      state: 'Kebbi',
    },
    {
      state: 'Kogi',
    },
    {
      state: 'Kwara',
    },
    {
      state: 'Lagos',
    },
    {
      state: 'Nasarawa',
    },
    {
      state: 'Niger',
    },
    {
      state: 'Ogun',
    },
    {
      state: 'Ondo',
    },
    {
      state: 'Osun',
    },
    {
      state: 'Oyo',
    },
    {
      state: 'Plateau',
    },
    {
      state: 'Rivers',
    },
    {
      state: 'Sokoto',
    },
    {
      state: 'Taraba',
    },
    {
      state: 'Yobe',
    },
    {
      state: 'Zamfara',
    },
  ],
  NIU: [
    {
      state: 'Niue',
    },
  ],
  NFK: [
    {
      state: 'Norfolk Island',
    },
  ],
  MKD: [
    {
      state: 'Aracinovo',
    },
    {
      state: 'Berovo',
    },
    {
      state: 'Bitola',
    },
    {
      state: 'Bogdanci',
    },
    {
      state: 'Bogovinje',
    },
    {
      state: 'Bosilovo',
    },
    {
      state: 'Brvenica',
    },
    {
      state: 'Caska',
    },
    {
      state: 'Centar Zupa',
    },
    {
      state: 'Cesinovo-Oblesevo',
    },
    {
      state: 'Debar',
    },
    {
      state: 'Delcevo',
    },
    {
      state: 'Dojran',
    },
    {
      state: 'Dolneni',
    },
    {
      state: 'Gevgelija',
    },
    {
      state: 'Gostivar',
    },
    {
      state: 'Ilinden',
    },
    {
      state: 'Jegunovce',
    },
    {
      state: 'Karbinci',
    },
    {
      state: 'Kavadarci',
    },
    {
      state: 'Kicevo',
    },
    {
      state: 'Kocani',
    },
    {
      state: 'Kratovo',
    },
    {
      state: 'Kriva Palanka',
    },
    {
      state: 'Krivogastani',
    },
    {
      state: 'Krusevo',
    },
    {
      state: 'Kumanovo',
    },
    {
      state: 'Lipkovo',
    },
    {
      state: 'Makedonska Kamenica',
    },
    {
      state: 'Mavrovo i Rostusa',
    },
    {
      state: 'Mogila',
    },
    {
      state: 'Negotino',
    },
    {
      state: 'Novo Selo',
    },
    {
      state: 'Ohrid',
    },
    {
      state: 'Pehcevo',
    },
    {
      state: 'Petrovec',
    },
    {
      state: 'Plasnica',
    },
    {
      state: 'Prilep',
    },
    {
      state: 'Probistip',
    },
    {
      state: 'Radovis',
    },
    {
      state: 'Rankovce',
    },
    {
      state: 'Resen',
    },
    {
      state: 'Rosoman',
    },
    {
      state: 'Skopje',
    },
    {
      state: 'Sopiste',
    },
    {
      state: 'Stip',
    },
    {
      state: 'Struga',
    },
    {
      state: 'Strumica',
    },
    {
      state: 'Studenicani',
    },
    {
      state: 'Sveti Nikole',
    },
    {
      state: 'Tearce',
    },
    {
      state: 'Tetovo',
    },
    {
      state: 'Valandovo',
    },
    {
      state: 'Vasilevo',
    },
    {
      state: 'Veles',
    },
    {
      state: 'Vevcani',
    },
    {
      state: 'Vinica',
    },
    {
      state: 'Vrapciste',
    },
    {
      state: 'Zelenikovo',
    },
    {
      state: 'Zelino',
    },
  ],
  MNP: [
    {
      state: 'Northern Mariana Islands',
    },
  ],
  NOR: [
    { state: 'Agder' },
    { state: 'Akershus' },
    { state: 'Buskerud' },
    { state: 'Finnmark' },
    { state: 'Innlandet' },
    { state: 'Møre og Romsdal' },
    { state: 'Nordland' },
    { state: 'Oslo' },
    { state: 'Østfold' },
    { state: 'Rogaland' },
    { state: 'Telemark' },
    { state: 'Troms' },
    { state: 'Trøndelag' },
    { state: 'Vestfold' },
    { state: 'Vestland' },
  ],
  OMN: [
    {
      state: 'Ad Dakhiliyah',
    },
    {
      state: 'Al Buraymi',
    },
    {
      state: 'Al Wusta',
    },
    {
      state: 'Az Zahirah',
    },
    {
      state: 'Janub al Batinah',
    },
    {
      state: 'Janub ash Sharqiyah',
    },
    {
      state: 'Masqat',
    },
    {
      state: 'Musandam',
    },
    {
      state: 'Shamal al Batinah',
    },
    {
      state: 'Shamal ash Sharqiyah',
    },
    {
      state: 'Zufar',
    },
  ],
  PAK: [
    {
      state: 'Azad Jammu and Kashmir',
    },
    {
      state: 'Balochistan',
    },
    {
      state: 'Federally Administered Tribal Areas',
    },
    {
      state: 'Gilgit-Baltistan',
    },
    {
      state: 'Islamabad',
    },
    {
      state: 'Khyber Pakhtunkhwa',
    },
    {
      state: 'Punjab',
    },
    {
      state: 'Sindh',
    },
  ],
  PLW: [
    {
      state: 'Airai',
    },
    {
      state: 'Kayangel',
    },
    {
      state: 'Koror',
    },
    {
      state: 'Melekeok',
    },
    {
      state: 'Ngaraard',
    },
    {
      state: 'Ngardmau',
    },
  ],
  PAN: [
    {
      state: 'Bocas del Toro',
    },
    {
      state: 'Chiriqui',
    },
    {
      state: 'Cocle',
    },
    {
      state: 'Colon',
    },
    {
      state: 'Darien',
    },
    {
      state: 'Herrera',
    },
    {
      state: 'Los Santos',
    },
    {
      state: 'Ngobe-Bugle',
    },
    {
      state: 'Panama',
    },
    {
      state: 'Veraguas',
    },
  ],
  PNG: [
    {
      state: 'Bougainville',
    },
    {
      state: 'Central',
    },
    {
      state: 'East New Britain',
    },
    {
      state: 'East Sepik',
    },
    {
      state: 'Eastern Highlands',
    },
    {
      state: 'Madang',
    },
    {
      state: 'Manus',
    },
    {
      state: 'Milne Bay',
    },
    {
      state: 'Morobe',
    },
    {
      state: 'National Capital District (Port Moresby)',
    },
    {
      state: 'New Ireland',
    },
    {
      state: 'Southern Highlands',
    },
    {
      state: 'West New Britain',
    },
    {
      state: 'West Sepik',
    },
    {
      state: 'Western',
    },
    {
      state: 'Western Highlands',
    },
  ],
  PRY: [
    {
      state: 'Alto Paraguay',
    },
    {
      state: 'Alto Parana',
    },
    {
      state: 'Amambay',
    },
    {
      state: 'Asuncion',
    },
    {
      state: 'Boqueron',
    },
    {
      state: 'Caaguazu',
    },
    {
      state: 'Caazapa',
    },
    {
      state: 'Canindeyu',
    },
    {
      state: 'Central',
    },
    {
      state: 'Concepcion',
    },
    {
      state: 'Cordillera',
    },
    {
      state: 'Guaira',
    },
    {
      state: 'Itapua',
    },
    {
      state: 'Misiones',
    },
    {
      state: 'Neembucu',
    },
    {
      state: 'Paraguari',
    },
    {
      state: 'Presidente Hayes',
    },
    {
      state: 'San Pedro',
    },
  ],
  PER: [
    {
      state: 'Amazonas',
    },
    {
      state: 'Ancash',
    },
    {
      state: 'Apurimac',
    },
    {
      state: 'Arequipa',
    },
    {
      state: 'Ayacucho',
    },
    {
      state: 'Cajamarca',
    },
    {
      state: 'Cusco',
    },
    {
      state: 'El Callao',
    },
    {
      state: 'Huancavelica',
    },
    {
      state: 'Huanuco',
    },
    {
      state: 'Ica',
    },
    {
      state: 'Junin',
    },
    {
      state: 'La Libertad',
    },
    {
      state: 'Lambayeque',
    },
    {
      state: 'Lima',
    },
    {
      state: 'Loreto',
    },
    {
      state: 'Madre de Dios',
    },
    {
      state: 'Moquegua',
    },
    {
      state: 'Pasco',
    },
    {
      state: 'Piura',
    },
    {
      state: 'Puno',
    },
    {
      state: 'San Martin',
    },
    {
      state: 'Tacna',
    },
    {
      state: 'Tumbes',
    },
    {
      state: 'Ucayali',
    },
  ],
  PHL: [
    {
      state: 'Abra',
    },
    {
      state: 'Agusan del Norte',
    },
    {
      state: 'Agusan del Sur',
    },
    {
      state: 'Aklan',
    },
    {
      state: 'Albay',
    },
    {
      state: 'Antique',
    },
    {
      state: 'Apayao',
    },
    {
      state: 'Aurora',
    },
    {
      state: 'Basilan',
    },
    {
      state: 'Bataan',
    },
    {
      state: 'Batanes',
    },
    {
      state: 'Batangas',
    },
    {
      state: 'Benguet',
    },
    {
      state: 'Biliran',
    },
    {
      state: 'Bohol',
    },
    {
      state: 'Bukidnon',
    },
    {
      state: 'Bulacan',
    },
    {
      state: 'Cagayan',
    },
    {
      state: 'Camarines Norte',
    },
    {
      state: 'Camarines Sur',
    },
    {
      state: 'Camiguin',
    },
    {
      state: 'Capiz',
    },
    {
      state: 'Catanduanes',
    },
    {
      state: 'Cavite',
    },
    {
      state: 'Cebu',
    },
    {
      state: 'Compostela Valley',
    },
    {
      state: 'Cotabato',
    },
    {
      state: 'Davao Oriental',
    },
    {
      state: 'Davao del Norte',
    },
    {
      state: 'Davao del Sur',
    },
    {
      state: 'Dinagat Islands',
    },
    {
      state: 'Eastern Samar',
    },
    {
      state: 'Guimaras',
    },
    {
      state: 'Ifugao',
    },
    {
      state: 'Ilocos Norte',
    },
    {
      state: 'Ilocos Sur',
    },
    {
      state: 'Iloilo',
    },
    {
      state: 'Isabela',
    },
    {
      state: 'Kalinga',
    },
    {
      state: 'La Union',
    },
    {
      state: 'Laguna',
    },
    {
      state: 'Lanao del Norte',
    },
    {
      state: 'Lanao del Sur',
    },
    {
      state: 'Leyte',
    },
    {
      state: 'Maguindanao',
    },
    {
      state: 'Marinduque',
    },
    {
      state: 'Masbate',
    },
    {
      state: 'Mindoro Occidental',
    },
    {
      state: 'Mindoro Oriental',
    },
    {
      state: 'Misamis Occidental',
    },
    {
      state: 'Misamis Oriental',
    },
    {
      state: 'Mountain Province',
    },
    {
      state: 'National Capital Region',
    },
    {
      state: 'Negros Occidental',
    },
    {
      state: 'Negros Oriental',
    },
    {
      state: 'Northern Samar',
    },
    {
      state: 'Nueva Ecija',
    },
    {
      state: 'Nueva Vizcaya',
    },
    {
      state: 'Palawan',
    },
    {
      state: 'Pampanga',
    },
    {
      state: 'Pangasinan',
    },
    {
      state: 'Quezon',
    },
    {
      state: 'Quirino',
    },
    {
      state: 'Rizal',
    },
    {
      state: 'Romblon',
    },
    {
      state: 'Samar',
    },
    {
      state: 'Sarangani',
    },
    {
      state: 'Siquijor',
    },
    {
      state: 'Sorsogon',
    },
    {
      state: 'South Cotabato',
    },
    {
      state: 'Southern Leyte',
    },
    {
      state: 'Sultan Kudarat',
    },
    {
      state: 'Sulu',
    },
    {
      state: 'Surigao del Norte',
    },
    {
      state: 'Surigao del Sur',
    },
    {
      state: 'Tarlac',
    },
    {
      state: 'Tawi-Tawi',
    },
    {
      state: 'Zambales',
    },
    {
      state: 'Zamboanga Sibugay',
    },
    {
      state: 'Zamboanga del Norte',
    },
    {
      state: 'Zamboanga del Sur',
    },
  ],
  PCN: [
    {
      state: 'Pitcairn',
    },
  ],
  POL: [
    {
      state: 'Dolnoslaskie',
    },
    {
      state: 'Kujawsko-pomorskie',
    },
    {
      state: 'Lodzkie',
    },
    {
      state: 'Lubelskie',
    },
    {
      state: 'Lubuskie',
    },
    {
      state: 'Malopolskie',
    },
    {
      state: 'Mazowieckie',
    },
    {
      state: 'Opolskie',
    },
    {
      state: 'Podkarpackie',
    },
    {
      state: 'Podlaskie',
    },
    {
      state: 'Pomorskie',
    },
    {
      state: 'Slaskie',
    },
    {
      state: 'Swietokrzyskie',
    },
    {
      state: 'Warminsko-mazurskie',
    },
    {
      state: 'Wielkopolskie',
    },
    {
      state: 'Zachodniopomorskie',
    },
  ],
  PRT: [
    {
      state: 'Aveiro',
    },
    {
      state: 'Beja',
    },
    {
      state: 'Braga',
    },
    {
      state: 'Braganca',
    },
    {
      state: 'Castelo Branco',
    },
    {
      state: 'Coimbra',
    },
    {
      state: 'Evora',
    },
    {
      state: 'Faro',
    },
    {
      state: 'Guarda',
    },
    {
      state: 'Leiria',
    },
    {
      state: 'Lisboa',
    },
    {
      state: 'Portalegre',
    },
    {
      state: 'Porto',
    },
    {
      state: 'Regiao Autonoma da Madeira',
    },
    {
      state: 'Regiao Autonoma dos Acores',
    },
    {
      state: 'Santarem',
    },
    {
      state: 'Setubal',
    },
    {
      state: 'Viana do Castelo',
    },
    {
      state: 'Vila Real',
    },
    {
      state: 'Viseu',
    },
  ],
  PRI: [
    {
      state: 'Adjuntas',
    },
    {
      state: 'Aguada',
    },
    {
      state: 'Aguadilla',
    },
    {
      state: 'Aguas Buenas',
    },
    {
      state: 'Aibonito',
    },
    {
      state: 'Anasco',
    },
    {
      state: 'Arecibo',
    },
    {
      state: 'Arroyo',
    },
    {
      state: 'Barceloneta',
    },
    {
      state: 'Barranquitas',
    },
    {
      state: 'Bayamon',
    },
    {
      state: 'Cabo Rojo',
    },
    {
      state: 'Caguas',
    },
    {
      state: 'Camuy',
    },
    {
      state: 'Canovanas',
    },
    {
      state: 'Carolina',
    },
    {
      state: 'Catano',
    },
    {
      state: 'Cayey',
    },
    {
      state: 'Ceiba',
    },
    {
      state: 'Ciales',
    },
    {
      state: 'Cidra',
    },
    {
      state: 'Coamo',
    },
    {
      state: 'Comerio',
    },
    {
      state: 'Corozal',
    },
    {
      state: 'Culebra',
    },
    {
      state: 'Dorado',
    },
    {
      state: 'Fajardo',
    },
    {
      state: 'Florida',
    },
    {
      state: 'Guanica',
    },
    {
      state: 'Guayama',
    },
    {
      state: 'Guayanilla',
    },
    {
      state: 'Guaynabo',
    },
    {
      state: 'Gurabo',
    },
    {
      state: 'Hatillo',
    },
    {
      state: 'Hormigueros',
    },
    {
      state: 'Humacao',
    },
    {
      state: 'Isabela',
    },
    {
      state: 'Juana Diaz',
    },
    {
      state: 'Lajas',
    },
    {
      state: 'Lares',
    },
    {
      state: 'Las Marias',
    },
    {
      state: 'Las Piedras',
    },
    {
      state: 'Loiza',
    },
    {
      state: 'Luquillo',
    },
    {
      state: 'Manati',
    },
    {
      state: 'Maricao',
    },
    {
      state: 'Mayaguez',
    },
    {
      state: 'Moca',
    },
    {
      state: 'Morovis',
    },
    {
      state: 'Municipio de Jayuya',
    },
    {
      state: 'Municipio de Juncos',
    },
    {
      state: 'Naguabo',
    },
    {
      state: 'Naranjito',
    },
    {
      state: 'Orocovis',
    },
    {
      state: 'Patillas',
    },
    {
      state: 'Penuelas',
    },
    {
      state: 'Ponce',
    },
    {
      state: 'Quebradillas',
    },
    {
      state: 'Rincon',
    },
    {
      state: 'Rio Grande',
    },
    {
      state: 'Sabana Grande',
    },
    {
      state: 'Salinas',
    },
    {
      state: 'San German',
    },
    {
      state: 'San Juan',
    },
    {
      state: 'San Lorenzo',
    },
    {
      state: 'San Sebastian',
    },
    {
      state: 'Santa Isabel Municipio',
    },
    {
      state: 'Toa Alta',
    },
    {
      state: 'Toa Baja',
    },
    {
      state: 'Trujillo Alto',
    },
    {
      state: 'Utuado',
    },
    {
      state: 'Vega Alta',
    },
    {
      state: 'Vega Baja',
    },
    {
      state: 'Vieques',
    },
    {
      state: 'Villalba',
    },
    {
      state: 'Yabucoa',
    },
    {
      state: 'Yauco',
    },
  ],
  QAT: [
    {
      state: 'Ad Dawhah',
    },
    {
      state: 'Al Khawr wa adh Dhakhirah',
    },
    {
      state: 'Al Wakrah',
    },
    {
      state: 'Ar Rayyan',
    },
    {
      state: 'Ash Shamal',
    },
    {
      state: "Az Za'ayin",
    },
    {
      state: 'Umm Salal',
    },
  ],
  REU: [
    {
      state: 'Reunion',
    },
  ],
  ROU: [
    {
      state: 'Alba',
    },
    {
      state: 'Arad',
    },
    {
      state: 'Arges',
    },
    {
      state: 'Bacau',
    },
    {
      state: 'Bihor',
    },
    {
      state: 'Bistrita-Nasaud',
    },
    {
      state: 'Botosani',
    },
    {
      state: 'Braila',
    },
    {
      state: 'Brasov',
    },
    {
      state: 'Bucuresti',
    },
    {
      state: 'Buzau',
    },
    {
      state: 'Calarasi',
    },
    {
      state: 'Caras-Severin',
    },
    {
      state: 'Cluj',
    },
    {
      state: 'Constanta',
    },
    {
      state: 'Covasna',
    },
    {
      state: 'Dambovita',
    },
    {
      state: 'Dolj',
    },
    {
      state: 'Galati',
    },
    {
      state: 'Giurgiu',
    },
    {
      state: 'Gorj',
    },
    {
      state: 'Harghita',
    },
    {
      state: 'Hunedoara',
    },
    {
      state: 'Ialomita',
    },
    {
      state: 'Iasi',
    },
    {
      state: 'Ilfov',
    },
    {
      state: 'Maramures',
    },
    {
      state: 'Mehedinti',
    },
    {
      state: 'Mures',
    },
    {
      state: 'Neamt',
    },
    {
      state: 'Olt',
    },
    {
      state: 'Prahova',
    },
    {
      state: 'Salaj',
    },
    {
      state: 'Satu Mare',
    },
    {
      state: 'Sibiu',
    },
    {
      state: 'Suceava',
    },
    {
      state: 'Teleorman',
    },
    {
      state: 'Timis',
    },
    {
      state: 'Tulcea',
    },
    {
      state: 'Valcea',
    },
    {
      state: 'Vaslui',
    },
    {
      state: 'Vrancea',
    },
  ],
  RUS: [
    {
      state: 'Adygeya, Respublika',
    },
    {
      state: 'Altay, Respublika',
    },
    {
      state: 'Altayskiy kray',
    },
    {
      state: "Amurskaya oblast'",
    },
    {
      state: "Arkhangel'skaya oblast'",
    },
    {
      state: "Astrakhanskaya oblast'",
    },
    {
      state: 'Bashkortostan, Respublika',
    },
    {
      state: "Belgorodskaya oblast'",
    },
    {
      state: "Bryanskaya oblast'",
    },
    {
      state: 'Buryatiya, Respublika',
    },
    {
      state: 'Chechenskaya Respublika',
    },
    {
      state: "Chelyabinskaya oblast'",
    },
    {
      state: 'Chukotskiy avtonomnyy okrug',
    },
    {
      state: 'Chuvashskaya Respublika',
    },
    {
      state: 'Dagestan, Respublika',
    },
    {
      state: 'Ingushetiya, Respublika',
    },
    {
      state: "Irkutskaya oblast'",
    },
    {
      state: "Ivanovskaya oblast'",
    },
    {
      state: 'Kabardino-Balkarskaya Respublika',
    },
    {
      state: "Kaliningradskaya oblast'",
    },
    {
      state: 'Kalmykiya, Respublika',
    },
    {
      state: "Kaluzhskaya oblast'",
    },
    {
      state: 'Kamchatskiy kray',
    },
    {
      state: 'Karachayevo-Cherkesskaya Respublika',
    },
    {
      state: 'Kareliya, Respublika',
    },
    {
      state: "Kemerovskaya oblast'",
    },
    {
      state: 'Khabarovskiy kray',
    },
    {
      state: 'Khakasiya, Respublika',
    },
    {
      state: 'Khanty-Mansiyskiy avtonomnyy okrug',
    },
    {
      state: "Kirovskaya oblast'",
    },
    {
      state: 'Komi, Respublika',
    },
    {
      state: "Kostromskaya oblast'",
    },
    {
      state: 'Krasnodarskiy kray',
    },
    {
      state: 'Krasnoyarskiy kray',
    },
    {
      state: "Kurganskaya oblast'",
    },
    {
      state: "Kurskaya oblast'",
    },
    {
      state: "Leningradskaya oblast'",
    },
    {
      state: "Lipetskaya oblast'",
    },
    {
      state: "Magadanskaya oblast'",
    },
    {
      state: 'Mariy El, Respublika',
    },
    {
      state: 'Mordoviya, Respublika',
    },
    {
      state: "Moskovskaya oblast'",
    },
    {
      state: 'Moskva',
    },
    {
      state: "Murmanskaya oblast'",
    },
    {
      state: 'Nenetskiy avtonomnyy okrug',
    },
    {
      state: "Nizhegorodskaya oblast'",
    },
    {
      state: "Novgorodskaya oblast'",
    },
    {
      state: "Novosibirskaya oblast'",
    },
    {
      state: "Omskaya oblast'",
    },
    {
      state: "Orenburgskaya oblast'",
    },
    {
      state: "Orlovskaya oblast'",
    },
    {
      state: "Penzenskaya oblast'",
    },
    {
      state: 'Permskiy kray',
    },
    {
      state: 'Primorskiy kray',
    },
    {
      state: "Pskovskaya oblast'",
    },
    {
      state: "Rostovskaya oblast'",
    },
    {
      state: "Ryazanskaya oblast'",
    },
    {
      state: 'Saha, Respublika',
    },
    {
      state: "Sakhalinskaya oblast'",
    },
    {
      state: "Samarskaya oblast'",
    },
    {
      state: 'Sankt-Peterburg',
    },
    {
      state: "Saratovskaya oblast'",
    },
    {
      state: 'Severnaya Osetiya, Respublika',
    },
    {
      state: "Smolenskaya oblast'",
    },
    {
      state: "Stavropol'skiy kray",
    },
    {
      state: "Sverdlovskaya oblast'",
    },
    {
      state: "Tambovskaya oblast'",
    },
    {
      state: 'Tatarstan, Respublika',
    },
    {
      state: "Tomskaya oblast'",
    },
    {
      state: "Tul'skaya oblast'",
    },
    {
      state: "Tverskaya oblast'",
    },
    {
      state: "Tyumenskaya oblast'",
    },
    {
      state: 'Tyva, Respublika',
    },
    {
      state: 'Udmurtskaya Respublika',
    },
    {
      state: "Ul'yanovskaya oblast'",
    },
    {
      state: "Vladimirskaya oblast'",
    },
    {
      state: "Volgogradskaya oblast'",
    },
    {
      state: "Vologodskaya oblast'",
    },
    {
      state: "Voronezhskaya oblast'",
    },
    {
      state: 'Yamalo-Nenetskiy avtonomnyy okrug',
    },
    {
      state: "Yaroslavskaya oblast'",
    },
    {
      state: "Yevreyskaya avtonomnaya oblast'",
    },
    {
      state: "Zabaykal'skiy kray",
    },
  ],
  RWA: [
    {
      state: 'Est',
    },
    {
      state: 'Nord',
    },
    {
      state: 'Ouest',
    },
    {
      state: 'Sud',
    },
    {
      state: 'Ville de Kigali',
    },
  ],
  BLM: [
    {
      state: 'Saint Barthelemy',
    },
  ],
  KNA: [
    {
      state: 'Saint Anne Sandy Point',
    },
    {
      state: 'Saint George Basseterre',
    },
    {
      state: 'Saint James Windward',
    },
    {
      state: 'Saint John Capisterre',
    },
    {
      state: 'Saint John Figtree',
    },
    {
      state: 'Saint Mary Cayon',
    },
    {
      state: 'Saint Paul Capisterre',
    },
    {
      state: 'Saint Paul Charlestown',
    },
    {
      state: 'Saint Peter Basseterre',
    },
    {
      state: 'Saint Thomas Lowland',
    },
    {
      state: 'Saint Thomas Middle Island',
    },
  ],
  LCA: [
    {
      state: 'Anse la Raye',
    },
    {
      state: 'Castries',
    },
    {
      state: 'Dennery',
    },
    {
      state: 'Gros Islet',
    },
    {
      state: 'Laborie',
    },
    {
      state: 'Micoud',
    },
    {
      state: 'Soufriere',
    },
    {
      state: 'Vieux Fort',
    },
  ],
  MAF: [
    {
      state: 'Saint Martin (French Part)',
    },
  ],
  SPM: [
    {
      state: 'Saint Pierre and Miquelon',
    },
  ],
  VCT: [
    {
      state: 'Charlotte',
    },
    {
      state: 'Grenadines',
    },
    {
      state: 'Saint David',
    },
    {
      state: 'Saint George',
    },
    {
      state: 'Saint Patrick',
    },
  ],
  VEN: [
    {
      state: 'Amazonas',
    },
    {
      state: 'Anzoategui',
    },
    {
      state: 'Apure',
    },
    {
      state: 'Aragua',
    },
    {
      state: 'Barinas',
    },
    {
      state: 'Bolivar',
    },
    {
      state: 'Carabobo',
    },
    {
      state: 'Cojedes',
    },
    {
      state: 'Delta Amacuro',
    },
    {
      state: 'Dependencias Federales',
    },
    {
      state: 'Distrito Capital',
    },
    {
      state: 'Falcon',
    },
    {
      state: 'Guarico',
    },
    {
      state: 'Lara',
    },
    {
      state: 'Merida',
    },
    {
      state: 'Miranda',
    },
    {
      state: 'Monagas',
    },
    {
      state: 'Nueva Esparta',
    },
    {
      state: 'Portuguesa',
    },
    {
      state: 'Sucre',
    },
    {
      state: 'Tachira',
    },
    {
      state: 'Trujillo',
    },
    {
      state: 'Vargas',
    },
    {
      state: 'Yaracuy',
    },
    {
      state: 'Zulia',
    },
  ],
  WSM: [
    {
      state: 'Atua',
    },
    {
      state: "Fa'asaleleaga",
    },
    {
      state: 'Tuamasaga',
    },
  ],
  SMR: [
    {
      state: 'Domagnano',
    },
    {
      state: 'San Marino',
    },
    {
      state: 'Serravalle',
    },
  ],
  STP: [
    {
      state: 'Principe',
    },
    {
      state: 'Sao Tome',
    },
  ],
  SAU: [
    {
      state: "'Asir",
    },
    {
      state: 'Al Bahah',
    },
    {
      state: 'Al Hudud ash Shamaliyah',
    },
    {
      state: 'Al Jawf',
    },
    {
      state: 'Al Madinah al Munawwarah',
    },
    {
      state: 'Al Qasim',
    },
    {
      state: 'Ar Riyad',
    },
    {
      state: 'Ash Sharqiyah',
    },
    {
      state: "Ha'il",
    },
    {
      state: 'Jazan',
    },
    {
      state: 'Makkah al Mukarramah',
    },
    {
      state: 'Najran',
    },
    {
      state: 'Tabuk',
    },
  ],
  SEN: [
    {
      state: 'Dakar',
    },
    {
      state: 'Diourbel',
    },
    {
      state: 'Fatick',
    },
    {
      state: 'Kaffrine',
    },
    {
      state: 'Kaolack',
    },
    {
      state: 'Kedougou',
    },
    {
      state: 'Kolda',
    },
    {
      state: 'Louga',
    },
    {
      state: 'Matam',
    },
    {
      state: 'Saint-Louis',
    },
    {
      state: 'Sedhiou',
    },
    {
      state: 'Tambacounda',
    },
    {
      state: 'Thies',
    },
    {
      state: 'Ziguinchor',
    },
  ],
  SRB: [
    {
      state: 'Beograd',
    },
    {
      state: 'Borski okrug',
    },
    {
      state: 'Branicevski okrug',
    },
    {
      state: 'Jablanicki okrug',
    },
    {
      state: 'Juznobacki okrug',
    },
    {
      state: 'Juznobanatski okrug',
    },
    {
      state: 'Kolubarski okrug',
    },
    {
      state: 'Kosovsko-Mitrovacki okrug',
    },
    {
      state: 'Macvanski okrug',
    },
    {
      state: 'Moravicki okrug',
    },
    {
      state: 'Nisavski okrug',
    },
    {
      state: 'Pcinjski okrug',
    },
    {
      state: 'Pecki okrug',
    },
    {
      state: 'Pirotski okrug',
    },
    {
      state: 'Podunavski okrug',
    },
    {
      state: 'Pomoravski okrug',
    },
    {
      state: 'Prizrenski okrug',
    },
    {
      state: 'Rasinski okrug',
    },
    {
      state: 'Raski okrug',
    },
    {
      state: 'Severnobacki okrug',
    },
    {
      state: 'Severnobanatski okrug',
    },
    {
      state: 'Srednjebanatski okrug',
    },
    {
      state: 'Sremski okrug',
    },
    {
      state: 'Sumadijski okrug',
    },
    {
      state: 'Toplicki okrug',
    },
    {
      state: 'Zajecarski okrug',
    },
    {
      state: 'Zapadnobacki okrug',
    },
    {
      state: 'Zlatiborski okrug',
    },
  ],
  SYC: [
    {
      state: 'Anse Boileau',
    },
    {
      state: 'Anse aux Pins',
    },
    {
      state: 'Baie Lazare',
    },
    {
      state: 'Beau Vallon',
    },
    {
      state: 'Bel Ombre',
    },
    {
      state: 'Cascade',
    },
    {
      state: 'English River',
    },
    {
      state: 'Grand Anse Mahe',
    },
    {
      state: 'La Digue',
    },
    {
      state: 'Pointe Larue',
    },
    {
      state: 'Takamaka',
    },
  ],
  SLE: [
    {
      state: 'Eastern',
    },
    {
      state: 'Northern',
    },
    {
      state: 'Western Area',
    },
  ],
  SGP: [
    {
      state: 'Singapore',
    },
  ],
  SXM: [
    {
      state: 'Sint Maarten (Dutch Part)',
    },
  ],
  SVK: [
    {
      state: 'Banskobystricky kraj',
    },
    {
      state: 'Bratislavsky kraj',
    },
    {
      state: 'Kosicky kraj',
    },
    {
      state: 'Nitriansky kraj',
    },
    {
      state: 'Presovsky kraj',
    },
    {
      state: 'Trenciansky kraj',
    },
    {
      state: 'Trnavsky kraj',
    },
    {
      state: 'Zilinsky kraj',
    },
  ],
  SVN: [
    {
      state: 'Ajdovscina',
    },
    {
      state: 'Apace',
    },
    {
      state: 'Beltinci',
    },
    {
      state: 'Benedikt',
    },
    {
      state: 'Bistrica ob Sotli',
    },
    {
      state: 'Bled',
    },
    {
      state: 'Bloke',
    },
    {
      state: 'Bohinj',
    },
    {
      state: 'Borovnica',
    },
    {
      state: 'Bovec',
    },
    {
      state: 'Braslovce',
    },
    {
      state: 'Brda',
    },
    {
      state: 'Brezice',
    },
    {
      state: 'Brezovica',
    },
    {
      state: 'Cankova',
    },
    {
      state: 'Celje',
    },
    {
      state: 'Cerklje na Gorenjskem',
    },
    {
      state: 'Cerknica',
    },
    {
      state: 'Cerkno',
    },
    {
      state: 'Cirkulane',
    },
    {
      state: 'Crensovci',
    },
    {
      state: 'Crnomelj',
    },
    {
      state: 'Destrnik',
    },
    {
      state: 'Divaca',
    },
    {
      state: 'Dobje',
    },
    {
      state: 'Dobrepolje',
    },
    {
      state: 'Dobrna',
    },
    {
      state: 'Dobrova-Polhov Gradec',
    },
    {
      state: 'Dobrovnik',
    },
    {
      state: 'Domzale',
    },
    {
      state: 'Dornava',
    },
    {
      state: 'Dravograd',
    },
    {
      state: 'Duplek',
    },
    {
      state: 'Gorje',
    },
    {
      state: 'Gornja Radgona',
    },
    {
      state: 'Gornji Petrovci',
    },
    {
      state: 'Grad',
    },
    {
      state: 'Grosuplje',
    },
    {
      state: 'Hajdina',
    },
    {
      state: 'Hoce-Slivnica',
    },
    {
      state: 'Hodos',
    },
    {
      state: 'Horjul',
    },
    {
      state: 'Hrastnik',
    },
    {
      state: 'Hrpelje-Kozina',
    },
    {
      state: 'Idrija',
    },
    {
      state: 'Ig',
    },
    {
      state: 'Ilirska Bistrica',
    },
    {
      state: 'Ivancna Gorica',
    },
    {
      state: 'Izola',
    },
    {
      state: 'Jesenice',
    },
    {
      state: 'Jursinci',
    },
    {
      state: 'Kamnik',
    },
    {
      state: 'Kanal',
    },
    {
      state: 'Kidricevo',
    },
    {
      state: 'Kobarid',
    },
    {
      state: 'Kobilje',
    },
    {
      state: 'Kocevje',
    },
    {
      state: 'Komen',
    },
    {
      state: 'Komenda',
    },
    {
      state: 'Koper',
    },
    {
      state: 'Kosanjevica na Krki',
    },
    {
      state: 'Kostel',
    },
    {
      state: 'Kranj',
    },
    {
      state: 'Kranjska Gora',
    },
    {
      state: 'Krizevci',
    },
    {
      state: 'Krsko',
    },
    {
      state: 'Kungota',
    },
    {
      state: 'Kuzma',
    },
    {
      state: 'Lasko',
    },
    {
      state: 'Lenart',
    },
    {
      state: 'Lendava',
    },
    {
      state: 'Litija',
    },
    {
      state: 'Ljubljana',
    },
    {
      state: 'Ljutomer',
    },
    {
      state: 'Log-Dragomer',
    },
    {
      state: 'Logatec',
    },
    {
      state: 'Loska Dolina',
    },
    {
      state: 'Loski Potok',
    },
    {
      state: 'Lovrenc na Pohorju',
    },
    {
      state: 'Luce',
    },
    {
      state: 'Lukovica',
    },
    {
      state: 'Majsperk',
    },
    {
      state: 'Makole',
    },
    {
      state: 'Maribor',
    },
    {
      state: 'Markovci',
    },
    {
      state: 'Medvode',
    },
    {
      state: 'Menges',
    },
    {
      state: 'Metlika',
    },
    {
      state: 'Mezica',
    },
    {
      state: 'Miklavz na Dravskem Polju',
    },
    {
      state: 'Miren-Kostanjevica',
    },
    {
      state: 'Mirna Pec',
    },
    {
      state: 'Mislinja',
    },
    {
      state: 'Mokronog-Trebelno',
    },
    {
      state: 'Moravce',
    },
    {
      state: 'Mozirje',
    },
    {
      state: 'Murska Sobota',
    },
    {
      state: 'Muta',
    },
    {
      state: 'Naklo',
    },
    {
      state: 'Nazarje',
    },
    {
      state: 'Nova Gorica',
    },
    {
      state: 'Novo Mesto',
    },
    {
      state: 'Odranci',
    },
    {
      state: 'Oplotnica',
    },
    {
      state: 'Ormoz',
    },
    {
      state: 'Piran',
    },
    {
      state: 'Pivka',
    },
    {
      state: 'Podcetrtek',
    },
    {
      state: 'Podlehnik',
    },
    {
      state: 'Poljcane',
    },
    {
      state: 'Polzela',
    },
    {
      state: 'Postojna',
    },
    {
      state: 'Prebold',
    },
    {
      state: 'Preddvor',
    },
    {
      state: 'Prevalje',
    },
    {
      state: 'Ptuj',
    },
    {
      state: 'Puconci',
    },
    {
      state: 'Race-Fram',
    },
    {
      state: 'Radece',
    },
    {
      state: 'Radenci',
    },
    {
      state: 'Radlje ob Dravi',
    },
    {
      state: 'Radovljica',
    },
    {
      state: 'Ravne na Koroskem',
    },
    {
      state: 'Razkrizje',
    },
    {
      state: 'Recica ob Savinji',
    },
    {
      state: 'Rence-Vogrsko',
    },
    {
      state: 'Ribnica',
    },
    {
      state: 'Rogaska Slatina',
    },
    {
      state: 'Rogasovci',
    },
    {
      state: 'Ruse',
    },
    {
      state: 'Salovci',
    },
    {
      state: 'Semic',
    },
    {
      state: 'Sempeter-Vrtojba',
    },
    {
      state: 'Sencur',
    },
    {
      state: 'Sentilj',
    },
    {
      state: 'Sentjernej',
    },
    {
      state: 'Sentjur',
    },
    {
      state: 'Sentrupert',
    },
    {
      state: 'Sevnica',
    },
    {
      state: 'Sezana',
    },
    {
      state: 'Skocjan',
    },
    {
      state: 'Skofja Loka',
    },
    {
      state: 'Skofljica',
    },
    {
      state: 'Slovenj Gradec',
    },
    {
      state: 'Slovenska Bistrica',
    },
    {
      state: 'Slovenske Konjice',
    },
    {
      state: 'Smarje pri Jelsah',
    },
    {
      state: 'Smarjeske Toplice',
    },
    {
      state: 'Smartno ob Paki',
    },
    {
      state: 'Smartno pri Litiji',
    },
    {
      state: 'Sodrazica',
    },
    {
      state: 'Solcava',
    },
    {
      state: 'Sostanj',
    },
    {
      state: 'Starse',
    },
    {
      state: 'Store',
    },
    {
      state: 'Straza',
    },
    {
      state: 'Sveta Trojica v Slovenskih Goricah',
    },
    {
      state: 'Sveti Andraz v Slovenskih Goricah',
    },
    {
      state: 'Sveti Jurij',
    },
    {
      state: 'Sveti Jurij v Slovenskih Goricah',
    },
    {
      state: 'Sveti Tomaz',
    },
    {
      state: 'Tabor',
    },
    {
      state: 'Tisina',
    },
    {
      state: 'Tolmin',
    },
    {
      state: 'Trbovlje',
    },
    {
      state: 'Trebnje',
    },
    {
      state: 'Trnovska Vas',
    },
    {
      state: 'Trzic',
    },
    {
      state: 'Trzin',
    },
    {
      state: 'Turnisce',
    },
    {
      state: 'Velenje',
    },
    {
      state: 'Velika Polana',
    },
    {
      state: 'Velike Lasce',
    },
    {
      state: 'Verzej',
    },
    {
      state: 'Videm',
    },
    {
      state: 'Vipava',
    },
    {
      state: 'Vitanje',
    },
    {
      state: 'Vodice',
    },
    {
      state: 'Vojnik',
    },
    {
      state: 'Vransko',
    },
    {
      state: 'Vrhnika',
    },
    {
      state: 'Vuzenica',
    },
    {
      state: 'Zagorje ob Savi',
    },
    {
      state: 'Zalec',
    },
    {
      state: 'Zavrc',
    },
    {
      state: 'Zelezniki',
    },
    {
      state: 'Zetale',
    },
    {
      state: 'Ziri',
    },
    {
      state: 'Zrece',
    },
    {
      state: 'Zuzemberk',
    },
  ],
  SLB: [
    {
      state: 'Choiseul',
    },
    {
      state: 'Guadalcanal',
    },
    {
      state: 'Western',
    },
  ],
  SOM: [
    {
      state: 'Awdal',
    },
    {
      state: 'Banaadir',
    },
    {
      state: 'Nugaal',
    },
    {
      state: 'Shabeellaha Hoose',
    },
    {
      state: 'Sool',
    },
    {
      state: 'Togdheer',
    },
    {
      state: 'Woqooyi Galbeed',
    },
  ],
  ZAF: [
    {
      state: 'Eastern Cape',
    },
    {
      state: 'Free State',
    },
    {
      state: 'Gauteng',
    },
    {
      state: 'Kwazulu-Natal',
    },
    {
      state: 'Limpopo',
    },
    {
      state: 'Mpumalanga',
    },
    {
      state: 'North-West',
    },
    {
      state: 'Northern Cape',
    },
    {
      state: 'Western Cape',
    },
  ],
  SGS: [
    {
      state: 'South Georgia and the South Sandwich Islands',
    },
  ],
  SSD: [
    {
      state: 'Central Equatoria',
    },
    {
      state: 'Eastern Equatoria',
    },
    {
      state: 'Northern Bahr el Ghazal',
    },
    {
      state: 'Upper Nile',
    },
    {
      state: 'Western Equatoria',
    },
  ],
  ESP: [
    {
      state: 'Andalucia',
    },
    {
      state: 'Aragon',
    },
    {
      state: 'Asturias, Principado de',
    },
    {
      state: 'Canarias',
    },
    {
      state: 'Cantabria',
    },
    {
      state: 'Castilla y Leon',
    },
    {
      state: 'Castilla-La Mancha',
    },
    {
      state: 'Catalunya',
    },
    {
      state: 'Ceuta',
    },
    {
      state: 'Extremadura',
    },
    {
      state: 'Galicia',
    },
    {
      state: 'Illes Balears',
    },
    {
      state: 'La Rioja',
    },
    {
      state: 'Madrid, Comunidad de',
    },
    {
      state: 'Melilla',
    },
    {
      state: 'Murcia, Region de',
    },
    {
      state: 'Navarra, Comunidad Foral de',
    },
    {
      state: 'Pais Vasco',
    },
    {
      state: 'Valenciana, Comunidad',
    },
  ],
  LKA: [
    {
      state: 'Central Province',
    },
    {
      state: 'Eastern Province',
    },
    {
      state: 'North Central Province',
    },
    {
      state: 'North Western Province',
    },
    {
      state: 'Northern Province',
    },
    {
      state: 'Sabaragamuwa Province',
    },
    {
      state: 'Southern Province',
    },
    {
      state: 'Uva Province',
    },
    {
      state: 'Western Province',
    },
  ],
  SDN: [
    {
      state: 'Blue Nile',
    },
    {
      state: 'Gedaref',
    },
    {
      state: 'Gezira',
    },
    {
      state: 'Kassala',
    },
    {
      state: 'Khartoum',
    },
    {
      state: 'North Darfur',
    },
    {
      state: 'North Kordofan',
    },
    {
      state: 'Northern',
    },
    {
      state: 'Red Sea',
    },
    {
      state: 'River Nile',
    },
    {
      state: 'Sennar',
    },
    {
      state: 'South Darfur',
    },
    {
      state: 'South Kordofan',
    },
    {
      state: 'West Darfur',
    },
    {
      state: 'White Nile',
    },
  ],
  SUR: [
    {
      state: 'Brokopondo',
    },
    {
      state: 'Commewijne',
    },
    {
      state: 'Nickerie',
    },
    {
      state: 'Para',
    },
    {
      state: 'Paramaribo',
    },
    {
      state: 'Saramacca',
    },
    {
      state: 'Sipaliwini',
    },
    {
      state: 'Wanica',
    },
  ],
  SWE: [
    {
      state: 'Blekinge lan',
    },
    {
      state: 'Dalarnas lan',
    },
    {
      state: 'Gavleborgs lan',
    },
    {
      state: 'Gotlands lan',
    },
    {
      state: 'Hallands lan',
    },
    {
      state: 'Jamtlands lan',
    },
    {
      state: 'Jonkopings lan',
    },
    {
      state: 'Kalmar lan',
    },
    {
      state: 'Kronobergs lan',
    },
    {
      state: 'Norrbottens lan',
    },
    {
      state: 'Orebro lan',
    },
    {
      state: 'Ostergotlands lan',
    },
    {
      state: 'Skane lan',
    },
    {
      state: 'Sodermanlands lan',
    },
    {
      state: 'Stockholms lan',
    },
    {
      state: 'Uppsala lan',
    },
    {
      state: 'Varmlands lan',
    },
    {
      state: 'Vasterbottens lan',
    },
    {
      state: 'Vasternorrlands lan',
    },
    {
      state: 'Vastmanlands lan',
    },
    {
      state: 'Vastra Gotalands lan',
    },
  ],
  CHE: [
    {
      state: 'Aargau',
    },
    {
      state: 'Appenzell Ausserrhoden',
    },
    {
      state: 'Appenzell Innerrhoden',
    },
    {
      state: 'Basel-Landschaft',
    },
    {
      state: 'Basel-Stadt',
    },
    {
      state: 'Bern',
    },
    {
      state: 'Fribourg',
    },
    {
      state: 'Geneve',
    },
    {
      state: 'Glarus',
    },
    {
      state: 'Graubunden',
    },
    {
      state: 'Jura',
    },
    {
      state: 'Luzern',
    },
    {
      state: 'Neuchatel',
    },
    {
      state: 'Nidwalden',
    },
    {
      state: 'Obwalden',
    },
    {
      state: 'Sankt Gallen',
    },
    {
      state: 'Schaffhausen',
    },
    {
      state: 'Schwyz',
    },
    {
      state: 'Solothurn',
    },
    {
      state: 'Thurgau',
    },
    {
      state: 'Ticino',
    },
    {
      state: 'Uri',
    },
    {
      state: 'Valais',
    },
    {
      state: 'Vaud',
    },
    {
      state: 'Zug',
    },
    {
      state: 'Zurich',
    },
  ],
  TJK: [
    {
      state: 'Dushanbe',
    },
    {
      state: 'Khatlon',
    },
    {
      state: 'Nohiyahoi Tobei Jumhuri',
    },
    {
      state: 'Sughd',
    },
  ],
  THA: [
    {
      state: 'Amnat Charoen',
    },
    {
      state: 'Ang Thong',
    },
    {
      state: 'Bueng Kan',
    },
    {
      state: 'Buri Ram',
    },
    {
      state: 'Chachoengsao',
    },
    {
      state: 'Chai Nat',
    },
    {
      state: 'Chaiyaphum',
    },
    {
      state: 'Chanthaburi',
    },
    {
      state: 'Chiang Mai',
    },
    {
      state: 'Chiang Rai',
    },
    {
      state: 'Chon Buri',
    },
    {
      state: 'Chumphon',
    },
    {
      state: 'Kalasin',
    },
    {
      state: 'Kamphaeng Phet',
    },
    {
      state: 'Kanchanaburi',
    },
    {
      state: 'Khon Kaen',
    },
    {
      state: 'Krabi',
    },
    {
      state: 'Krung Thep Maha Nakhon',
    },
    {
      state: 'Lampang',
    },
    {
      state: 'Lamphun',
    },
    {
      state: 'Loei',
    },
    {
      state: 'Lop Buri',
    },
    {
      state: 'Mae Hong Son',
    },
    {
      state: 'Maha Sarakham',
    },
    {
      state: 'Mukdahan',
    },
    {
      state: 'Nakhon Nayok',
    },
    {
      state: 'Nakhon Pathom',
    },
    {
      state: 'Nakhon Phanom',
    },
    {
      state: 'Nakhon Ratchasima',
    },
    {
      state: 'Nakhon Sawan',
    },
    {
      state: 'Nakhon Si Thammarat',
    },
    {
      state: 'Nan',
    },
    {
      state: 'Narathiwat',
    },
    {
      state: 'Nong Bua Lam Phu',
    },
    {
      state: 'Nong Khai',
    },
    {
      state: 'Nonthaburi',
    },
    {
      state: 'Pathum Thani',
    },
    {
      state: 'Pattani',
    },
    {
      state: 'Phangnga',
    },
    {
      state: 'Phatthalung',
    },
    {
      state: 'Phayao',
    },
    {
      state: 'Phetchabun',
    },
    {
      state: 'Phetchaburi',
    },
    {
      state: 'Phichit',
    },
    {
      state: 'Phitsanulok',
    },
    {
      state: 'Phra Nakhon Si Ayutthaya',
    },
    {
      state: 'Phrae',
    },
    {
      state: 'Phuket',
    },
    {
      state: 'Prachin Buri',
    },
    {
      state: 'Prachuap Khiri Khan',
    },
    {
      state: 'Ranong',
    },
    {
      state: 'Ratchaburi',
    },
    {
      state: 'Rayong',
    },
    {
      state: 'Roi Et',
    },
    {
      state: 'Sa Kaeo',
    },
    {
      state: 'Sakon Nakhon',
    },
    {
      state: 'Samut Prakan',
    },
    {
      state: 'Samut Sakhon',
    },
    {
      state: 'Samut Songkhram',
    },
    {
      state: 'Saraburi',
    },
    {
      state: 'Satun',
    },
    {
      state: 'Si Sa Ket',
    },
    {
      state: 'Sing Buri',
    },
    {
      state: 'Songkhla',
    },
    {
      state: 'Sukhothai',
    },
    {
      state: 'Suphan Buri',
    },
    {
      state: 'Surat Thani',
    },
    {
      state: 'Surin',
    },
    {
      state: 'Tak',
    },
    {
      state: 'Trang',
    },
    {
      state: 'Trat',
    },
    {
      state: 'Ubon Ratchathani',
    },
    {
      state: 'Udon Thani',
    },
    {
      state: 'Uthai Thani',
    },
    {
      state: 'Uttaradit',
    },
    {
      state: 'Yala',
    },
    {
      state: 'Yasothon',
    },
  ],
  TLS: [
    {
      state: 'Ainaro',
    },
    {
      state: 'Cova Lima',
    },
    {
      state: 'Dili',
    },
  ],
  TGO: [
    {
      state: 'Centrale',
    },
    {
      state: 'Kara',
    },
    {
      state: 'Maritime',
    },
    {
      state: 'Plateaux',
    },
  ],
  TKL: [
    {
      state: 'Tokelau',
    },
  ],
  TON: [
    {
      state: 'Niuas',
    },
    {
      state: 'Tongatapu',
    },
  ],
  TTO: [
    {
      state: 'Arima',
    },
    {
      state: 'Chaguanas',
    },
    {
      state: 'Couva-Tabaquite-Talparo',
    },
    {
      state: 'Diego Martin',
    },
    {
      state: 'Mayaro-Rio Claro',
    },
    {
      state: 'Penal-Debe',
    },
    {
      state: 'Point Fortin',
    },
    {
      state: 'Port of Spain',
    },
    {
      state: 'Princes Town',
    },
    {
      state: 'San Fernando',
    },
    {
      state: 'San Juan-Laventille',
    },
    {
      state: 'Sangre Grande',
    },
    {
      state: 'Siparia',
    },
    {
      state: 'Tobago',
    },
    {
      state: 'Tunapuna-Piarco',
    },
  ],
  TUN: [
    {
      state: 'Beja',
    },
    {
      state: 'Ben Arous',
    },
    {
      state: 'Bizerte',
    },
    {
      state: 'Gabes',
    },
    {
      state: 'Gafsa',
    },
    {
      state: 'Jendouba',
    },
    {
      state: 'Kairouan',
    },
    {
      state: 'Kasserine',
    },
    {
      state: 'Kebili',
    },
    {
      state: "L'Ariana",
    },
    {
      state: 'La Manouba',
    },
    {
      state: 'Le Kef',
    },
    {
      state: 'Mahdia',
    },
    {
      state: 'Medenine',
    },
    {
      state: 'Monastir',
    },
    {
      state: 'Nabeul',
    },
    {
      state: 'Sfax',
    },
    {
      state: 'Sidi Bouzid',
    },
    {
      state: 'Siliana',
    },
    {
      state: 'Sousse',
    },
    {
      state: 'Tataouine',
    },
    {
      state: 'Tozeur',
    },
    {
      state: 'Tunis',
    },
    {
      state: 'Zaghouan',
    },
  ],
  TUR: [
    {
      state: 'Adana',
    },
    {
      state: 'Adiyaman',
    },
    {
      state: 'Afyonkarahisar',
    },
    {
      state: 'Agri',
    },
    {
      state: 'Aksaray',
    },
    {
      state: 'Amasya',
    },
    {
      state: 'Ankara',
    },
    {
      state: 'Antalya',
    },
    {
      state: 'Ardahan',
    },
    {
      state: 'Artvin',
    },
    {
      state: 'Aydin',
    },
    {
      state: 'Balikesir',
    },
    {
      state: 'Bartin',
    },
    {
      state: 'Batman',
    },
    {
      state: 'Bayburt',
    },
    {
      state: 'Bilecik',
    },
    {
      state: 'Bingol',
    },
    {
      state: 'Bitlis',
    },
    {
      state: 'Bolu',
    },
    {
      state: 'Burdur',
    },
    {
      state: 'Bursa',
    },
    {
      state: 'Canakkale',
    },
    {
      state: 'Cankiri',
    },
    {
      state: 'Corum',
    },
    {
      state: 'Denizli',
    },
    {
      state: 'Diyarbakir',
    },
    {
      state: 'Duzce',
    },
    {
      state: 'Edirne',
    },
    {
      state: 'Elazig',
    },
    {
      state: 'Erzincan',
    },
    {
      state: 'Erzurum',
    },
    {
      state: 'Eskisehir',
    },
    {
      state: 'Gaziantep',
    },
    {
      state: 'Giresun',
    },
    {
      state: 'Gumushane',
    },
    {
      state: 'Hakkari',
    },
    {
      state: 'Hatay',
    },
    {
      state: 'Igdir',
    },
    {
      state: 'Isparta',
    },
    {
      state: 'Istanbul',
    },
    {
      state: 'Izmir',
    },
    {
      state: 'Kahramanmaras',
    },
    {
      state: 'Karabuk',
    },
    {
      state: 'Karaman',
    },
    {
      state: 'Kars',
    },
    {
      state: 'Kastamonu',
    },
    {
      state: 'Kayseri',
    },
    {
      state: 'Kilis',
    },
    {
      state: 'Kirikkale',
    },
    {
      state: 'Kirklareli',
    },
    {
      state: 'Kirsehir',
    },
    {
      state: 'Kocaeli',
    },
    {
      state: 'Konya',
    },
    {
      state: 'Kutahya',
    },
    {
      state: 'Malatya',
    },
    {
      state: 'Manisa',
    },
    {
      state: 'Mardin',
    },
    {
      state: 'Mersin',
    },
    {
      state: 'Mugla',
    },
    {
      state: 'Mus',
    },
    {
      state: 'Nevsehir',
    },
    {
      state: 'Nigde',
    },
    {
      state: 'Ordu',
    },
    {
      state: 'Osmaniye',
    },
    {
      state: 'Rize',
    },
    {
      state: 'Sakarya',
    },
    {
      state: 'Samsun',
    },
    {
      state: 'Sanliurfa',
    },
    {
      state: 'Siirt',
    },
    {
      state: 'Sinop',
    },
    {
      state: 'Sirnak',
    },
    {
      state: 'Sivas',
    },
    {
      state: 'Tekirdag',
    },
    {
      state: 'Tokat',
    },
    {
      state: 'Trabzon',
    },
    {
      state: 'Tunceli',
    },
    {
      state: 'Usak',
    },
    {
      state: 'Van',
    },
    {
      state: 'Yalova',
    },
    {
      state: 'Yozgat',
    },
    {
      state: 'Zonguldak',
    },
  ],
  TKM: [
    {
      state: 'Ahal',
    },
    {
      state: 'Balkan',
    },
    {
      state: 'Dasoguz',
    },
    {
      state: 'Lebap',
    },
    {
      state: 'Mary',
    },
  ],
  TCA: [
    {
      state: 'Turks and Caicos Islands',
    },
  ],
  TUV: [
    {
      state: 'Funafuti',
    },
  ],
  TZA: [
    {
      state: 'Arusha',
    },
    {
      state: 'Dar es Salaam',
    },
    {
      state: 'Dodoma',
    },
    {
      state: 'Geita',
    },
    {
      state: 'Iringa',
    },
    {
      state: 'Kagera',
    },
    {
      state: 'Kaskazini Pemba',
    },
    {
      state: 'Kaskazini Unguja',
    },
    {
      state: 'Katavi',
    },
    {
      state: 'Kigoma',
    },
    {
      state: 'Kilimanjaro',
    },
    {
      state: 'Kusini Pemba',
    },
    {
      state: 'Kusini Unguja',
    },
    {
      state: 'Lindi',
    },
    {
      state: 'Manyara',
    },
    {
      state: 'Mara',
    },
    {
      state: 'Mbeya',
    },
    {
      state: 'Mjini Magharibi',
    },
    {
      state: 'Morogoro',
    },
    {
      state: 'Mtwara',
    },
    {
      state: 'Mwanza',
    },
    {
      state: 'Njombe',
    },
    {
      state: 'Pwani',
    },
    {
      state: 'Rukwa',
    },
    {
      state: 'Ruvuma',
    },
    {
      state: 'Shinyanga',
    },
    {
      state: 'Simiyu',
    },
    {
      state: 'Singida',
    },
    {
      state: 'Songwe',
    },
    {
      state: 'Tabora',
    },
    {
      state: 'Tanga',
    },
  ],
  UGA: [
    {
      state: 'Amuru',
    },
    {
      state: 'Arua',
    },
    {
      state: 'Bugiri',
    },
    {
      state: 'Buikwe',
    },
    {
      state: 'Bukedea',
    },
    {
      state: 'Buliisa',
    },
    {
      state: 'Buvuma',
    },
    {
      state: 'Gulu',
    },
    {
      state: 'Hoima',
    },
    {
      state: 'Iganga',
    },
    {
      state: 'Jinja',
    },
    {
      state: 'Kabale',
    },
    {
      state: 'Kabarole',
    },
    {
      state: 'Kalangala',
    },
    {
      state: 'Kaliro',
    },
    {
      state: 'Kalungu',
    },
    {
      state: 'Kampala',
    },
    {
      state: 'Kamuli',
    },
    {
      state: 'Kamwenge',
    },
    {
      state: 'Kapchorwa',
    },
    {
      state: 'Kasese',
    },
    {
      state: 'Kayunga',
    },
    {
      state: 'Kibaale',
    },
    {
      state: 'Kiboga',
    },
    {
      state: 'Kiruhura',
    },
    {
      state: 'Kitgum',
    },
    {
      state: 'Kyenjojo',
    },
    {
      state: 'Lira',
    },
    {
      state: 'Luwero',
    },
    {
      state: 'Masaka',
    },
    {
      state: 'Masindi',
    },
    {
      state: 'Mayuge',
    },
    {
      state: 'Mbale',
    },
    {
      state: 'Mbarara',
    },
    {
      state: 'Mityana',
    },
    {
      state: 'Mpigi',
    },
    {
      state: 'Mubende',
    },
    {
      state: 'Mukono',
    },
    {
      state: 'Nakaseke',
    },
    {
      state: 'Ntungamo',
    },
    {
      state: 'Oyam',
    },
    {
      state: 'Pallisa',
    },
    {
      state: 'Rukungiri',
    },
    {
      state: 'Sironko',
    },
    {
      state: 'Soroti',
    },
    {
      state: 'Wakiso',
    },
  ],
  UKR: [
    {
      state: 'Avtonomna Respublika Krym',
    },
    {
      state: 'Cherkaska oblast',
    },
    {
      state: 'Chernihivska oblast',
    },
    {
      state: 'Chernivetska oblast',
    },
    {
      state: 'Dnipropetrovska oblast',
    },
    {
      state: 'Donetska oblast',
    },
    {
      state: 'Ivano-Frankivska oblast',
    },
    {
      state: 'Kharkivska oblast',
    },
    {
      state: 'Khersonska oblast',
    },
    {
      state: 'Khmelnytska oblast',
    },
    {
      state: 'Kirovohradska oblast',
    },
    {
      state: 'Kyiv',
    },
    {
      state: 'Kyivska oblast',
    },
    {
      state: 'Luhanska oblast',
    },
    {
      state: 'Lvivska oblast',
    },
    {
      state: 'Mykolaivska oblast',
    },
    {
      state: 'Odeska oblast',
    },
    {
      state: 'Poltavska oblast',
    },
    {
      state: 'Rivnenska oblast',
    },
    {
      state: 'Sevastopol',
    },
    {
      state: 'Sumska oblast',
    },
    {
      state: 'Ternopilska oblast',
    },
    {
      state: 'Vinnytska oblast',
    },
    {
      state: 'Volynska oblast',
    },
    {
      state: 'Zakarpatska oblast',
    },
    {
      state: 'Zaporizka oblast',
    },
    {
      state: 'Zhytomyrska oblast',
    },
  ],
  ARE: [
    {
      state: 'Ajman',
    },
    {
      state: 'Abu Dhabi',
    },
    {
      state: 'Fujairah',
    },
    {
      state: 'Sharjah',
    },
    {
      state: 'Dubai',
    },
    {
      state: "Ra's al-Khaimah",
    },
    {
      state: 'Umm al-Qaiwain',
    },
  ],
  GBR: [
    {
      state: 'England',
    },
    {
      state: 'Northern Ireland',
    },
    {
      state: 'Scotland',
    },
    {
      state: 'Wales',
    },
  ],
  USA: [
    {
      state: 'Alabama',
    },
    {
      state: 'Alaska',
    },
    {
      state: 'Arizona',
    },
    {
      state: 'Arkansas',
    },
    {
      state: 'California',
    },
    {
      state: 'Colorado',
    },
    {
      state: 'Connecticut',
    },
    {
      state: 'Delaware',
    },
    {
      state: 'District of Columbia',
    },
    {
      state: 'Florida',
    },
    {
      state: 'Georgia',
    },
    {
      state: 'Hawaii',
    },
    {
      state: 'Idaho',
    },
    {
      state: 'Illinois',
    },
    {
      state: 'Indiana',
    },
    {
      state: 'Iowa',
    },
    {
      state: 'Kansas',
    },
    {
      state: 'Kentucky',
    },
    {
      state: 'Louisiana',
    },
    {
      state: 'Maine',
    },
    {
      state: 'Maryland',
    },
    {
      state: 'Massachusetts',
    },
    {
      state: 'Michigan',
    },
    {
      state: 'Minnesota',
    },
    {
      state: 'Mississippi',
    },
    {
      state: 'Missouri',
    },
    {
      state: 'Montana',
    },
    {
      state: 'Nebraska',
    },
    {
      state: 'Nevada',
    },
    {
      state: 'New Hampshire',
    },
    {
      state: 'New Jersey',
    },
    {
      state: 'New Mexico',
    },
    {
      state: 'New York',
    },
    {
      state: 'North Carolina',
    },
    {
      state: 'North Dakota',
    },
    {
      state: 'Ohio',
    },
    {
      state: 'Oklahoma',
    },
    {
      state: 'Oregon',
    },
    {
      state: 'Pennsylvania',
    },
    {
      state: 'Rhode Island',
    },
    {
      state: 'South Carolina',
    },
    {
      state: 'South Dakota',
    },
    {
      state: 'Tennessee',
    },
    {
      state: 'Texas',
    },
    {
      state: 'Utah',
    },
    {
      state: 'Vermont',
    },
    {
      state: 'Virginia',
    },
    {
      state: 'Washington',
    },
    {
      state: 'West Virginia',
    },
    {
      state: 'Wisconsin',
    },
    {
      state: 'Wyoming',
    },
  ],
  UMI: [
    {
      state: 'Palmyra Atoll',
    },
  ],
  URY: [
    {
      state: 'Artigas',
    },
    {
      state: 'Canelones',
    },
    {
      state: 'Cerro Largo',
    },
    {
      state: 'Colonia',
    },
    {
      state: 'Durazno',
    },
    {
      state: 'Flores',
    },
    {
      state: 'Florida',
    },
    {
      state: 'Lavalleja',
    },
    {
      state: 'Maldonado',
    },
    {
      state: 'Montevideo',
    },
    {
      state: 'Paysandu',
    },
    {
      state: 'Rio Negro',
    },
    {
      state: 'Rivera',
    },
    {
      state: 'Rocha',
    },
    {
      state: 'Salto',
    },
    {
      state: 'San Jose',
    },
    {
      state: 'Soriano',
    },
    {
      state: 'Tacuarembo',
    },
    {
      state: 'Treinta y Tres',
    },
  ],
  UZB: [
    {
      state: 'Andijon',
    },
    {
      state: 'Buxoro',
    },
    {
      state: "Farg'ona",
    },
    {
      state: 'Jizzax',
    },
    {
      state: 'Namangan',
    },
    {
      state: 'Navoiy',
    },
    {
      state: 'Qashqadaryo',
    },
    {
      state: "Qoraqalpog'iston Respublikasi",
    },
    {
      state: 'Samarqand',
    },
    {
      state: 'Sirdaryo',
    },
    {
      state: 'Surxondaryo',
    },
    {
      state: 'Toshkent',
    },
    {
      state: 'Xorazm',
    },
  ],
  VUT: [
    {
      state: 'Shefa',
    },
    {
      state: 'Tafea',
    },
    {
      state: 'Torba',
    },
  ],
  VNM: [
    {
      state: 'An Giang',
    },
    {
      state: 'Ba Ria - Vung Tau',
    },
    {
      state: 'Bac Giang',
    },
    {
      state: 'Bac Kan',
    },
    {
      state: 'Bac Lieu',
    },
    {
      state: 'Bac Ninh',
    },
    {
      state: 'Ben Tre',
    },
    {
      state: 'Binh Dinh',
    },
    {
      state: 'Binh Duong',
    },
    {
      state: 'Binh Phuoc',
    },
    {
      state: 'Binh Thuan',
    },
    {
      state: 'Ca Mau',
    },
    {
      state: 'Can Tho',
    },
    {
      state: 'Cao Bang',
    },
    {
      state: 'Da Nang',
    },
    {
      state: 'Dak Lak',
    },
    {
      state: 'Dak Nong',
    },
    {
      state: 'Dien Bien',
    },
    {
      state: 'Dong Nai',
    },
    {
      state: 'Dong Thap',
    },
    {
      state: 'Gia Lai',
    },
    {
      state: 'Ha Giang',
    },
    {
      state: 'Ha Nam',
    },
    {
      state: 'Ha Noi',
    },
    {
      state: 'Ha Tinh',
    },
    {
      state: 'Hai Duong',
    },
    {
      state: 'Hai Phong',
    },
    {
      state: 'Ho Chi Minh',
    },
    {
      state: 'Hoa Binh',
    },
    {
      state: 'Hung Yen',
    },
    {
      state: 'Khanh Hoa',
    },
    {
      state: 'Kien Giang',
    },
    {
      state: 'Kon Tum',
    },
    {
      state: 'Lai Chau',
    },
    {
      state: 'Lam Dong',
    },
    {
      state: 'Lang Son',
    },
    {
      state: 'Lao Cai',
    },
    {
      state: 'Long An',
    },
    {
      state: 'Nam Dinh',
    },
    {
      state: 'Nghe An',
    },
    {
      state: 'Ninh Binh',
    },
    {
      state: 'Ninh Thuan',
    },
    {
      state: 'Phu Tho',
    },
    {
      state: 'Phu Yen',
    },
    {
      state: 'Quang Binh',
    },
    {
      state: 'Quang Nam',
    },
    {
      state: 'Quang Ngai',
    },
    {
      state: 'Quang Ninh',
    },
    {
      state: 'Quang Tri',
    },
    {
      state: 'Soc Trang',
    },
    {
      state: 'Son La',
    },
    {
      state: 'Tay Ninh',
    },
    {
      state: 'Thai Binh',
    },
    {
      state: 'Thai Nguyen',
    },
    {
      state: 'Thanh Hoa',
    },
    {
      state: 'Thua Thien-Hue',
    },
    {
      state: 'Tien Giang',
    },
    {
      state: 'Tra Vinh',
    },
    {
      state: 'Tuyen Quang',
    },
    {
      state: 'Vinh Long',
    },
    {
      state: 'Vinh Phuc',
    },
    {
      state: 'Yen Bai',
    },
  ],
  VGB: [
    {
      state: 'Virgin Islands, British',
    },
  ],
  VIR: [
    {
      state: 'Virgin Islands, U.S.',
    },
  ],
  WLF: [
    {
      state: 'Sigave',
    },
    {
      state: 'Uvea',
    },
  ],
  YEM: [
    {
      state: "'Adan",
    },
    {
      state: "'Amran",
    },
    {
      state: "Ad Dali'",
    },
    {
      state: "Al Bayda'",
    },
    {
      state: 'Al Hudaydah',
    },
    {
      state: 'Al Mahrah',
    },
    {
      state: "Amanat al 'Asimah",
    },
    {
      state: 'Dhamar',
    },
    {
      state: 'Hadramawt',
    },
    {
      state: 'Hajjah',
    },
    {
      state: 'Ibb',
    },
    {
      state: 'Lahij',
    },
    {
      state: "Ma'rib",
    },
    {
      state: "Sa'dah",
    },
    {
      state: "San'a'",
    },
    {
      state: 'Shabwah',
    },
    {
      state: "Ta'izz",
    },
  ],
  ZMB: [
    {
      state: 'Central',
    },
    {
      state: 'Copperbelt',
    },
    {
      state: 'Eastern',
    },
    {
      state: 'Luapula',
    },
    {
      state: 'Lusaka',
    },
    {
      state: 'North-Western',
    },
    {
      state: 'Northern',
    },
    {
      state: 'Southern',
    },
    {
      state: 'Western',
    },
  ],
  ZWE: [
    {
      state: 'Bulawayo',
    },
    {
      state: 'Harare',
    },
    {
      state: 'Manicaland',
    },
    {
      state: 'Mashonaland Central',
    },
    {
      state: 'Mashonaland East',
    },
    {
      state: 'Mashonaland West',
    },
    {
      state: 'Masvingo',
    },
    {
      state: 'Matabeleland North',
    },
    {
      state: 'Matabeleland South',
    },
    {
      state: 'Midlands',
    },
  ],
};

// Online converter: https://www.convertcsv.com/csv-to-json.htm
// For inputStateabbreviations select step 4
// Top: {
// Repeating Section for each Line of CSV:  '{f1}':{
//                                                  abbreviations: ['{f2}', '{f3}']}
//                                            }
// Bottom: }
const inputStateabbreviations: StateWithabbreviations = {
  Alaska: {
    abbreviations: ['AK', 'Alaska'],
  },
  Arizona: {
    abbreviations: ['AZ', 'Ariz.'],
  },
  Arkansas: {
    abbreviations: ['AR', 'Ark.'],
  },
  California: {
    abbreviations: ['CA', 'Calif.'],
  },
  Colorado: {
    abbreviations: ['CO', 'Colo.'],
  },
  Connecticut: {
    abbreviations: ['CT', 'Conn.'],
  },
  Delaware: {
    abbreviations: ['DE', 'Del.'],
  },
  'District of Columbia': {
    abbreviations: ['DC', 'D.C.'],
  },
  Florida: {
    abbreviations: ['FL', 'Fla.'],
  },
  Georgia: {
    abbreviations: ['GA', 'Ga.'],
  },
  Hawaii: {
    abbreviations: ['HI', 'Hawaii'],
  },
  Idaho: {
    abbreviations: ['ID', 'Idaho'],
  },
  Illinois: {
    abbreviations: ['IL', 'Ill.'],
  },
  Indiana: {
    abbreviations: ['IN', 'Ind.'],
  },
  Iowa: {
    abbreviations: ['IA', 'Iowa'],
  },
  Kansas: {
    abbreviations: ['KS', 'Kans.'],
  },
  Kentucky: {
    abbreviations: ['KY', 'Ky.'],
  },
  Louisiana: {
    abbreviations: ['LA', 'La.'],
  },
  Maine: {
    abbreviations: ['ME', 'Maine'],
  },
  Maryland: {
    abbreviations: ['MD', 'Md.'],
  },
  Massachusetts: {
    abbreviations: ['MA', 'Mass.'],
  },
  Michigan: {
    abbreviations: ['MI', 'Mich.'],
  },
  Minnesota: {
    abbreviations: ['MN', 'Minn.'],
  },
  Mississippi: {
    abbreviations: ['MS', 'Miss.'],
  },
  Missouri: {
    abbreviations: ['MO', 'Mo.'],
  },
  Montana: {
    abbreviations: ['MT', 'Mont.'],
  },
  Nebraska: {
    abbreviations: ['NE', 'Nebr.'],
  },
  Nevada: {
    abbreviations: ['NV', 'Nev.'],
  },
  'New Hampshire': {
    abbreviations: ['NH', 'N.H.'],
  },
  'New Jersey': {
    abbreviations: ['NJ', 'N.J.'],
  },
  'New Mexico': {
    abbreviations: ['NM', 'N. Mex.'],
  },
  'New York': {
    abbreviations: ['NY', 'N.Y.'],
  },
  'North Carolina': {
    abbreviations: ['NC', 'N.C.'],
  },
  'North Dakota': {
    abbreviations: ['ND', 'N. Dak.'],
  },
  Ohio: {
    abbreviations: ['OH', 'Ohio'],
  },
  Oklahoma: {
    abbreviations: ['OK', 'Okla.'],
  },
  Oregon: {
    abbreviations: ['OR', 'Oreg.'],
  },
  Pennsylvania: {
    abbreviations: ['PA', 'Pa.'],
  },
  'Rhode Island': {
    abbreviations: ['RI', 'R.I.'],
  },
  'South Carolina': {
    abbreviations: ['SC', 'S.C.'],
  },
  'South Dakota': {
    abbreviations: ['SD', 'S. Dak.'],
  },
  Tennessee: {
    abbreviations: ['TN', 'Tenn.'],
  },
  Texas: {
    abbreviations: ['TX', 'Tex.'],
  },
  Utah: {
    abbreviations: ['UT', 'Utah'],
  },
  Vermont: {
    abbreviations: ['VT', 'Vt.'],
  },
  Virginia: {
    abbreviations: ['VA', 'Va.'],
  },
  Washington: {
    abbreviations: ['WA', 'Wash.'],
  },
  'West Virginia': {
    abbreviations: ['WV', 'W. Va.'],
  },
  Wisconsin: {
    abbreviations: ['WI', 'Wis.'],
  },
  Wyoming: {
    abbreviations: ['WY', 'Wyo.'],
  },
  'American Samoa': {
    abbreviations: ['AS', 'A.S.'],
  },
  Guam: {
    abbreviations: ['GU', 'Guam'],
  },
  'Puerto Rico': {
    abbreviations: ['PR', 'P.R.'],
  },
  'U.S. Virgin Islands': {
    abbreviations: ['VI', 'V.I.'],
  },
};

const formatData = () => {
  Object.keys(inputCountries).forEach((countryCode: string) => {
    inputCountries[countryCode].code = countryCode;
    if (inputStates[countryCode]) {
      inputStates[countryCode].forEach((stateObject) => {
        if (inputCountries?.[countryCode]?.states) {
          inputCountries[countryCode].states?.push({
            title: stateObject.state.toLocaleUpperCase(),
            code: stateObject.state.toLocaleUpperCase(),
            abbreviations: inputStateabbreviations[stateObject.state]?.abbreviations,
          });
        } else {
          inputCountries[countryCode].states = [
            {
              title: stateObject.state.toLocaleUpperCase(),
              code: stateObject.state.toLocaleUpperCase(),
            },
          ];
        }
      });
    }
  });
  return inputCountries;
};

export default formatData();
