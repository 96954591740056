import { AppState, AppThunkDispatch } from '..';
import { showNotification } from '../../utils/util';
import CustomerAssetWorkflow, {
  AssetRecommendationsRequest,
} from '../../services/customer-asset-workflow';
import { assetRecommendationsActionNames } from './types';
import i18n from 'i18next';
import TEXT from '../../globals/translation-map';
import { default as Axios, CancelTokenSource } from 'axios';

let ajaxRequest: CancelTokenSource | null = null;

const getAssetRecommendationsData = (
  fetchRequest: AssetRecommendationsRequest,
  input: {
    pageNumber: number;
    sortBy: string;
    orderBy: string;
  },
) => {
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    if (ajaxRequest) {
      ajaxRequest.cancel();
    }
    ajaxRequest = Axios.CancelToken.source();
    const { pageNumber, sortBy, orderBy } = input;
    let filtersData = fetchRequest?.body ? fetchRequest?.body : {};
    const {
      login: { authInfo, environmentVariables },
      assetRecommendations: { cursors },
    } = getState();

    const assetRecommendationsService = new CustomerAssetWorkflow(
      authInfo,
      environmentVariables.customerAssetWorkflowV1,
    );

    dispatch({
      type: assetRecommendationsActionNames.SET_IS_LOADING_ASSET_RECOMMENDATIONS_DATA,
      payload: true,
    });
    dispatch({
      type: assetRecommendationsActionNames.SET_ASSET_RECOMMENDATIONS_DATA,
      payload: [],
    });
    try {
      let assetRecommendationsSearchRequest: AssetRecommendationsRequest = {
        limit: 100,
        body: filtersData,
        sortBy: sortBy,
        orderBy: orderBy,
        ...(cursors && cursors.length !== 0 && { cursor: cursors[pageNumber - 1] }),
      };
      const [assetRecommendationsResponse, recordCount] = await Promise.all([
        assetRecommendationsService.searchAssetRecommendations(
          assetRecommendationsSearchRequest,
          ajaxRequest ? ajaxRequest.token : undefined,
        ),
        assetRecommendationsService.countAssetRecommendations(
          assetRecommendationsSearchRequest,
          ajaxRequest ? ajaxRequest.token : undefined,
        ),
      ]);
      dispatch({
        type: assetRecommendationsActionNames.SET_ASSET_RECOMMENDATIONS_DATA,
        payload: assetRecommendationsResponse?.data?.recommendations || [],
      });
      dispatch({
        type: assetRecommendationsActionNames.SET_ASSET_RECOMMENDATIONS_COUNT,
        payload: recordCount?.data?.count || '',
      });

      if (
        assetRecommendationsResponse?.data.responseMetadata?.nextCursor &&
        assetRecommendationsResponse?.data.responseMetadata.nextCursor !== ''
      )
        dispatch({
          type: assetRecommendationsActionNames.SET_ASSET_RECOMMENDATIONS_CURSOR,
          payload: [...cursors, assetRecommendationsResponse?.data.responseMetadata.nextCursor],
        });
    } catch {
      showNotification(
        dispatch,
        'error',
        i18n.t(TEXT.CUSTOMER_ASSET_WORKFLOW.ERRORS.ASSETS_RECOMMENDATION_RETRIEVE_FAILED),
        { persist: true },
      );
    } finally {
      dispatch({
        type: assetRecommendationsActionNames.SET_IS_LOADING_ASSET_RECOMMENDATIONS_DATA,
        payload: false,
      });
    }
  };
};

export default getAssetRecommendationsData;
