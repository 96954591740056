import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  divider: {
    marginTop: 8,
  },
  footerActions: {
    backgroundColor: 'white',
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: '100%',
    height: theme.spacing(9),
    mr: 2,
    pb: 2,
  },
  appStepper: {
    marginTop: theme.spacing(0.3),
    marginBottom: theme.spacing(0.3),
    width: theme.spacing(100),
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent: 'center',
  },
  label: {
    fontSize: '14px',
    fontWeight: 600,
  },
  data: {
    fontSize: '14px',
    fontWeight: '400',
  },
}));
