import { SetIsLoading } from '../api/loadable';
import {
  AccessType,
  CreateInviteErrorResponse,
  InviteResponse,
  ModelError,
  Status,
} from '../../entities/user-onboarding-v1/models';
import { OrganizationResponse } from '../../entities/customer-master-v1/organizationResponse';

export enum Roles {
  TESTUSERADMIN = 'User Management Test Admin',
  STANDARDTEST = 'Standard Test',
  USERMANAGEMENTADMIN_FM3 = 'Application User Management Administrator FM3',
  DEALER_ADMIN = 'Dealer Admin',
  DATA_STEWARD_READ_ONLY = 'Data Steward Read Only',
  DIGITAL_ACCOUNT_MANAGER = 'Digital Account Manager',
  DATA_STEWARD_ADMINISTRATOR = 'Data Steward Administrator',
  DEALER_READ_ONLY = 'Dealer Read only',
  DEALER_SUPPORT_ADMIN = 'Dealer Support Admin',
}

export enum invitationsActionNames {
  SET_INVITATION_INITIAL_STATE = 'SET_INVITATION_INITIAL_STATE',
  CLEAR_CREATE_INVITAIONS = 'CLEAR_CREATE_INVITAIONS',
  CLEAR_INVITATION_FILTERS = 'CLEAR_INVITATION_FILTERS',
  CLEAR_INVITATIONS = 'CLEAR_INVITATIONS',
  RESET_CURSORS_FOR_INVITATIONS = 'RESET_CURSORS_FOR_INVITATIONS',
  SET_ACCOUNTS = 'SET_ACCOUNTS',
  SET_COUNT_FOR_ITEMS = 'SET_COUNT_FOR_ITEMS',
  SET_CURSORS_FOR_INVITATIONS = 'SET_CURSORS_FOR_INVITATIONS',
  SET_CURSORS_FOR_ITEMS = 'SET_CURSORS_FOR_ITEMS',
  SET_INVITATION_FILTERS = 'SET_INVITATION_FILTERS',
  SET_INVITATION_SEARCH_VALUE = 'SET_INVITATION_SEARCH_VALUE',
  SET_INVITATIONS = 'SET_INVITATIONS',
  SET_INVITATIONS_RESPONSE = 'SET_INVITATIONS_RESPONSE',
  SET_IS_LOADING_COUNT = 'SET_IS_LOADING_COUNT',
  SET_IS_LOADING_INVITATIONS = 'SET_IS_LOADING_INVITATIONS',
  SET_IS_USERS_PRIMARY_PARTY = 'SET_IS_USERS_PRIMARY_PARTY',
  SET_LANGUAGE = 'SET_LANGUAGE',
  SET_INVITATIONS_ORDER_BY_VALUE = 'SET_INVITATIONS_ORDER_BY_VALUE',
  SET_SELECTED_CUSTOMER_FOR_INVITATIONS = ' SET_SELECTED_CUSTOMER_FOR_INVITATIONS',
  SET_INVITATIONS_SORT_BY_VALUE = 'SET_INVITATIONS_SORT_BY_VALUE',
  SET_INVITATIONS_TOTAL_COUNT = 'SET_INVITATIONS_TOTAL_COUNT',
  SET_CREATE_INVITE_LINKS = 'SET_CREATE_INVITE_LINKS',
  SET_STALE_INVITATIONS = 'SET_STALE_INVITATIONS',
}
export const invitationsPermissionName = 'invitations';
export const invitationsPermissionMethods = {
  update: 'update',
  view: 'view',
  undoRevoke: 'undoRevoke',
  revoke: 'revoke',
  create: 'create',
  resendEmail: 'resendEmail',
  process: 'process',
};

export enum RoleType {
  READ_ONLY = 'Read Only',
  NO_ACCESS = 'No Access',
  ADMINISTRATOR = 'Administrator',
  STANDARD_USER = 'Standard User',
  ASSET_SECURITY = 'Asset Security',
  SOS_PILOT_PRIVILEGE = 'SOS Pilot Privilege',
}

export type ErrorType = {
  code: number;
  message: string;
};

type CursorType = {
  nextCursors: Array<string> | [];
  currentPage?: number;
};
export type InviteAccount = {
  emailAddress: string;
  username?: string;
};

export type InviteRequest = {
  partyNumber: string;
  isUsersPrimaryParty: boolean;
  email: InviteAccount;
  username: string;
  language: string;
  applications: [
    {
      applicationId: string;
      accessType: AccessType;
      accessIdentifiers: Array<string>;
    },
  ];
};

type InvitationsFilters = {
  orderByRules: Array<string>;
  sortByRules: Array<string>;
  selectedAccount: string;
  manualSelectedAccountEntry: boolean;
  selectedCustomers: Array<OrganizationResponse>;
  selectedApplications: Array<string>;
  selectedInvitationStatus: Array<Status>;
  startDate?: Date;
  endDate?: Date;
  selectedTimeRangeButton?: number;
};

export interface CreateInviteErrorResponseUpdate extends InviteResponse {
  error?: ModelError;
}

export interface InvitationsState {
  createLinks?: boolean;
  invitations?: Array<InviteResponse | CreateInviteErrorResponse>;
  totalInvitationCount?: number;
  totalItemsCount?: number;
  cursorForItems?: string;
  cursorsForInvitations: CursorType;
  isLoadingInvitations?: boolean;
  isLoadingInvitationsCount?: boolean;
  invitationSearchValue: string;
  invitationsFilters: InvitationsFilters;
  isUsersPrimaryParty: boolean;
  staleInvitations: boolean;
  createInvitations: {
    accounts: Array<InviteAccount>;
    selectedLanguage: string;
    invitationsResponse?: Array<InviteResponse | CreateInviteErrorResponseUpdate>;
    selectedCustomer?: OrganizationResponse;
  };
}

export interface SetInitialInvitations {
  type: typeof invitationsActionNames.SET_INVITATION_INITIAL_STATE;
}
export interface SetMyInvitations {
  type: typeof invitationsActionNames.SET_INVITATIONS;
  payload: Array<InviteResponse | CreateInviteErrorResponse>;
}

interface ClearMyInvitations {
  type: typeof invitationsActionNames.CLEAR_INVITATIONS;
}

interface SetCursorsForInvitations {
  type: typeof invitationsActionNames.SET_CURSORS_FOR_INVITATIONS;
  payload: { cursor: string; currentPage: number };
}
interface SetCursorsForItems {
  type: typeof invitationsActionNames.SET_CURSORS_FOR_ITEMS;
  payload: string;
}
interface SetCountForItems {
  type: typeof invitationsActionNames.SET_COUNT_FOR_ITEMS;
  payload: number;
}

interface ResetCursorsForInvitations {
  type: typeof invitationsActionNames.RESET_CURSORS_FOR_INVITATIONS;
}

interface SetTotalCount {
  type: typeof invitationsActionNames.SET_INVITATIONS_TOTAL_COUNT;
  payload: number;
}

export interface SetInvitationSearchValue {
  type: typeof invitationsActionNames.SET_INVITATION_SEARCH_VALUE;
  payload: string;
}

export interface SetInvitationFilters {
  type: typeof invitationsActionNames.SET_INVITATION_FILTERS;
  payload: InvitationsFilters;
}

export interface ClearInvitationFilters {
  type: typeof invitationsActionNames.CLEAR_INVITATION_FILTERS;
}
export interface SetLanguage {
  type: typeof invitationsActionNames.SET_LANGUAGE;
  payload: string;
}
export interface SetAccounts {
  type: typeof invitationsActionNames.SET_ACCOUNTS;
  payload: Array<InviteAccount>;
}
interface ClearCreateInvitations {
  type: typeof invitationsActionNames.CLEAR_CREATE_INVITAIONS;
}
export interface SetInvitationsResponse {
  type: typeof invitationsActionNames.SET_INVITATIONS_RESPONSE;
  payload: Array<InviteResponse | CreateInviteErrorResponse>;
}
export interface SetSelectedCustomerForInvitations {
  type: typeof invitationsActionNames.SET_SELECTED_CUSTOMER_FOR_INVITATIONS;
  payload: OrganizationResponse;
}

export interface SetIsUsersPrimaryParty {
  type: typeof invitationsActionNames.SET_IS_USERS_PRIMARY_PARTY;
  payload: boolean;
}
export interface SetCreateInviteLinks {
  type: typeof invitationsActionNames.SET_CREATE_INVITE_LINKS;
  payload: boolean;
}
export interface SetSortByRules {
  type: typeof invitationsActionNames.SET_INVITATIONS_SORT_BY_VALUE;
  payload: Array<string>;
}
export interface SetOrderByRules {
  type: typeof invitationsActionNames.SET_INVITATIONS_ORDER_BY_VALUE;
  payload: Array<string>;
}
export interface setStaleInvitations {
  type: typeof invitationsActionNames.SET_STALE_INVITATIONS;
  payload: boolean;
}
export type InvitationsActionTypes =
  | SetInitialInvitations
  | ClearCreateInvitations
  | ClearInvitationFilters
  | ClearMyInvitations
  | ResetCursorsForInvitations
  | SetAccounts
  | SetCountForItems
  | SetCursorsForInvitations
  | SetCursorsForItems
  | SetInvitationFilters
  | SetInvitationSearchValue
  | SetInvitationsResponse
  | SetIsLoading<invitationsActionNames.SET_IS_LOADING_COUNT>
  | SetIsLoading<invitationsActionNames.SET_IS_LOADING_INVITATIONS>
  | SetIsUsersPrimaryParty
  | SetLanguage
  | SetMyInvitations
  | SetOrderByRules
  | SetSelectedCustomerForInvitations
  | SetSortByRules
  | SetCreateInviteLinks
  | SetTotalCount
  | setStaleInvitations;
