import { AddCustomerActionTypes, addCustomerActionNames, AddCustomerState } from './types';

const initialState: AddCustomerState = {
  duplicateCustomers: [],
};

const addCustomerReducer = (
  state: AddCustomerState = initialState,
  action: AddCustomerActionTypes,
): AddCustomerState => {
  switch (action.type) {
    case addCustomerActionNames.SET_CREATE_CUSTOMER: {
      return {
        ...state,
        createCustomer: action.payload,
      };
    }
    case addCustomerActionNames.CLEAR_CREATE_CUSTOMER: {
      return {
        ...state,
        createCustomer: initialState.createCustomer,
      };
    }
    case addCustomerActionNames.SET_DUPLICATE_CUSTOMERS: {
      return {
        ...state,
        duplicateCustomers: [...state.duplicateCustomers, ...action.payload],
      };
    }
    case addCustomerActionNames.CLEAR_DUPLICATE_CUSTOMERS: {
      return {
        ...state,
        duplicateCustomers: initialState.duplicateCustomers,
      };
    }
    case addCustomerActionNames.SET_IS_LOADING_FOR_DUPLICATE_ENTRIES: {
      return {
        ...state,
        isLoadingForDuplicateEntries: action.payload,
      };
    }
    case addCustomerActionNames.SET_SELECTED_CUSTOMER: {
      return {
        ...state,
        selectedCustomer: action.payload,
      };
    }
    case addCustomerActionNames.CLEAR_SELECTED_CUSTOMER: {
      return {
        ...state,
        selectedCustomer: initialState.selectedCustomer,
      };
    }
    case addCustomerActionNames.SET_IMPORTED_DEALER_CUSTOMER: {
      return {
        ...state,
        importedDealerCustomer: action.payload,
      };
    }
    case addCustomerActionNames.SET_LOCKED_DCN_FLAG: {
      return {
        ...state,
        lockedDcnFlag: action.payload,
      };
    }
    case addCustomerActionNames.CLEAR_IMPORTED_DEALER_CUSTOMER: {
      return {
        ...state,
        importedDealerCustomer: initialState.importedDealerCustomer,
      };
    }
    case addCustomerActionNames.SET_ASSOCIATED_DEALER_CUSTOMERS: {
      return {
        ...state,
        associatedDcns: action.payload,
      };
    }
    case addCustomerActionNames.SET_DISASSOCIATED_DEALER_CUSTOMERS: {
      return {
        ...state,
        disassociatedDcns: action.payload,
      };
    }
    case addCustomerActionNames.CLEAR_ASSOCIATED_DEALER_CUSTOMERS: {
      return {
        ...state,
        associatedDcns: initialState.associatedDcns,
      };
    }
    case addCustomerActionNames.SET_ASSOCIATED_USERS: {
      return {
        ...state,
        associatedUsers: action.payload,
      };
    }
    case addCustomerActionNames.CLEAR_ASSOCIATED_USERS: {
      return {
        ...state,
        associatedUsers: initialState.associatedUsers,
      };
    }
    case addCustomerActionNames.SET_CUSTOMER_INFO_ENTERED_FLAG: {
      return {
        ...state,
        customerInfoEntered: action.payload,
      };
    }
    case addCustomerActionNames.SET_NEXT_CURSOR: {
      return {
        ...state,
        nextCursor: action.payload,
      };
    }
    case addCustomerActionNames.SET_TOTAL_COUNT: {
      return {
        ...state,
        totalDuplicateCount: action.payload,
      };
    }
    case addCustomerActionNames.CREATE_NEW_DEALER_FROM_IMPORT: {
      return {
        ...state,
        createNewDealerFromImport: action.payload,
      };
    }
    default:
      return state;
  }
};

export default addCustomerReducer;
