// TODO: Use global theming instead of hardcoding pixel values
import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

const useStyles = makeStyles()((theme) => ({
  container: {
    borderBottom: `2px solid ${theme.palette.grey[400]}`,
  },
}));

const useTabsStyles = makeStyles()(() => ({
  root: {
    width: '100%',
  },
  indicator: {
    backgroundColor: CAT_COLOR.GOLDEN_POPPY,
  },
  hidden: {
    display: 'none',
  },
}));

const useTabItemStyles = makeStyles()(() => ({
  root: {
    textTransform: 'none',
    fontWeight: 400,
    boxShadow: `inset 0 -1px 0 0 ${CAT_COLOR.LAVENDAR_MIST}`,
    backgroundColor: CAT_COLOR.WHITE,
    '&:hover': {
      backgroundColor: CAT_COLOR.ALABASTER,
      boxShadow: `inset 0 -1px 0 0 ${CAT_COLOR.LAVENDAR_MIST}`,
    },
  },
  selected: {
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
    fontWeight: 600,
    backgroundColor: CAT_COLOR.WHITE,
  },
}));

export { useStyles, useTabsStyles, useTabItemStyles };
