import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { FC } from 'react';
import { FormikProps } from 'formik';

import AdvanceFiltersForm from '../../form-components/advance-filters';
import StepperButton from '../../../../components/stepper-button';
import useStyles from './styles';
import { AuthInfo } from '../../../../model/types';
import { EnvironmentVariables } from '../../../../environments';
import { useTranslation } from 'react-i18next';
import TEXT from '../../../../globals/translation-map';
import globalStyles from '../../../../globals/styles';

type RecommendationFilters = {
  dealerCode?: string[];
};

export type FiltersDialogProperties = {
  authInfo: AuthInfo | null;
  environmentVariables: EnvironmentVariables;
  onClickOfApply: Function;
  handleFiltersDialogClose: Function;
  userSelectedFilters?: RecommendationFilters;
  clearFilters: Function;
  dealerCodes?: Array<string>;
  formik: FormikProps<any>;
};

const FilterDialog: FC<FiltersDialogProperties> = (props: FiltersDialogProperties) => {
  const { classes } = useStyles();
  const { classes: globalClasses } = globalStyles();
  const { clearFilters, handleFiltersDialogClose, onClickOfApply, formik, dealerCodes } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      data-testid="filter-dialog"
      aria-labelledby="advance-filters-dialog"
      open
      onClose={() => {
        handleFiltersDialogClose();
      }}
      PaperProps={{
        className: globalClasses.dynamicDialog,
      }}
    >
      <DialogTitle data-testid="filter-asset-recommendations-dialog-title">
        <Typography variant="h3" component="span" className={classes.filterByText}>
          {t(TEXT.COMMON.FILTERS.TITLES.RECOMMENDATIONS)}
        </Typography>
        <IconButton
          className={classes.iconPosition}
          data-testid="cancel-notification-button"
          onClick={() => {
            handleFiltersDialogClose();
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <AdvanceFiltersForm
          formikProps={formik}
          selectedDealerCode={formik.values.dealerCode ?? formik.initialValues.dealerCode}
          dealerCodes={dealerCodes}
        />
      </DialogContent>
      <Divider />
      <DialogActions className={classes.footerSpacing}>
        <Box display="flex" justifyContent="flex-end">
          <Box mr={1}>
            <StepperButton
              buttonText={t(TEXT.COMMON.CLOSE)}
              id="cancel-advance-filter"
              color="secondary"
              onClick={() => {
                handleFiltersDialogClose();
              }}
            />
          </Box>
          <Box mr={2}>
            <StepperButton
              buttonText={t(TEXT.COMMON.CLEAR_ALL)}
              id="clear-advance-filter"
              color="secondary"
              onClick={() => {
                clearFilters();
                formik.setFieldValue('dealerCode', []);
              }}
            />
          </Box>
          <Box mr={2}>
            <StepperButton
              type="submit"
              buttonText={t(TEXT.COMMON.APPLY)}
              id="apply-advance-filters"
              color="primary"
              disabled={!formik.dirty || !!Object.entries(formik.errors).length}
              onClick={() => {
                onClickOfApply(formik.values);
                handleFiltersDialogClose();
              }}
            />
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
export default FilterDialog;
