import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    textAlign: 'left',
  },
  header: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
  },
  text: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
  },
  iconBlock: {
    paddingRight: theme.spacing(1),
    display: 'block',
    marginBottom: theme.spacing(3.5),
  },
  textBlock: {
    width: '90%',
    display: 'block',
  },
}));
