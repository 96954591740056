import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import VariableRowInfiniteScrollTable from '../../../../components/variable-row-infinite-scroll-table';
import getScreenWidth from '../../../../hooks/get-screen-width';
import { userLeadsTableData } from '../../dummy-data';

import useUserLeadsTableColumns, { TableCel } from './use-user-leads-table-columns';
import TEXT from '../../../../globals/translation-map';
import searchWarningLogo from '../../../../assets/images/search-warning.png';
import noUsersFoundLogo from '../../../../assets/images/no-users-found.png';
import noAppliedFilterLogo from '../../../../assets/images/filters-required.png';
import NoDataComponent from '../../../../components/variable-row-infinite-scroll-table/components/no-data-component';
import { Trans, useTranslation } from 'react-i18next';

const nonExpandedRowSizeBasedOnScreenRes = { xl: 80, lg: 80, md: 96, sm: 160, xs: 160 };
const expandedRowSizeBasedOnScreenRes = {
  xl: 180,
  lg: 180,
  md: 180,
  sm: 180,
  xs: 180,
};
const tableHeight = window.innerHeight - 0.34 * window.innerHeight;

const UserLeadsTable: React.FC = () => {
  const screenWidth = getScreenWidth();
  const { t } = useTranslation();

  const userLeadsTableColumns: TableCel[] = useUserLeadsTableColumns();

  const noResultProps = {
    line1: t(TEXT.COMMON.ERRORS.NO_RESULTS_FOUND),
    line2: t(TEXT.COMMON.ERRORS.REVISING_SEARCH_CRITERIA),
    searchWarningImage: searchWarningLogo,
  };

  const noUserFoundProps = {
    searchWarningImage: noUsersFoundLogo,
    line1: t(TEXT.USER_LEADS.TABLE.NO_USER_LEADS),
  };

  console.log(noUserFoundProps, noResultProps);

  const noFilterAppliedProps = {
    searchWarningImage: noAppliedFilterLogo,
    line1: (
      <Typography fontWeight="bold" align="center" data-testid="no-data-filter-link">
        <Trans
          i18nKey={TEXT.COMMON.ERRORS.SELECT_FILTERS}
          components={[<Link sx={{ fontSize: 14 }} underline="hover" onClick={() => {}} />]}
        />
      </Typography>
    ),
  };

  return (
    <Box>
      <VariableRowInfiniteScrollTable
        rows={userLeadsTableData}
        columns={userLeadsTableColumns}
        nonExpandedRowSizeBasedOnScreenRes={nonExpandedRowSizeBasedOnScreenRes}
        expandedRowSizeBasedOnScreenRes={expandedRowSizeBasedOnScreenRes}
        noDataComponent={<NoDataComponent {...noFilterAppliedProps} />}
        screenWidth={screenWidth}
        hideNoDataComponent={false}
        height={tableHeight}
        hasNextPage={false}
        isNextPageLoading={false}
        displayLoadingIcon={false}
        loadNextPage={() => {}}
        expandedRowFlag={false}
        expandedRowComponent={(item: any) => null}
        skipEntitlementCheck={true}
      />
    </Box>
  );
};

export default UserLeadsTable;
