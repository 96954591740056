import { RecommendationFeedback } from '../../entities/customer-master-v1/recommendationFeedback';
import { SearchOrganizationResponse } from '../../entities/customer-master-v1/searchOrganizationResponse';
import { SearchRecommendationResponse } from '../../entities/customer-master-v1/searchRecommendationResponse';
import { UpdateCustomerOrganizationAndDealerCustomerRecommendation } from '../../entities/customer-master-v1/updateCustomerOrganizationAndDealerCustomerRecommendation';
import { UpdateCustomerOrganizationRecommendation } from '../../entities/customer-master-v1/updateCustomerOrganizationRecommendation';
import { UpdateDealerCustomerRecommendation } from '../../entities/customer-master-v1/updateDealerCustomerRecommendation';
import { RecommendationFilters } from '../../pages/my-work-queue';
import {
  AddFeedbackForRecommendationItemsResponse,
  MultipleDCNRequestBody,
  RecommendationItems,
} from '../../services/recommendations';
import { SetIsLoading } from '../api/loadable';
import { SetDuplicateCustomers } from '../customer-organization/types';

export enum customerRecommendationsActionNames {
  CUSTOMER_SET_IS_LOADING_RECOMMENDATIONS = 'CUSTOMER_SET_IS_LOADING_RECOMMENDATIONS',
  CUSTOMER_RESET_CURSORS_FOR_RECOMMENDATIONS = 'CUSTOMER_RESET_CURSORS_FOR_RECOMMENDATIONS',
  CUSTOMER_CLEAR_RECOMMENDATION_ITEMS = 'CUSTOMER_CLEAR_RECOMMENDATION_ITEMS',
  CUSTOMER_SET_SELECTED_RECOMMENDATION = 'CUSTOMER_SET_SELECTED_RECOMMENDATION',
  CUSTOMER_SET_ITEMS_FOR_SELECTED_RECOMMENDATION_GROUP_ID = 'CUSTOMER_SET_ITEMS_FOR_SELECTED_RECOMMENDATION_GROUP_ID',
  CUSTOMER_SET_CURSORS_FOR_ITEMS = 'CUSTOMER_SET_CURSORS_FOR_ITEMS',
  CUSTOMER_SET_COUNT_FOR_ITEMS = 'CUSTOMER_SET_COUNT_FOR_ITEMS',
  CUSTOMER_SET_UPDATED_CUSTOMER_NAME = 'CUSTOMER_SET_UPDATED_CUSTOMER_NAME',
  CUSTOMER_SET_ASSOCIATION_FEEDBACK_REQUEST = 'CUSTOMER_SET_ASSOCIATION_FEEDBACK_REQUEST',
  CUSTOMER_SET_ASSOCIATION_FEEDBACK_RESPONSE = 'CUSTOMER_SET_ASSOCIATION_FEEDBACK_RESPONSE',
  CUSTOMER_SET_RECOMMENDATION_FILTERS = 'CUSTOMER_SET_RECOMMENDATION_FILTERS',
  CUSTOMER_SET_IS_LOADING_DUPLICATE_CUSTOMERS_RECOMMENDATIONS = 'CUSTOMER_SET_IS_LOADING_DUPLICATE_CUSTOMERS_RECOMMENDATIONS',
  CUSTOMER_SET_DUPLICATE_CUSTOMERS_RECOMMENDATIONS = 'CUSTOMER_SET_DUPLICATE_CUSTOMERS_RECOMMENDATIONS',
  CUSTOMER_SET_PRIMARY_CUSTOMER_DETAILS_FOR_RECOMMENDATIONS = 'CUSTOMER_SET_PRIMARY_CUSTOMER_DETAILS_FOR_RECOMMENDATIONS',
  CLEAR_ASSOCIATIONS_FEEDBACK = 'CLEAR_ASSOCIATIONS_FEEDBACK',
}

interface CustomerResetCursorsForRecommendations {
  type: typeof customerRecommendationsActionNames.CUSTOMER_RESET_CURSORS_FOR_RECOMMENDATIONS;
}
interface CustomerClearRecommendationItems {
  type: typeof customerRecommendationsActionNames.CUSTOMER_CLEAR_RECOMMENDATION_ITEMS;
}

interface CustomerSetSelectedRecommendation {
  type: typeof customerRecommendationsActionNames.CUSTOMER_SET_SELECTED_RECOMMENDATION;
  payload: SearchRecommendationResponse;
}

interface CustomerSetItemsForSelectedRecommendations {
  type: typeof customerRecommendationsActionNames.CUSTOMER_SET_ITEMS_FOR_SELECTED_RECOMMENDATION_GROUP_ID;
  payload: RecommendationItems & {
    feedback?: RecommendationFeedback | undefined;
  };
}

interface CustomerSetCursorsForItems {
  type: typeof customerRecommendationsActionNames.CUSTOMER_SET_CURSORS_FOR_ITEMS;
  payload: string;
}

interface CustomerSetCountForItems {
  type: typeof customerRecommendationsActionNames.CUSTOMER_SET_COUNT_FOR_ITEMS;
  payload: number;
}

interface CustomerSetUpdatedCustomerName {
  type: typeof customerRecommendationsActionNames.CUSTOMER_SET_UPDATED_CUSTOMER_NAME;
  payload: string;
}

export interface CustomerSetAssociationFeedbackRequest {
  type: typeof customerRecommendationsActionNames.CUSTOMER_SET_ASSOCIATION_FEEDBACK_REQUEST;
  payload:
    | UpdateDealerCustomerRecommendation
    | UpdateCustomerOrganizationRecommendation
    | UpdateCustomerOrganizationAndDealerCustomerRecommendation
    | MultipleDCNRequestBody;
}

export interface CustomerSetAssociationFeedbackResponse {
  type: typeof customerRecommendationsActionNames.CUSTOMER_SET_ASSOCIATION_FEEDBACK_RESPONSE;
  payload: AddFeedbackForRecommendationItemsResponse;
}

interface CustomerSetRecommendationFilters {
  type: typeof customerRecommendationsActionNames.CUSTOMER_SET_RECOMMENDATION_FILTERS;
  payload: RecommendationFilters;
}

interface CustomerSetPrimaryCustomerDetailsForRecommendations {
  type: typeof customerRecommendationsActionNames.CUSTOMER_SET_PRIMARY_CUSTOMER_DETAILS_FOR_RECOMMENDATIONS;
  payload: SearchOrganizationResponse;
}
export interface ClearAssociationsFeedback {
  type: typeof customerRecommendationsActionNames.CLEAR_ASSOCIATIONS_FEEDBACK;
}

export type CustomerRecommendationsActionTypes =
  | CustomerResetCursorsForRecommendations
  | CustomerClearRecommendationItems
  | CustomerSetSelectedRecommendation
  | CustomerSetItemsForSelectedRecommendations
  | CustomerSetCursorsForItems
  | CustomerSetCountForItems
  | CustomerSetUpdatedCustomerName
  | CustomerSetAssociationFeedbackRequest
  | CustomerSetAssociationFeedbackResponse
  | CustomerSetRecommendationFilters
  | CustomerSetPrimaryCustomerDetailsForRecommendations
  | SetIsLoading<customerRecommendationsActionNames.CUSTOMER_SET_IS_LOADING_RECOMMENDATIONS>
  | SetIsLoading<customerRecommendationsActionNames.CUSTOMER_SET_IS_LOADING_DUPLICATE_CUSTOMERS_RECOMMENDATIONS>
  | SetDuplicateCustomers<customerRecommendationsActionNames.CUSTOMER_SET_DUPLICATE_CUSTOMERS_RECOMMENDATIONS>
  | ClearAssociationsFeedback;
