/* eslint-disable react-hooks/rules-of-hooks */
import { Theme, useTheme, useMediaQuery } from '@mui/material';
import { Breakpoint } from '@mui/material/styles';

type BreakpointOrNull = Breakpoint | null;

const getScreenWidth = () => {
  const theme: Theme = useTheme();
  const keys: Breakpoint[] = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};

export default getScreenWidth;
