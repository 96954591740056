import React, { memo } from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import useStyles from './styles';
import shortid from 'shortid';

type OptionType = {
  key: string;
  value: string;
};
export type ColumnSortPropertiesType = {
  sortBy: string;
  orderBy: string;
  propertyName: string;
  handleSortChange: Function;
  handleOrderByChange: Function;
  sortPopUpOpen: Function;
  setSortingSelected: Function;
  options: Array<OptionType>;
};

const ColumnSort = (properties: ColumnSortPropertiesType) => {
  const { classes } = useStyles();
  const {
    handleSortChange,
    handleOrderByChange,
    sortBy,
    orderBy,
    sortPopUpOpen,
    setSortingSelected,
    options,
    propertyName,
  } = properties;

  return (
    <List component="nav" data-testid="sort-container">
      {options.map((option: OptionType) => {
        return (
          <ListItem
            key={shortid.generate()}
            data-testid="list-item-option1"
            button
            onClick={() => {
              handleSortChange(propertyName);
              handleOrderByChange(option.key);
              sortPopUpOpen(false);
              setSortingSelected(true);
            }}
            className={
              sortBy === propertyName && orderBy === option.key ? classes.selected : undefined
            }
          >
            <ListItemText data-testid="list-item-ascending" primary={option.value} />
          </ListItem>
        );
      })}
    </List>
  );
};

export default memo(ColumnSort);
