import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../../../globals/color-properties';

export default makeStyles()((theme) => ({
  filterButton: {
    paddingRight: '6px',
    border: 'none',
    fontWeight: 100,
    '&:disabled': {
      border: 'none',
      backgroundColor: CAT_COLOR.WHITE,
    },
    '&:hover': {
      border: 'none',
      backgroundColor: CAT_COLOR.WHITE_SMOKE,
    },
  },
  dividerText: {
    marginTop: theme.spacing(1.25),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
}));
