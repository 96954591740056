import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  placeholder: {
    fontSize: '14px',
    color: CAT_COLOR.LIGHT_SLATE_GREY,
  },
  linkButton: {
    textAlign: 'end',
  },
  moreTagContainer: {
    textAlign: 'end',
    display: 'flex',
    flexFlow: 'wrap',
    alignContent: 'flex-start',
    width: '100%',
  },
  right: {
    placeContent: 'flex-end',
  },
  center: {
    placeContent: 'center',
  },
  left: {
    placeContent: 'flex-start',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row',
    flexDirection: 'row-reverse',
    flexWrap: 'wrap',
  },
  scrollableContainer: {
    height: '100px',
    overflow: 'auto',
  },
}));
