import { Count } from '../../entities/customerAssetWorkflow-v1/count';
import { DeleteFilterActionPayloadType } from '../../components/filter-tags/types';
import {
  InlineResponse200,
} from '../../entities/customerAssetWorkflow-v1/models';

export enum assetRecommendationsActionNames {
  SET_ASSET_RECOMMENDATIONS_COUNT = 'SET_ASSET_RECOMMENDATIONS_COUNT',
  SET_ASSET_RECOMMENDATIONS_CURSOR = 'SET_ASSET_RECOMMENDATIONS_CURSOR',
  SET_ASSET_RECOMMENDATIONS_DATA = 'SET_ASSET_RECOMMENDATIONS_DATA',
  SET_IS_LOADING_ASSET_RECOMMENDATIONS_DATA = 'SET_IS_LOADING_ASSET_RECOMMENDATIONS_DATA',
  SET_ASSET_RECOMMENDATIONS_ORDER_BY_VALUE = 'SET_ASSET_RECOMMENDATIONS_ORDER_BY_VALUE',
  SET_ASSET_RECOMMENDATIONS_SORT_BY_VALUE = 'SET_ASSET_RECOMMENDATIONS_SORT_BY_VALUE',
  SET_ASSET_RECOMMENDATION_FILTERS = 'SET_ASSET_RECOMMENDATION_FILTERS',
  DELETE_ASSET_RECOMMENDATION_FILTER_VALUE_BY_TYPE = 'DELETE_RECOMMENDATION_FILTER_VALUE_BY_TYPE',
}

type AssetRecommendationsFilters = {
  orderByRules: Array<string>;
  sortByRules: Array<string>;
};

export interface SetSortByRules {
  type: typeof assetRecommendationsActionNames.SET_ASSET_RECOMMENDATIONS_SORT_BY_VALUE;
  payload: Array<string>;
}

export interface SetOrderByRules {
  type: typeof assetRecommendationsActionNames.SET_ASSET_RECOMMENDATIONS_ORDER_BY_VALUE;
  payload: Array<string>;
}

export interface AssetRecommendationsState {
  assetRecommendationsData: Array<InlineResponse200>
  isLoadingAssetRecommendations: boolean;
  totalCount?: number;
  filters: AssetRecommendationsFilters;
  cursors: Array<string>;
  assetRecommendationFilters?: AssetRecommendationFilters;
}

export interface SetAssetRecommendations {
  type: typeof assetRecommendationsActionNames.SET_ASSET_RECOMMENDATIONS_DATA;
  payload: Array<InlineResponse200>
}
export interface SetAssetRecommendationsLoading {
  type: typeof assetRecommendationsActionNames.SET_IS_LOADING_ASSET_RECOMMENDATIONS_DATA;
  payload: boolean;
}

export interface SetAssetRecommendationsCount {
  type: typeof assetRecommendationsActionNames.SET_ASSET_RECOMMENDATIONS_COUNT;
  payload: Count;
}
export interface SetAssetRecommendationsCursor {
  type: typeof assetRecommendationsActionNames.SET_ASSET_RECOMMENDATIONS_CURSOR;
  payload: Array<string>;
}

export interface SetAssetRecommendationFilters {
  type: typeof assetRecommendationsActionNames.SET_ASSET_RECOMMENDATION_FILTERS;
  payload: AssetRecommendationFilters;
}

export interface DeleteAssetRecommendationFilterValueByType {
  type: typeof assetRecommendationsActionNames.DELETE_ASSET_RECOMMENDATION_FILTER_VALUE_BY_TYPE;
  payload: DeleteFilterActionPayloadType<AssetRecommendationFilterType>;
}

export type assetRecommendationsActionTypes =
  | SetAssetRecommendations
  | SetAssetRecommendationsLoading
  | SetAssetRecommendationsCursor
  | SetAssetRecommendationsCount
  | SetOrderByRules
  | SetSortByRules
  | SetAssetRecommendationFilters
  | DeleteAssetRecommendationFilterValueByType;

export enum AssetRecommendationFilterType {
  dealerCode = 'dealerCode',
}

type AssetRecommendationFilters = {
  dealerCode?: string[];
};
