import { default as Axios,AxiosResponse } from 'axios';
import { Domain } from '../entities/entitlements-v1/domain';
import {
  LastLogin,
  PartiesResponse,
  ServiceBasedPermission,
  UserBasedPermission,
} from '../entities/entitlements-v1/models';

class Entitlements {
  private token: string | undefined;

  private baseUrl: string;

  constructor(token: string | undefined, baseUrl: string) {
    this.token = token;
    this.baseUrl = baseUrl;
  }

  getUserPermissions(
    input: GetUserAuthorizationsRequest,
  ): Promise<AxiosResponse<UserBasedPermission | ServiceBasedPermission>> {
    const token = this.token ? this.token : '';
    return Axios.get<UserBasedPermission | ServiceBasedPermission>(`${this.baseUrl}/permissions`, {
      params: input,
      headers: {
        Authorization: `Bearer ${token}`,
        'Cache-Control': 'no-cache',
      },
    });
  }

  getParties(): Promise<AxiosResponse<PartiesResponse>> {
    const token = this.token ? this.token : '';
    return Axios.get<PartiesResponse>(`${this.baseUrl}/parties`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Cache-Control': 'no-cache',
      },
    });
  }

  updateLastLoginTime(input?: LastLogin): Promise<AxiosResponse<{}>> {
    const token = this.token ? this.token : '';
    return Axios.patch<PartiesResponse>(`${this.baseUrl}/users/lastLogin`, input, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Cache-Control': 'no-cache',
      },
    });
  }
}

export type GetUserAuthorizationsRequest = {
  partyNumber?: string;
  includeAppPermissions?: string;
  includeDataPermissions?: string;
  domains?: Array<Domain>;
};

export default Entitlements;
