import React, { CSSProperties, MouseEventHandler } from 'react';
import { FormControlLabel, Radio, styled, SxProps, Theme } from '@mui/material';
import { CatRadioField } from 'blocks-react/bedrock/components/RadioField';
import CAT_COLOR from '../../globals/color-properties';
import { UI_BLOCKS, useUIBlocks } from '../../hooks/use-ui-blocks';
type RadioButtonType = {
  value: number | string | boolean;
  checked: boolean;
  disabled?: boolean;
  testId?: string;
  size?: number;
  color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  label?: string;
  sx?: SxProps<Theme>;
  style?: CSSProperties;
  hideLabel?: boolean;
  onClick?: MouseEventHandler;
  onChange?: (checked: string) => void;
  children?: React.ReactNode;
};

const RadioButton = (props: RadioButtonType) => {
  const {
    size = 16,
    testId,
    onChange,
    checked,
    label,
    children,
    hideLabel,
    style,
    ...controlledProps
  } = props;

  const isBlocksRadioButton = useUIBlocks(UI_BLOCKS.RADIO);

  const CatThemeIcon = styled('span')(() => ({
    borderRadius: '50%',
    width: size,
    height: size,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: CAT_COLOR.WHITE_LILAC,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: CAT_COLOR.PORCELAIN,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  }));

  const CheckedIcon = styled(CatThemeIcon)({
    backgroundColor: CAT_COLOR.BRIGHT_GOLD,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: size,
      height: size,
      backgroundImage: 'radial-gradient(#000,#000 40%,transparent 56%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: CAT_COLOR.SUPERNOVA,
    },
  });

  const handleChange = () => {
    onChange?.(String(controlledProps?.value));
  };

  if (isBlocksRadioButton) {
    return (
      <>
        <CatRadioField
          checked={checked}
          data-testid={testId}
          onBlRadioChange={handleChange}
          value={String(controlledProps?.value)}
          label={children ? undefined : label}
          hideLabel={hideLabel}
          style={style}
        />
        {children}
      </>
    );
  }

  return (
    <>
      <FormControlLabel
        label={label}
        control={
          <Radio
            sx={{
              '&:hover': {
                bgcolor: 'transparent',
              },
            }}
            disableRipple
            color="default"
            checkedIcon={<CheckedIcon />}
            icon={<CatThemeIcon />}
            data-testid={testId}
            onChange={handleChange}
            checked={checked}
            {...controlledProps}
          />
        }
      />
      {!label && children}
    </>
  );
};

export default RadioButton;
