import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const RemoveDcnIcon = ({ stroke = '#78869E', ...restProps }: iconType) => (
  <SvgIcon
    stroke={stroke}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...restProps}
    viewBox="0 0 30 30"
    style={{ width: '30px', height: '30px' }}
  >
    <circle cx="15" cy="15" r="14.5" stroke="#FFCC11" />
    <line
      x1="10.166"
      y1="15.198"
      x2="20.666"
      y2="15.198"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default RemoveDcnIcon;
