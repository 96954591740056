import React from 'react';
import { Box, useTheme } from '@mui/material';
import useStyles from './styles';
import FilterTagChip from '../filter-tag-chip';
import { nameIdPairArray } from '../../globals/generic-types';

type TagPoolProperies = {
  showAll?: boolean;
  hideRemoveButton?: boolean;
  limit?: number;
  value: nameIdPairArray;
  setValue: Function;
  id: string;
  position?: 'left' | 'center' | 'right';
  scrollable?: boolean;
};

const TagPool = (props: TagPoolProperies) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const {
    id,
    setValue,
    limit,
    showAll,
    value,
    hideRemoveButton,
    position = 'right',
    scrollable = false,
  } = props;
  return (
    value && (
      <Box className={scrollable ? classes.scrollableContainer : classes.container}>
        <Box className={`${classes.moreTagContainer} ${classes[position]}`}>
          {value.map(
            (tag: { id: string; name: string }, parentIndex: number) =>
              (!limit || parentIndex < ((showAll && value.length) || limit)) && (
                <Box mt={1} key={tag.id}>
                  <FilterTagChip
                    key={`${id}-chip-${parentIndex}`}
                    data-testid={`${id}-chip-${parentIndex}`}
                    text={tag.id}
                    fontSize="12px"
                    maxWidth={theme.spacing(23)}
                    removeFilter={
                      !hideRemoveButton
                        ? () => {
                            setValue(
                              value.filter(
                                (nodeValue: { id: string; name: string }, index: number) =>
                                  parentIndex !== index,
                              ),
                            );
                          }
                        : undefined
                    }
                  />
                </Box>
              ),
          )}
        </Box>
      </Box>
    )
  );
};

export default TagPool;
