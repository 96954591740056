import {
  countriesStatesActionNames,
  CountriesStatesActionTypes,
  CountriesStatesState,
} from './types';

const initialState: CountriesStatesState = {
  countries: [],
};

 const countriesStatesReducer = (
  state: CountriesStatesState = initialState,
  action: CountriesStatesActionTypes,
): CountriesStatesState => {
  switch (action.type) {
    case countriesStatesActionNames.SET_COUNTRIES: {
      return {
        ...state,
        countries: action.payload,
      };
    }
    default:
      return state;
  }
};

export default countriesStatesReducer;