import { default as Axios, AxiosResponse } from 'axios';
import {
  AssociateDealerCustomerRequest,
  Filters,
  SearchDealerCustomerResponse,
  ResponseMetadata,
  ErrorResponse,
  DealerCustomerOrganizationRequest,
  UpdateDealerCustomerOrganizationRequest,
  DealerCustomerOrganizationResponse,
  ContainsFilter,
  FilterType,
  DealerCustomerSearchPropertiesForFiltering,
  StringEqualsFilter,
  DealerCustomerSortingProperties,
  DisassociateDealerCustomerPartialFailureResponse,
  AssociateDealerCustomerDetails,
} from '../entities/customer-master-v1/models';
import { AuthInfo } from '../model/types';

class DealerCustomer {
  private authInfo: AuthInfo | null;

  private baseUrl: string;

  constructor(authInfo: AuthInfo | null, baseUrl: string) {
    this.authInfo = authInfo;
    this.baseUrl = baseUrl;
  }

  searchDealerCustomer(
    input: SearchDealerCustomerRequest,
  ): Promise<AxiosResponse<DealerCustomerSearchResponse | ErrorResponse>> {
    const { body, ...queryParameters } = input;
    const token = this.authInfo ? this.authInfo.token : '';
    return Axios.post<DealerCustomerSearchResponse | ErrorResponse>(
      `${this.baseUrl}/dealerCustomers/search`,
      body,
      {
        params: queryParameters,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  getDealerCustomerOrganization(
    dealerCustomerIdentifier: string,
  ): Promise<AxiosResponse<SearchDealerCustomerResponse | ErrorResponse>> {
    const token = this.authInfo ? this.authInfo.token : '';
    return Axios.get<SearchDealerCustomerResponse | ErrorResponse>(
      `${this.baseUrl}/dealerCustomers/${dealerCustomerIdentifier}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  associateDealerCustomer(
    input: AssociateDealerCustomerRequest,
  ): Promise<AxiosResponse<{ description: string } | ErrorResponse>> {
    const { ...body } = input;
    const token = this.authInfo ? this.authInfo.token : '';
    return Axios.post<{ description: string } | ErrorResponse>(
      `${this.baseUrl}/dealerCustomers/associate`,
      body,
      {
        params: {
          skipSubscriptionsCheck: true,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  disassociateDealerCustomer(
    input: Array<AssociateDealerCustomerDetails> = [],
  ): Promise<AxiosResponse<ErrorResponse | DisassociateDealerCustomerPartialFailureResponse>> {
    const token = this.authInfo ? this.authInfo.token : '';
    return Axios.post<ErrorResponse | DisassociateDealerCustomerPartialFailureResponse>(
      `${this.baseUrl}/dealerCustomers/disassociate`,
      {
        disassociateDealerCustomers: [...input],
      },
      {
        params: {
          skipSubscriptionsCheck: true,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  createDealerCustomer(
    input: CreateDealerCustomerRequest,
  ): Promise<AxiosResponse<DealerCustomerOrganizationResponse | CreateDealerErrorResponse>> {
    const { queryParameters, body } = input;
    const token = this.authInfo ? this.authInfo.token : '';
    return Axios.post<DealerCustomerOrganizationResponse | CreateDealerErrorResponse>(
      `${this.baseUrl}/dealerCustomers`,
      body,
      {
        params: { ...queryParameters, skipSubscriptionsCheck: true },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  editDealerCustomer(
    input: EditDealerCustomerRequest,
    dealerCustomerIdentifier: string,
  ): Promise<AxiosResponse<DealerCustomerOrganizationResponse>> {
    const { body, queryParameters } = input;
    const token = this.authInfo ? this.authInfo.token : '';
    return Axios.put<DealerCustomerOrganizationResponse>(
      `${this.baseUrl}/dealerCustomers/${dealerCustomerIdentifier}`,
      body,
      {
        params: queryParameters,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
}

export const createDealerCustomerSearchRequestbyCustomerOrg = (
  customerOrgId: string,
  selectedAccount?: string,
): SearchDealerCustomerRequest => {
  const expressions = selectedAccount ? '$0 & $1' : '$0';
  const conditions: Array<ContainsFilter | StringEqualsFilter> = [];
  conditions.push({
    propertyName:
      DealerCustomerSearchPropertiesForFiltering.AssociatedCustomerOrganizationIdentifier,
    type: FilterType.StringEquals,
    values: [customerOrgId],
  });
  if (selectedAccount) {
    conditions.push({
      propertyName: DealerCustomerSearchPropertiesForFiltering.DealerCode,
      type: FilterType.StringEquals,
      values: [selectedAccount],
    });
  }

  return {
    body: {
      logicalExpression: expressions,
      filters: conditions,
    },
    limit: 2500,
    sortBy: DealerCustomerSortingProperties.DealerCustomerIdentifier,
    orderBy: 'ASC',
  };
};
export type SearchDealerCustomerRequest = {
  body?: Filters;
  sortBy?: string;
  orderBy?: string;
  xCatUserPreferences?: string;
  cursor?: string;
  limit?: number;
  skip?: number;
};

export type SearchDealerCustomerOrganizationRequest = {
  body?: Filters;
  limit?: number;
  cursor?: string;
};

export type AssociateDcnRequest = {
  body?: AssociateDealerCustomerRequest;
  xCatUserPreferences?: string;
};

export type DealerCustomerSearchResponse = {
  dealerCustomers: Array<SearchDealerCustomerResponse>;
  responseMetadata: ResponseMetadata;
};

export type CreateDealerCustomerRequest = {
  queryParameters: {
    dealerCode?: string;
    cwsId?: string;
  };
  body: DealerCustomerOrganizationRequest;
};

export type EditDealerCustomerRequest = {
  queryParameters: {
    dealerCode?: string;
    cwsId?: string;
  };
  body: UpdateDealerCustomerOrganizationRequest;
};

export type CreateDealerErrorResponse = {
  code: string;
  error: string;
  path: string;
};

export default DealerCustomer;
