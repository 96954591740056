import { default as Axios, AxiosResponse } from 'axios';
import {
  ErrorResponse,
  Filters,
  DealerCodeSearchItem,
} from '../entities/customer-master-v1/models';
import { AuthInfo } from '../model/types';

class DealerCode {
  private authInfo: AuthInfo | null;

  private baseUrl: string;

  constructor(authInfo: AuthInfo | null, baseUrl: string) {
    this.authInfo = authInfo;
    this.baseUrl = baseUrl;
  }

  searchDealerCodes(
    input: SearchDealerCodesRequest,
  ): Promise<AxiosResponse<DealerCodeSearchResponse | ErrorResponse>> {
    const { token } = this.authInfo as AuthInfo;
    const { body } = input;
    return Axios.post<DealerCodeSearchResponse | ErrorResponse>(
      `${this.baseUrl}/dealerCodes/search`,
      body || {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
}

export type SearchDealerCodesRequest = {
  body?: Filters;
  xCatUserPreferences?: string;
};

export type DealerCodeSearchResponse = {
  dealerCodes: Array<DealerCodeSearchItem>;
};

export default DealerCode;
