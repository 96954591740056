/**
 * User Onboarding
 * User Onboarding API provides functionality to manage users access, requests and invites. 
 *
 * OpenAPI spec version: 1.3.0
 * Contact: Platform_Core_Services_-_CAT@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Invite properties that can be used for sorting. 
 */
export type InvitePropertiesForSorting = 'email' | 'createdTime' | 'status' | 'partyName';

export const InvitePropertiesForSorting = {
    Email: 'email' as InvitePropertiesForSorting,
    CreatedTime: 'createdTime' as InvitePropertiesForSorting,
    Status: 'status' as InvitePropertiesForSorting,
    PartyName: 'partyName' as InvitePropertiesForSorting
};