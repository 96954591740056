import React from 'react';
import { styled } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionDetails, { AccordionDetailsProps } from '@mui/material/AccordionDetails';

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} expanded={props.expanded} />
))(({ theme }) => ({
  '&:first-of-type': {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  '&:last-child': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  marginLeft: theme.spacing(1),

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
  },
}));

type variantAcDetails = 'grey';
enum variantAcDetailsMap {
  'grey' = '#F9FAFA',
}

interface ExtAccordionDetailsProps extends AccordionDetailsProps {
  variant?: variantAcDetails;
}

export const AccordionDetails = (props: ExtAccordionDetailsProps): JSX.Element => {
  const variant: variantAcDetails = props?.variant ?? 'grey';

  return (
    <MuiAccordionDetails {...props} style={{ backgroundColor: variantAcDetailsMap[variant] }} />
  );
};
