import { default as Axios, AxiosResponse, CancelToken } from 'axios';
import { Count, ErrorResponse, Filters } from '../entities/customer-master-v1/models';
import { AuthInfo } from '../model/types';
import {
  ApplicationInvitesArray,
  ApplicationsResponse,
  CreateInvitesPartialSuccessResponseArray,
  CreateInvitesRequestArray,
  CreateInvitesResponseArray,
  FiltersAndApplicationMetadataSearchResponseAttributesRequest,
  IntegerEqualsFilter,
  InviteResponse,
  RolesResponse,
  StringEqualsFilter,
  TimeRangeFilter,
  UpdateInviteRequest,
} from '../entities/user-onboarding-v1/models';

export type InvitationsSearchRequest = {
  body?: {
    logicalExpression?: string;
    filters: (IntegerEqualsFilter | StringEqualsFilter | TimeRangeFilter)[];
    responseAttributes?: string[];
  };
  xCatUserPreferences?: string;
  cursor?: string;
  limit?: number;
  skip?: number;
  sortBy?: string;
  orderBy?: string;
};

export type InvitationsSearchCountRequest = {
  body?: Filters | {};
  partyNumber?: string;
  xCatUserPreferences?: string;
};

export type InvitationsSearchCountResponse = {
  count: Count;
};

class InvitationsService {
  private authInfo: AuthInfo | null;
  private baseUrl: string;

  constructor(authInfo: AuthInfo | null, baseUrl: string) {
    this.authInfo = authInfo;
    this.baseUrl = baseUrl;
  }

  getInvitesByTimeframe(
    input: InvitationsSearchRequest,
    partyNumber: string,
  ): Promise<AxiosResponse<CreateInvitesResponseArray | ErrorResponse>> {
    const { token } = this.authInfo as AuthInfo;
    const { body, ...queryParameters } = input;

    return Axios.post<CreateInvitesResponseArray | ErrorResponse>(
      `${this.baseUrl}/invites/search?partyNumber=` + partyNumber,
      body,
      {
        params: queryParameters,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  editInvite(
    input: { inviteId: string; partyNumber: string },
    inviteInfo: UpdateInviteRequest,
  ): Promise<AxiosResponse<InviteResponse | ErrorResponse>> {
    const { inviteId, ...queryParameters } = input;
    const { token } = this.authInfo as AuthInfo;
    return Axios.patch<InviteResponse | ErrorResponse>(
      `${this.baseUrl}/invites/${inviteId}`,
      inviteInfo,
      {
        params: queryParameters,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  resendInvite(
    input: InvitationObject,
    partyNumber: string | number | undefined,
  ): Promise<AxiosResponse<InviteResponse | ErrorResponse>> {
    const { token } = this.authInfo as AuthInfo;
    const { invitation, ...queryParameters } = input;
    return Axios.post<InviteResponse | ErrorResponse>(
      `${this.baseUrl}/invites/${invitation.inviteId}/email/resend${
        partyNumber ? '?partyNumber=' + partyNumber : ''
      }`,
      '',
      {
        params: queryParameters,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  revokeInvite(input: InvitationObject): Promise<AxiosResponse<InviteResponse | ErrorResponse>> {
    const { token } = this.authInfo as AuthInfo;
    const { invitation, ...queryParameters } = input;
    return Axios.post<InviteResponse | ErrorResponse>(
      `${this.baseUrl}/invites/${invitation.inviteId}/revoke`,
      undefined,
      {
        params: queryParameters,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  undoRevokeInvite(
    input: InvitationObject,
  ): Promise<AxiosResponse<InviteResponse | ErrorResponse>> {
    const { token } = this.authInfo as AuthInfo;
    const { invitation, ...queryParameters } = input;
    return Axios.post<InviteResponse | ErrorResponse>(
      `${this.baseUrl}/invites/${invitation.inviteId}/undoRevoke`,
      undefined,
      {
        params: queryParameters,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  inviteUser(
    partyNumber: string,
    userInviteRequest: CreateInvitesRequestArray,
  ): Promise<AxiosResponse<CreateInvitesResponseArray | CreateInvitesPartialSuccessResponseArray>> {
    const { token } = this.authInfo as AuthInfo;
    return Axios.post<CreateInvitesResponseArray | CreateInvitesPartialSuccessResponseArray>(
      `${this.baseUrl}/invites?partyNumber=${partyNumber}`,
      userInviteRequest,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  countInvites(
    input: InvitationsSearchCountRequest,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<InvitationsSearchCountResponse | ErrorResponse>> {
    const { body, ...queryParameters } = input;
    const token = this.authInfo ? this.authInfo.token : '';
    return Axios.post<InvitationsSearchCountResponse | ErrorResponse>(
      `${this.baseUrl}/invites/COUNT`,
      body,
      {
        cancelToken,
        params: queryParameters,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
  getMetadata(
    input: GetMetadataType,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<ApplicationsResponse | ErrorResponse>> {
    const { body, ...queryParameters } = input;
    return Axios.post<ApplicationsResponse | ErrorResponse>(
      `${this.baseUrl}/applications/metadata/search`,
      body,
      {
        cancelToken,
        params: queryParameters,
        headers: {
          Authorization: `Bearer ${this?.authInfo?.token}`,
        },
      },
    );
  }

  getApplicationMetadata(
    applicationId: string,
    account: string,
  ): Promise<AxiosResponse<RolesResponse | ErrorResponse>> {
    return Axios.get<RolesResponse | ErrorResponse>(
      `${this.baseUrl}/applications/${applicationId}/parties/${account}/roles`,
      {
        headers: {
          Authorization: `Bearer ${this?.authInfo?.token}`,
        },
      },
    );
  }

  getApplicationRoles(
    applicationId: string,
    account: string,
    ccid?: string,
  ): Promise<AxiosResponse<RolesResponse | ErrorResponse>> {
    return Axios.get<RolesResponse | ErrorResponse>(
      `${this.baseUrl}/applications/${applicationId}/parties/${ccid}/roles`,
      {
        params: {
          partyNumber: account,
        },
        headers: {
          Authorization: `Bearer ${this?.authInfo?.token}`,
        },
      },
    );
  }
}

type GetMetadataType = {
  body: FiltersAndApplicationMetadataSearchResponseAttributesRequest;
};

export default InvitationsService;
export type AccessType = {
  identifier: string;
  name?: string;
};

export type ApplicationWithFullIdentifiers = {
  applicationName?: string;
} & ApplicationInvitesArray;

export type InvitationObject = {
  invitation: InviteResponse;
  partyNumber: string;
};

export const INVITATION_APPLICATIONS = {
  PCC: 'PCC',
  VISION_LINK: 'FM3',
  NONPRODPCC: 'PCC-NONPROD',
};
