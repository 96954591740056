import { OptionsObject, SnackbarKey } from 'notistack';
import { ReactElement } from 'react';

export enum snackbarActionNames {
  ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR',
  CLOSE_SNACKBAR = 'CLOSE_SNACKBAR',
  REMOVE_SNACKBAR = 'REMOVE_SNACKBAR',
  SET_CANCEL_NOTIFICATION_VISIBILITY_FLAG = 'SET_CANCEL_NOTIFICATION_VISIBILITY_FLAG',
}

export type SnackBarNotification = {
  key: SnackbarKey;
  message: string | ReactElement;
  options: OptionsObject;
  dismissed?: boolean;
  dismissAll?: boolean;
};

export interface SnackbarState {
  notifications: Array<SnackBarNotification>;
  isCancelNotificationVisible?: boolean;
}

interface SetEnqueueSnackbar {
  type: typeof snackbarActionNames.ENQUEUE_SNACKBAR;
  notification: SnackBarNotification;
}
interface SetCloseSnackbar {
  type: typeof snackbarActionNames.CLOSE_SNACKBAR;
  notification: SnackBarNotification;
}

interface SetRemoveSnackbar {
  type: typeof snackbarActionNames.REMOVE_SNACKBAR;
  notification: SnackBarNotification;
}

interface SetCancelNotificationVisibilityFlag {
  type: typeof snackbarActionNames.SET_CANCEL_NOTIFICATION_VISIBILITY_FLAG;
  payload: boolean;
}

export type SnackbarActionTypes =
  | SetEnqueueSnackbar
  | SetCloseSnackbar
  | SetRemoveSnackbar
  | SetCancelNotificationVisibilityFlag;
