import { DeleteFilterActionPayloadType } from '../../components/filter-tags/types';
import {
  SearchRecommendationResponse,
  UpdateCustomerOrganizationRecommendation,
  UpdateDealerCustomerRecommendation,
  RecommendationFeedback,
  UpdateCustomerOrganizationAndDealerCustomerRecommendation,
  SearchOrganizationResponse,
  SearchRecommendationAssociateMultipleDcnResponse,
  AssociateMultipleDcnPartialFailureResponse,
} from '../../entities/customer-master-v1/models';
import { RecommendationType } from '../../entities/customer-master-v1/recommendationType';
import {
  AddFeedbackForRecommendationItemsResponse,
  MultipleDCNRequestBody,
  RecommendationItems,
} from '../../services/recommendations';
import { SetIsLoading } from '../api/loadable';
import { SetDuplicateCustomers } from '../customer-organization/types';

export enum recommendationsActionNames {
  ADD_FEEDBACK_FOR_ITEM = 'ADD_FEEDBACK_FOR_ITEM',
  CLEAR_CUSTOMER_ORGANIZATION_ITEMS = 'CLEAR_CUSTOMER_ORGANIZATION_ITEMS',
  CLEAR_MY_RECOMMENDATIONS = 'CLEAR_MY_RECOMMENDATIONS',
  CLEAR_RECOMMENDATION_ITEMS = 'CLEAR_RECOMMENDATION_ITEMS',
  RESET_CURSORS_FOR_RECOMMENDATIONS = 'RESET_CURSORS_FOR_RECOMMENDATIONS',
  SET_COMMENT = 'SET_COMMENT',
  SET_COUNT_FOR_ITEMS = 'SET_COUNT_FOR_ITEMS',
  SET_CURSORS_FOR_ITEMS = 'SET_CURSORS_FOR_ITEMS',
  SET_CURSORS_FOR_RECOMMENDATIONS = 'SET_CURSORS_FOR_RECOMMENDATIONS',
  SET_IS_LOADING_RECOMMENDATIONS = 'SET_IS_LOADING_RECOMMENDATIONS',
  SET_ASSOCIATION_FEEDBACK_REQUEST = 'SET_ASSOCIATION_FEEDBACK_REQUEST',
  SET_ASSOCIATION_FEEDBACK_RESPONSE = 'SET_ASSOCIATION_FEEDBACK_RESPONSE',
  SET_IS_LOADING_COUNT = 'SET_IS_LOADING_COUNT',
  SET_ITEMS_FOR_SELECTED_RECOMMENDATION_GROUP_ID = 'SET_ITEMS_FOR_SELECTED_RECOMMENDATION_GROUP_ID',
  SET_MY_RECOMMENDATIONS = 'SET_MY_RECOMMENDATIONS',
  SET_PRIMARY_CUSTOMER_DETAILS_FOR_RECOMMENDATIONS = 'SET_PRIMARY_CUSTOMER_DETAILS_FOR_RECOMMENDATIONS',
  SET_RECOMMENDATION_FILTERS = 'SET_RECOMMENDATION_FILTERS',
  DELETE_RECOMMENDATION_FILTER_VALUE_BY_TYPE = 'DELETE_RECOMMENDATION_FILTER_VALUE_BY_TYPE',
  SET_SEARCH_INPUT_FOR_RECOMMENDATIONS = 'SET_SEARCH_INPUT_FOR_RECOMMENDATIONS',
  SET_SELECTED_RECOMMENDATION = 'SET_SELECTED_RECOMMENDATION',
  SET_DUPLICATE_CUSTOMERS_RECOMMENDATIONS = 'SET_DUPLICATE_CUSTOMERS_RECOMMENDATIONS',
  SET_IS_LOADING_DUPLICATE_CUSTOMERS_RECOMMENDATIONS = 'SET_IS_LOADING_DUPLICATE_CUSTOMERS_RECOMMENDATIONS',
  SET_TOTAL_COUNT = 'SET_TOTAL_COUNT',
  RESET_RECOMMENDATIONS = 'RESET_RECOMMENDATIONS',
  SET_UPDATED_CUSTOMER_NAME = 'SET_UPDATED_CUSTOMER_NAME',
  SET_CUSTOMER_RECOMMENDATIONS_SORT = 'SET_CUSTOMER_RECOMMENDATIONS_SORT',
  SET_CUSTOMER_RECOMMENDATIONS_ORDER = 'SET_CUSTOMER_RECOMMENDATIONS_ORDER',
  SET_CUSTOMER_RECOMMENDATIONS_STALE_DATA = 'SET_CUSTOMER_RECOMMENDATIONS_STALE_DATA',
}

export enum recommendationReasonAttributes {
  COUNTRY_MATCH = 'COUNTRY_MATCH',
  EMAIL_MATCH = 'EMAIL_MATCH',
  FUZZY_NAME_MATCH = 'FUZZY_NAME_MATCH',
  LOCATION_MATCH = 'LOCATION_MATCH',
  PHONE_MATCH = 'PHONE_MATCH',
}

export type ErrorType = {
  code: number;
  message: string;
};

type RecommendationFilters = {
  workItemType?: RecommendationType;
  startDate?: Date;
  endDate?: Date;
  dealerCode?: string[];
};

export enum RecommendationFilterType {
  workItemType = 'workItemType',
  startDate = 'startDate',
  endDate = 'endDate',
  dealerCode = 'dealerCode',
}

type CursorType = {
  nextCursors: Array<string> | [];
  currentPage?: number;
};

export interface DeleteRecommendationFilterValueByType {
  type: typeof recommendationsActionNames.DELETE_RECOMMENDATION_FILTER_VALUE_BY_TYPE;
  payload: DeleteFilterActionPayloadType<RecommendationFilterType>;
}

interface IupdatedCustomerName {
  updatedCustomerName?: string;
}

export interface RecommendationState {
  myRecommendations?: Array<SearchRecommendationResponse & { recommendationTypeString?: string }>;
  searchInputForRecommendations: string;
  totalRecommendationCount?: number;
  selectedRecommendation?:
    | (SearchRecommendationResponse & IupdatedCustomerName)
    | (SearchRecommendationAssociateMultipleDcnResponse & IupdatedCustomerName);
  primaryCustomerOrganizationDetails?: SearchOrganizationResponse;
  itemsForSelectedRecommendationGroupId?: RecommendationItems & {
    feedback?: RecommendationFeedback | undefined;
    feedbackReason?: string;
  };
  associationFeedback?: {
    request?:
      | UpdateDealerCustomerRecommendation
      | UpdateCustomerOrganizationRecommendation
      | UpdateCustomerOrganizationAndDealerCustomerRecommendation
      | MultipleDCNRequestBody;
    response?:
      | AddFeedbackForRecommendationItemsResponse
      | AssociateMultipleDcnPartialFailureResponse;
  };
  totalItemsCount?: number;
  duplicateCustomers: Array<SearchOrganizationResponse>;
  itemsFeedbackForSelectedRecommendationGroupId?:
    | UpdateDealerCustomerRecommendation
    | UpdateCustomerOrganizationRecommendation
    | UpdateCustomerOrganizationAndDealerCustomerRecommendation;
  cursorForItems?: string;
  cursorsForRecommendations: CursorType;
  isLoadingRecommendations?: boolean;
  isLoadingRecommendationsCount?: boolean;
  isLoadingDuplicateCustomers?: boolean;
  comment?: string;
  recommendationFilters?: RecommendationFilters;
  sortBy: string;
  orderBy: string;
  staleData: boolean;
}

export interface SetAssociationFeedbackRequest {
  type: typeof recommendationsActionNames.SET_ASSOCIATION_FEEDBACK_REQUEST;
  payload:
    | UpdateDealerCustomerRecommendation
    | UpdateCustomerOrganizationRecommendation
    | UpdateCustomerOrganizationAndDealerCustomerRecommendation
    | MultipleDCNRequestBody;
}

export interface SetAssociationFeedbackResponse {
  type: typeof recommendationsActionNames.SET_ASSOCIATION_FEEDBACK_RESPONSE;
  payload: AddFeedbackForRecommendationItemsResponse;
}

interface SetMyRecommendations {
  type: typeof recommendationsActionNames.SET_MY_RECOMMENDATIONS;
  payload: Array<SearchRecommendationResponse & { recommendationTypeString?: string }>;
}
interface SetSelectedRecommendation {
  type: typeof recommendationsActionNames.SET_SELECTED_RECOMMENDATION;
  payload: SearchRecommendationResponse;
}

interface SetUpdatedCustomerName {
  type: typeof recommendationsActionNames.SET_UPDATED_CUSTOMER_NAME;
  payload: string;
}

interface SetItemsForSelectedRecommendations {
  type: typeof recommendationsActionNames.SET_ITEMS_FOR_SELECTED_RECOMMENDATION_GROUP_ID;
  payload: RecommendationItems & {
    feedback?: RecommendationFeedback | undefined;
  };
}
interface AddFeedbackForItems {
  type: typeof recommendationsActionNames.ADD_FEEDBACK_FOR_ITEM;
  payload:
    | UpdateDealerCustomerRecommendation
    | UpdateCustomerOrganizationRecommendation
    | UpdateCustomerOrganizationAndDealerCustomerRecommendation;
}

interface ClearMyRecommendations {
  type: typeof recommendationsActionNames.CLEAR_MY_RECOMMENDATIONS;
}
interface ClearRecommendationItems {
  type: typeof recommendationsActionNames.CLEAR_RECOMMENDATION_ITEMS;
}

interface ClearCustomerOrganizationItems {
  type: typeof recommendationsActionNames.CLEAR_CUSTOMER_ORGANIZATION_ITEMS;
}

interface SetCursorsForRecommendations {
  type: typeof recommendationsActionNames.SET_CURSORS_FOR_RECOMMENDATIONS;
  payload: { cursor: string; currentPage: number };
}
interface SetCursorsForItems {
  type: typeof recommendationsActionNames.SET_CURSORS_FOR_ITEMS;
  payload: string;
}
interface SetCountForItems {
  type: typeof recommendationsActionNames.SET_COUNT_FOR_ITEMS;
  payload: number;
}

interface SetPrimaryCustomerDetailsForRecommendations {
  type: typeof recommendationsActionNames.SET_PRIMARY_CUSTOMER_DETAILS_FOR_RECOMMENDATIONS;
  payload: SearchOrganizationResponse;
}
interface ResetCursorsForRecommendations {
  type: typeof recommendationsActionNames.RESET_CURSORS_FOR_RECOMMENDATIONS;
}

interface SetTotalCount {
  type: typeof recommendationsActionNames.SET_TOTAL_COUNT;
  payload: number;
}

interface SetSearchInputforRecommendations {
  type: typeof recommendationsActionNames.SET_SEARCH_INPUT_FOR_RECOMMENDATIONS;
  payload: string;
}
interface SetComment {
  type: typeof recommendationsActionNames.SET_COMMENT;
  payload: string;
}
interface SetRecommendationFilters {
  type: typeof recommendationsActionNames.SET_RECOMMENDATION_FILTERS;
  payload: RecommendationFilters;
}

interface ResetRecommendations {
  type: typeof recommendationsActionNames.RESET_RECOMMENDATIONS;
}

export interface SetCustomerRecommendationsSort {
  type: recommendationsActionNames.SET_CUSTOMER_RECOMMENDATIONS_SORT;
  payload: string;
}
export interface SetCustomerRecommendationsOrder {
  type: recommendationsActionNames.SET_CUSTOMER_RECOMMENDATIONS_ORDER;
  payload: string;
}
export interface SetCustomerRecommendationsStaleData {
  type: recommendationsActionNames.SET_CUSTOMER_RECOMMENDATIONS_STALE_DATA;
  payload: boolean;
}

export type CustomerOrganizationActionTypes =
  | AddFeedbackForItems
  | ClearCustomerOrganizationItems
  | ClearMyRecommendations
  | ClearRecommendationItems
  | ResetCursorsForRecommendations
  | SetComment
  | SetCountForItems
  | SetCursorsForItems
  | SetCursorsForRecommendations
  | SetItemsForSelectedRecommendations
  | SetMyRecommendations
  | SetPrimaryCustomerDetailsForRecommendations
  | SetRecommendationFilters
  | DeleteRecommendationFilterValueByType
  | SetSearchInputforRecommendations
  | SetSelectedRecommendation
  | SetTotalCount
  | ResetRecommendations
  | SetAssociationFeedbackRequest
  | SetAssociationFeedbackResponse
  | SetUpdatedCustomerName
  | SetIsLoading<recommendationsActionNames.SET_IS_LOADING_RECOMMENDATIONS>
  | SetIsLoading<recommendationsActionNames.SET_IS_LOADING_COUNT>
  | SetIsLoading<recommendationsActionNames.SET_IS_LOADING_DUPLICATE_CUSTOMERS_RECOMMENDATIONS>
  | SetDuplicateCustomers<recommendationsActionNames.SET_DUPLICATE_CUSTOMERS_RECOMMENDATIONS>
  | SetCustomerRecommendationsSort
  | SetCustomerRecommendationsOrder
  | SetCustomerRecommendationsStaleData;
