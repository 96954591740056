import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  searchIcon: {
    color: CAT_COLOR.MULLED_WINE,
  },
  cancelIcon: {
    color: CAT_COLOR.BLACK,
  },
  errorIcon: {
    color: CAT_COLOR.CHINNABAR,
    marginLeft: '20%',
  },
  autoCompletePadding: {
    padding: '4px 4px 4px 0',
  },
  errorSearchBox: {
    padding: '4px 4px 4px 0',
    '& .MuiTextField-root .MuiInput-root': {
      border: `1px solid ${CAT_COLOR.CHINNABAR}`,
    },
  },
  SearchInput: {
    '& .MuiInput-root:hover': {
      backgroundColor: CAT_COLOR.ALABASTER,
      borderRadius: '2px',
    },
    '& .MuiInput-root .MuiInputBase-input': {
      borderBottom: '1px solid transparent',
      color: CAT_COLOR.BLACK,
      '&:hover': {
        borderBottom: '1px solid transparent',
      },
      '&:focus': {
        borderBottom: '1px solid transparent',
        backgroundColor: 'transparent',
      },
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingRight: '0px !important',
    },
  },
  inputError: {
    color: CAT_COLOR.CHINNABAR,
    fontSize: '12px',
  },
}));
