import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const TopLevelIcon = ({ marginTop, marginLeft, stroke = '#78869E', ...restProps }: iconType) => (
  <SvgIcon
    viewBox="0 0 22 22"
    stroke={stroke}
    {...restProps}
    style={{
      height: 'auto',
      fill: 'none',
      width: restProps.width,
      marginTop: marginTop ?? 0,
      marginLeft: marginLeft ?? 0,
    }}
  >
    <rect
      x="8.24988"
      y="1.25"
      width="5.5"
      height="5.5"
      rx="1.25"
      fill="black"
      stroke="black"
      strokeWidth="1.5"
    />
    <rect
      x="15.2499"
      y="15.25"
      width="5.5"
      height="5.5"
      rx="1.25"
      fill="black"
      stroke="black"
      strokeWidth="1.5"
    />
    <rect
      x="1.24988"
      y="15.25"
      width="5.5"
      height="5.5"
      rx="1.25"
      fill="black"
      stroke="black"
      strokeWidth="1.5"
    />
    <path
      d="M10.4999 7.5C10.4999 10.1667 8.78557 10.7 6.64273 11.2333C4.49988 11.7667 4.64273 13.4 4.49988 15"
      stroke="black"
      strokeWidth="1.5"
    />
    <path
      d="M11.4999 7.5C11.4999 10.1667 13.2142 10.7 15.357 11.2333C17.4999 11.7667 17.357 13.4 17.4999 15"
      stroke="black"
      strokeWidth="1.5"
    />
  </SvgIcon>
);

export default TopLevelIcon;
