import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const ProfileImageSecondary = (props: iconType) => {
  return (
    <SvgIcon
      {...props}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14.5" cy="14.5" r="12.5" fill="black" />
      <path
        d="M11.7391 17.3912C11.7391 17.3912 10.0435 16.826 10.0435 13.9999C9.10522 13.9999 9.10522 11.7391 10.0435 11.7391C10.0435 11.366 8.34783 7.21736 11.1739 7.78257C11.7391 5.52172 17.9565 5.52172 18.5217 7.78257C18.9174 9.35387 17.9565 11.4565 17.9565 11.7391C18.8948 11.7391 18.8948 13.9999 17.9565 13.9999C17.9565 16.826 16.2609 17.3912 16.2609 17.3912V20.2173C19.0643 21.2686 21.8339 22.139 23.2583 23.1337C25.5757 20.7825 27 17.5608 27 13.9999C27 6.82171 21.1783 1 14 1C6.82174 1 1 6.82171 1 13.9999C1 17.5608 2.43565 20.7938 4.76435 23.1451C6.26783 22.1051 9.22956 21.1668 11.7391 20.2173V17.3912Z"
        fill="black"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M4.76465 23.1451C7.12726 25.5303 10.3942 26.9999 14.0003 26.9999C17.6177 26.9999 20.896 25.519 23.2586 23.1338"
        fill="black"
      />
      <path
        d="M4.76465 23.1451C7.12726 25.5303 10.3942 26.9999 14.0003 26.9999C17.6177 26.9999 20.896 25.519 23.2586 23.1338"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinejoin="round"
        fill="black"
      />
    </SvgIcon>
  );
};

export default ProfileImageSecondary;
