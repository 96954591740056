import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  filterButton: {
    padding: theme.spacing(0.75),
    border: 'none',
    fontWeight: 100,
    '&:disabled': {
      border: 'none',
      backgroundColor: CAT_COLOR.WHITE,
    },
    '&:hover': {
      border: 'none',
      backgroundColor: CAT_COLOR.WHITE_SMOKE,
    },
  },
  statusHeader: {
    fontWeight: 600,
    fontSize: '12px',
    marginRight: theme.spacing(0.5),
  },
  clearAll: {
    marginTop: theme.spacing(0.75),
    color: CAT_COLOR.WATER_BLUE,
    fontSize: '12px',
    cursor: 'pointer',
  },
  firstColValue: {
    paddingLeft: '17px',
    display: 'flex',
  },
  standardText: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
  },
  boldRowText: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  smallRowText: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
  },
  tabsContainer: {
    borderBottom: 1,
    borderColor: 'divider',
  },
}));
