import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  drawer: {
    paddingTop: theme.spacing(8),
    flexShrink: 0,
    height: '100%',
  },
  drawerOpen: {
    backgroundColor: theme.palette.primary.contrastText,
    width: theme.spacing(32),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: theme.palette.primary.contrastText,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(10),
  },
  content: {
    flexGrow: 1,
    padding: 0,
    height: 'calc(100vh - 65px)',
    overflow: 'auto',
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  rightsInfo: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    fontSize: '14px',
    textAlign: 'center',
    color: CAT_COLOR.BLACK_OLIVE,
  },
}));
