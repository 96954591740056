import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const ProspectAccountIcon = ({ marginTop, marginLeft, ...restProps }: iconType) => (
  <SvgIcon
    {...restProps}
    style={{
      height: 'auto',
      width: restProps.width ?? '21',
      marginTop: marginTop ?? 0,
      marginLeft: marginLeft ?? 0,
    }}
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M17.8489 10C17.8489 14.2923 14.3531 17.7727 10.0419 17.7727C5.7307 17.7727 2.23489 14.2923 2.23489 10C2.23489 5.70774 5.7307 2.22729 10.0419 2.22729C14.3531 2.22729 17.8489 5.70774 17.8489 10Z"
      stroke="#43485C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M10.0419 0.5V4.59361"
      stroke="#43485C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.5 10H4.61169"
      stroke="#43485C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0419 19.4999V15.4062"
      stroke="#43485C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5836 10H15.4719"
      stroke="#43485C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5117 13.4634H6.57211C6.57211 11.5548 8.12484 10.0089 10.0419 10.0089C11.959 10.0089 13.5117 11.5635 13.5117 13.4634Z"
      stroke="#43485C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M10.0419 9.99994C11.0001 9.99994 11.7768 9.22662 11.7768 8.27268C11.7768 7.31873 11.0001 6.54541 10.0419 6.54541C9.08374 6.54541 8.30701 7.31873 8.30701 8.27268C8.30701 9.22662 9.08374 9.99994 10.0419 9.99994Z"
      stroke="#43485C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default ProspectAccountIcon;
