export enum userLeadsActionNames {
  SET_ACTIVE_USER_LEAD = 'SET_ACTIVE_USER_LEAD',
  SET_USER_LEADS_FILTERS = 'SET_USER_LEADS_FILTERS',
  CLEAR_USER_LEADS_FILTERS = 'CLEAR_USER_LEADS_FILTERS',
}

export enum UserLeadsFiltersFields {
  selectedAccount = 'selectedAccount',
  status = 'status',
  dateRange = 'dateRange',
}

export type FiltersDateType = {
  startDate: Date | undefined;
  endDate: Date | undefined;
  selectedButton: number | undefined;
};

//TODO: Define types when it is known
export interface UserLeadsState {
  selectedUserLead: any;
  filters: UserLeadsFilters;
}
export type UserLeadsFilters = {
  // orderByRules: Array<string>; Uncomment and complete once API is ready
  // sortByRules: Array<string>;
  selectedAccount: string;
  status: string[];
  dateRange: FiltersDateType;
};

interface SetUserLead {
  type: typeof userLeadsActionNames.SET_ACTIVE_USER_LEAD;
  payload: any;
}

interface SetFilterValues {
  type: typeof userLeadsActionNames.SET_USER_LEADS_FILTERS;
  payload: UserLeadsFilters;
}

interface ClearUserLeadsFilters {
  type: typeof userLeadsActionNames.CLEAR_USER_LEADS_FILTERS;
}

export type UserLeadsActionTypes = SetUserLead | SetFilterValues | ClearUserLeadsFilters;
