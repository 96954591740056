import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
  },
  box: {
    backgroundColor: theme.palette.background.paper,
    zIndex: 5000,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  title: {
    fontFamily: 'lato-hairline',
    fontSize: '60px',
    paddingLeft: theme.spacing(6),
    paddingBottom: '0px',
    paddingTop: theme.spacing(3),
    textTransform: 'uppercase',
  },
  message: {
    fontSize: '16px',
  },
}));
