export const userLeadsTableData = [
  {
    email: 'firslastname@gmail.com',
    username: '',
    partyNumber: '1231283752',
    lastName: 'Adams',
    firstName: 'John',
    partyName: '',
    accountDetails: {
      accountEnrollmentTime: new Date('2021-06-01'),
    },
    status: 'PENDING',
  },
  {
    email: 'firslastname@gmail.com',
    username: 'aliweseman',
    partyNumber: '',
    lastName: 'Madison',
    firstName: 'James',
    partyName: '',
    accountDetails: {
      accountEnrollmentTime: new Date('2020-05-01'),
    },
    status: 'PENDING',
  },
  {
    email: 'firslastname@gmail.com',
    username: 'bigMonroe',
    partyNumber: '',
    lastName: 'Monroe',
    firstName: 'James',
    partyName: '',
    accountDetails: {
      accountEnrollmentTime: new Date('2019-09-01'),
    },
    status: 'PENDING',
  },
  {
    email: 'firslastname@gmail.com',
    username: 'jacksonWill',
    partyNumber: '1231283752',
    lastName: 'Jackson',
    firstName: 'Andrew',
    partyName: 'customer4',
    accountDetails: {
      accountEnrollmentTime: new Date('2022-03-01'),
    },
    status: 'IGNORED',
  },
  {
    email: 'firslastname@gmail.com',
    username: 'greatWashington',
    partyNumber: '1231283752',
    lastName: 'Washington',
    firstName: 'George',
    partyName: 'customer5',
    accountDetails: {
      accountEnrollmentTime: new Date('2024-03-01'),
    },
    status: 'COMPLETED',
  },
];
