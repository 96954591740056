import { AppThunkDispatch, AppState } from '..';
import { KeyValuePair } from '../../entities/user-preferences-v2/keyValuePair';
import UserPreferences from '../../services/user-preferences';
import { showNotification } from '../../utils/util';
import { globalPreferenceActionNames } from './types';
import i18n from 'i18next';
import TEXT from "../../globals/translation-map";

const fetchGlobalPreferencesCache = () => {
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const {
      login: { authInfo, environmentVariables },
      globalPreferences,
    } = getState();

    if (!globalPreferences?.languages?.length) {
      try {
        dispatch({
          type: globalPreferenceActionNames.SET_IS_LOADING_GLOBAL_PREF,
          payload: true,
        });
        const preferenceService = new UserPreferences(
          authInfo,
          environmentVariables.userPreferencesV2,
        );

        const response = await preferenceService.getGlobalPreferences();
        if (response.status === 200) {
          const languageListValues = response.data.properties.languages.map(
            (language: KeyValuePair, index: number) => {
              return {
                title: language.value,
                code: language.key,
                index: index,
              };
            },
          );

          dispatch({
            type: globalPreferenceActionNames.SET_GLOBAL_PREFERENCE,
            payload: {
              defaults: response.data.defaults,
              languages: languageListValues,
            },
          });
        }
      } catch {
        showNotification(
          dispatch,
          'error',
          i18n.t(TEXT.COMMON.ERRORS.GENERIC)
        );
      }
      dispatch({
        type: globalPreferenceActionNames.SET_IS_LOADING_GLOBAL_PREF,
        payload: false,
      });
    } else {
      Promise.resolve();
    }
  };
};

const fetchUserGlobalPreferences = () => {
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const {
      login: { authInfo, environmentVariables },
    } = getState();

    try {
      dispatch({
        type: globalPreferenceActionNames.SET_IS_LOADING_GLOBAL_PREF,
        payload: true,
      });
      const preferenceService = new UserPreferences(
        authInfo,
        environmentVariables.userPreferencesV2,
      );

      const response = await preferenceService.getUserGlobalPreferences();
      const defaultPreferences = response.data?.defaults;
      const userProvided = response.data?.userProvided || {};

      i18n.changeLanguage(userProvided?.language || defaultPreferences.language);

      if (response.status === 200) {
        dispatch({
          type: globalPreferenceActionNames.SET_USER_GLOBAL_PREFERENCE,
          payload: {
            userPreferences: {
              ...defaultPreferences,
              ...userProvided,
            },
          },
        });
      }
    } catch {
      showNotification(
        dispatch,
        'error',
          i18n.t(TEXT.COMMON.ERRORS.GENERIC)
      );
    }
    dispatch({
      type: globalPreferenceActionNames.SET_IS_LOADING_GLOBAL_PREF,
      payload: false,
    });
  };
};

export { fetchGlobalPreferencesCache, fetchUserGlobalPreferences };
