import { RefObject, useEffect } from 'react';

/* pass multiple refs as array in case there is need to target different part
 * of dom elements
 */
function useOutsideClick(refs: Array<RefObject<HTMLElement>>, fn: Function) {
  useEffect(() => {
    /**
     * execute fn if clicked on outside of element(s)
     */

    function handleClickOutside(event: Event) {
      const testItems = refs.filter((item: RefObject<HTMLElement>) => {
        const testnull = item.current!;
        return testnull && !testnull.contains(event.target as HTMLElement) ? true : false;
      });
      if (testItems.length === refs.length) fn();
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [...refs]);
}

export default useOutsideClick;
