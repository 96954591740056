import React from 'react';
import { Box } from '@mui/material';
import LoadingIcon from '../../assets/icons/loading';
import { CatLoadingIndicator } from 'blocks-react/bedrock/components/LoadingIndicator';
import useStyles from './styles';
import { UI_BLOCKS, useUIBlocks } from '../../hooks/use-ui-blocks';

type LoadingProperties = {
  height?: string | number;
  width?: string | number;
  display?: 'block' | 'inline-block' | 'inline';
  small?: boolean;
};

const Loading = (props: LoadingProperties) => {
  const { height = 50, width = 100, display, small } = props;
  const { classes } = useStyles();
  const blocksSpinnerEnabled = useUIBlocks(UI_BLOCKS.SPINNER);

  return blocksSpinnerEnabled ? (
    <CatLoadingIndicator data-testid="cat-loading" small={small} />
  ) : (
    <Box data-testid="app-loading" className={classes.loading} display={display}>
      <LoadingIcon height={height} width={width} />
    </Box>
  );
};

export default Loading;
