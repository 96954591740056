import { makeStyles } from 'tss-react/mui';
import { keyframes } from 'tss-react';

export default makeStyles()((theme) => ({
  loading: {
    '& svg': {
      overflow: 'hidden',

      '& g': {
        '& rect': {
          animation: `${keyframes`
         100% {
          height: 2.438rem;
           transform: translateY(0);
            opacity: 0.2;
          }
          0% {
              opacity: 0.2;
              height: 2.438rem;
              transform: translateY(0);

          }
          50% {
            height: 5.625rem;
            transform: translateY(-1.563rem);
            opacity: 0.8;
          }
          `} 2s infinite linear`,
          '&:nth-of-type(1)': { animationDelay: '0s' },
          '&:nth-of-type(2)': { animationDelay: '0.2s' },
          '&:nth-of-type(3)': { animationDelay: '0.4s' },
          '&:nth-of-type(4)': { animationDelay: '0.6s' },
          '&:nth-of-type(5)': { animationDelay: '0.8s' },
          '&:nth-of-type(6)': { animationDelay: '1s' },
          '&:nth-of-type(7)': { animationDelay: '1.2s' },
        },
      },
    },
  },
}));
