import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const LinkIcon = (props: iconType) => {
  const { stroke = 'black', width = '20px', height = '20px' } = props;
  return (
    <SvgIcon
      {...props}
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="0 0 20 20"
      style={{ fill: 'none', width, height }}
    >
      <path
        d="M11.5717 9.39034L11.6462 9.4606C12.2926 10.0695 13.345 10.0695 13.9914 9.4606L17.2728 6.36911C18.2424 5.45572 18.2424 3.96463 17.2728 3.05904L15.8144 1.68504C14.8449 0.771652 13.2621 0.771652 12.3009 1.68504L9.01939 4.77653C8.37303 5.38546 8.37303 6.37692 9.01939 6.98585L9.09397 7.05611"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.42834 8.60899L7.35377 8.53873C6.70741 7.9298 5.655 7.9298 5.00864 8.53873L1.72715 11.6302C0.757617 12.5436 0.757617 14.0347 1.72715 14.9403L3.1856 16.3143C4.15514 17.2277 5.73787 17.2277 6.69912 16.3143L9.98061 13.2228C10.627 12.6139 10.627 11.6224 9.98061 11.0135L9.90603 10.9432"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.27649 12.0366L12.7235 5.96289"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default LinkIcon;
